import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import BEMultipleSelect from '../../../../../Components/BEMultipleSelect';
import { Form, Select } from 'antd';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import { AllMonths } from '../../../../../Config/Functions/UsefullFunctions';
import { BETextArea } from '../../../../../Components/BEFormItems/BETextArea';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import FileUpload from '../../../../../Components/BEFileUpload/FileUpload';
import { postMetricsData, editMetricsData, patchMetricsStatus, postMetricsStatus, postDataLabels, deleteDataLabels } from '../../../Actions';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import dayjs from 'dayjs';
import { rotateArray } from '../../../../../Config/Functions/UsefullFunctions';
import BEConfirmModal from '../../../../../Components/BEConfirmModal';
import { BusinessUnitType } from '../../../../../Redux/Types/entityTypes';
import { BEEyeButton } from '../../../../../Components/BEEyeButton';
import { BEMessage } from '../../../../../Components/BEMessage';
import { getMyMetrics } from '../../../../../views/Actions/OnboardingStep4Actions';
import { MyMetric } from '../../../../../Redux/Types/dataManagerTypes';

export interface INewDataEntryProps {
    open: boolean
    setOpen: Function
    my_metrics: number
    isEdit?: boolean
    values?: any
    metric: any;
    isDerivedMetricsEntry?: boolean;
    childMetricsData?: MyMetric[];
}

export const getDateBYMonthofEntry = (month: string, year: number, yearEnd: number) => {
    const monthIndex = AllMonths.indexOf(month);
    if (monthIndex >= yearEnd || yearEnd === 12) {
        return new Date(year, monthIndex, 2).toISOString().split('T')[0]
    }
    else {
        return new Date(year + 1, monthIndex, 2).toISOString().split('T')[0]
    }

}

export function NewDataEntry(props: INewDataEntryProps) {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const [file, setFile] = React.useState<any>(null);
    const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
    const [loading, setLoading] = React.useState(false);
    const [isAddAnother, setIsAddAnother] = React.useState<boolean>(false)
    const yearEnd = useTypedSelector(state => state.companies.yearEnd);
    const role = useTypedSelector((state) => state.user.userRole);
    const userID = Number(JSON.parse(localStorage.getItem('userInfo') || '{}').id);
    const currentMonth = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentMonth);
    const curentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    const companyDetails = useTypedSelector(state => state.companies.companyDetails);
    const metricsStatus = useTypedSelector(state => state.dataManager.metrics.metricStatus);
    const myMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.myMetricsPerYear);
    const currentBU = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
    const BusinessUnits = useTypedSelector(state => state.entity.BusinessUnits);
    const [filteredMetrics, setFilteredMetrics] = React.useState<any>([]);
    const [showConfirmSave, setShowConfirmSave] = React.useState(false);
    const [modalMessage, setModalMessage] = React.useState("");
    const BURefs = useTypedSelector(state => state.entity.BusinessUnitContributors?.data);
    const tableDataDM = useTypedSelector(state => state.dataManager.metrics.tableDataDM);
    const [labelChange, setLabelChange] = React.useState<string>("");
    const metrics = useTypedSelector(state => state.onBoarding.metrics.directMetrics);
    const allLabels = useTypedSelector(state => state.dataManager.labels);
    const [labelData, setLabelData] = React.useState<string[]>([]);
    const [optionLabels, setOptionLabels] = React.useState<string[]>([]);
    const [selectedLabelData, setSelectedLabelData] = React.useState<string[]>([]);


    // For the purpose of data entry while adding a derived metric data i.e. the data of one of the leaf nodes
    const [myMetrics, setMyMetrics] = React.useState<number>(props.my_metrics);
    const [metric, setMetric] = React.useState<MyMetric>(props.metric);

    const limitedDecimalCheckValidator = (rule: any, value: any) => {
        if (value && value.toString().split(".")[1]?.length > 2) {
            form.setFields([{
                name: 'cost',
                errors: ['Cost can have only 2 decimal places']
            }])
            return Promise.reject('Cost can have only 2 decimal places');
        }
        else return Promise.resolve();
    }

    const changeMetricStatusToPending = async (values: any) => {
        let currentRowYear = curentYear
        let currentRowMonth = AllMonths.indexOf(values.month) + 1;
        let currentMetricStatus = metricsStatus?.data.filter((item: any) => item.my_metric === metric.id && item.year === currentRowYear && item.month === currentRowMonth && item.business_unit === currentBusinessUnit)[0];
        if ((currentMetricStatus && currentMetricStatus?.status !== 1 && role === 'CONTRIBUTOR') || (currentMetricStatus && currentMetricStatus?.status === 3 && role !== 'CONTRIBUTOR')) {
            await dispatch(patchMetricsStatus(currentMetricStatus?.id, {
                status: 1
            }));
        }
        setLoading(false);
        form.resetFields();
        setFile(null);
        props.setOpen(false);
    }

    const onFinish = async (values: any) => {
        setLoading(true);
        const currentYear = curentYear;

        // const metric = filteredMetrics?.find((item: any) => item.id === props.my_metrics)
        // const minValue = metric?.min_limit
        // const maxValue = metric?.max_limit
        // const limitStatus = metric?.limit_status
        // const limitCrossed: boolean = (values.value > maxValue || values.value < minValue) && limitStatus

        const limitCrossed: boolean = (values.value > metric.max_limit || values.value < metric.min_limit) && metric.limit_status
        let dateOfEntry = getDateBYMonthofEntry(values.month, currentYear, yearEnd);

        {/*if (props.metric.annual || metrics?.data?.find((item) => item.bcode === metric.bcode)?.annual) {
            dateOfEntry = getDateBYMonthofEntry(AllMonths[yearEnd - 1], currentYear, yearEnd);
        }
        const alreadyAssignedBUsForAnnual = fullYearTableData[myMetrics]?.map((item) => Number(item.business_unit));
        const chosenMetric = metrics?.data?.find((item) => item.bcode === metric.bcode);

        if (!chosenMetric?.applicable_to_all && chosenMetric?.annual) {

            if (BusinessUnits?.data?.find((item) => item.id === values.business_unit)?.is_headquarter === false) {
                BEMessage.error('Please select a business unit that is a headquarter for this metric');
                setLoading(false);
                return;
            }
        }

        if ((props.metric.annual || chosenMetric?.annual) && alreadyAssignedBUsForAnnual?.includes(values.business_unit)) {

            if (!chosenMetric?.applicable_to_all) {
                BEMessage.error('Data can be filled only once per HQ-A metric');
                setLoading(false);
                return;
            }

            BEMessage.error('Data already entered for this business unit');
            setLoading(false);
            return;
        }*/}

        // check if for annual metrics, for a business unit, data is already entered for the month
        // if yes restrict the user to enter data for that month

        if (props.metric.annual || metric.annual) {
            const isRepeatedEntry = tableDataDM[myMetrics]
                ?.find((item) => item.business_unit === (currentBusinessUnit === 0 ? values.business_unit : currentBusinessUnit) &&
                    Number(item.date_of_entry.split('-')[1]) === AllMonths.indexOf(values.month) + 1)

            if (isRepeatedEntry) {
                BEMessage.error('Data already entered for this business unit in the month of ' + values.month);
                setLoading(false);
                return;
            }
        }

        const data = {
            ...values,
            my_metric: myMetrics,
            business_unit: currentBusinessUnit === 0 ? values.business_unit : currentBusinessUnit,
            date_of_entry: dateOfEntry,
            year: currentYear,
            status: role === 'CONTRIBUTOR' || limitCrossed ? 1 : 2,
            bcode: metric.bcode,
        }
        delete data.month
        delete data.label
        if (!data.source) delete data.source;
        if (!data.description) delete data.description;
        if (file) data.evidence = file;
        if (!data.cost) delete data.cost;
        if (!props.isEdit || props.values?.status === 3) {
            await dispatch(postMetricsData(data)).then(async (res: any) => {
                changeMetricStatusToPending(values);
                setLoading(false);
                form.resetFields();
                setFile(null);
                props.setOpen(isAddAnother);
                console.log(res)
                const data = selectedLabelData.map((item) => {
                    return {
                        label_name: item.replace(" (New label)", "").trim(),
                        my_metric_data: res?.data.id
                    }
                })
                console.log(data);
                await dispatch(postDataLabels(data))
            })
            // dispatch(setDateSlicerMonthDM(monthIndex+1))
        }
        else {
            delete data.business_unit;
            await dispatch(editMetricsData(props.values.id, data)).then(async (res: any) => {
                setLoading(false);
                form.resetFields();
                setFile(null);
                props.setOpen(false);
                const labels = allLabels?.data.filter((item) => item.my_metric_data === props.values.id).map((item) => item.label_name);
                const labelsToDelete = labels.filter((item) => !selectedLabelData.includes(item)).map((item) => item.replace(" (New label)", "").trim());
                const labelsToAdd = selectedLabelData.filter((item) => !labels.includes(item)).map((item) => item.replace(" (New label)", "").trim());
                const data = labelsToAdd.map((item) => {
                    return {
                        label_name: item,
                        my_metric_data: props.values.id
                    }
                })
                await dispatch(postDataLabels(data));
                Promise.all(labelsToDelete.map(async (item) => {
                    await dispatch(deleteDataLabels(item));
                }))
            })
        }
    }

    // React.useEffect(() => {
    //     console.log(props.metric, myMetrics);
    //     console.log(metrics?.data?.find((item) => item.bcode === metric.bcode));
    //     console.log(metric)
    // }, [metric]);

    React.useEffect(() => {
        setMetric(props.metric);
        setMyMetrics(props.my_metrics);
        const labels = Array.from(new Set(allLabels?.data.map((item) => item.label_name)));
        setOptionLabels(labels);
    }, [props.open]);

    React.useEffect(() => {
        if (myMetricsPerYear?.status === "idle") {
            dispatch(getMyMetrics());
        }
        setFilteredMetrics(myMetricsPerYear?.data);
    }, [myMetricsPerYear]);

    React.useEffect(() => {
        if (props.isEdit) {
            const monthIndex = AllMonths.indexOf(
                dayjs(props.values.date_of_entry).format('MMMM')
            );
            form.setFieldsValue({
                value: props.values.value,
                description: props.values.description,
                source: props.values.source,
                month: AllMonths[monthIndex],
                cost: props.values.cost,
            })

        }
        else {
            if (currentMonth !== 0 && currentMonth < 13)
                form.setFieldsValue({
                    month: AllMonths[currentMonth - 1],
                })
            if (props.isDerivedMetricsEntry)
                form.resetFields();
        }
    }, [props.open, props.isEdit, props.values]);

    React.useEffect(() => {
        const labels = Array.from(new Set(allLabels?.data.map((item) => item.label_name)));
        if (props.isEdit) {
            const selectedLabels = Array.from(new Set(allLabels?.data.filter((item) => item.my_metric_data === props?.values.id).map((item) => item.label_name)));
            setSelectedLabelData(selectedLabels);
        }
        setLabelData(labels);
    }, [props.open, metric]);

    React.useEffect(() => {
        setLabelData(Array.from(new Set([...labelData, ...selectedLabelData].map((item) => item))));
        form.setFieldsValue({
            label: selectedLabelData
        });
    }, [selectedLabelData, props.open]);

    const limitCrossCheck = () => {
        const value = form.getFieldValue('value');
        const metric = filteredMetrics?.find((item: any) => item.id === myMetrics)
        if (metric?.limit_status && value < metric?.min_limit) {
            setShowConfirmSave(true);
            const lowerLimitTitle = `The entered value is below the lower limit set by the
                                    administrator, for this metric the lower limit is ${metric.min_limit} ${metric.unit}.
                                    Please confirm to proceed.`
            setModalMessage(lowerLimitTitle);
        } else if (metric?.limit_status && value > metric?.max_limit) {
            setShowConfirmSave(true);
            const upperLimitTitle = `The entered value is above the upper limit set by the
                                    administrator, for this metric the upper limit is ${metric.max_limit} ${metric.unit}.
                                    Please confirm to proceed.`
            setModalMessage(upperLimitTitle);
        } else {
            form.submit();
        }
    }

    return (
        <BEDrawer
            open={props.open}
            setOpen={props.setOpen}
            heading={
                props.isEdit ? 'Edit Data Entry' :
                    'New Data Entry'
            }
            width="400px"
            footer={
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    {
                        props.isEdit ?
                            <BEButton size='large' className='primary'
                                onClick={() => limitCrossCheck()}
                                loading={loading}
                            >
                                Update
                            </BEButton>
                            :
                            <BEButton size='large' className='primary' onMouseEnter={() => setIsAddAnother(false)}
                                onClick={() => limitCrossCheck()}
                                loading={loading && !isAddAnother}
                            >
                                + Add Entry
                            </BEButton>
                    }
                    <BEButton size='large' onMouseEnter={() => setIsAddAnother(true)} loading={loading && isAddAnother} onClick={() => {
                        props.isEdit ? props.setOpen(false) : limitCrossCheck()
                    }} style={{ marginRight: 10 }}>
                        {props.isEdit ? 'Cancel' : 'Save and Add another'}
                    </BEButton>
                </div>
            }
        >
            <BEConfirmModal title='Limit Exceeded' visible={showConfirmSave} setVisible={setShowConfirmSave}
                onConfirm={() => form.submit()}
                message={modalMessage}
            />
            <Form
                form={form}
                layout='vertical'
                onFinish={onFinish}
            >

                {
                    currentBU === 0 && props.isEdit !== true &&
                    <>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <LableRequired>
                                Business Unit
                            </LableRequired>
                            {/*(props.metric.annual || metric.annual) &&
                                <BEEyeButton title='Business units for annual metrics' discription='For annual metrics, data can be entered once per business unit in a financial year.'></BEEyeButton>*/}
                        </div>
                        <BEMultipleSelect isDataStrArray
                            single name='business_unit'
                            data={
                                role === 'CONTRIBUTOR' ?
                                    BusinessUnits?.data.filter((item: BusinessUnitType) => BURefs.filter((ref: any) => ref.business_unit === item.id && ref.user === userID).length > 0)
                                    : BusinessUnits?.data
                                /*(
                                    props.metric.annual ?
                                        (props.metric.applicable_to_all ?
                                            (currentBusinessGroup ? BusinessUnits?.data
                                                .filter((item) => business_unit_ref?.data.filter((item: any) => item.business_group === currentBusinessGroup)
                                                    .map((item) => Number(item.business_unit)).includes(Number(item.id)))
                                                .filter((item: BusinessUnitType) => !Array.from(new Set(fullYearTableData[props.metric.id]?.map((item) => Number(item.business_unit))))?.includes(Number(item.id)))
                                                : BusinessUnits?.data.filter((item: BusinessUnitType) => !Array.from(new Set(fullYearTableData[props.metric.id]?.map((item) => Number(item.business_unit))))?.includes(Number(item.id))))
                                            : BusinessUnits?.data.filter((item) => item.is_headquarter && (fullYearTableData[props.metric.id]?.map((item) => Number(item.business_unit)))?.includes(Number(item.id)))
                                        ) :
                                        BusinessUnits?.data
                                )*/
                            }
                            loading={BusinessUnits?.status === 'loading'}
                            valueFeild='id' labelFeild='name'
                            rules={[{
                                required: true, message: 'Please select business unit'
                            }]} />
                    </>
                }
                {props?.isDerivedMetricsEntry &&
                    <>
                        <LableRequired>
                            Metric Name
                        </LableRequired>
                        <Form.Item name='metric_name' rules={[{
                            required: true, message: 'Please select metric'
                        }
                        ]}>

                            <BEMultipleSelect
                                isDataStrArray
                                single
                                data={props?.childMetricsData || []}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select metric',
                                    },
                                ]}
                                valueFeild='id'
                                labelFeild='title'
                                onChange={(item: any) => {
                                    setMyMetrics(item);
                                    const selectedBcode = myMetricsPerYear?.data?.find((metric: any) => metric.id === item)?.bcode;
                                    const unit: string = metrics?.data?.find((metric: any) => metric.bcode === selectedBcode)?.unit as string;
                                    setMetric({ ...myMetricsPerYear?.data?.find((metric: any) => metric.id === item) as MyMetric, unit: unit });
                                }}
                            />
                        </Form.Item>
                    </>
                }
                {
                    /*(!props.metric.annual && !metrics?.data?.find((item) => item.bcode === metric.bcode)?.annual)
                    && */
                    <>
                        <LableRequired>
                            Month
                        </LableRequired>
                        <BEMultipleSelect isDataStrArray single name='month' data={rotateArray(AllMonths, yearEnd, true)} rules={[
                            {
                                required: true,
                                message: 'Please select month',
                            },
                        ]} />
                    </>
                }
                <LableRequired>
                    Value ({metric.unit})
                </LableRequired>
                <Form.Item name='value' rules={[{
                    required: true, message: 'Please enter value'
                }
                ]}>
                    <BEInput type='number' />
                </Form.Item>

                <p>
                    Description
                </p>
                <Form.Item name='description'>
                    <BETextArea rows={4} placeholder='Description of the entry' form={form} />
                </Form.Item>
                <p>
                    Cost ({companyDetails?.data?.currency})
                </p>
                <Form.Item name='cost' rules={[
                    { message: 'Please select source', required: false },
                    { validator: limitedDecimalCheckValidator }
                ]}>
                    <BEInput />
                </Form.Item>
                <p>
                    Labels
                </p>
                <Form.Item name='label'>
                    <Select
                        showSearch
                        onSearch={(val) => {
                            setLabelChange(val);
                        }}
                        onBlur={() => {
                            {
                                if (labelChange) {
                                    setSelectedLabelData(Array.from(new Set([...selectedLabelData, labelChange + " (New label)"])));
                                }
                            }
                        }}
                        mode='multiple'
                        onChange={(val) => {
                            setSelectedLabelData(val);
                            setLabelChange("");
                        }}
                        value={selectedLabelData}
                    >
                        {labelChange === "" ?
                        optionLabels.map((item, index) =>
                            <Select.Option key={index} value={item}>
                                {item}
                            </Select.Option>
                        )
                        :
                        [...optionLabels, labelChange].map((item, index) =>
                            <Select.Option key={index} value={item}>
                                {item === labelChange ? item + " (New label)" : item}
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>

                <p>
                    Evidence {
                        props.isEdit && props.values.evidence &&
                        <a href={props.values.evidence} target='_blank' style={{ marginLeft: 10 }}>
                            - View Previous
                        </a>
                    }
                </p>
                {
                    props.open &&

                    <FileUpload
                        required={false}
                        text='Upload Evidence'
                        hint='File can be in any format up to 5MB in size'
                        fileSize={5000000}
                        fileType='any'
                        handleFileChange={(file: any) => {
                            setFile(file);
                        }} />}
            </Form>

        </BEDrawer>
    );
}
