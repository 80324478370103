import * as React from 'react';
import './style.scss';
import AddIcon from '../BEIcons/AddIcon';
import { PrimaryTheme } from '../../Config/Theme/theames';
import SubtractIcon from '../BEIcons/SubtractIcon';
import { GlobalLoader } from '../GlobalLoader';
export interface IBESectionDropDownProps {
    data: any[]; // check commented code at the end of this file for example
    loading?: boolean;
}

export function BESectionDropDown(props: IBESectionDropDownProps) {
    const [activeSubSection, setActiveSubSection] = React.useState<number>(-1);
    const [selectedSection, setSelectedSection] = React.useState<number>(-1);
    // const [openedTab, setOpenedTab] = React.useState<number[]>([0]);

    React.useEffect(() => {
        if (props?.data?.length > 0) {
            setSelectedSection(0);
            props?.data[0]?.onClick();
        }
    }, [props?.data]);

    React.useEffect(() => {
        let firstSubSection = props?.data[selectedSection]?.subSections?.[0];
        if(firstSubSection) {
            setActiveSubSection(selectedSection + 0.0);
            firstSubSection.onClick();
        }
    }, [selectedSection]);


    return (
        <>
            {props.loading ? <GlobalLoader height='20vh' /> :
                (props?.data?.map((item: any, index: number) =>
                    <div key={index} className={`main-tab-container ${
                        // openedTab.includes(index) && 
                        selectedSection === index ? `active` : ``}`}>
                        <div
                            className={`main-tab ${
                                // openedTab.includes(index) && 
                                selectedSection === index ? `active` : ``}`}
                            onClick={() => {
                                setSelectedSection(index);
                                setActiveSubSection(-1);
                                item.onClick();
                                // !item?.subSections && item.onClick();
                                // const tabPresent = openedTab.includes(index);
                                // if (tabPresent) {
                                //     item?.subSections && setOpenedTab(openedTab.filter((item) => item !== index));
                                // } else {
                                //     item?.subSections && item.onClick();
                                //     setOpenedTab([...openedTab, index]);
                                // }
                            }}
                        >
                            <span>{item.section}</span>
                            {item?.subSections &&
                                <div
                                    className='icon'
                                //     onClick={() => {
                                //         const tabPresent = openedTab.includes(index);
                                //         if (tabPresent) {
                                //             setOpenedTab(openedTab.filter((item) => item !== index));
                                //         } else {
                                //             setOpenedTab([...openedTab, index]);
                                //         }
                                // }}
                                >
                                    {
                                        // openedTab.includes(index)
                                        selectedSection !== index &&
                                        // ? <SubtractIcon fill={PrimaryTheme.primaryGray} inheritSize /> :
                                        <AddIcon stroke={PrimaryTheme.primaryGray} inheritSize />
                                    }
                                </div>}
                        </div>
                        {
                            //    openedTab.includes(index)
                            selectedSection === index && item?.subSections &&
                            <div className='sub-tabs-container'>
                                {item.subSections?.map((subSection: any, indexSub: number) =>
                                    <div
                                        key={index}
                                        className={`sub-tab ${activeSubSection === (index + indexSub / Math.pow(10, (indexSub.toString().length))) ? `active` : ``}`}
                                        onClick={() => {
                                            setSelectedSection(index);
                                            setActiveSubSection(index + indexSub / Math.pow(10, (indexSub.toString().length)));
                                            subSection.onClick();
                                        }}>
                                        {subSection.name}
                                    </div>)}
                            </div>
                        }
                    </div>
                ))
            }
        </>
    );
}



// Demo data to send:

// const data = [
//     {
//         section: 'Section A',
//         onClick: () => {setRightBodyContent('Section A')},
//     },
//     {
//         section: 'Section B',
//         subSections: [
//             { name: 'Sub Section D', onClick: () => { setRightBodyContent('Sub Section D')}},
//             { name: 'Sub Section E', onClick: () => { setRightBodyContent('Sub Section E')}},
//             { name: 'Sub Section F', onClick: () => { setRightBodyContent('Sub Section F')}},
//         ],
//         onClick: () => {setRightBodyContent('Section B')},
//     },
//     {
//         section: 'Section C',
//         subSections: [
//             { name: 'Sub Section G', onClick: () => { setRightBodyContent('Sub Section G')}},
//             { name: 'Sub Section H', onClick: () => { setRightBodyContent('Sub Section H')}},
//             { name: 'Sub Section I', onClick: () => { setRightBodyContent('Sub Section I')}},
//         ],
//         onClick: () => {setRightBodyContent('Section C')},
//     }
// ]
