import * as React from 'react';
import { BESectionDropDown } from '../../../../../../Components/BESectionDropDown';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { filterDataForLeftSection } from '../../../../Actions/filterActions';
import { setSelectedTemplateDimension, setSelectedTemplateSection } from '../../../../../../Redux/ReportingReducer';

export interface ILeftSectionEditReportProps {
}

export function LeftSectionEditReport (props: ILeftSectionEditReportProps) {
  const selectedTopics = useTypedSelector(state => state.reporting.selectedTemplateTopics)
  const currentReport = useTypedSelector(state => state.reporting.currentReport)
  const assignedDepartmentByReportTopics = useTypedSelector(state => state.reporting.assignedDepartmentByReportTopics)
  const currentDepartmentReporting = useTypedSelector(state => state.reporting.DepartmentTypeReporting)
  const currentGRESBSection = useTypedSelector(state => state.reporting.currentGRESBSection)
  const dispatch = useAppDispatch()
  const [data, setData] = React.useState<any>([])

  const getFilteredData = async () => {
    let res = await dispatch(filterDataForLeftSection(selectedTopics?.data));
    // const sectionsByDepartment = assignedDepartmentByReportTopics?.data
    // .filter((item: any) => (item.department === currentDepartmentReporting || currentDepartmentReporting === 'All') && item.report === currentReport?.id)
    // .map((item: any) => item.section);
    // setData(currentDepartmentReporting === 'All' ? res.sort((a, b) => a.section.localeCompare(b.section)) : res.filter((item: any) => sectionsByDepartment.includes(item.section)).sort((a, b) => a.section.localeCompare(b.section)));
    setData(res)
  }
  React.useEffect(() => {
    if(selectedTopics?.status === 'success') {
      getFilteredData();
    }
  }, [selectedTopics, currentDepartmentReporting, currentGRESBSection]);


  return (
    <div>
      <BESectionDropDown data={data} />
    </div>
  );
}
