import { Modal } from 'antd';
import * as React from 'react';
import { BEButton } from '../../../../../../../../../../../Components/BEFormItems/BEButton';
import '../UploadWarningModal/styles.scss';

export interface IRejectedDataWarningModalProps {
    open: boolean;
    onCancel: Function;
    errorPercent: any;
    onDownload?: Function;
}

export function RejectedDataWarningModal(props: IRejectedDataWarningModalProps) {
    return (
        <Modal className="modal" width={"30%"} footer={null} open={props.open} onCancel={() => props.onCancel(false)}>
            <h2>Warning: {props.errorPercent}% Errors Found</h2>
            <br />
            <p>
                Kindly download the feedback file by clicking the download button below and reupload the file after correcting mistakes.
            </p>
            <br />
            <div className="note" >
                <p>Please note : The downloaded excels will have new columns (remarks & status) , delete them  while reuploading the file after corrections.</p>
            </div>
            <div className="close-btn">
                <BEButton
                    className='primary'
                    onClick={() => props?.onDownload && props?.onDownload()}
                >
                    Download and close
                </BEButton>
                <BEButton onClick={() => props.onCancel(false)}>
                    Close
                </BEButton>
            </div>
        </Modal>
    );
}
