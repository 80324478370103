import * as React from 'react';
import { StepCollapse } from '../../../../../../Components/BECollapse/StepCollapse';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';
import { AddStakeHolder } from '../../AddStakeholder';
import { BETable } from '../../../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { getGlobalStakeholders, getMaterialityStakeholders,deleteStakeholderById } from '../../../../Actions/matirialityActions';
import { BEMessage } from '../../../../../../Components/BEMessage';
import AddStakeHolderToAssessment from '../../AddStakeholderToAssessment';

export interface IStep3Props {
  open: boolean;
  setOpen?: Function
  onCancle: Function
}

export function Step3 (props: IStep3Props) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false)
  const stakeholders = useTypedSelector((state) => state.matiriality.stakeholders);
  // const globalStakeholders = useTypedSelector(state => state.matiriality.globalStakeholders)
  const currentSurvey = useTypedSelector((state) => state.matiriality.currentMaterialitySurvey);

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },{
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },{
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },{
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    // {
    //   title: 'Organization',
    //   dataIndex: 'organization',
    //   key: 'organization',
    // },
    {
      title: 'External',
      dataIndex: 'internalExternal',
      key: 'external',
      render: (value, record) => {
        return value === true ? "Yes" : "No";
      },
    }
  ]

  React.useEffect(() => {
    if(currentSurvey)
    dispatch(getMaterialityStakeholders(currentSurvey?.id || 0))
    // dispatch(getGlobalStakeholders())
  }, [currentSurvey]);

  return (
    <div>
      <StepCollapse
      step={3}
      heading='Select Stakeholders'
      subheading='Select the topics which you want to include in this survey, and also define their order of priority for the company'
      open={props.open}
      setOpen={props.setOpen}
      disabled={!currentSurvey || !currentSurvey?.topicsChosen}
      >
        <div style={{display:'flex',justifyContent:'end'}} className='stpe-3-materiality'>
            <BEButton
            onClick={()=>setOpen(true)}
            className='primary' size='large'>+ Add Stakeholder</BEButton>
        </div>
        <div style={{padding:'1rem',paddingTop:0}}>
          <BETable
          handleDelete={async (stakeholder:any)=>{
            await dispatch(deleteStakeholderById(stakeholder.id))
            dispatch(getMaterialityStakeholders(currentSurvey?.id || 0))
          }}
          columns={columns}
          data={stakeholders?.data}
          loading={stakeholders?.status==='loading'}
          pagination
          />
        </div>
        <AddStakeHolderToAssessment open={open} setOpen={setOpen}/>
        <div>
           <BEButton onClick={()=>{
              BEMessage.success('Survey Created Successfully')
              props.onCancle()
           }} size='large'
           className='primary'
           >Save & Next</BEButton>
           <BEButton
            onClick={()=>props.onCancle()}
           size='large' style={{marginLeft:'1rem'}}>
              Cancel
           </BEButton>
        </div>
      </StepCollapse>
    </div>
  );
}
