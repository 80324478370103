import * as React from 'react';
import { optionsListItem } from '../..';
import '../../style.scss';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import CloseIcon from '../../../BEIcons/CloseIcon';

export interface IBESubjectTagListProps {
  titleList: optionsListItem[];
  setTitleList: Function;
}

export interface IBESubjectTagProps {
  value: string;
  label: string;
  setKeyToRemove: Function;
}

export function BESubjectTag(props: IBESubjectTagProps) {
  return (
    <div
      style={{
        padding: "0.5rem",
        backgroundColor: PrimaryTheme.primaryGreyLight,
        borderRadius: "5px",
        display: "flex",
        flexDirection: "row",
        gap: "0.5rem",
        maxWidth: "200"
      }}>
      <span>
        {props.label?.length > 30 ? props.label.slice(0, 30) + "..." : props.label}
      </span>
      <div
        style={{ height: "1.25rem", cursor: "pointer" }}
        onClick={() => props.setKeyToRemove(props.value)}
      >
        <CloseIcon
          inheritSize
          stroke={PrimaryTheme.primaryGray}
        />
      </div>
    </div>
  );
}

export function BESubjectTagList(props: IBESubjectTagListProps) {
  const [titleList, setTitleList] = React.useState<optionsListItem[]>(props.titleList);

  React.useEffect(() => {
    setTitleList(props.titleList);
  }, [props.titleList]);

  const setKeyToRemove = async (key: string) => {
    const newTitleList = titleList?.filter((item) => item.value !== key);
    setTitleList(newTitleList);
    await props.setTitleList(newTitleList);
  };



  return (
    <div style={{
      padding: "0.5rem",
      margin: "0.5rem 0.5rem 1rem 0.5rem",
      width: "100%",
      backgroundColor: PrimaryTheme.primaryGreyLightest,
      borderRadius: "5px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: "0.5rem",
    }}>
      {titleList?.map((item, index) =>
        <BESubjectTag
          setKeyToRemove={setKeyToRemove}
          key={index}
          label={item.label}
          value={item.value}
        />
      )}
    </div>
  );
}
