import React, { useEffect, useState } from 'react'
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { getCompanyDetails } from '../../../views/Actions/OnboardingActions';
import { Card, Col, Row } from 'antd';
import { BEButton } from '../../../Components/BEFormItems/BEButton';
import EditIcon from '../../../Components/BEIcons/EditIcon';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import dayjs from 'dayjs';
import EditCompanyDetails from './EditCompanyDetails';
import { GlobalLoader } from '../../../Components/GlobalLoader';
import ChevronRightIcon from '../../../Components/BEIcons/ChevronRightIcon';
import { navigateTo } from '../../../Navigation/NavigationManager';
import { ChangeDateFormat } from '../../../Config/Functions/UsefullFunctions';
import { EsgMetricsConfigurations } from '../Drawers/EsgMetricsConfigurations/EsgMetricsConfiguration';
import { TopSection } from '../Components/TopSection';
import BEBackButton from '../../../Components/BEBackButton';
import SettingsBackButton from '../Components/BackButton';

function isJson(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const GeneralSettings = () => {
  const dispatch = useAppDispatch()
  const companyDetails = useTypedSelector((state) => state.companies.companyDetails)
  const userRole = useTypedSelector((state) => state.user.userRole)
  const [openMetricsConfig, setOpenMetricsConfig] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)

  useEffect(() => {
    if (companyDetails?.status === 'idle') dispatch(getCompanyDetails());
  }, [companyDetails]);

  return (
    <>
      <TopSection />
      <div style={{ margin: "2rem 1.5rem" ,paddingBottom:'2.5rem'}}>
        {openEdit && <EditCompanyDetails open={openEdit} setOpen={setOpenEdit} />}
          {companyDetails?.status === "loading" ? (
            <GlobalLoader />
          ) : (
            <div>
              <Row style={{ fontWeight: 600 }}>
                <Col span={22} style={{ fontSize: "18px" }}>
                  <SettingsBackButton title='Back'/>
                </Col>
                {
                  userRole !== "CONTRIBUTOR" &&
                  <Col
                    span={2}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <BEButton
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        padding: "1.2rem 1rem",
                      }}
                      onClick={() => setOpenEdit(true)}
                    >
                      <span style={{ height: "1rem" }}>
                        <EditIcon inheritSize stroke={PrimaryTheme.primaryBlack} />
                      </span>{" "}
                      Edit
                    </BEButton>
                  </Col>
                }
              </Row>
              <Row style={{ marginTop: "2rem" }}>
                <Col span={8} style={{ fontWeight: 600 }}>
                  Company Logo
                </Col>
                <Col span={16} style={{ width: "4rem" }}>
                  <img
                    src={
                      companyDetails?.data?.logo
                        ? companyDetails?.data?.logo
                        : "https://placehold.co/400?text=Company+Logo"
                    }
                    alt=""
                    style={{
                      objectFit: "contain",
                      maxHeight: '100px',
                      width: "200px",
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "2rem" }}>
                <Col span={8} style={{ fontWeight: 600 }}>
                  Name
                </Col>
                <Col span={16}>{companyDetails?.data?.firm_name}</Col>
              </Row>
              <Row style={{ marginTop: "2rem" }}>
                <Col span={8} style={{ fontWeight: 600 }}>
                  Year of Incorporation
                </Col>
                <Col span={16}>{companyDetails?.data?.year_of_incorporation}</Col>
              </Row>
              <Row style={{ marginTop: "2rem" }}>
                <Col span={8} style={{ fontWeight: 600 }}>
                  Address
                </Col>
                <Col span={16}>{companyDetails?.data?.address}</Col>
              </Row>
              <Row style={{ marginTop: "2rem" }}>
                <Col span={8} style={{ fontWeight: 600 }}>
                  Industry
                </Col>
                <Col span={16}>
                  {companyDetails?.data?.industry &&
                    isJson(companyDetails?.data?.industry)
                    ? JSON.parse(companyDetails?.data?.industry)
                    : companyDetails?.data?.industry}
                </Col>
              </Row>
              <Row style={{ marginTop: "2rem" }}>
                <Col span={8} style={{ fontWeight: 600 }}>
                  Website
                </Col>
                <Col span={16}>
                  <a href={companyDetails?.data?.website} target="_blank" rel="noopener noreferrer" >
                    {companyDetails?.data?.website
                      ? companyDetails?.data.website
                      : "--"}
                  </a>
                </Col>
              </Row>
              <Row style={{ marginTop: "2rem" }}>
                <Col span={8} style={{ fontWeight: 600 }}>
                  Currency
                </Col>
                <Col span={16}>
                  {companyDetails?.data?.currency}
                </Col>
              </Row>
              <Row style={{ marginTop: "2rem" }}>
                <Col span={8} style={{ fontWeight: 600 }}>
                  Sector
                </Col>
                <Col span={16}>
                  {companyDetails?.data?.sector.slice(0, 1)}{companyDetails?.data?.sector.slice(1)?.toLowerCase()}
                </Col>
              </Row>
              {/* <Row style={{ marginTop: "2rem" }}>
              <Col span={8} style={{ fontWeight: 600 }}>
                Revenue in Last Finanacial Year
              </Col>
              <Col span={16}>
                {companyDetails?.data?.currency} {companyDetails?.data?.revenue}
              </Col>
            </Row> */}
              <Row style={{ marginTop: "2rem" }}>
                <Col span={8} style={{ fontWeight: 600 }}>
                  Reporting Year End
                </Col>
                <Col span={16}>
                  {companyDetails?.data?.reporting_year_end
                    ? ChangeDateFormat(companyDetails?.data?.reporting_year_end).slice(0, 6)
                    : "N/A"}
                </Col>
              </Row>
            </div>
          )}
        {(userRole === "SUPER_ADMIN" || userRole === "ADMIN" || "ESG_CONSULTANT") && (
            <Row style={{marginTop:'7rem'}}>
              <Col span={16}>
                <div style={{ fontSize: "20px", fontWeight: 600 }}>
                  ESG Metrics Configuration
                </div>
                <div style={{ color: PrimaryTheme.secondaryGray,marginTop:'1rem' }}>
                  You can use this button to edit the selection of ESG Metrics by year or apply the selection of
                  a particular framework. Please be careful while doing this as it will impact calculations across the platform.
                </div>
              </Col>
              <Col
                span={8}
                style={{ display: "flex", justifyContent: "flex-end",alignItems:'center' }}
              >
                <BEButton
                  size="large"
                  className="primary"
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() =>
                    setOpenMetricsConfig(true)
                  }
                >
                  Edit ESG Metric Selection <ChevronRightIcon fill="white" />
                </BEButton>
              </Col>
            </Row>
          
        )}
        <EsgMetricsConfigurations open={openMetricsConfig} setOpen={setOpenMetricsConfig} />
      </div>
    </>
  );
}

export default GeneralSettings