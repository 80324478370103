import * as React from 'react';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { GlobalLoader } from '../../../../Components/GlobalLoader';
import { TopTabsDM } from './TopTabsDM';
import DataEntryIcon from '../../../../Components/BEIcons/DataEntryIcon';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { EsgMetricsConfigurations } from '../../../Settings/Drawers/EsgMetricsConfigurations/EsgMetricsConfiguration';

export interface IDataEntryProps {
}

export function DataEntry(props: IDataEntryProps) {
  const myMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.myMetricsPerYear);
  const combinedSelectedMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.combinedSelectedMetricsPerYear);
  const [companyDetails, setCompanyDetails] = React.useState<boolean>(false);
  const role = useTypedSelector((state) => state.user.userRole);

  if (myMetricsPerYear?.status === 'loading' || combinedSelectedMetricsPerYear.status === 'idle') return <GlobalLoader height='50vh' />

  return (
    <div className='data-entry'>
      {
        combinedSelectedMetricsPerYear?.data?.length === 0 ?
          (
            role !== "ADMIN"
              &&
              role !== "SUPER_ADMIN"
              &&
              role !== "ESG_CONSULTANT"
              ?
              <div className='empty-state-access-roles'>
                <p style={{ color: PrimaryTheme.primaryGreyDark }}>
                  {role === "CONTRIBUTOR" ? "The administrator is yet to assign metrics to your department and for you." :
                    role === "DEPARTMENT_HEAD" ? "The administrator is yet to assign metrics to your department." :
                      "Please contact the System Administrator."}
                </p>
              </div>
              :
              <div className='empty-state main'>
                <div><div className='imgs'><DataEntryIcon fill={PrimaryTheme.primaryGreen} inheritSize /></div></div>
                <p style={{ color: PrimaryTheme.primaryGreyDark }}>Please configure metrics by clicking the button below.</p>

                <BEButton
                  onClick={() => setCompanyDetails(true)}
                  className='primary' size='large'
                >+ Launch Configuration
                </BEButton>
              </div>)
          :
          <TopTabsDM />
      }
      <EsgMetricsConfigurations open={companyDetails} setOpen={setCompanyDetails} />
    </div>
  );
}
