import { Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import { BETable } from "../../../../../../../../Components/BETable";
import { BEButton } from "../../../../../../../../Components/BEFormItems/BEButton";
import { PrimaryTheme } from "../../../../../../../../Config/Theme/theames";
import React from "react";

interface ViewDataModalProps{
	open:boolean;
	setOpen:Function;
	acceptedData:any;
	fileId:number,
}
export default function ViewDataModal(props:ViewDataModalProps){
	const [page, setPage] = React.useState(1);
	const column: ColumnsType<any> = [
		{
			title: 'S.No',
			dataIndex: 'index',
			key: 'index',
			render: (text, record, index) => index + 1,
		},
		{
			title: 'VALUE',
			dataIndex: 'value',
			key: 'value',
			render: (text, record) => {
				return <p>{text}</p>
			}
		},
		{
			title: 'DESCRIPTION',
			dataIndex: 'description',
			key: 'description',
			render: (text, record) => {
				return <p>{text}</p>
			}
		},
		{
			title: 'DATE',
			dataIndex: 'date_of_entry',
			key: 'date_of_entry',
			render: (text, record) => {
				return <p>{text.split('T')[0]}</p>
			}
		},
		{
			title: 'COST',
			dataIndex: 'cost',
			key: 'cost',
			render: (text, record) => {
				return <p>{text}</p>
			}
		},
		{
			title: 'Business unit',
			dataIndex: 'business_unit_name',
			key: 'business_unit_name',
			render: (text, record) => {
				return <p>{text}</p>
			},

		},
	];
	return <>
		<Modal
			open={props.open}
			onCancel={() => props.setOpen(false)}
			width={'80%'}
			footer={null}
		>
			<h2 style={{marginBottom:'1rem'}}>Upload 1 - file #{props.fileId}</h2>
			<div style={{minHeight:'70vh'}}>
			<BETable
			 handleChangePage={(page: number)=> setPage(page)}
			 columns={column} data={props.acceptedData} pagination
			 />
			</div>
			<div style={{display:'flex', gap:'1rem'}}>
				{/* <BEButton style={{color:PrimaryTheme.primaryRedError,borderColor:PrimaryTheme.primaryRedError}}>
					Delete
				</BEButton> */}
				<BEButton onClick={()=>props.setOpen(false)}>
					Cancel
				</BEButton>
			</div>
		</Modal>
	
	</>
}