import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import {
  GET_SCOPE_1_CONFIGURATIONS,
  EDIT_SCOPE_1_CONFIGURATION,
  GET_SCOPE_2_CONFIGURATIONS,
  GET_SCOPE_2_SPECIFICATIONS, EDIT_SCOPE_2_CONFIGURATION,
  RESET_SCOPE_1_CONFIGURATIONS_BY_ID,
  GET_ALL_EMISSION_CALCULATIONS,
  GET_SCOPE_3_EMISSIONS_CALCULATIONS,
} from "../../../Utils/Routes/EmissionsRoutes";
import {
  setScope1Configurations,
  setScope2Configurations,
  setScope2Specifications,
  setScope1calculationForMonths,
  setScope2calculationForMonths,
  setScope3calculationForMonths,
} from "../../../Redux/EmmisionsReducer";
import { BEMessage } from "../../../Components/BEMessage";
import { StatusType } from "../../../Redux/Types/userTypes";
import { RootState } from "../../../Redux/store";
import { shortMonths } from "../../../Config/Data/genralData";
import dayjs from "dayjs";
import { getMonthsByQuarter, getStartDateAndEndDate } from "../../../Config/Functions/UsefullFunctions";


export const resetScope1ConfigurationsById = (id: number) => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(RESET_SCOPE_1_CONFIGURATIONS_BY_ID(id));
    if (res) {
      dispatch(getScope1Configurations());
      BEMessage.success("Configuration reset successfully");
    }
    else {
      BEMessage.error("Couldn't reset configuration");
      console.log(error);
    }
  }
}

export const getAllEmissionCalculations = (year:number) => {
  return async (dispatch: any) => {
    dispatch(setScope1calculationForMonths({ status: "loading", data: [] ,forYear:year }));
    dispatch(setScope2calculationForMonths({ status: "loading", data: [] ,forYear:year }));
    const [res, error] = await handleAPICall(GET_ALL_EMISSION_CALCULATIONS(year));
    if (res) {
      const scope1Data = res?.data.filter((item: any) => item.scope === 'Scope 1');
      const scope2Data = res?.data.filter((item: any) => item.scope === 'Scope 2');
      dispatch(setScope1calculationForMonths({ status: "success", data: scope1Data ,forYear:year }));
      dispatch(setScope2calculationForMonths({ status: "success", data: scope2Data ,forYear:year }));
    }
    else {
      dispatch(setScope1calculationForMonths({ status: "error", data: [] ,forYear:0 }));
      dispatch(setScope2calculationForMonths({ status: "error", data: [] ,forYear:0 }));
    }
  }
}

export const getScope3EmissionCalculations = (year:number) => {
  return async (dispatch: any) => {
    dispatch(setScope3calculationForMonths({ status: "loading", data: [] ,forYear:year }));
    const [res, error] = await handleAPICall(GET_SCOPE_3_EMISSIONS_CALCULATIONS(year));
    if (res) {
      dispatch(setScope3calculationForMonths({ status: "success", data: res?.data ,forYear:year }));
    }
    else {
      dispatch(setScope3calculationForMonths({ status: "error", data: [] ,forYear:0 }));
    }
  }
}


export const getScope1Configurations = () => {
  return async (dispatch: any) => {
    dispatch(setScope1Configurations({
      status: "loading",
      data: []
    }));
    const [data, error] = await handleAPICall(GET_SCOPE_1_CONFIGURATIONS());
    if (data) {
      dispatch(setScope1Configurations({
        status: "success",
        data: data?.data
      }));
    }
    else {
      console.log(error);
      dispatch(setScope1Configurations({
        status: "error",
        data: []
      }));
    }
  }
}

export const getScope2Configurations = () => {
  return async (dispatch: any) => {
    dispatch(setScope2Configurations({ status: 'loading', data: [] }))
    const [res, error] = await handleAPICall(GET_SCOPE_2_CONFIGURATIONS())
    if (res) {
      dispatch(setScope2Configurations({ status: 'success', data: res?.data }))
    }
    else {
      dispatch(setScope2Configurations({ status: 'error', data: [] }))
      console.log(error)
    }
  }
}

export const editScope1Configuration = (id: number, data: any) => {
  return async (dispatch: any) => {

    const [res, error] = await handleAPICall(EDIT_SCOPE_1_CONFIGURATION(id, data));
    if (res) {
      dispatch(getScope1Configurations());
      BEMessage.success("Metric conversion factors updated successfully");
    }
    else {
      BEMessage.error(res?.msg);
      console.log(error);
    }
  }
}

export const editScope2Configuration = (id: number, data: any) => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(
      EDIT_SCOPE_2_CONFIGURATION(id, data)
    );
    if (res) {
      dispatch(getScope2Configurations());
      BEMessage.success('Metric conversion factors updated successfully')
    } else {
      BEMessage.error("Couldn't update metric conversion factors");
      console.log(error);
    }
  };
};



export const filterDataByMonthOrBU = (filterByMonth: boolean, filterByBU: boolean) => {
  return async (dispatch: any, getState: () => RootState) => {
    const state = getState();
    const yearEnd = state.companies.yearEnd;
    const businessUnitRefs = state.entity.BusinessUnitRefs;
    const currentMonth = state.dataManager.metrics.dateSlicer.currentMonth;
    const currentBU = state.common.homePage.currentBusinessUnit;
    const currentBG = state.common.homePage.currentBusinessGroup;
    const scope1CalculationsByMonth = state.emissions.calculations.scope1calculationForMonths;
    const scope2CalculationsByMonth = state.emissions.calculations.scope2calculationForMonths;
    const scope3CalculationsByMonth = state.emissions.calculations.scope3calculationForMonths;

    let filteredScope1Data: any = []
    let filteredScope2Data: any = []
    let filteredScope3Data: any = []

    if (filterByMonth) {
      let filteredDataByMonth: any = []
      let filteredDataByMonth2: any = []
      let filteredDataByMonth3: any = []
      if (currentMonth === 0) {
        filteredDataByMonth = scope1CalculationsByMonth?.data
        filteredDataByMonth2 = scope2CalculationsByMonth?.data
        filteredDataByMonth3 = scope3CalculationsByMonth?.data
      }
      else if (currentMonth < 13) {
        filteredDataByMonth = scope1CalculationsByMonth?.data.filter((obj: any) => obj.month === currentMonth)
        filteredDataByMonth2 = scope2CalculationsByMonth?.data.filter((obj: any) => obj.month === currentMonth)
        filteredDataByMonth3 = scope3CalculationsByMonth?.data.filter((obj: any) => obj.month === currentMonth)
      }
      else {
        const monthsOfQuarter = getMonthsByQuarter(currentMonth, dayjs(yearEnd).month())
        filteredDataByMonth = scope1CalculationsByMonth?.data.filter((obj: any) => monthsOfQuarter.includes(obj.month))
        filteredDataByMonth2 = scope2CalculationsByMonth?.data.filter((obj: any) => monthsOfQuarter.includes(obj.month))
        filteredDataByMonth3 = scope3CalculationsByMonth?.data.filter((obj: any) => monthsOfQuarter.includes(obj.month))
      }
      filteredScope1Data = filteredDataByMonth
      filteredScope2Data = filteredDataByMonth2
      filteredScope3Data = filteredDataByMonth3
    }
    else{
      filteredScope1Data = scope1CalculationsByMonth?.data
      filteredScope2Data = scope2CalculationsByMonth?.data
      filteredScope3Data = scope3CalculationsByMonth?.data
    }


    if(filterByBU){
      let filteredData = [];
      let filteredData2 = [];
      let filteredData3 = [];
      if (currentBU !== 0) {
        filteredData = filteredScope1Data.filter((obj: any) => obj?.business_unit === currentBU)
        filteredData2 = filteredScope2Data.filter((obj: any) => obj?.business_unit === currentBU)
        filteredData3 = filteredScope3Data.filter((obj: any) => obj?.business_unit === currentBU)
      }
      else if (currentBG !== 0) {
        let BUs = businessUnitRefs?.data.filter((obj: any) => obj.business_group === currentBG).map((obj2: any) => obj2.business_unit)
        filteredData = filteredScope1Data.filter((obj: any) => BUs.includes(obj?.business_unit))
        filteredData2 = filteredScope2Data.filter((obj: any) => BUs.includes(obj?.business_unit))
        filteredData3 = filteredScope3Data.filter((obj: any) => BUs.includes(obj?.business_unit))
      }
      else {
        filteredData = filteredScope1Data
        filteredData2 = filteredScope2Data
        filteredData3 = filteredScope3Data
      }
      filteredScope1Data = filteredData
      filteredScope2Data = filteredData2
      filteredScope3Data = filteredData3
    }
    return {
      'scope1Data': filteredScope1Data,
      'scope2Data': filteredScope2Data,
      'scope3Data': filteredScope3Data
    }
  }
}


export const getScope2Specifications = () => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(GET_SCOPE_2_SPECIFICATIONS());
    if (res) {
      console.log(res)
      dispatch(setScope2Specifications(res?.data))
    }
    else {
      console.log(error)
    }
  }
}