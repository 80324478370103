
import {
  GET_ALL_SUPPLIER_ASSESSMENTS,
  GET_ALL_SUPPLIERS,
  GET_ALL_ASSESMENT_SUPPLIER_REF,
  GET_SUPPLIER_OPTIONS,
  GET_SUPPLIER_QUESTIONS,
  GET_SUPPLIER_ANSWERS_BY_ASSESSMENT,
  GET_SUPPLIER_STATUS,
  GET_SUPPLIER_ASSESSMENT_SCORE,
  ADD_SUPPLIER_ASSESSMENT,
  SEND_SUPPLIER_ASSESSMENT,
  ADD_GLOBAL_SUPPLIER,
  BULK_UPLOAD_GLOBAL_SUPPLIERS_BY_EXCEL,
  BULK_UPLOAD_SUPPLIERS,
  DELETE_GLOBAL_SUPPLIER,
  ADD_SUPPLIER_REFRENCE,
  GET_RISK_METRICS,
  ADD_SUPPLIER_QUESTIONS,
  DELETE_SUPPLIER_ASSESSMENT,
  GET_SUPPLIER_SCORE,
  PATCH_CORE_SUPPLIER_ASSESSMENT,
  GET_BRSR_RISK_METRICS,
  DELETE_SUPPLIER_REFRENCE,
  SEND_REMAINDER_TO_SUPPLIER,
  GET_SUPPLIER_ADDED_QUESTIONS,
  DELETE_SUPPLIER_QUESTIONS
} from "../../../Utils/Routes/SupplierRoutes";
import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import { navigateTo } from "../../../Navigation/NavigationManager";
import { AppDispatch, RootState } from "../../../Redux/store";
import { BEMessage } from "../../../Components/BEMessage";
import { 
  setSuppliersSurveys,
  setSuppliers,
  setSuppliersSurveyRefs,
  setSupplierOptions,
  setSupplierQuestions,
  setSupplierAnswers,
  setSupplierStatus,
  setSupplierResults,
  setCreateSurveyStep,
  setCurrentAssessment,
  setSuppliersRiskMetrics,
  setSupplierScore,
  setAllAnswers,
  setLastAddedSupplier
} from "../../../Redux/SupplierReducer";


export const sendRemainderToSupplier = (body:any) => async (dispatch:AppDispatch) => {
    const [data, error] = await handleAPICall(SEND_REMAINDER_TO_SUPPLIER(body));
    if (data) {
        BEMessage.success("Remainder sent successfully");
    }
    else{
        console.log(error);
    }
}
export const getSupplierScore = (id:any) => async (dispatch:AppDispatch) => {
    dispatch(setSupplierScore({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_SUPPLIER_SCORE(id));
    if (data) {
        dispatch(setSupplierScore({
            status: "success",
            data: data?.data
        }));
    }
    else{
        console.log(error);
        dispatch(setSupplierScore({
            status: "error",
            data: []
        }));
    }
}


export const getSupplierSurveys = () => async (dispatch:AppDispatch) => {
    dispatch(setSuppliersSurveys({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_ALL_SUPPLIER_ASSESSMENTS());
    if (data) {
        dispatch(setSuppliersSurveys({
            status: "success",
            data: data?.data
        }));
    }
    else{
        console.log(error);
        dispatch(setSuppliersSurveys({
            status: "error",
            data: []
        }));
    }
}

export const getSuppliers = () => async (dispatch:AppDispatch) => {
    dispatch(setSuppliers({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_ALL_SUPPLIERS());
    if (data) {
        dispatch(setSuppliers({
            status: "success",
            data: data?.data
        }));
    }
    else{
        console.log(error);
        dispatch(setSuppliers({
            status: "error",
            data: []
        }));
    }
}

export const deleteGlobalSupplier = (id:number) => async (dispatch:AppDispatch) => {
    const [data, error] = await handleAPICall(DELETE_GLOBAL_SUPPLIER(id));
    if (data) {
        BEMessage.success("Supplier deleted successfully");
        dispatch(getSuppliers());
    }
    else{
        console.log(error);
        BEMessage.error("Supplier couldn't be deleted");
    }
}

export const getSupplierSurveyRefs = (id:number) => async (dispatch:AppDispatch) => {
    dispatch(setSuppliersSurveyRefs({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_ALL_ASSESMENT_SUPPLIER_REF(id));
    if (data) {
        dispatch(setSuppliersSurveyRefs({
            status: "success",
            data: data?.data
        }));
    }
    else{
        console.log(error);
        dispatch(setSuppliersSurveyRefs({
            status: "error",
            data: []
        }));
    }
}

export const deleteSupplierRef = (id:number,surveyID:any) => async (dispatch:AppDispatch) => {
    const [data, error] = await handleAPICall(DELETE_SUPPLIER_REFRENCE(id));
    if (data) {
        dispatch(getSupplierSurveyRefs(surveyID));
        BEMessage.success("Supplier deleted successfully");
    }
    else{
        console.log(error);
        BEMessage.error("Supplier couldn't be deleted");
    }
}

export const getSupplierOptions = () => async (dispatch:AppDispatch) => {
    dispatch(setSupplierOptions({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_SUPPLIER_OPTIONS());
    if (data) {
        dispatch(setSupplierOptions({
            status: "success",
            data: data?.data
        }));
    }
    else{
        console.log(error);
        dispatch(setSupplierOptions({
            status: "error",
            data: []
        }));
    }
}

export const getSupplierQuestions = () => async (dispatch:AppDispatch) => {
    dispatch(setSupplierQuestions({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_SUPPLIER_QUESTIONS());
    if (data) {
        dispatch(setSupplierQuestions({
            status: "success",
            data: data?.data
        }));
    }
    else{
        console.log(error);
        dispatch(setSupplierQuestions({
            status: "error",
            data: []
        }));
    }
}

export const getSupplierAnswers = (id:any) => async (dispatch:AppDispatch) => {
    dispatch(setAllAnswers({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_SUPPLIER_ANSWERS_BY_ASSESSMENT(id));
    if (data) {
        dispatch(setAllAnswers({
            status: "success",
            data: data?.data
        }));
    }
    else{
        console.log(error);
        dispatch(setAllAnswers({
            status: "error",
            data: []
        }));
    }
}

export const getSupplierStatus = (id:any) => async (dispatch:AppDispatch) => {
    dispatch(setSupplierStatus({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_SUPPLIER_STATUS(id));
    if (data) {
        dispatch(setSupplierStatus({
            status: "success",
            data: data?.data
        }));
    }
    else{
        console.log(error);
        dispatch(setSupplierStatus({
            status: "error",
            data: []
        }));
    }
}

export const getSupplierResult = (id:any) => async (dispatch:AppDispatch) => {
    dispatch(setSupplierResults({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_SUPPLIER_ASSESSMENT_SCORE(id));
    if (data) {
        dispatch(setSupplierResults({
            status: "success",
            data: data?.data,
            average: data.average,
            percentage: data.percentage,
        }));
    }
    else{
        console.log(error);
        dispatch(setSupplierResults({
            status: "error",
            data: []
        }));
    }
}

export const addCoreSupplierAssessment = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(ADD_SUPPLIER_ASSESSMENT(body))
    if (data) {
        BEMessage.success("BRSR Core Assessment added successfully");
        console.log(data);
        dispatch(setCurrentAssessment(data?.data))
        dispatch(setCreateSurveyStep(2))
        dispatch(getSupplierSurveys());
        return
    }
    else {
        BEMessage.error("BRSR Core Assessment couldn't be added");
        return
    }
}

export const patchCoreSupplierAssessment = (id: any,body: any, ) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(PATCH_CORE_SUPPLIER_ASSESSMENT(body, id))
    if (data) {
        console.log(data)
        dispatch(getSupplierSurveys());
        dispatch(setCurrentAssessment(data?.data))
        dispatch(setCreateSurveyStep(2))
        return true
    }
    else {
        BEMessage.error("BRSR Core Assessment couldn't be updated");
        return false
    }
}

export const deleteSupplierAssessment = (id: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(DELETE_SUPPLIER_ASSESSMENT(id))
    if (data) {
        BEMessage.success("BRSR Core Assessment deleted successfully");
        console.log(data)
        dispatch(getSupplierSurveys());
        dispatch(setCurrentAssessment(null))
        dispatch(setCreateSurveyStep(1))
        return
    }
    else {
        BEMessage.error("BRSR Core Assessment couldn't be deleted");
        return
    }
}
export const inviteSuppliers = (data:any,id:Number) => async (dispatch:AppDispatch) => {
    const [response, error] = await handleAPICall(SEND_SUPPLIER_ASSESSMENT(data,id));
    if (response) {
        BEMessage.success("Invitation sent successfully");
        dispatch(getSupplierStatus(data[0].assessment));
    }
    else{
        console.log(error);
    }
}

export const addGlobalSupplier = (body: any) => async (dispatch: AppDispatch,getState:()=>RootState) => {
    let allSUppliers = getState().supplier.Suppliers?.data;
    if(allSUppliers?.find((supplier:any)=>supplier.email === body.email)){
        BEMessage.error("Supplier email already exists");
        return;
    }
    const [data, error] = await handleAPICall(ADD_GLOBAL_SUPPLIER(body));
    if (data) {
        console.log(data)
        dispatch(setLastAddedSupplier(data?.data[0]))
        BEMessage.success("Supplier added successfully");
        dispatch(getSuppliers());
    }
    else {
        BEMessage.error("Error in adding supplier")
    }
}

export const bulkUploadGlobalSuppliersByExcel = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(BULK_UPLOAD_GLOBAL_SUPPLIERS_BY_EXCEL(body));
    if (data) {
        console.log(data)
        dispatch(getSuppliers());
        return data;
    }
    else {
        return null;
    }
}


export const bulkUploadSuppliers = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(BULK_UPLOAD_SUPPLIERS(body));
    if (data) {
        console.log(data)
        BEMessage.success("Supplier added successfully");
        dispatch(getSuppliers());
    }
    else {
        BEMessage.error("Supplier email already exists")
    }
}

export const addSupplierAssessmentRef = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(ADD_SUPPLIER_REFRENCE(body))
    if (data) {
        BEMessage.success("Suppliers added to assessement")
        dispatch(getSupplierSurveyRefs(body[0].assessment));
        // dispatch(setCreateSurveyStep(4));
        console.log(data)
    }
    else {
        BEMessage.error("Suppliers couldn't be added to assessment")
    }
}


export const getSupsplierRiskMetrics = (id:any) => async (dispatch:AppDispatch) => {
    dispatch(setSuppliersRiskMetrics({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_RISK_METRICS(id));
    if (data) {
        dispatch(setSuppliersRiskMetrics({
            status: "success",
            data: data?.data
        }));
    }
    else{
        console.log(error);
        dispatch(setSuppliersRiskMetrics({
            status: "error",
            data: []
        }));
    }
}

export const addSupplierQuestions = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(ADD_SUPPLIER_QUESTIONS(body))
    if (data) {
        console.log(data)
        return true
    }
    else {
        BEMessage.error("Supplier questions couldn't be added");
        return false
    }
}


export const deleteSupplierQuestions = (id: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(DELETE_SUPPLIER_QUESTIONS(id))
    if (data) {
        console.log(data)
        return true
    }
    else {
        BEMessage.error("Supplier questions couldn't be deleted");
        return false
    }
}

export const getSupplierAddedQuestions = (id: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_SUPPLIER_ADDED_QUESTIONS(id))
    if (data) {
        return data?.data
    }
    else {
        BEMessage.error("Supplier questions couldn't be added");
        return null
    }
}

export const getBRSRRiskMetrics = (id:any) => async (dispatch:AppDispatch) => {
    setAllAnswers({
            status: "loading",
            data: []
        });
    const [data, error] = await handleAPICall(GET_BRSR_RISK_METRICS(id));
    if (data) {
        dispatch(setSuppliers({
            status: "success",
            data: data.suppliers
        }));
        dispatch(setSupplierQuestions({
            status: "success",
            data: data?.data
        }));
        dispatch(setAllAnswers({
            status: "success",
            data: data.answers
        }));
        return true;
    }
    else{
        console.log(error);
        BEMessage.error("Couldn't fetch BRSR Risk Metrics");
        return false;
    }
}

export const bulkUplodSuppliers = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(BULK_UPLOAD_SUPPLIERS(body))
    if (data) {
        console.log(data)
        return true
    }
    else {
        BEMessage.error("Suppliers couldn't be added");
        return false
    }
}





