import * as React from 'react';
import { StepCollapse } from '../../../../../../Components/BECollapse/StepCollapse';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { Col, Radio, Row } from 'antd';
import { SideItem } from '../../../../../../views/Onboarding/Step4/SideFilter/SIdeItem';
import {
  getSupplierQuestions,
  addSupplierQuestions,
  patchCoreSupplierAssessment,
  getSupplierAddedQuestions,
  deleteSupplierQuestions
} from '../../../../Actions/supplierAction';
import './style.scss'
import { GlobalLoader } from '../../../../../../Components/GlobalLoader';
import { ColumnsType } from 'antd/es/table';
import { BETable } from '../../../../../../Components/BETable';
import { Checkbox } from 'antd';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';
import { setCreateSurveyStep } from '../../../../../../Redux/MatirialityReducer';
import { PrimaryTheme } from '../../../../../../Config/Theme/theames';
import { BEInput } from '../../../../../../Components/BEFormItems/BEInput';
import { BEMessage } from '../../../../../../Components/BEMessage';

export interface IStep3Props {
  open: boolean;
  onCancle: Function;
}

export function Step2(props: IStep3Props) {
  const dispatch = useAppDispatch()
  const currentAssessment = useTypedSelector((state) => state.supplier.currentAssessment);
  const supplierQuestions = useTypedSelector((state) => state.supplier.SupplierQuestions);
  const [selectedQuestions, setSelectedQuestions] = React.useState<any>([])
  const [assessmentType, setAssessmentType] = React.useState('CUSTOM')
  const [currentPillar, setCurrentPillar] = React.useState<any>(null)
  const [loading, setLoading] = React.useState(false);
  const AllAsessments = useTypedSelector((state) => state.supplier.SuppliersSurveys);
  const [filteredQuestions, setFilteredQuestions] = React.useState<any>([])
  const createSurveyStep = useTypedSelector((state) => state.supplier.createSurveyStep);
  const [addedQuestions, setAddedQuestions] = React.useState<any>([])
  const [fullQuestions, setFullQuestions] = React.useState<any>([])
  const [currentPage, setCurrentPage] = React.useState(1);

  React.useEffect(() => {
    if (props.open && currentAssessment) {
      dispatch(getSupplierQuestions());
    }
    else setAddedQuestions([]);
  }, [props.open, currentAssessment])

  const getAddedQuestions = async () => {
    let res = await dispatch(getSupplierAddedQuestions(currentAssessment?.id))
    if (res) {
      console.log(res);
      setAddedQuestions(res.map((item: any) => item.question));
      setSelectedQuestions(res.map((item: any) => item.question))
      setFullQuestions(res)
    }
  }

  React.useEffect(() => {
    if (currentAssessment && currentAssessment.type !== 'N/A') {
      getAddedQuestions()
      setAssessmentType(currentAssessment.type);
    }
  }, [currentAssessment])

  React.useEffect(() => {
    if (supplierQuestions?.data.length > 0) {
      setCurrentPillar(supplierQuestions?.data[0].attribute)
      setFilteredQuestions(supplierQuestions?.data)
    }
  }, [supplierQuestions?.data]);



  const columns: ColumnsType<any> = [
    assessmentType === 'CUSTOM' ? {
      title: <Checkbox
        checked={
          //all questions of this pillar and of this page are selected, one page has max 10 questions
          supplierQuestions?.data.filter((question: any) => question.attribute === currentPillar).slice((currentPage - 1) * 10, currentPage * 10).filter((question: any) => selectedQuestions.findIndex((obj: any) => obj === question.id) > -1).length === supplierQuestions?.data.filter((question: any) => question.attribute === currentPillar).slice((currentPage - 1) * 10, currentPage * 10).length
        }
        onChange={(e) => {
          let allSelectedQuestionsExceptThisPage = selectedQuestions.filter((question: any) => {
            return supplierQuestions?.data.filter((question: any) => question.attribute === currentPillar).slice((currentPage - 1) * 10, currentPage * 10).findIndex((obj: any) => obj.id === question) === -1
          })
          if (e.target.checked) {
            setSelectedQuestions(
              [...allSelectedQuestionsExceptThisPage, ...supplierQuestions?.data.filter((question: any) => question.attribute === currentPillar).slice((currentPage - 1) * 10, currentPage * 10).map((question: any) => question.id)]
            )
          }
          else {
            setSelectedQuestions(allSelectedQuestionsExceptThisPage)
          }
        }}
      />,
      render: (text, record) => {
        return <Checkbox onChange={(e) => {
          if (e.target.checked) {
            setSelectedQuestions([...selectedQuestions, record.id])
          } else {
            setSelectedQuestions(selectedQuestions.filter((item: any) => item !== record.id))
          }
        }}
          checked={selectedQuestions.includes(record.id)}
        />
      }
    } : {},
    {
      title: 'Attribute',
      dataIndex: 'attribute'
    },
    {
      title: "Question",
      dataIndex: "question",
    },
  ]

  const onEdit = async () => {
    setLoading(true);
    let dataToSend: any = [];
    let dataToDelete: any = [];
    selectedQuestions.forEach((item: any) => {
      if (addedQuestions.findIndex((obj: any) => obj === item) === -1) {
        dataToSend.push({
          assessment: currentAssessment?.id,
          question: item
        })
      }
    })
    addedQuestions.forEach((item: any) => {
      if (selectedQuestions.findIndex((obj: any) => obj === item) === -1) {
        dataToDelete.push(
          fullQuestions?.find((obj: any) => obj.question === item).id
        )
      }
    })
    let flag: any = true;
    if (dataToSend.length > 0)
      flag = await dispatch(addSupplierQuestions(dataToSend))
    if (dataToDelete.length > 0) {
      for (let i = 0; i < dataToDelete.length; i++) {
        const res1 = await dispatch(deleteSupplierQuestions(dataToDelete[i]))
        if (!res1) {
          flag = false;
          break;
        }
      }
    }
    flag ? BEMessage.success('Questions updated successfully') : BEMessage.error('Something went wrong')
    setLoading(false);
    setSelectedQuestions([])
    dispatch(setCreateSurveyStep(3));
  }

  const onFinish = async () => {
    if (currentAssessment?.type !== 'N/A') return onEdit()
    setLoading(true)
    let dataToSend: any = []
    if (assessmentType === 'BRSR-CORE') {
      supplierQuestions?.data.forEach((item: any) => {
        if (item.brsr_core) {
          dataToSend.push({
            assessment: currentAssessment?.id,
            question: item.id
          })
        }
      })
    }
    else {
      selectedQuestions.forEach((item: any) => {
        dataToSend.push({
          assessment: currentAssessment?.id,
          question: item
        })
      })
    }

    let res1 = await dispatch(addSupplierQuestions(dataToSend))
    let res2 = await dispatch(patchCoreSupplierAssessment(currentAssessment?.id, {
      type: assessmentType,
      status: 2,
    }))
    if (res1 && res2) {
      dispatch(setCreateSurveyStep(3));
      BEMessage.success('Questions added successfully')
    }
    setSelectedQuestions([])
    setLoading(false);
  }

  return (
    <StepCollapse
      step={2}
      open={props.open}
      heading="Choose Questions"
      subheading="Select the questions you want to include in your assessment"
      disabled={
        currentAssessment === null ||
        currentAssessment?.status < 1
      }
      setOpen={() => {
        dispatch(setCreateSurveyStep(
          createSurveyStep === 2 ? 0 : 2
        ))
      }}
    >
      <div className="supplier-step-2">
        <p style={{ color: "black" }}>
          <strong>What type of questions do you want to use?</strong>
        </p>
        {AllAsessments?.data.findIndex(
          (item: any) =>
            item.reporting_year.slice(0, 4) ===
            currentAssessment?.reporting_year.slice(0, 4) &&
            item.type === "BRSR-CORE" && assessmentType === "BRSR-CORE"
        ) > -1
          && currentAssessment?.type !== 'BRSR-CORE'
          ? (
            <p
              style={{ color: PrimaryTheme.primaryRedError, fontSize: "0.8rem" }}
            >
              You have already created a BRSR-CORE assessment for this year.
              You can add more suppliers to the same assessment.
            </p>
          ) : (
            <p style={{ fontSize: "0.8rem" }}>
              You can create only one BRSR-CORE assessment per year.
            </p>
          )}
        <div
          style={{
            marginBottom: "0.5rem",
          }}
        >
          <Radio.Group
            onChange={(e) => setAssessmentType(e.target.value)}
            value={assessmentType}
            disabled={currentAssessment?.type !== 'N/A'}
          >
            <Radio
              value="BRSR-CORE"
            // disabled={
            //   AllAsessments?.data.findIndex(
            //     (item: any) =>
            //       item.reporting_year.slice(0, 4) ===
            //         currentAssessment?.reporting_year.slice(0, 4) &&
            //       item.type === "BRSR-CORE"
            //   ) > -1
            // }
            >
              BRSR-CORE
            </Radio>
            <Radio value="CUSTOM">Custom</Radio>
          </Radio.Group>
        </div>
        {assessmentType === "BRSR-CORE" ? (
          <p>Use the predefined set of questions as per the BRSR Template</p>
        ) : (
          <p>Select survey questions</p>
        )}
        {assessmentType === "CUSTOM" && (
          <Row style={{ marginBottom: "0.5rem" }}>
            <Col span={8}>
              <BEInput
                search
                placeholder="Search for questions"
                size="large"
                onChange={(e) => {
                  const value = e.target.value?.toLowerCase();
                  const filteredData = supplierQuestions?.data?.filter(
                    (item: any) => {
                      return (
                        item.question?.toLowerCase().includes(value) ||
                        item.description?.toLowerCase().includes(value)
                      );
                    }
                  );
                  setFilteredQuestions(filteredData);
                }}
              />
            </Col>
          </Row>
        )}
        {supplierQuestions?.status === "loading" ? (
          <GlobalLoader height="10rem" />
        ) : (
          <div className="all-questions">
            <div className="question-section">
              {Array.from(
                new Set(
                  assessmentType === "CUSTOM" ?
                    filteredQuestions.map((item: any) => item.attribute)
                    : supplierQuestions?.data.filter(
                      (question: any) =>
                        question.brsr_core === true
                    ).map((item: any) => item.attribute) // attribute is replaced from pillar
                ))?.map((item: any) => {
                  return (
                    <SideItem
                      innerStyle={{ width: "180px" }}
                      style={{ width: "200px" }}
                      key={item}
                      active={currentPillar === item}
                      name={`
                        ${item}
                        (${assessmentType === "CUSTOM"
                          ? selectedQuestions.filter((id: number) => {
                            const question = supplierQuestions?.data?.find((q) => q.id === id)
                            return question?.attribute === item
                          }).length + `/${supplierQuestions?.data?.filter((question: any) => question.attribute === item)?.length}`
                          : supplierQuestions?.data.filter(
                            (question: any) =>
                              question.attribute === item &&
                              question.brsr_core === true
                          ).length
                        })`}
                      onClick={() => setCurrentPillar(item)}
                    />
                  );
                })}
            </div>
            <div className="questions-box">
              <BETable
                handleChangePage={(page: number) => setCurrentPage(page)}
                columns={columns}
                data={filteredQuestions.filter(
                  (question: any) =>
                    question.attribute === currentPillar &&
                    (assessmentType !== "BRSR-CORE" || question.brsr_core === true)
                )}
                pagination
              />
            </div>
          </div>
        )}
        <div>
          <BEButton
            className="primary"
            size="large"
            onClick={() => {
              onFinish();
            }}
            loading={loading}
            disabled={
              (selectedQuestions.length === 0 && assessmentType === "CUSTOM")
              ||
              (AllAsessments?.data.findIndex((item: any) => item.reporting_year.slice(0, 4) ===
                currentAssessment?.reporting_year.slice(0, 4) &&
                item.type === "BRSR-CORE"
              ) > -1
                && assessmentType === "BRSR-CORE"
              )
            }
          >
            Save & Next
          </BEButton>
          <BEButton
            size="large"
            style={{ marginLeft: "1rem" }}
            onClick={() => props.onCancle()}
          >
            Cancel
          </BEButton>
        </div>
      </div>
    </StepCollapse>
  );
}
