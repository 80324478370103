import { Progress } from 'antd'
import React from 'react'
import { PrimaryTheme } from '../../../Config/Theme/theames'
import { navigateTo } from '../../../Navigation/NavigationManager';
import ToolTip from '../../BEToolTip';
import { AddCommaToNumber } from '../../../Config/Functions/UsefullFunctions';
import DragIcon from '../../BEIcons/DragIcon';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import TargetProgressBar from '../../../Features/TargetsNew/Components/TargetProgressBar';


export interface IDashboardCardProps {
    metricId: number;
    metricName: string;
    metricValue: number;
    metricUnit: string;
    isDerived: boolean;
}

export function DashboardCard(props: IDashboardCardProps) {
    const role = useTypedSelector((state) => state.user.userRole);
    const myMetricTargets = useTypedSelector(state => state.targets.MyMetricTargets);
    const myDerivedMetricTargets = useTypedSelector(state => state.targets.MyDerivedMetricTargets);
    const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
    const currentBusinessGroup = useTypedSelector(state => state.common.homePage.currentBusinessGroup);
    const businessUnitsRefs = useTypedSelector(state => state.entity.BusinessUnitRefs);
    const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    const [target, setTarget] = React.useState<any>(null);

    // Get the target
    React.useEffect(() => {
        let target: any = null;
        if (props.isDerived) {
            if (currentBusinessUnit) {
                target = myDerivedMetricTargets?.data?.find((item) =>
                    item.derived_metric === props.metricId &&
                    item.business_unit === currentBusinessUnit &&
                    item?.end_year === currentYear &&
                    item?.start_year === currentYear
                );
            } else if (currentBusinessGroup) {
                const businessUnits = businessUnitsRefs.data.filter((item: any) => item.business_group === currentBusinessGroup).map((item) => item.business_unit);
                target = myDerivedMetricTargets?.data?.find((item) =>
                    item.derived_metric === props.metricId &&
                    businessUnits.includes(item.business_unit as number) &&
                    item?.end_year === currentYear &&
                    item?.start_year === currentYear
                );
            } else {
                target = myDerivedMetricTargets?.data?.find((item) =>
                    item.derived_metric === props.metricId &&
                    item?.end_year === currentYear &&
                    item?.start_year === currentYear
                );
            }
        } else {
            if (currentBusinessUnit) {
                target = myMetricTargets?.data?.find((item) =>
                    item.metric === props.metricId &&
                    item.business_unit === currentBusinessUnit &&
                    item?.end_year === currentYear &&
                    item?.start_year === currentYear
                );
            } else if (currentBusinessGroup) {
                const businessUnits = businessUnitsRefs.data.filter((item: any) => item.business_group === currentBusinessGroup).map((item) => item.business_unit);
                target = myMetricTargets?.data?.find((item) =>
                    item.metric === props.metricId &&
                    businessUnits.includes(item.business_unit as number) &&
                    item?.end_year === currentYear &&
                    item?.start_year === currentYear
                );
            } else {
                target = myMetricTargets?.data?.find((item) =>
                    item.metric === props.metricId &&
                    item?.end_year === currentYear &&
                    item?.start_year === currentYear
                );
                console.log(target);
            }
        }
        console.log('target', target);

        if (target) {
            setTarget(target);
        }
    }, [props.metricId, props.isDerived, myMetricTargets, myDerivedMetricTargets, currentBusinessUnit, currentBusinessGroup]);



    return (
        <div style={{
            padding: '1rem',
            border: `1px solid ${PrimaryTheme.primaryGreyLight}`,
            borderRadius: '6px', display: "flex",
            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
            justifyContent: "center", alignItems: "center"
        }}>
            <div style={{ borderRadius: '6px', width: "100%", backgroundColor: "white", display: "flex", gap: "1rem", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <span style={{ fontSize: "14px", lineHeight: "12px", color: "#101010", fontWeight: "600", opacity: "50%" }}>
                        <ToolTip title={props.metricName}> <div style={{height:'1.7rem',lineHeight:'1.3rem'}}>{props.metricName?.toUpperCase()}</div></ToolTip>
                    </span>
                    <div style={{ flexGrow: 1 }} />
                    <div style={{ width: "0.8rem", cursor: "grab" }}>
                        <DragIcon fill={PrimaryTheme.primaryGray} inheritSize />
                    </div>
                </div>
                <span style={{ fontSize: "20px", lineHeight: "32px", color: "#101010", fontWeight: "400" }}>
                    {AddCommaToNumber(props.metricValue, false, true, true, 2, true)}{" "}{props.metricUnit === 'Percentage' ? '%' : ['Rate','Number','Description','Ratio'].includes(props.metricUnit) ? '' : props.metricUnit}
                </span>
                {
                    role !== 'CONTRIBUTOR' &&
                    (target === null ?
                        <div style={{
                            fontSize: "small",
                            lineHeight: "18px", color: PrimaryTheme.primaryGreen
                        }}>
                            <div>Please set target from{" "}</div>
                            <div
                                /*style={{ textDecoration: "underline", color: PrimaryTheme.primaryGreenDark, cursor: "pointer" }}*/
                                onClick={() => navigateTo("/home/targets")}
                            >
                                Targets section
                            </div>
                        </div> :
                        <div style={{ display: "flex", flexDirection: "column", width: 180, height:'36px'}}>
                            <TargetProgressBar
                                targetType={target.target_type}
                                progress={target.progress}
                                baseLineValue={target.baseline}
                                targetValue={target.target_value}
                                current_value={target.current_value}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    )
}
