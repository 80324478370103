import * as React from 'react';
import { NewBETable } from '../../../../../../../../../../Components/BETable/newBETable';
import { ColumnsType } from 'antd/es/table';
import { Checkbox } from 'antd';
import { useTypedSelector } from '../../../../../../../../../../Config/Hooks/useTypedSelector';
import { TemplateSelectedTopicTypes } from '../../../../../../../../../../Redux/Types/reportingTypes';
import { useAppDispatch } from '../../../../../../../../../../Config/Hooks/useAppDispatch';
import { fillReportData } from '../../../../../../../../Actions/template';

export interface IType4TopicProps {
  uniqueCode: string;
  nonEditable?: boolean;
  data?: any[];
}

export function Type4Topic(props: IType4TopicProps) {
  const dispatch = useAppDispatch();
  const [data, setData] = React.useState<any>([])
  const selectedTopics = useTypedSelector(state => state.reporting.allSelectedTemplateTopics);
  const currentReport = useTypedSelector(state => state.reporting.currentReport);
  const reportData = useTypedSelector(state => state.reporting.reportData?.data);

  const columns: ColumnsType<any> = [
    {
      title: '',
      dataIndex: 'name',
      width: '30%',
      render: (text: any) => <b>{text}</b>
    },
    ...Array.from(new Set(
      
      props?.data ? props?.data.map((item: any) => item.data_label_2) : // For Gresb only
      selectedTopics?.data.filter((item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code).map((item2: TemplateSelectedTopicTypes) => item2?.data_label_2)
    
    ))
      // .sort((a: string, b: string) => a.localeCompare(b))
      .map((dataLable: string) => {
        return {
          title: <p className='table-title'>{dataLable}</p>,
          dataIndex: dataLable,
          render: (text: any, record: any) =>
            <>
              {
                !(props?.data ? props?.data.filter((item1: any) => item1.data_label_2 === dataLable && item1.accounting_metric === record.name)[0]?.id :
                selectedTopics?.data.filter((item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code && item1.data_label_2 === dataLable && item1.accounting_metric === record.name)[0]?.id) ?
                  <p>N/A</p> :
                  //find topic id
                  <Checkbox
                    onChange={(e: any) => handleCheck(dataLable, record, e)}
                    checked={text}
                    disabled={props.nonEditable}
                  />
              }
            </>
        }
      })
  ]

  React.useEffect(() => {
    let allUniqueDataLable2 = Array.from(new Set(

      props?.data ? props?.data.map((item: any) => item.data_label_2) : // For Gresb only
      selectedTopics?.data.filter((item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code).map((item2: TemplateSelectedTopicTypes) => item2.data_label_2)

    ));
    let allUniqueAccountingMetric = Array.from(new Set(

      props?.data ? props?.data.map((item: any) => item.accounting_metric) : // For Gresb only
      selectedTopics?.data.filter((item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code).map((item2: TemplateSelectedTopicTypes) => item2.accounting_metric)

    ))
    // .sort((a: string, b: string) => a.localeCompare(b));
    let tempData: any = [];
    allUniqueAccountingMetric.forEach((accountingMetric: string) => {
      let tempObj: any = { name: accountingMetric };
      allUniqueDataLable2.forEach((dataLabel2: string) => {
        let topicID = null;

        if (props?.data) {
          topicID = props?.data.filter((item1: any) => item1.data_label_2 === dataLabel2 && item1.accounting_metric === accountingMetric)[0]?.id;
        } else {
          topicID = selectedTopics?.data.filter((item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code && item1.data_label_2 === dataLabel2 && item1.accounting_metric === accountingMetric)[0]?.id;
        }

        if (topicID) {
          if (reportData[topicID]) tempObj[dataLabel2] = (reportData[topicID][0]?.data === 'Yes' ? true : false);
          else tempObj[dataLabel2] = false;
        }
      });
      tempData.push(tempObj);
    });
    setData(tempData);
  }, [selectedTopics, reportData, props.uniqueCode, props?.data]);

  const handleCheck = (feild: string, record: any, e: any) => {

    let topicID = null;

    // for gresb data
    if (props?.data) {
      topicID = props?.data.filter((item1: any) => item1.data_label_2 === feild && item1.accounting_metric === record.name)[0]?.id;
    } else {
      topicID = selectedTopics?.data.filter((item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code && item1.data_label_2 === feild && item1.accounting_metric === record.name)[0]?.id;
    }

    dispatch(fillReportData({
      topic: topicID,
      data: e.target.checked ? 'Yes' : 'No',
      esg_report: currentReport?.id
    }))
  }
  return (
    <div>
      <NewBETable
        columns={columns}
        data={data}
        pagination={false}
      />
    </div>
  );
}
