import React, { useState } from "react";
import { BEInput } from "../../../../../Components/BEFormItems/BEInput";
import { PrimaryTheme } from "../../../../../Config/Theme/theames";
import { BEButton } from "../../../../../Components/BEFormItems/BEButton";
import { useTypedSelector } from "../../../../../Config/Hooks/useTypedSelector";
import { useAppDispatch } from "../../../../../Config/Hooks/useAppDispatch";
import ChevronDownIcon from "../../../../../Components/BEIcons/ChevronDownIcon";
import CrossIcon from "../../../../../Components/BEIcons/CrossIcon";
import { Radio } from "antd";
import { getMetricsByFramework, addMetrics } from "../../../../Actions/OnboardingStep4Actions";
import { BEMessage } from "../../../../../Components/BEMessage";
import useOutsideClick from "../../../../../Config/Hooks/useOutsideClick";
import { postMyDerivedMetrics } from "../../../../../Features/DataManager/Actions";
import { findBECodesOfAllChildren, findBECodesOfAllParents } from "../../../../Actions/calculatedMetricsActions";
import { defaultDerivedMetrics } from "../../../../../Config/Data/dashboardData";

const MetricSelector = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [frameworks, setFrameworks] = useState<any>(['BRSR', 'GRI', 'TCFD', 'SASB', 'CDP', 'ESRS', 'WEF']);
  const derivedMetricsRepresentationGraph = useTypedSelector((state) => state.settings.derivedMetricsRepresentationGraph);
  const metrics = useTypedSelector((state) => state.onBoarding.metrics.directMetrics?.data);
  const derivedMetrics = useTypedSelector((state) => state.onBoarding.metrics.derivedMetrics?.data);
  const [selected, setSelected] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const myMetricsPerYear = useTypedSelector((state) => state.onBoarding.metrics.myMetricsPerYear?.data);
  const myDerivedMetricsPerYear = useTypedSelector((state) => state.onBoarding.metrics.myDerivedMetricsPerYear?.data);
  const metricsYear = useTypedSelector((state) => state.dataManager.metrics.dateSlicer.currentYear);
  const ref = React.useRef(null);

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  const handleApply = async () => {
    if (selected === '') {
      BEMessage.error('Please select a framework')
      return;
    }
    setLoading(true);
    let [frameworkDirectMetrics, frameworkDerivedMetrics]: any = await dispatch(getMetricsByFramework(selected));
    let directMetricsToAdd: any = [];
    let derivedMetricsToAdd: any = [];
    
    frameworkDerivedMetrics.map((item: any) => {
      if (!myDerivedMetricsPerYear?.find((i) => i.derived_metric === item.id)) {
        derivedMetricsToAdd.push({
          derived_metric: item.id,
          year: metricsYear
        })
      }
      const allParentBcodes = findBECodesOfAllParents(derivedMetricsRepresentationGraph?.data, [item.bcode]);
      let allChildrenBcodes: any[] = [];
      if (!defaultDerivedMetrics.includes(item.bcode)) {
        allChildrenBcodes = findBECodesOfAllChildren(derivedMetricsRepresentationGraph?.data, [item.bcode]);
      }
      [...allChildrenBcodes, ...allParentBcodes].map((bcode: any) => {
        const metric = metrics?.find((i: any) => i.bcode === bcode && !i?.hasOwnProperty('direct'));
        if (metric) {
          if (!myMetricsPerYear?.find((i: any) => i.metric === metric?.id)) {
            frameworkDirectMetrics.push(metric);
            directMetricsToAdd.push({
              metric: metric?.id,
              year: metricsYear,
              bcode: bcode
            })
          }
        } else {
          const derivedMetric = derivedMetrics?.find((i: any) => i.bcode === bcode);
          if (derivedMetric) {
            if (!myDerivedMetricsPerYear?.find((i: any) => i.derived_metric === derivedMetric?.id)) {
              derivedMetricsToAdd.push({
                derived_metric: derivedMetric?.id,
                year: metricsYear
              })
            }
          }
        }
      })
    })
    frameworkDirectMetrics.map((item: any) => {
      if (!myMetricsPerYear?.find((i: any) => i.metric === item.id)) {
        directMetricsToAdd.push({
          metric: item.id,
          year: metricsYear,
          bcode: item.bcode
        })
      }
      const allParentBcodes = findBECodesOfAllParents(derivedMetricsRepresentationGraph?.data, [item.bcode]);
      allParentBcodes.map((bcode: any) => {
        const metric = metrics?.find((i: any) => i.bcode === bcode && !i?.hasOwnProperty('direct'));
        if (metric) {
          if (!myMetricsPerYear?.find((i: any) => i.metric === metric?.id)) {
            directMetricsToAdd.push({
              metric: metric?.id,
              year: metricsYear,
              bcode: bcode
            })
          }
        } else {
          const derivedMetric = derivedMetrics?.find((i: any) => i.bcode === bcode);
          if (derivedMetric) {
            if (!myDerivedMetricsPerYear?.find((i: any) => i.derived_metric === derivedMetric?.id)) {
              derivedMetricsToAdd.push({
                derived_metric: derivedMetric?.id,
                year: metricsYear
              })
            }
          }
        }
      })
    })
    derivedMetricsToAdd = Array.from(new Set(derivedMetricsToAdd.map((item: any) => item.derived_metric))).map((id: any) => derivedMetricsToAdd?.find((i: any) => i.derived_metric === id));
    directMetricsToAdd = Array.from(new Set(directMetricsToAdd.map((item: any) => item.metric))).map((id: any) => directMetricsToAdd?.find((i: any) => i.metric === id));
    if (directMetricsToAdd.length === 0 && derivedMetricsToAdd.length === 0) {
      BEMessage.info('All metrics corresponding to this framework are already added')
      setOpen(false);
      setLoading(false);
      return;
    }
    // add direct metrics in chunk of 50 using Promise.all
    let chunkSize = 50;
    let res2 = true;
    for (let i = 0; i < directMetricsToAdd.length; i += chunkSize) {
      let chunk = directMetricsToAdd.slice(i, i + chunkSize);
      let res = await dispatch(addMetrics(chunk));
      if (!res) res2 = false;
    }
    let res1 = await dispatch(postMyDerivedMetrics(derivedMetricsToAdd));
    if (res1 || res2) BEMessage.success(`${[...directMetricsToAdd, ...derivedMetricsToAdd].length} metrics corresponding to ${selected} framework added successfully`)
    // else BEMessage.error('Something went wrong')
    setLoading(false);
    setOpen(false);
  }

  return (
    <div
      ref={ref}
      style={{
        zIndex: 4,
        width: "300px",
        position: 'relative',
        top: '0.25rem'
      }}
    >
      {/* <div className="top-text">
        Apply framework specific metrics:
      </div> */}
      <BEInput
        placeholder="Apply framework specific metrics:"
        size="large"
        value={selected}
        onClick={() => setOpen(!open)}
        // onChange={(e) => {
        //   const filteredData = Frameworks.filter((fr) =>
        //     fr.code?.toLowerCase().includes(e.target.value?.toLowerCase())
        //   );
        //   setFrameworks(filteredData);
        // }}
        suffix={
          <div
            style={{ display: "flex", alignItems: "center", height: open ? '0.5em' : '1rem', cursor: 'pointer' }}
            onClick={() => setSelected([])}
          >
            {open ? (
              <CrossIcon stroke={PrimaryTheme.primaryGray} inheritSize />
            ) : (
              <ChevronDownIcon fill={PrimaryTheme.primaryGray} inheritSize />
            )}
          </div>
        }
      />
      <div
        style={{
          display: open ? "inherit" : "none",
          width: '90%',
          position: "absolute",
          padding: "1rem",
          background: "white",
          borderRadius: "8px",
          border: `1px solid ${PrimaryTheme.primaryGreenLight}`,
          boxSizing: "content-box",
          boxShadow: `0px 4px 6px 2px ${PrimaryTheme.primaryGreyLight}`,
        }}
      >
        {/* <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
          {frameworks.map((framework: any) => {
            return (
              <Checkbox
                style={{ marginBottom: "0.5rem", width: "50%" }}
                onChange={() => handleChange(framework)}
                checked={
                  selected?.findIndex((i: any) => i.code === framework.code) !==
                  -1
                }
              >
                {framework.code}
              </Checkbox>
            );
          })}
        </div> */}
        <Radio.Group
          onChange={(e) => {
            setSelected(e.target.value);
          }}
          value={selected}
        >
          {frameworks.map((framework: any) => {
            return (
              <Radio
                style={{ marginBottom: "0.5rem", width: "50%" }}
                value={framework}
              >
                {framework}
              </Radio>
            );
          })}
        </Radio.Group>

        <BEButton
          loading={loading}
          className="primary"
          size="large"
          style={{ width: "100%" }}
          onClick={() => {
            handleApply();
          }}
        >
          Apply
        </BEButton>
      </div>
    </div>
  );
};

export default MetricSelector;
