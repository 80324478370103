import * as React from 'react';
import './style.scss';
import SwitchIcon from '../BEIcons/SwitchIcon';

import { PrimaryTheme } from '../../Config/Theme/theames';

export interface IBECollapseProps {
    children?: React.ReactNode
    heading?: React.ReactNode
    headingStyle?: React.CSSProperties;
    headingRightContent?: React.ReactNode;
    closeCollapse?: boolean;
    titleWidth?: string;
}

export function BECollapse(props: IBECollapseProps) {
    const [open, setOpen] = React.useState(false);

    const handleClick = (e: any) => {
        if (e.currentTarget === e.target)
            setOpen(!open)
    }

    React.useEffect(() => {
        if (props.closeCollapse) {
            setOpen(false);
        }
    }, [props.closeCollapse])

    return (
        <div className='be-collapse'
            onClick={handleClick}
        >
            <div className='heading'
                onClick={handleClick}
                style={{...props.headingStyle,}}
            >
                <div style={{display:'flex',width:props?.titleWidth ? props.titleWidth : 'fit-content'}}>
                    {props.heading}
                </div>
                {
                    props.headingRightContent && props.headingRightContent
                }
                <div className='switch-arrow '
                    style={{ transform: open ? 'rotateZ(0deg)' : 'rotateZ(90deg)' }}
                    onClick={() => setOpen(!open)}>
                    <SwitchIcon stroke={PrimaryTheme.primaryGray} />
                </div>
            </div>
            {
                open &&
                <div className='body-collapse'>
                    {open && props.children}
                </div>
            }
        </div>
    );
}
