import * as React from 'react';
import StackedColumnCharts from '../../../../../../Components/BECharts/StackedColumnCharts';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { rotateArray } from '../../../../../../Config/Functions/UsefullFunctions';
import { shortMonths } from '../../../../../../Config/Data/genralData';
import { filterDataByMonthOrBU } from '../../../../Actions';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';

export interface ITotalEmissionsByScopeProps {
}

export function TotalEmissionsByScopeGraph(props: ITotalEmissionsByScopeProps) {
  const dispatch = useAppDispatch();
  const yearEnd = useTypedSelector(state => state.companies.yearEnd);
  const scope1CalculationsByMonth = useTypedSelector(state => state.emissions.calculations.scope1calculationForMonths);
  const scope2CalculationsByMonth = useTypedSelector(state => state.emissions.calculations.scope2calculationForMonths);
  const [chartData, setChartData] = React.useState<any>([]);
  const currentBU = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
  const currentBG = useTypedSelector(state => state.common.homePage.currentBusinessGroup);
  const [scope1FilteredData, setScope1FilteredData] = React.useState<any[]>([]);
  const [scope2FilteredData, setScope2FilteredData] = React.useState<any[]>([]);
  const currentMonth = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentMonth);

  const filterDataFunction = async ()=>{
    let res = await dispatch(filterDataByMonthOrBU(true, true))
    setScope1FilteredData(res?.scope1Data)
    setScope2FilteredData(res?.scope2Data)
  }

  React.useEffect(() => {
    filterDataFunction()
  }, [currentBU, currentBG, scope1CalculationsByMonth, scope2CalculationsByMonth, currentMonth])

  React.useEffect(() => {
    let scope1MonthsAggrigateArey: any = new Array(12).fill(0);
    let scope2MonthsAggrigateArey: any = new Array(12).fill(0);
    
    scope1FilteredData.forEach((element: any) => {
      let monthIndex = ((element.month-1)+ 12 - yearEnd)%12;
      scope1MonthsAggrigateArey[monthIndex] += element.tco2e;
    });
    scope2FilteredData.forEach((element: any) => {
      let monthIndex = ((element.month-1)+ 12 - yearEnd)%12;
      scope2MonthsAggrigateArey[monthIndex] += element.tco2e;
    });
    scope1MonthsAggrigateArey = scope1MonthsAggrigateArey.map((element: any) => {
      return Number(element.toFixed(2))
    })
    scope2MonthsAggrigateArey = scope2MonthsAggrigateArey.map((element: any) => {
      return Number(element.toFixed(2))
    })
    let tempChartData: any = [
      {
        name: 'Scope 1',
        data: scope1MonthsAggrigateArey
      },
      {
        name: 'Scope 2',
        data: scope2MonthsAggrigateArey
      }
    ]
    setChartData(tempChartData)
  }, [scope1FilteredData, scope2FilteredData]);

  return (
    <div style={{ margin: "2rem 0" }}>
      <p className='chart-title'>Total emissions by Scope (tCO2e)</p>
      <div style={{ padding: "1rem" }} className='bordered-container'>
        <StackedColumnCharts
          data={chartData}
          xAxisLabels={rotateArray(shortMonths, yearEnd, true)}
          // chartTitle="Total emissions by Scope (tCO2e)"
          yAxisLabel="Tonnes of Co2 equivalent"
        />
      </div>
    </div>
  );
}
