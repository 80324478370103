import { Card, Col, Row } from "antd";
import React from "react";
import { PrimaryTheme } from "../../../Config/Theme/theames";
import TrackerIcon from "../../BEIcons/TrackerIcon";

interface TrackerCardProps {
    title: string;
    body:any;
    icon?: React.ReactNode;
    loading?: boolean;
    highlight?: string;
    noHighlight?: boolean;
}

const TrackerCard = (props:TrackerCardProps) => {
  return (
    <Card loading={props.loading}
     style={{ width: "200px", marginRight: "1rem" , padding: '1.5rem'}}>
      <Row>
        <Col span={17}>
          <div
            style={{
              fontWeight: 600,
              fontSize: "10px",
              color: PrimaryTheme.primaryGray,
            }}
          >
            {props.title}
          </div>
          <div
            style={{
              fontWeight: 600,
              fontSize: "28px",
              color: PrimaryTheme.primaryBlack,
            }}
          >
            {props.body}
          </div>
        </Col>
        <Col span={7}>
          {!props.noHighlight &&
          <div style={{backgroundColor: PrimaryTheme.primaryGreyLight, display:'flex',alignItems: 'center', justifyContent: 'center', borderRadius: '8px', height: '1.5rem', width:'1.5rem', padding: '0.5rem', fontSize: '0.6rem'}}>
            {props.icon?props.icon:props.highlight}
          </div>}
        </Col>
      </Row>
    </Card>
  );
};

export default TrackerCard;
