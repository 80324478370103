import { Navigate, useParams } from 'react-router-dom';
import { MetricFolder } from '../../Views/AllFolders/SystemFolders/FYFolders/MetricFolder';
import { AllSupplierEvidences } from '../../Views/AllFolders/SupplierFolders/AllEvidences';

export interface IFolderContentProps {
}

export function FolderContent3 (props: IFolderContentProps) {
  const params = useParams();
  return (
    <div>
        {
          Number(params.folder_id)=== 0?
            <MetricFolder/> :
          Number(params.folder_id)=== -1 ? 
            <AllSupplierEvidences/>:
            <Navigate to='/home/settings/vault'/>
        }
    </div>
  );
}
