import * as React from 'react';
import { Col, Row } from 'antd';
import './index.scss';
import DonutCharts from '../../../../../../Components/BECharts/DonutCharts';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import EmptyEmissions from '../../../../../../assets/images/EmptyEmissions.png'
// import EmptyEmisions from '../../../../../../Components/BEIcons/EmptyEmission';

export interface IEmmisionsDonutChartProps {
}

export function EmmisionsDonutChart(props: IEmmisionsDonutChartProps) {
  const currentBU = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
  const currentBG = useTypedSelector(state => state.common.homePage.currentBusinessGroup);
  const businessUnits = useTypedSelector(state => state.entity.BusinessUnits);
  const businessGroups = useTypedSelector(state => state.entity.BusinessGroups);
  const scope1CalculationsByMonth = useTypedSelector(state => state.emissions.calculations.scope1calculationForMonths);
  const scope2CalculationsByMonth = useTypedSelector(state => state.emissions.calculations.scope2calculationForMonths);
  const businessUnitRefs = useTypedSelector(state => state.entity.BusinessUnitRefs);
  const [totalDonutData, setTotalDonutData] = React.useState<any>([]);
  const [scope1DonutData, setScope1DonutData] = React.useState<any>([]);
  const [scope2DonutData, setScope2DonutData] = React.useState<any>([]);
  const [filteredData, setFilteredData] = React.useState<any>([]);  // if BG = 0 - all data  else - BG data of that month
  const [filteredData2, setFilteredData2] = React.useState<any>([]); // if BG = 0 - all data  else - BG data of that month                        
  const currentMonth = useTypedSelector(state => state.emissions.dateSlicer.currentMonth);

  React.useEffect(() => {
    let filteredDataByMonth = currentMonth ? scope1CalculationsByMonth?.data.filter((obj: any) => obj.month === currentMonth) : scope1CalculationsByMonth?.data
    let filteredDataByMonth2 = currentMonth ? scope2CalculationsByMonth?.data.filter((obj: any) => obj.month === currentMonth) : scope2CalculationsByMonth?.data

    let tempFilteredData: any = [];
    let tempFilteredData2: any = [];
    //if current bg !== 0 then filter by bg
    //else all data
    if (currentBG !== 0) {
      let BUs = businessUnitRefs?.data.filter((element: any) => {
        return element.business_group === currentBG
      }).map((element: any) => element.business_unit)
      tempFilteredData = filteredDataByMonth.filter((element: any) => {
        return BUs.includes(element.business_unit)
      })
      tempFilteredData2 = filteredDataByMonth2.filter((element: any) => {
        return BUs.includes(element.business_unit)
      })
    }
    else {
      tempFilteredData = filteredDataByMonth
      tempFilteredData2 = filteredDataByMonth2
    }
    setFilteredData(tempFilteredData)
    setFilteredData2(tempFilteredData2)
  }, [scope1CalculationsByMonth, scope2CalculationsByMonth, currentBU, currentBG, currentMonth]);

  React.useEffect(() => {
    let tempScope1DonutData: any = [];
    let tempScope2DonutData: any = [];
    let tempTotalDonutData: any = [];
    if (currentBG !== 0) {
      let buDataMap: any = {}
      let buDataMap2: any = {}

      filteredData.forEach((element: any) => {
        if (buDataMap[element.business_unit]) {
          buDataMap[element.business_unit] = element.tco2e + buDataMap[element.business_unit]
        }
        else {
          buDataMap[element.business_unit] = element.tco2e
        }
      })

      filteredData2.forEach((element: any) => {
        if (buDataMap2[element.business_unit]) {
          buDataMap2[element.business_unit] = element.tco2e + buDataMap2[element.business_unit]
        }
        else {
          buDataMap2[element.business_unit] = element.tco2e
        }
      })
      Object.keys(buDataMap).forEach((element: any) => {
        tempScope1DonutData.push({ name: businessUnits?.data?.find((item: any) => item.id == element)?.name, value: buDataMap[element] })
      })
      Object.keys(buDataMap2).forEach((element: any) => {
        tempScope2DonutData.push({ name: businessUnits?.data?.find((item: any) => item.id == element)?.name, value: buDataMap2[element] })
      })
    }
    else {
      let bgDataMap: any = {}
      let bgDataMap2: any = {}
      filteredData.forEach((element: any) => {
        let buBGs = businessUnitRefs?.data.filter((item: any) => item.business_unit === element.business_unit).map((item: any) => item.business_group)
        buBGs.forEach((item: any) => {
          if (bgDataMap[item]) {
            bgDataMap[item] = element.tco2e + bgDataMap[item]
          }
          else {
            bgDataMap[item] = element.tco2e
          }
        })
      })
      filteredData2.forEach((element: any) => {
        let buBGs = businessUnitRefs?.data.filter((item: any) => item.business_unit === element.business_unit).map((item: any) => item.business_group)
        buBGs.forEach((item: any) => {
          if (bgDataMap2[item]) {
            bgDataMap2[item] = element.tco2e + bgDataMap2[item]
          }
          else {
            bgDataMap2[item] = element.tco2e
          }
        })
      })
      
      Object.keys(bgDataMap).forEach((element: any) => {
        let bgName = businessGroups?.data.filter((item: any) => item.id == element)[0]?.name
        tempScope1DonutData.push({ name: bgName, value: bgDataMap[element] })
      })
      Object.keys(bgDataMap2).forEach((element: any) => {
        let bgName = businessGroups?.data.filter((item: any) => item.id == element)[0]?.name
        tempScope2DonutData.push({ name: bgName, value: bgDataMap2[element] })
      })
    }
    setScope1DonutData(tempScope1DonutData)
    setScope2DonutData(tempScope2DonutData)

    // set total Data
    tempScope1DonutData.forEach((element: any) => {
      let tempObj: any = {
        name: element.name,
        value: element.value
      }
      let tempObj2: any = tempScope2DonutData?.find((item: any) => item.name === element.name)
      tempObj.value = tempObj ? tempObj.value : 0
      tempObj.value = tempObj.value + (tempObj2 ? tempObj2.value : 0)
      tempTotalDonutData.push(tempObj)
    })

    tempScope2DonutData.forEach((element: any) => {
      let tempObj: any = {
        name: element.name,
        value: element.value
      }
      let tempObj2: any = tempScope1DonutData?.find((item: any) => item.name === element.name)
      tempObj.value = tempObj ? tempObj.value : 0
      tempObj.value = tempObj.value + (tempObj2 ? tempObj2.value : 0)
      if (!tempTotalDonutData?.find((item: any) => item.name === element.name)) {
        tempTotalDonutData.push(tempObj)
      }
    })
    setTotalDonutData(tempTotalDonutData)
  }, [filteredData, filteredData2]);

  return (
    <Row gutter={[16, 16]} style={{ margin: "1rem 0" }}>

      <Col span={8}>
        <div>
          <p className='chart-title'>Total emissions by {currentBG ? "Business Unit" : "Business Group"} (tCO2e)</p>
          {
            totalDonutData.length ?
              <DonutCharts
                colorTheme={2}
                loading={scope1CalculationsByMonth?.status === 'loading' || scope2CalculationsByMonth?.status === 'loading'}
                data={totalDonutData}
              // chartTitle={`Total emissions by ${currentBG ? "Business Unit" : "Business Group"
              //   }    (tCO2e)`}
              /> :

              <div className='empty-card-emissions'>
                <div className='circle'>
                  <img src={EmptyEmissions} alt="empty emissions" />
                </div>
                <div>
                  Data has not been added for the relevant metrics
                </div>
              </div>
          }
        </div>
      </Col>
      <Col span={8}>
        <div>
          <p className='chart-title'>Scope 1 emissions by {currentBG ? "Business Unit" : "Business Group"} (tCO2e)</p>

          {
            scope1DonutData.length ?
              <DonutCharts
                colorTheme={2}
                loading={scope1CalculationsByMonth?.status === 'loading'}
                data={scope1DonutData}
              // chartTitle={`Scope 1 emissions by ${currentBG ? "Business Unit" : "Business Group"
              //   }    (tCO2e)`}
              />
              :
              <div className='empty-card-emissions'>
                <div className='circle'>
                  <img src={EmptyEmissions} alt="empty emissions" />
                </div>
                <div>
                  Data has not been added for the relevant metrics
                </div>
              </div>
          }
        </div>
      </Col>
      <Col span={8}>
        <div>
          <p className='chart-title'>Scope 2 emissions by {currentBG ? "Business Unit" : "Business Group"} (tCO2e)</p>

          {
            scope2DonutData.length ?
              <DonutCharts
                colorTheme={2}
                loading={scope2CalculationsByMonth?.status === 'loading'}
                data={scope2DonutData}
              // chartTitle={`Scope 2 emissions by ${currentBG ? "Business Unit" : "Business Group"
              //   }    (tCO2e)`}
              /> :
              <div className='empty-card-emissions'>
                <div className='circle'>
                  <img src={EmptyEmissions} alt="empty emissions" />
                </div>
                <div>
                  Data has not been added for the relevant metrics
                </div>
              </div>
          }
        </div>
      </Col>
    </Row>
  );
}
