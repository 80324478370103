import * as React from 'react';
import { businessGroupType } from '../../../Redux/Types/entityTypes';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { generateRandomColor } from '../../../Config/Functions/UsefullFunctions';
import EditIcon from '../../BEIcons/EditIcon';
import { AddBusinessGroup } from '../../../views/Onboarding/Drawers/AddBusinessGroup';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import ToolTip from '../../BEToolTip';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { setCurrentBusinessGroup, setCurrentBusinessUnit } from '../../../Redux/CommonReducer';
import { useNavigate } from 'react-router-dom';

export interface IBusinessGroupcardProps {
    businessGroup: businessGroupType;
    openEditModal?: Function
    noEdit?: boolean
}

export function BusinessGroupcard(props: IBusinessGroupcardProps) {
    const [openEditDrawer, setOpenEditDrawer] = React.useState(false);
    const BURefs = useTypedSelector(state => state.entity.BusinessUnitRefs?.data);
    const allBusinessUnits = useTypedSelector((state) => state.entity.BusinessUnits);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleBGClick = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>, bg_id: Number) => {
        dispatch(setCurrentBusinessGroup(bg_id));
        dispatch(setCurrentBusinessUnit(0));
        navigate('/home/dashboard');
    }

    return (
        <div style={{
            width: '204px', padding: '1.5rem', position: 'relative', flexShrink: 0,
            border: `1px solid rgba(243, 243, 243, 1)`, borderRadius: '8px'
        }}>
            <p
                style={{
                    fontWeight: "500",
                    textDecoration: "underline",
                    cursor: "pointer"
                }}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                    handleBGClick(e, props.businessGroup.id);
                }}
            >
                {
                    props?.businessGroup?.name
                }
            </p>
            <ToolTip title={
                <div>
                    {
                        BURefs.filter((item: any) => item.business_group === props.businessGroup.id).map((item: any) => {
                            return <p>{allBusinessUnits?.data?.find((item2: any) => item2.id === item.business_unit)?.name}</p>
                        })
                    }
                </div>
            }>
                <p style={{ fontSize: '0.8rem', color: PrimaryTheme.primaryGray, margin: '0.5rem 0' }}>{

                    // props?.businessGroup?.business_units
                }
                    {
                        BURefs.filter((item: any) => item.business_group === props.businessGroup.id).length
                    }{' '}
                    business units

                </p>
            </ToolTip>

            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <div style={{
                    width: '16px', height: '16px', borderRadius: '50%', backgroundColor: generateRandomColor(), color: 'white',
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                    <p>{
                        props?.businessGroup?.created_by[0]
                    }</p>
                </div>
                <p style={{ fontSize: '0.8rem', color: PrimaryTheme.primaryGray }}>
                    {
                        props?.businessGroup?.created_by
                    }
                </p>
                {
                    !props.noEdit &&
                    <div
                        onClick={() => { setOpenEditDrawer(true) }}
                        style={{ position: 'absolute', width: '16px', height: '16px', bottom: '1rem', right: '1rem', cursor: 'pointer' }}>
                        <EditIcon stroke={PrimaryTheme.primaryGreenDark} inheritSize />
                    </div>
                }
            </div>
            <AddBusinessGroup open={openEditDrawer} setOpen={setOpenEditDrawer} isEdit businessGroup={props.businessGroup} />
        </div>
    );
}
