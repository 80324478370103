import * as React from 'react';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import '../index.scss';
import { ColumnsType } from 'antd/es/table';
import { ChangeDateFormat } from '../../../../../Config/Functions/UsefullFunctions';
import { NewBETable } from '../../../../../Components/BETable/newBETable';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useParams } from 'react-router-dom';
import { UploadFile } from '../../Drawers/UploadFile';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import EditIcon from '../../../../../Components/BEIcons/EditIcon';
import DownloadIcon from '../../../../../Components/BEIcons/DownloadIcon';
import DeleteIcon from '../../../../../Components/BEIcons/DeleteIcon';
import BEConfirmModal from '../../../../../Components/BEConfirmModal';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { deleteVaultFiles } from '../../../Actions';
import File from '../../../Components/File';
import BECardPagination from '../../../../../Components/BECardsPagination';

export interface IUsersFolderProps {
}

export function UsersFolder(props: IUsersFolderProps) {

  const VaultFiles = useTypedSelector((state) => state.vault.Files);
  const VaultFolders = useTypedSelector((state) => state.vault.Folders);
  const users = useTypedSelector((state) => state.userMgmt?.data);
  const [tableData, setTableData] = React.useState<any>([]);
  const [uploadFile, setUploadFile] = React.useState<boolean>(false);
  const [fileId, setFileId] = React.useState(0);
  const [isEdit, setIsEdit] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const { folder_id } = useParams();
  const [searchValue, setSearchValue] = React.useState<string>('');
  const view = useTypedSelector((state) => state.vault.view);
  const dispatch = useAppDispatch();

  const columns: ColumnsType<any> = [
    {
      title: 'S. No.',
      dataIndex: 'sno',
      key: 'sno',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'File',
      dataIndex: 'file',
      key: 'file',
      render: (text: any) => {
        // open the file in a new tab
        return (
          <a href={text} style={{ display: "flex", flexDirection: "row", gap: "0.5rem" }} target='_blank' download>
            File <DownloadIcon /></a>
        )
      }
    },
    {
      title: 'Uploaded On',
      dataIndex: 'uploaded_on',
      key: 'uploaded_on',
      render: (value, data: any) => {
        return <p>{ChangeDateFormat(data.uploaded_on)}</p>;
      },
    },
    {
      title: 'Uploaded By',
      dataIndex: 'uploaded_by',
      key: 'uploaded_by',
      render: (value, data: any) => {
        const user = users?.find((item: any) => item.id === data.uploaded_by);
        return <p>{user?.first_name} {user?.last_name}</p>
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => (
        <div style={{ display: "flex", gap: "1rem", flexDirection: "row" }}>
          <div style={{ width: "1rem" }} onClick={() => {
            openEditFunction(record);
          }}>
            <EditIcon inheritSize={true} stroke={PrimaryTheme.primaryGreyDark} />
          </div>
          <div style={{ width: "1rem" }} onClick={() => {
            setFileId(record.id);
            setConfirmDelete(true);
          }}>
            <DeleteIcon inheritSize={true} stroke={PrimaryTheme.primaryGreyDark} />
          </div>
        </div>)
    }
  ];

  const openEditFunction = (file: any) => {
    setIsEdit(true);
    setFileId(file.id);
    setUploadFile(true);
  }

  React.useEffect(() => {
    setTableData(VaultFiles?.data.filter((item) => item.folder === Number(folder_id)));
  }, [VaultFiles])

  return (
    <div className='user-folders-container'>
      <div className='top-section'>
        <h3>{VaultFolders?.data?.find((item) => item.id === Number(folder_id))?.title}</h3>
        <div style={{ flexGrow: 1 }} />
        <BEButton size='large' className='primary'
          onClick={() => {
            setIsEdit(false);
            setUploadFile(true);
          }}
          loading={false}
        >
          Upload New Files
        </BEButton>
      </div>
      <div style={{ width: "100%" }}>
        {
          view === 'list' ?
            <NewBETable
              data={tableData}
              columns={columns}
              pagination
              search
            /> :
            <div className='files-grid-view'>
              {
                /* {
                tableData.map((item: any, index: number) => {
                  return (
                    <File  fileName='File Name' download={() => {}} />
                  )
                })
              } */
              }
              <BECardPagination
                open={view === 'grid'}
                noOfColumns={8}
                noOfRows={1}
                ComponentsArrey={
                  tableData.filter((item: any) => searchValue !== '' ? item.title?.toLowerCase().includes(searchValue?.toLowerCase()) : true)
                  .map((item: any, index: number) => {
                    return <File fileName={item.title}
                      key={index}
                      file={item}
                      setOpenEdit={openEditFunction}
                    />
                  })}
                search
                setSearchedValue={setSearchValue}
              />
            </div>
        }
      </div>
      <UploadFile
        open={uploadFile}
        setOpen={setUploadFile}
        isEdit={isEdit}
        FileId={fileId}
      />
      <BEConfirmModal
        title='Delete File?'
        visible={confirmDelete}
        setVisible={setConfirmDelete}
        onConfirm={async () => await dispatch(deleteVaultFiles(fileId))}
        message='Are you sure you want to delete this file?'
      />
    </div>
  );
}
