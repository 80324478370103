import { Card, Col, Input, List, Modal, Row } from "antd";
import React, { useState } from "react";
import { PrimaryTheme } from "../../../Config/Theme/theames";
import USA from "../../../assets/images/Country/USA.png";
import IND from "../../../assets/images/Country/IND.png";
import UAE from "../../../assets/images/Country/UAE.webp";
import './styles.scss'

interface CurrencyModalProps {
  openModal: boolean;
  setOpenModal: Function;
  setCurrency: Function;
}

const data = [
  {
    currency: "US Dollar",
    label: "USD",
    icon: USA,
  },
  {
    currency: "Indian Rupee",
    label: "INR",
    icon: IND,
  },
  {
    currency: "UAE Dirham",
    label: "AED",
    icon: UAE,
  }
];

const CurrencyModal = (props: CurrencyModalProps) => {
  const [list, setList] = useState(data);
  const search = (text: string) => {
    let filteredList = data.filter((item) => {
      return item.currency?.toLowerCase().includes(text?.toLowerCase());
    });
    setList(filteredList);
  };
  return (
    <Modal
      className="curency-selector"
      closable
      maskClosable={true}
      open={props.openModal}
      onCancel={() => props.setOpenModal(false)}
      okText="Submit"
      cancelText="Reset"
      footer={null}
      width={"65vw"}
    >
      <div>
        <div style={{ fontSize: "20px", fontWeight: 600, lineHeight: "30px" }}>
          Select Currency for Revenue
        </div>
        <p style={{ fontSize: "14px", fontWeight: 400, lineHeight: "21px" }}>
          Enter details about your company
        </p>
        <Row style={{ margin: "1rem auto" }}>
          <Col span={24}>
            <Input
              style={{ width: "100%" }}
              size="large"
              onChange={(e) => {
                search(e.target.value);
              }}
              placeholder="Search for a currency"
            />
          </Col>
        </Row>
        <List
          className="list"
          grid={{ gutter: 16, column: 4 }}
          style={{ height: "50vh", overflowY: "auto", overflowX: "hidden" }}
          dataSource={list}
          renderItem={(item) => (
            <List.Item>
              <Card
                hoverable
                onClick={() => {
                    props.setCurrency(item.label);
                    props.setOpenModal(false)
                }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                  }}
                >
                  <img
                  style={{height: '0.8rem'}}
                  src={item.icon} alt={item.label} /> {item.currency}
                </div>
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "18px",
                    color: PrimaryTheme.primaryGray,
                  }}
                >
                  {item.label}
                </p>
              </Card>
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
};

export default CurrencyModal;
