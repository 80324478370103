import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { TopicEvidanceTable } from '../../Drawers/NewEditReport/RightBody/RightBodyTabs/ChildrenRightTabs/EvidenceTable';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';

export interface IReportingReviewEvidenceDrawerProps {
  open: boolean;
  setOpen: Function;
  uniqueCode: string;
}

export function ReportingReviewEvidenceDrawer (props: IReportingReviewEvidenceDrawerProps) {
  const selectedTopics = useTypedSelector(state => state.reporting.selectedTemplateTopics);
  return (
   <BEDrawer
    open={props.open}
    setOpen={props.setOpen}
    heading='Evidence'
    width='600px'
    >
      <p style={{fontSize:'1rem',padding:'1rem'}}>
        {
        `${selectedTopics?.data.filter((topic: any) => topic.unique_code === props.uniqueCode)[0]?.topic}`
        }
        </p>
     <TopicEvidanceTable uniqueCode={props.uniqueCode} review={true}/> 
    </BEDrawer>
  );
}
