import * as React from 'react';
import { roleMap } from '../../../Config/Data/UsersMap';
import { Roles } from '../../../Config/Data/genralData';
import { Features } from '../../../Config/Data/genralData';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import { ColumnsType } from 'antd/es/table';
import { getUserPermissions, patchRolePermission } from '../../../views/Actions/HomeActions';
import BESwitch from '../../../Components/BEFormItems/BESwitch';
import { BETable } from '../../../Components/BETable';
import './style.scss'
import { GlobalLoader } from '../../../Components/GlobalLoader';
import { TopSection } from '../Components/TopSection';
import SettingsBackButton from '../Components/BackButton';

export interface IPermissionsProps {
}

export function Permissions(props: IPermissionsProps) {
  const dispatch = useAppDispatch();
  const permissions = useTypedSelector(state => state.user.permissions);
  const [columns, setColumns] = React.useState<ColumnsType<any>>([]);
  const [tableData, settableData] = React.useState<any[]>([]);
  const [currentRole, setCurrentRole] = React.useState<string>('SUPER_ADMIN');
  const [currentSection, setCurrentSection] = React.useState<number>(1);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingData, setLoadingData] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (permissions?.status === 'success') {
      setTableColumns();
      setTableData();
    }
  }, [permissions, currentRole, currentSection]);

  React.useEffect(() => {
    if (permissions?.status === 'idle') {
      setLoadingData(true);
      dispatch(getUserPermissions()).then(() => setLoadingData(false));
    }
  }, []);

  const handleSwitchChange = async (role: string, section: number, checked: boolean) => {
    setCurrentRole(role);
    setCurrentSection(section);
    setLoading(true);
    let permissionID = permissions?.data?.find((perm) => perm.section === section && perm.role === role)?.id;
    await dispatch(patchRolePermission(permissionID, { permission: checked ? 0 : 1 }));
    setLoading(false);
  }

  const setTableColumns = () => {
    const tempColumns: ColumnsType<any> = [];
    tempColumns.push({
      title: 'Section',
      dataIndex: 'section',
      key: 'section',
      render: (text: any, record: any) => {
        return Features[record.section - 1]
      }
    });
    Array.from(new Set(permissions?.data.map((perm) => perm.role))).sort((a: any, b: any) => Roles.indexOf(a) - Roles.indexOf(b))
      .forEach((role: any) => {
        tempColumns.push({
          title: roleMap[role] + (role === 'AUDITOR' ? ' (Read Only)' : ''),
          align: 'center',
          dataIndex: role,
          key: role,
          render: (text: any, record: any) => {
            return <div>
              <BESwitch checked={record[role]}
               disabled={role === 'SUPER_ADMIN' || role === 'AUDITOR' || record.section === 2 || ((role === 'CONTRIBUTOR' || role === 'ADMIN') && record.section === 1)
                || (role === 'CONTRIBUTOR' && (record.section === 3 || record.section === 8 || record.section === 9))}
                size='small'
                loading={loading && role == currentRole && record.section == currentSection}
                onClick={async (checked: boolean) => await handleSwitchChange(role, record.section, checked)} />
            </div>
          }
        });
      });
    setColumns(tempColumns);
  }
  const setTableData = () => {
    const TempData: any = [];
    Features.forEach((feature, index) => {
      let tempRow: any = {};
      Roles.forEach((role) => {
        const permission = permissions?.data?.find((perm) => perm.section === index + 1 && perm.role === role);
        tempRow[role] = permission?.permission === 0 ? false : true;
      });
      tempRow['section'] = index + 1;
      TempData.push(tempRow);
    });
    settableData(TempData);
  }
  if (loadingData) return <GlobalLoader height='50vh' />
  return (
    <>
    <TopSection />
    <div className='permissions'>
      <SettingsBackButton title='Back' style={{marginBottom:'1rem'}}/>
      <BETable columns={columns} data={tableData} />
    </div>
    </>
  );
}
