import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../Config/Hooks/useAppDispatch";
import { getAllTemplateDrafts, getAllReportBusinessUnits, getAllReports, deleteReportTemplate } from "../../Actions/template";
import { useTypedSelector } from "../../../../Config/Hooks/useTypedSelector";
import { GlobalLoader } from "../../../../Components/GlobalLoader";
import { PrimaryTheme } from "../../../../Config/Theme/theames";
import { BEButton } from "../../../../Components/BEFormItems/BEButton";
import Reporting from "../../../../Components/BEIcons/Reporting";
import { AddTemplate } from "../Drawers/AddTemplate";
import { ColumnsType } from "antd/es/table";
import { BETable } from "../../../../Components/BETable";
import { BEInput } from "../../../../Components/BEFormItems/BEInput";
import { Col, Row } from "antd";
import AssignTemplate from "../Drawers/AssignTemplate";
import BEStatusTag from "../../../../Components/BEStatusTag";
import DeleteIcon from "../../../../Components/BEIcons/DeleteIcon";
import EditIcon from "../../../../Components/BEIcons/EditIcon";
import { setCurrentAddTemplateState, setCurrentTemplate, setSelectedFramework } from "../../../../Redux/ReportingReducer";
import DownloadIcon from "../../../../Components/BEIcons/DownloadIcon";

const AllTemplates = () => {
  const dispatch = useAppDispatch();
  const templateDrafts = useTypedSelector(
    (state) => state.reporting.TemplateDrafts
  );
  const [openAddTemplate, setOpenAddTemplate] = useState(false);
  const [openAssignTemplate, setOpenAssignTemplate] = useState(false)
  const [currentTemplate, setCurrentTemplateI] = useState<any>(null);
  const reportBusinessUnits = useTypedSelector(state => state.reporting.reportBusinessUnits)
  const reports = useTypedSelector(state => state.reporting.allReports)

  const handleDelete = async (id: any) => {
    await dispatch(deleteReportTemplate(id))
  }
  const handleEdit = async (template: any) => {
    dispatch(setCurrentTemplate(template))
    dispatch(setCurrentAddTemplateState(3))
    setOpenAddTemplate(true)
  }
  useEffect(() => {
    if (openAddTemplate === false) {
      dispatch(getAllTemplateDrafts());
      if (reportBusinessUnits?.status === 'idle')
        dispatch(getAllReportBusinessUnits())
      if (reports?.status === 'idle')
        dispatch(getAllReports())
    }
  }, [openAddTemplate]);

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text: any, data: any) =>
        <span
          style={{
            fontWeight: 'bold',
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
          onClick={() => handleEdit(data)}
        >{text}</span>
    },
    {
      title: "Framework",
      dataIndex: "framework",
    },
    {
      title: "Applies To",
      dataIndex: "framework",
      render: (value, data: any) => {
        // if (reports?.data?.find((report: any) => report.template === data.id))
        //   return (<p>Assigned</p>)
        // else
        if (data.topicsChosen)
          return (<p style={{ color: PrimaryTheme.primaryGreen, cursor: 'pointer', fontWeight: 500 }} onClick={() => setOpenAssignTemplate(true)}
            onMouseEnter={() => {
              setCurrentTemplateI(data)
            }}
          >Assign to business units</p>)
      }
    },
    {
      title: "Status",
      dataIndex: "topicsChosen",
      render: (text, record) => (record.topicsChosen ? <BEStatusTag status="success">Published</BEStatusTag>
        : <BEStatusTag status="warning">Draft</BEStatusTag>
      ),
    },
    {
      title: "Action",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "1rem" }}>
          <div style={{ height: '1rem' }}
            onClick={() => handleEdit(record)}
          >
            <EditIcon
              inheritSize
              stroke={PrimaryTheme.primaryGray}
            />
          </div>
          <div style={{ height: '1rem' }}
            onClick={() => handleDelete(record.id)}
          >
            <DeleteIcon
              inheritSize
              stroke={PrimaryTheme.primaryGray}
            />
          </div>
        </div>
      ),
    }
  ];

  return (
    <div>
      {templateDrafts?.data.length === 0 && templateDrafts?.status === 'success' ?
        (
          <div className="empty-state">
            <div>
              <div className="imgs" style={{ height: "2.5rem" }}>
                <Reporting stroke={PrimaryTheme.primaryGreen} inheritSize />
              </div>
            </div>
            <p style={{ color: PrimaryTheme.primaryBlack }}>
              No templates have been added yet
            </p>
            <BEButton
              className="primary"
              size="large"
              onClick={() => {
                dispatch(setCurrentAddTemplateState(1))
                dispatch(setCurrentTemplate(null))
                dispatch(setSelectedFramework(null))
                setOpenAddTemplate(true);
              }}
            >
              + Add Template
            </BEButton>
          </div>
        ) : (
          <div></div>
        )}
      <AddTemplate open={openAddTemplate} setOpen={setOpenAddTemplate} />
      {/* <AssignTemplate template={currentTemplate?.id}
        open={openAssignTemplate} setOpen={setOpenAssignTemplate} /> */}

      {
        (templateDrafts?.data.length > 0 || templateDrafts?.status === 'loading') &&
        <div style={{ padding: "1rem 0" }}>
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Col span={8}>
              <BEInput search placeholder="Search for template" size="large" />
            </Col>
            <BEButton
              className="primary"
              size="large"
              onClick={() => {
                dispatch(setCurrentAddTemplateState(1))
                dispatch(setSelectedFramework(null))
                dispatch(setCurrentTemplate(null))
                setOpenAddTemplate(true);
              }}
            >
              + Add Template
            </BEButton>
          </Row>

          <div style={{ padding: '1rem 0' }}>
            <BETable
              loading={templateDrafts?.status === 'loading'}
              data={[...templateDrafts?.data].sort((a, b) => (a.id > b.id )? 1 : -1)}
              columns={columns}
            />
          </div>
        </div>}
    </div>
  );
};

export default AllTemplates;
