import React, { useEffect, useState } from "react";
import { StepCollapse } from "../../../../../../Components/BECollapse/StepCollapse";
import { Col, Form, Row } from "antd";
import { LableRequired } from "../../../../../../Components/BEFormItems/LableRequired";
import { BEInput } from "../../../../../../Components/BEFormItems/BEInput";
import { BEButton } from "../../../../../../Components/BEFormItems/BEButton";
import BEMultipleSelect from "../../../../../../Components/BEMultipleSelect";
import { useTypedSelector } from "../../../../../../Config/Hooks/useTypedSelector";
import { useAppDispatch } from "../../../../../../Config/Hooks/useAppDispatch";
import { addCoreSupplierAssessment, patchCoreSupplierAssessment } from "../../../../Actions/supplierAction";
import { setSuppliersSurveys, setCreateSurveyStep } from "../../../../../../Redux/SupplierReducer";

export interface IStep1Props {
  open: boolean;
  setOpen: Function;
  onCancle: Function;
}

// const years = [
//   "2022-23",
//   "2023-24",
//   "2024-25",
// ]

const Step1 = (props: IStep1Props) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch()
  const surveys = useTypedSelector((state) => state.supplier.SuppliersSurveys);
  const yearEnd = useTypedSelector(state => state.companies.companyDetails?.data?.reporting_year_end);
  const supplierCreateSurveyStep = useTypedSelector(state => state.supplier.createSurveyStep);
  const [loading, setLoading] = useState(false);
  const currentAssessment = useTypedSelector(state => state.supplier.currentAssessment);
  const supplierAssessmentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);

  useEffect(() => {
    if (currentAssessment) {
      form.setFieldsValue({
        title: currentAssessment.title,
        // reporting_year: currentAssessment.reporting_year,
      })
    }
    else form.resetFields()
  }, [currentAssessment, props.open])


  const onSubmit = async () => {
    setLoading(true);
    const obj = {
      title: form.getFieldValue("title"),
      // reporting_year: yearEnd !== 12 ? `${form.getFieldValue("reporting_year").slice(0, 4)}-12-31` : `${Number(form.getFieldValue("reporting_year").slice(0, 4)) + 1}-01-01`,
      type: 'N/A',
      internal: false,
      reporting_year: `${supplierAssessmentYear}-12-31`,
      status:1
    };
    if (!currentAssessment)
      await dispatch(addCoreSupplierAssessment(obj))
    else
      await dispatch(patchCoreSupplierAssessment(currentAssessment.id, obj))
    setLoading(false)
  };

  return (
    <div>
      <StepCollapse
        step={1}
        heading="Survey Details"
        subheading="Select the topics which you want to include in this survey, and also define their order of priority for the company"
        open={props.open}
        setOpen={() => {
          dispatch(setCreateSurveyStep(
            supplierCreateSurveyStep === 1 ? 0 : 1
          ))
        }}
      >
        <div style={{ width: "50%", padding: "1rem" }}>
          <Form form={form} onFinish={onSubmit}>
            <Row gutter={16}>
              <Col span={12}>
                <LableRequired>Title</LableRequired>
                <Form.Item
                  name="title"
                  rules={[{ required: true, message: "Title is required" }]}
                >
                  <BEInput size="large" placeholder="Enter title" />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row gutter={16}>
              <Col span={12}>
                <LableRequired>Reporting Year</LableRequired>
                <Form.Item
                  name="reporting_year"
                  rules={[{ required: true, message: "Reporting Year is required" }]}
                >
                  <BEMultipleSelect
                    name="reporting_year"
                    data={years}
                    form={form}
                    placeholder="Select Reporting Year"
                    single
                    isDataStrArray
                  />
                </Form.Item>
              </Col>
            </Row> */}

            <Col span={24} style={{ marginTop: "1rem" }}>
              <BEButton
                loading={loading}
                htmlType="submit"
                size="large"
                className="primary"
              >
                Save & Next
              </BEButton>
              <BEButton
                size="large"
                style={{ marginLeft: "1rem" }}
                onClick={() => props.onCancle()}
              >
                Cancel
              </BEButton>
            </Col>
          </Form>
        </div>
      </StepCollapse>
    </div>
  );
};

export default Step1;
