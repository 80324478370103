import * as React from 'react';
import TrackerIcon from '../../../../Components/BEIcons/TrackerIcon';
import DataEntryIcon from '../../../../Components/BEIcons/DataEntryIcon';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { BETabButton } from '../../../../Components/BETabButton';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { MonthsSlicer } from './MonthsSlicer';

export interface ITopSectionDMProps {
  section: 'dataEntry' | 'tracker';
  setSection: (section: 'dataEntry' | 'tracker') => void;
}

export function TopSectionDM(props: ITopSectionDMProps) {
  const dispatch = useAppDispatch();
  const myMetricsDM = useTypedSelector((state) => state.dataManager.metrics)
  const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
  const role = useTypedSelector((state) => state.user.userRole);
  const [loading, setLoading] = React.useState(false);


  // const ChangeStatus = async () => {
  //   let dataToApprove: any = [];
  //   myMetricsDM.myMetricsData?.data.map((item: any) => {
  //     if (role !== 'CONTRIBUTOR') {
  //       if (item?.status === 1) {
  //         dataToApprove.push({
  //           id: item.id,
  //           status: 2
  //         })
  //       }
  //     }
  //     else {
  //       if (item?.status === 0) {
  //         dataToApprove.push({
  //           id: item.id,
  //           status: 1
  //         })
  //       }
  //     }

  //   });
  //   setLoading(true);
  //   await dispatch(editMultipleMetricsData(dataToApprove, currentBusinessUnit));
  //   setLoading(false);
  // }

  return (
    <div className='top-container'>
      <BETabButton
        title='DATA ENTRY'
        active={props.section === 'dataEntry'}
        onClick={() => props.setSection('dataEntry')}
        icon={<DataEntryIcon inheritSize stroke={props.section === 'dataEntry' ? PrimaryTheme.primaryGreen : PrimaryTheme.primaryGray} />}
      />
      <BETabButton
        title='TRACKER'
        active={props.section === 'tracker'}
        onClick={() => props.setSection('tracker')}
        icon={<TrackerIcon inheritSize stroke={props.section === 'tracker' ? PrimaryTheme.primaryGreen : PrimaryTheme.primaryGray} />}
      />
      <div className='year' style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', gap: '1rem' }}>
        
        {/* {props.section === 'dataEntry' && <TopTabSearch/>} */}
        <MonthsSlicer />
      </div>
      {/* {
        role === 'CONTRIBUTOR' ?
          <BEButton className='primary' size='large'
            onClick={ChangeStatus}
            loading={loading}
          >
            Submit for Approval
          </BEButton>
          :
          props.section === 'dataEntry' &&
          <BEButton className='primary' size='large'
            onClick={ChangeStatus}
            loading={loading}
          >
            Approve All
          </BEButton>
      } */}
    </div>
  );
}
