

import React from 'react'
import IconProps from './Interface/IconProps'

const RiskManagementIcon = (props: IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
	  fill="none" xmlns="http://www.w3.org/2000/svg">
	  <path d="M13.4 19L12 17.6L13.575 16L12 14.425L13.4 13L15 14.6L16.575 13L18 14.425L16.4 16L18 17.6L16.575 19L15 17.425L13.4 19ZM3 17C3.28333 17 3.52083 16.9042 3.7125 16.7125C3.90417 16.5208 4 16.2833 4 16C4 15.7167 3.90417 15.4792 3.7125 15.2875C3.52083 15.0958 3.28333 15 3 15C2.71667 15 2.47917 15.0958 2.2875 15.2875C2.09583 15.4792 2 15.7167 2 16C2 16.2833 2.09583 16.5208 2.2875 16.7125C2.47917 16.9042 2.71667 17 3 17ZM3 19C2.16667 19 1.45833 18.7083 0.875 18.125C0.291667 17.5417 0 16.8333 0 16C0 15.1667 0.291667 14.4583 0.875 13.875C1.45833 13.2917 2.16667 13 3 13C3.61667 13 4.17917 13.1708 4.6875 13.5125C5.19583 13.8542 5.56667 14.3167 5.8 14.9C6.45 14.7167 6.97917 14.3583 7.3875 13.825C7.79583 13.2917 8 12.6833 8 12V8C8 6.61667 8.4875 5.4375 9.4625 4.4625C10.4375 3.4875 11.6167 3 13 3H14.15L12.575 1.425L14 0L18 4L14 8L12.575 6.6L14.15 5H13C12.1667 5 11.4583 5.29167 10.875 5.875C10.2917 6.45833 10 7.16667 10 8V12C10 13.2167 9.60833 14.2875 8.825 15.2125C8.04167 16.1375 7.05 16.7083 5.85 16.925C5.65 17.5417 5.2875 18.0417 4.7625 18.425C4.2375 18.8083 3.65 19 3 19ZM1.4 7L0 5.6L1.575 4L0 2.425L1.4 1L3 2.6L4.575 1L6 2.425L4.4 4L6 5.6L4.575 7L3 5.425L1.4 7Z"
	  fill={props.stroke ? props.stroke : "white"}/>
	  </svg>
	  
  );
}

export default RiskManagementIcon