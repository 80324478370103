import { AppDispatch } from "../../../Redux/store"
import { RootState } from "../../../Redux/store"
import dayjs from "dayjs";

// emmisions 
import {
  // getScope1CalculationsForMonths,
  // getScope2CalculationsForMonths
  getAllEmissionCalculations
} from "../../../Features/Emissions/Actions";

// set years
import { setDateSlicerYear as setEmmisionsYear } from "../../../Redux/EmmisionsReducer";
import { setAssessmentYear as setMaterialityAssessmentYear } from "../../../Redux/MatirialityReducer";
import { setAssessmentYear as SetSupplierAssessmentYear } from "../../../Redux/SupplierReducer";
import { setSelectedYear as setReportingYear } from "../../../Redux/ReportingReducer";
import { setDateSlicerYearDM as setDataManagerYear } from "../../../Redux/DataManagerReducer";

import {
  getAssignedDepartmentByCategory, 
  getContributorsByMetrics, 
  getDataLabels, 
  getDerivedMetricDataByMonth, 
  getDerivedMetrics, 
  getMetricsStatus, 
  getMyDerivedMetrics, 
  getMyMetricsDataByMonth, 
  getOmittedMetrics,
  getMyMetricsData,
  getMyMetricsDataBulkUploadFiles
} from "../../../Features/DataManager/Actions";
import { getSupplierSurveys } from "../../../Features/Suppliers/Actions/supplierAction";
import { getAppVersionAndDate,getFinancialYears } from "../HomeActions";
import { getAllUsers, getUserProfile } from "../UsersManagementActions";
import { getFYByDate } from "../../../Config/Functions/UsefullFunctions";
import { getMyDashboardMetrics, getQuickSightDashboardDetails, getMyDerivedDashboardMetrics } from "../../../Features/Dashboard/Actions";
import { setFinancialYears } from "../../../Redux/CommonReducer";
import { getDerivedMetricsRepresentationGraph } from "../calculatedMetricsActions";
import { getMyDerivedMetricTargets, getMyMetricTargets } from "../../../Features/TargetsNew/Actions";
import { getAssignedDepartmentByReportTopics, getAssignedReportToUsers, getOmittedReportTopics, getTopicsForGRESB } from "../../../Features/Reporting/Actions/template";
import { getGRESBSlugData, getGresbAssetData, getGresbEntities, getGresbEntityEvidences } from "../../../Features/Reporting/Actions/gresbActions";
import { getCombinedSelectedMetrics, getMetrics, getMyMetrics } from "../OnboardingStep4Actions";
import { useTypedSelector } from "../../../Config/Hooks/useTypedSelector";
import { decryptRole } from "../LoginActions";
import { setRole } from "../../../Redux/UserReducer";


export const handleBackgroundAPICalls = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  const enryptedRole = localStorage.getItem('role');
  let role = decryptRole(enryptedRole as string);
  if(enryptedRole){
    dispatch(setRole(role));
  }
  
  let permissions = state.user.permissions;
  let thisRolePermissions:any = {}; // 1: Dashboard, 2: DataManager, 3: EntityManager, 4: Reporting, 5: Emmisions, 6: Targets, 7: Surveys,8: Settings 9:COnfiguration
  for(let i = 1; i < 10; i++) { 
    thisRolePermissions[i] = permissions?.data?.find((perm: any) => perm.section === i && perm.role === role)?.permission;
  }

  // set initial years according to company reporting year end
  await dispatch(setCurrentYears());

  // dashboard & data manager
  if (state.onBoarding.metrics.directMetrics?.status === 'idle') await dispatch(getMetrics());
  if (state.dataManager.assignedDepartmentByCategory?.status === 'idle') dispatch(getAssignedDepartmentByCategory());
  if (state.reporting.assignedDepartmentByReportTopics?.status === 'idle') dispatch(getAssignedDepartmentByReportTopics());
  if (state.dataManager.metrics.omittedMetrics?.status === 'idle') dispatch(getOmittedMetrics());
  if (state.targets.MyMetricTargets?.status === 'idle') dispatch(getMyMetricTargets());
  if (state.targets.MyDerivedMetricTargets?.status === 'idle') dispatch(getMyDerivedMetricTargets());
  if (state.reporting.omittedReportTopics?.status === 'idle') dispatch(getOmittedReportTopics());
  if (state.onBoarding.metrics.derivedMetrics?.status === 'idle') await dispatch(getDerivedMetrics());
  if (state.onBoarding.metrics.myDerivedMetrics?.status === 'idle') dispatch(getMyDerivedMetrics());
  if (state.onBoarding.metrics.myDerivedMetricsDataByMonthAndBusinessUnit?.status === 'idle') dispatch(getDerivedMetricDataByMonth());
  if (state.onBoarding.metrics.myMetrics?.status === 'idle') dispatch(getMyMetrics());
  if (state.dataManager.metrics.metricStatus?.status === 'idle') dispatch(getMetricsStatus());
  if (state.onBoarding.metrics.myMetricsData?.status === 'idle') dispatch(getMyMetricsData());
  if (state.onBoarding.metrics.combinedSelectedMetrics?.status === 'idle') dispatch(getCombinedSelectedMetrics());
  if (state.dataManager.myMetricDataBulkUploadFiles?.status === 'idle') dispatch(getMyMetricsDataBulkUploadFiles());
  if (state.dataManager.metrics.myDashboardMetrics?.status === 'idle') dispatch(getMyDashboardMetrics());
  if (state.dataManager.labels?.status === 'idle') dispatch(getDataLabels());
  if (state.reporting.assignReportToUsers?.status === 'idle') dispatch(getAssignedReportToUsers());
  if (state.onBoarding.metrics.myMetricsDataByMonthAndBusinessUnit?.status === 'idle') dispatch(getMyMetricsDataByMonth());
  if (state.dataManager.metrics.myDerivedDashboardMetrics?.status === 'idle') dispatch(getMyDerivedDashboardMetrics());
  if (state.reporting.selectedGresbAssetTopics?.status === 'idle') dispatch(getTopicsForGRESB());
  if (state.dataManager.contributorsByMetrics?.status === 'idle') dispatch(getContributorsByMetrics());
  if (state.reporting.gresbAssetData?.status === 'idle') dispatch(getGresbAssetData());
  if (state.reporting.gresbEntityData?.status === 'idle') dispatch(getGresbEntities());
  if (state.reporting.gresbSlugData?.status === 'idle') dispatch(getGRESBSlugData());
  // if (state.dataManager.metrics.myDerivedMetricsData?.status === 'idle') dispatch(getMyDerivedMetricsData(thisFY));
  if (state.settings.derivedMetricsRepresentationGraph?.status === "idle") dispatch(getDerivedMetricsRepresentationGraph());
  // if (state.common.quickSightDashboard?.status === 'idle') dispatch(getQuickSightDashboardDetails());
  if (state.common.version.number === '') dispatch(getAppVersionAndDate());
  if (state.user.userProfile?.status === 'idle') dispatch(getUserProfile());
  if(state.common.financialYears?.status === 'idle') dispatch(getFinancialYears());
  
  if(thisRolePermissions[5]){
    // emmisions
    let thisFY = await dispatch(getCurentFY());
    // if (state.emissions.calculations.scope1calculationForMonths?.status === 'idle') dispatch(getScope1CalculationsForMonths(thisFY))
    // if (state.emissions.calculations.scope2calculationForMonths?.status === 'idle') dispatch(getScope2CalculationsForMonths(thisFY))
      if(state.emissions.calculations.scope1calculationForMonths?.status === 'idle') dispatch(getAllEmissionCalculations(thisFY))
  }

  // supplier & internal
  if (role !== 'CONTRIBUTOR') {
    if (state.supplier.SuppliersSurveys?.status === 'idle') dispatch(getSupplierSurveys())
  }


  if (thisRolePermissions[8]) {
    if (state.userMgmt?.status === 'idle') dispatch(getAllUsers());
  }

  //entity handled in entity cascader
}

export const setCurrentYears = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  let currentYear = await dispatch(getCurentFY());
  let years = getState().common.financialYears?.data;
  if (!years.includes(currentYear))
    dispatch(setFinancialYears(      //-- we get the FYs from selected metrics(this api takes time to load so we set the current year first to avoid undefined error)
      {
        status: 'success',
        data: [currentYear, ...years]
      }))
  // console.log('currentYear', currentYear)
  dispatch(setEmmisionsYear(currentYear));
  dispatch(setMaterialityAssessmentYear(currentYear));
  dispatch(SetSupplierAssessmentYear(currentYear));
  dispatch(setReportingYear(currentYear));
  dispatch(setDataManagerYear(currentYear));
}

export const getCurentFY = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  let state = getState();
  let YearEnd = state.companies.companyDetails?.data?.reporting_year_end;
  const year = getFYByDate(dayjs().format('YYYY-MM-DD'), YearEnd || '12-31');
  return year;
}
