import { Dropdown, MenuProps, Select, Tooltip } from 'antd';
import React from 'react';
import MenuIcon from '../../../../Components/BEIcons/MenuIcon';
import DeleteIcon from '../../../../Components/BEIcons/DeleteIcon';
import { deleteMyDashboardMetrics, patchMyDashboardMetrics } from '../../Actions';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { TrendGraph } from '../../../DataManager/Components/DataEntry/KPISdata/KPIData/TrendGraph';
import { GlobalLoader } from '../../../../Components/GlobalLoader';
import { ResponsiveContainer } from 'recharts';
import ToolTip from '../../../../Components/BEToolTip';
import { BEMessage } from '../../../../Components/BEMessage';
import DragIcon from '../../../../Components/BEIcons/DragIcon';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';

interface IDashboardBarChartProps {
    dashboardMetricId: number;
    metricName: string;
    metricId: number;
}

const DashboardLineChart = (props: IDashboardBarChartProps) => {
    const dispatch = useAppDispatch();
    const options = [
        { value: 'monthly', label: 'Monthly' },
        { value: 'yearly', label: 'Yearly' }
    ];
    const [isFullYear, setIsFullYear] = React.useState<boolean>(false);
    const [load, setLoad] = React.useState<boolean>(false);
    const role = useTypedSelector((state) => state.user.userRole);
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <span style={{ color: "rgba(237, 98, 98, 1)", display: "flex", gap: "8px" }}
                    onClick={async () => {
                        if (role === "AUDITOR" || role === "CONTRIBUTOR") {
                            BEMessage.error("You are not authorized to perform this action");
                            return;
                        }
                        setLoad(true);
                        const data = {
                            graph: false
                        }
                        await dispatch(patchMyDashboardMetrics(props.dashboardMetricId, data));
                        setLoad(false);
                    }}>
                    <span style={{ width: "16px" }}>
                        <DeleteIcon inheritSize stroke={"rgba(237, 98, 98, 1)"} />
                    </span>
                    Remove Metric
                </span>
            ),
        }
    ];

    const handleChange = (value: any) => {
        setIsFullYear(value === "yearly" ? true : false);
    }

    return (
        <div style={{
            padding: "1rem 0", marginBottom: "1rem",
            display: "flex", flexDirection: "column", alignItems: "center",
            borderRadius: "6px", border: "1px solid rgba(243, 243, 243, 1)"
        }}>
            <div style={{ display: "flex", flexDirection: "row", gap: "1 rem", width: "95%" }}>
                <span style={{
                    fontSize: "12px", lineHeight: "15px", fontWeight: "600", paddingBottom: "2px",
                    opacity: "0.9", borderBottom: "2px solid rgba(79, 165, 86, 1)"
                }}>
                    <ToolTip title={props.metricName}>  {props.metricName?.toUpperCase()}</ToolTip>
                </span>
                <div style={{ flexGrow: 1 }} />
                <div style={{ width: "0.8rem", cursor:"grab" }}>
                    <DragIcon fill={PrimaryTheme.primaryGray} inheritSize />
                </div>
                {/* <Select
                    style={{ width: "25%", visibility: "hidden" }}
                    size='large'
                    value={isFullYear ? "yearly" : "monthly"}
                    defaultValue={isFullYear ? "yearly" : "monthly"}
                    onChange={handleChange}
                    options={options}
                /> */}
                <Dropdown menu={{ items }}>
                    <a style={{ width: "5%", justifyContent: "end", display: "flex", alignItems: "center" }} onClick={(e) => e.preventDefault()}>
                        <MenuIcon inheritSize />
                    </a>
                </Dropdown>
            </div>
            <ResponsiveContainer width="100%">
                {load ? <GlobalLoader height='20vh' /> :
                    <>
                        {props.metricId !== undefined ? <TrendGraph metricID={props.metricId} isFullYear={isFullYear} />
                            : "No data available"}
                    </>}
            </ResponsiveContainer>
        </div>
    );
};

export default DashboardLineChart;