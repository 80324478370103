import * as React from 'react';
import StackedColumnCharts from '../../../../../../Components/BECharts/StackedColumnCharts';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { rotateArray } from '../../../../../../Config/Functions/UsefullFunctions';
import { shortMonths } from '../../../../../../Config/Data/genralData';
import { filterDataByMonthOrBU } from '../../../../Actions';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';

export interface ITotalEmissionsByScopeProps {
}

export function TotalEmissionsByTypeGraph(props: ITotalEmissionsByScopeProps) {
  const dispatch = useAppDispatch();
  const yearEnd = useTypedSelector(state => state.companies.yearEnd);
  const [chartData, setChartData] = React.useState<any>([]);
  const scope1Configurations = useTypedSelector(state => state.emissions.configurations.scope1Configurations);
  const scope2Configurations = useTypedSelector(state => state.emissions.configurations.scope2Configurations);
  const [filteredScope1Calculations, setScope1FilteredCalc] = React.useState<any[]>([]);
  const [filteredScope2Calculations, setScope2FilteredCalc] = React.useState<any[]>([]);
  const currentBU = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
  const currentBG = useTypedSelector(state => state.common.homePage.currentBusinessGroup);
  const currentMonth = useTypedSelector(state => state.emissions.dateSlicer.currentMonth);
  const scope1CalculationsByMonth = useTypedSelector(state => state.emissions.calculations.scope1calculationForMonths);
  const scope2CalculationsByMonth = useTypedSelector(state => state.emissions.calculations.scope2calculationForMonths);

  const filterDataFunction = async ()=>{
    let res = await dispatch(filterDataByMonthOrBU(true, true))
    setScope1FilteredCalc(res?.scope1Data)
    setScope2FilteredCalc(res?.scope2Data)
  }

  React.useEffect(() => {
    filterDataFunction()
  }, [currentBU, currentBG, scope1CalculationsByMonth, scope2CalculationsByMonth, currentMonth])


  React.useEffect(() => {
      let myMetricCatagoryMap:any = {};
      scope1Configurations?.data.forEach((item:any) => {
          myMetricCatagoryMap[item.my_metric] = item.emission_category;
      });
      let allEmissionCategory = Array.from(new Set( scope1Configurations?.data.map((item:any) => item.emission_category)));
      let allEmissionCategoryArray:any = allEmissionCategory.map((item:any) => {
        return{
          name:item,
          data: new Array(12).fill(0)
        }
      });
      filteredScope1Calculations.forEach((item:any) => {
        let monthIndex = ((item.month-1)+ 12 - yearEnd)%12;
        let index = allEmissionCategoryArray.findIndex((category:any) => category.name === myMetricCatagoryMap[item.my_metric]);
        if (allEmissionCategoryArray[index] !== undefined) {
          allEmissionCategoryArray[index].data[monthIndex] += item.tco2e;
        }
      });
      allEmissionCategoryArray.push({
        name:'Electricity',
        data: new Array(12).fill(0)
      })
      filteredScope2Calculations.forEach((item:any) => {
        let monthIndex = ((item.month-1)+ 12 - yearEnd)%12;
        allEmissionCategoryArray[allEmissionCategoryArray.length-1].data[monthIndex] += item.tco2e;
      });
      setChartData(allEmissionCategoryArray);
  }, [filteredScope1Calculations,filteredScope2Calculations, scope1Configurations,scope2Configurations]);

  return (
    <div style={{ margin: "3rem 0" }} >
    <p className='chart-title'>Total emissions by Source (tCO2e)</p>
      <div style={{ padding: "1rem" }} className='bordered-container'>
        <StackedColumnCharts
          colorTheme={3}
          data={chartData}
          xAxisLabels={rotateArray(shortMonths, yearEnd, true)}
          // chartTitle="Total Emissions by Source (tCO2e)"
          yAxisTitle="tCO2e"
          yAxisLabel="Tonnes of Co2 equivalent"
        />
      </div>
    </div>
  );
}
