import * as React from 'react';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { NewBETable } from '../../../../../../Components/BETable/newBETable';
import { GlobalLoader } from '../../../../../../Components/GlobalLoader';
import { VaultFolder } from '../../../../Components/VaultFolder';
import { navigateTo } from '../../../../../../Navigation/NavigationManager';
import { ColumnsType } from 'antd/es/table';
import { useParams } from 'react-router-dom';
import File, { FileIconWithMenu } from '../../../../Components/File';
import DownloadIcon from '../../../../../../Components/BEIcons/DownloadIcon';

export interface ISupplierFoldersProps {
}

export function AllSupplierEvidences (props: ISupplierFoldersProps) {
  const supplierEvidence = useTypedSelector(state => state.vault.supplierEvidence);
  const view = useTypedSelector(state => state.vault.view);
  const assessment = useParams<any>().fy_id;
  const supplier = useParams<any>().metric_id

  const columns:ColumnsType<any>=[
    {
      title: 'S. No.',
      dataIndex: 'sno',
      key: 'sno',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Question',
      dataIndex: 'question',
    },
    {
      title: 'Download',
      render: (text: any) => {
        // download the file
        return (
          <a href={text}  target='_blank' >
            <DownloadIcon/>
          </a>
        )
      }
    }
  ]

  return (
    <div className='system-folder-container' >
    {supplierEvidence.status !== 'loading' ?
      view=='grid' ? <div style={{ display: 'grid', gridTemplateColumns:'repeat(auto-fill, minmax(200px, 1fr))', gap: '1rem' }} >
      {

        supplierEvidence.data.filter((item:any)=>item.assessment===assessment && item.supplier===supplier)
        .map((item:any)=> {
          return <FileIconWithMenu 
          menuItems={
            [
              {
                key: '1',
                label: (
                  <a href={item.evidence} target='_blank'>
                    Download
                  </a>
                ),
            },
            ]
          } 
            iconSrc=''
            fileName={item.question}
            />
        })

      }
    </div>
    : <div>
      <NewBETable
        pagination
        columns={columns}
        data={
          supplierEvidence.data.filter((item:any)=>item.assessment===assessment && item.supplier===supplier)
            }
        />
     </div>
    :<GlobalLoader height='20rem' />}
  </div>
  );
}
