import * as React from 'react';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { getMetricsChildrenFromBEcode } from '../../../../views/Actions/calculatedMetricsActions';
import { BEMessage } from '../../../../Components/BEMessage';
import { GlobalLoader } from '../../../../Components/GlobalLoader';
import ToolTip from '../../../../Components/BEToolTip';
import StackedColumnCharts from '../../../../Components/BECharts/StackedColumnCharts';
import { Dropdown, MenuProps } from 'antd';
import MenuIcon from '../../../../Components/BEIcons/MenuIcon';
import DeleteIcon from '../../../../Components/BEIcons/DeleteIcon';
import DragIcon from '../../../../Components/BEIcons/DragIcon';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { rotateArray } from '../../../../Config/Functions/UsefullFunctions';
import { shortMonths } from '../../../../Config/Data/genralData';

export interface ICalculatedDashboardMetricsProps {
    bcode: string;
}

export function CalculatedDashboardMetrics(props: ICalculatedDashboardMetricsProps) {
    const combinedSelectedMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.combinedSelectedMetricsPerYear);
    const derivedMetricsRepresentationGraph = useTypedSelector((state) => state.settings.derivedMetricsRepresentationGraph);
    const tableDataDM = useTypedSelector(state => state.dataManager.metrics.tableDataDM);
    const currentMonth = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentMonth);
    const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    const tableFullYearDerivedMetricsDataDM = useTypedSelector(state => state.dataManager.metrics.tableFullYearDerivedMetricsDataDM);
    const myMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.myMetricsPerYear);
    const yearEnd = useTypedSelector(state => state.companies.yearEnd);
    const [dataToUse, setDataToUse] = React.useState<any>({});
    const [chartData, setChartData] = React.useState<any[]>([]);
    const [metricTitle, setMetricTitle] = React.useState<string>("");
    const [load, setLoad] = React.useState<boolean>(false);
    const [noMetricsSelected, setNoMetricsSelected] = React.useState<boolean>(false);
    const [average, setAverage] = React.useState<number>(0);
    const [unit, setUnit] = React.useState("");
    let monthsContainingData: Set<number> = new Set<number>();
    let currentTotalvalue = 0;

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <span style={{ color: "rgba(237, 98, 98, 1)", display: "flex", gap: "8px" }}
                    onClick={async () => { }}>
                    <span style={{ width: "16px" }}>
                        <DeleteIcon inheritSize stroke={"rgba(237, 98, 98, 1)"} />
                    </span>
                    Remove Metric
                </span>
            ),
        }
    ];
    React.useEffect(() => {
        setDataToUse({ ...tableDataDM, ...tableFullYearDerivedMetricsDataDM?.data });
    }, [tableDataDM, tableFullYearDerivedMetricsDataDM, currentMonth, currentYear]);

    const getChartMetricIds = () => {
        const childrenBECodes = getMetricsChildrenFromBEcode(derivedMetricsRepresentationGraph?.data, props.bcode);
        let selectedMetricsIds: number[] = [];
        childrenBECodes.forEach((bcode) => {
            const selectedMetric = combinedSelectedMetricsPerYear?.data?.find((metric) => metric.bcode === bcode);
            if (selectedMetric) {
                selectedMetricsIds.push(selectedMetric.id);
            }
        });
        return selectedMetricsIds;
    }

    const getCurrentData = (id: number) => {
        let currentDataYearly = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let title: string = "";
        if (!dataToUse[id] || !id) return {
            name: combinedSelectedMetricsPerYear?.data?.find((metric) => metric.id === id)?.title,
            data: currentDataYearly
        };
        dataToUse[id]
            .forEach((currentItem: any) => {
                const myMetricData = combinedSelectedMetricsPerYear?.data?.find((metric) => metric.id === id);
                title = myMetricData?.title as string;
                const month = myMetricData?.hasOwnProperty('direct') ? currentItem.month : currentItem.date_of_entry.split("-")[1];
                monthsContainingData.add(Number(month));
                currentDataYearly[Number(month) - 1] = Number((currentDataYearly[Number(month) - 1] + currentItem.value)?.toFixed(2));
                currentTotalvalue += currentItem.value;
            });
        currentDataYearly = rotateArray(currentDataYearly, yearEnd, true);
        return {
            name: title,
            data: currentDataYearly
        }
    };


    const getNewChartData = (metricIds: number[]) => {
        const chartData = metricIds.map((id: number) => {
            return getCurrentData(id);
        });
        return chartData;
    }

    React.useEffect(() => {
        setUnit(combinedSelectedMetricsPerYear?.data?.find((metric) => metric.bcode === props.bcode)?.unit as string);
        setMetricTitle(combinedSelectedMetricsPerYear?.data?.find((metric) => metric.bcode === props.bcode)?.title as string);
        setLoad(myMetricsPerYear?.status === "loading");

        if (myMetricsPerYear?.status === "success") {
            setLoad(true);
            const metricIds = getChartMetricIds();
            if (!metricIds.length) {
                setNoMetricsSelected(true);
                setLoad(false);
                // BEMessage.error("No metrics selected for this derived metric");
                return;
            }
            const newChartData = getNewChartData(metricIds);
            setChartData(newChartData);
            const average = monthsContainingData.size === 0 ? 0 : Math.floor(currentTotalvalue / monthsContainingData.size);
            setAverage(average);
            setLoad(false);
        }

    }, [props.bcode, myMetricsPerYear, dataToUse]);

    return (
        <div style={{
            padding: "1rem 0", marginBottom: "1rem",
            display: "flex", flexDirection: "column", alignItems: "center",
            borderRadius: "6px", border: "1px solid rgba(243, 243, 243, 1)"
        }}>
            {load ? <GlobalLoader height='42vh' /> :
                <>
                    <div style={{ display: "flex", flexDirection: "row", gap: "1 rem", width: "95%" }}>
                        <div style={{ borderBottom: "2px solid rgba(79, 165, 86, 1)", paddingBottom: "2px", }}>
                            <span style={{ fontSize: "12px", lineHeight: "15px", fontWeight: "600", opacity: "0.9" }}>
                                <ToolTip title={metricTitle}>{metricTitle?.slice(0, 26)?.toUpperCase()}{Number(metricTitle?.length) > 26 && " ..."}</ToolTip>
                            </span>
                        </div>
                        <div style={{ flexGrow: 1 }} />
                        <div style={{ width: "0.8rem", height: "1rem", cursor: "grab" }}>
                            <DragIcon fill={PrimaryTheme.primaryGray} inheritSize />
                        </div>
                        <Dropdown menu={{ items }}>
                            <a style={{ width: "5%", justifyContent: "end", display: "flex", alignItems: "center" }} onClick={(e) => e.preventDefault()}>
                                <MenuIcon inheritSize />
                            </a>
                        </Dropdown>
                    </div>
                    {noMetricsSelected ?
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "42vh" }}>
                        <p>
                            No metrics selected for this derived metric
                        </p>
                    </div>
                    :
                    <StackedColumnCharts
                        averageLine={average}
                        height='42vh'
                        data={chartData}
                        yAxisLabel={unit}
                        xAxisLabels={rotateArray(shortMonths, yearEnd, true)}
                    />}
                </>}
        </div>
    );
}
