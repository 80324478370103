import * as React from 'react';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import { PillerMap } from '../../../Step4/TopTabsFilter';
import { BETable } from '../../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import BEStatusTag from '../../../../../Components/BEStatusTag';
import { getFYByDate } from '../../../../../Config/Functions/UsefullFunctions';
import dayjs from 'dayjs';
import { YearSelectOptions } from '../../../../../Components/BEMultipleSelect/YearSelectOptions';
import ToolTip from '../../../../../Components/BEToolTip';
import { SetTableAndGraphDataDM } from '../../../../../Features/DataManager/Components/SetTableAndGraphDataFunctionDM';
import { setDateSlicerYearDM } from '../../../../../Redux/DataManagerReducer';
import { getMetrics, getMyMetrics } from '../../../../Actions/OnboardingStep4Actions';
import { getDerivedMetrics, getMyDerivedMetrics } from '../../../../../Features/DataManager/Actions';

export interface IReviewStep4Props {
}

export function ReviewStep4(props: IReviewStep4Props) {
    const dispatch = useAppDispatch();
    const metrics = useTypedSelector(state => state.onBoarding.metrics.directMetrics);
    const derivedMetrics = useTypedSelector(state => state.onBoarding.metrics.derivedMetrics);
    const myMetrics = useTypedSelector(state => state.onBoarding.metrics.myMetrics);
    const myMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.myMetricsPerYear);
    const myDerivedMetrics = useTypedSelector(state => state.onBoarding.metrics.myDerivedMetrics);
    const myDerivedMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.myDerivedMetricsPerYear);
    const [selectedMetrics, setSelectedMetrics] = React.useState<any>([]);
    const metricsCurrentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    const companyDetails = useTypedSelector(state => state.companies.companyDetails);

    React.useEffect(() => {
        // ******************* setting the default FY for the metrics *******************
        if(companyDetails?.status === 'success') {
          dispatch(setDateSlicerYearDM(getFYByDate(dayjs().format('YYYY-MM-DD'),companyDetails?.data?.reporting_year_end || '2024-02-01')))
        }

        if (metrics?.status === 'idle')
            dispatch(getMetrics())
        if (myMetrics?.status === 'idle')
            dispatch(getMyMetrics())
        if (derivedMetrics?.status === 'idle')
            dispatch(getDerivedMetrics())
        if (myDerivedMetrics?.status === 'idle')
            dispatch(getMyDerivedMetrics())


      },[companyDetails]);


    const columns: ColumnsType<any> = [
        {
            title:'Name',
            dataIndex:'title',
            width: '40%',
            render: (text:any, record:any) => {
                return (
                    <div>
                        <p>
                            {
                            record?.hasOwnProperty('direct') ? <div style={{display:"flex", flexDirection:"row", gap:"1rem"}}>{record.title}  <ToolTip title='The values for C metrics are calculated.'>
                            <BEStatusTag status='success'>C</BEStatusTag>
                          </ToolTip></div>:
                            record.title
                            }</p>
                    </div>
                )
            }
        },{
            title:'Unit',
            dataIndex:'unit',

        }
    ]
    
    React.useEffect(() => {
        if(metrics?.status === 'success' && myMetricsPerYear?.status === 'success' && myDerivedMetricsPerYear?.status === 'success') {
            let selectedMEtricsMap:any = {};
            let derivedMetricsMap:any = {};

            myDerivedMetricsPerYear?.data.forEach((metric) => {
                derivedMetricsMap[metric.derived_metric] = true;
            });

            myMetricsPerYear?.data.forEach((metric) => {
                selectedMEtricsMap[metric.metric] = true;
            });
            let temp:any = []

            console.log('selectedMEtricsMap', selectedMEtricsMap);

            const allMetrics = [...metrics?.data, ...derivedMetrics?.data];
            allMetrics?.forEach((metric) => {
                if((metric?.hasOwnProperty('direct') && derivedMetricsMap[metric.id]) || (selectedMEtricsMap[metric.id] && !metric?.hasOwnProperty('direct'))) {
                    temp.push(metric);
                }
            });
            setSelectedMetrics(temp);
        }
    }, [metrics, myMetricsPerYear, myDerivedMetricsPerYear, derivedMetrics]);

    if (metrics?.status === 'loading' || myMetricsPerYear?.status === 'loading' || myDerivedMetricsPerYear?.status === 'loading' || derivedMetrics?.status === 'loading') {
        return <GlobalLoader />
    }
    return (
        <div className='step-4'>
            <div className='outer-box'>
            <div style={{float:'right',marginTop:'1rem'}}>
                <YearSelectOptions onChange={(value:any) => dispatch(setDateSlicerYearDM(value))} value={metricsCurrentYear} />
            </div>
                {
                    Array.from(new Set(selectedMetrics?.map((item: any) => item.pillars))).map((item: any, index) => {
                        return (
                            <div className='pillar' >
                                <p>{PillerMap[item]}</p>
                                    {
                                        Array.from(new Set(selectedMetrics?.filter((metric: any) => metric.pillars === item).map((item1: any) => item1.category))).map((item2: any, index) => {
                                            return (
                                                <div className='category'>
                                                        <p>{item2}</p>
                                                    {
                                                        Array.from(new Set(selectedMetrics?.filter((metric: any) => metric.category === item2 && metric.pillars === item).map((item3: any) => item3.group))).map((item4: any, index) => {
                                                            return (
                                                                <div className='group'>
                                                                    <p>{item4}</p>
                                                                    <BETable
                                                                        className='metric-table'
                                                                        columns={columns}
                                                                        data={selectedMetrics?.filter((metric: any) => metric.group === item4 && metric.category === item2 && metric.pillars === item)}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            
                        )
                    })
                }
            </div>
            <SetTableAndGraphDataDM />
        </div>
    );
}
