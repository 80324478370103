import React from 'react';
import { BusinessGroupsSection } from '../../../views/Onboarding/Step2/BusinessGroupsSection';
import { BusinessUnitsSection } from '../../../views/Onboarding/Step2/BusinessUnitsSection';
import { TopSection } from '../Components/TopSection';
import SettingsBackButton from '../Components/BackButton';



const EntityManager = () => {

  return (
    <>
    <TopSection/>
    <SettingsBackButton title='Back' style={{margin: "2rem 1.5rem"}}/>
    <div style={{margin: "0 1.5rem"}} className='entity-manager'>
      <BusinessGroupsSection />
      <BusinessUnitsSection />
    </div>
    </>
  )
}

export default EntityManager;