import React from 'react'
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { getMaterialitySurveys } from '../../../Actions/matirialityActions';
import { getCompanyDetails } from '../../../../../views/Actions/OnboardingActions';

interface Props {
  heading?: string
  companyDetails?:{
    name: string;
    logo: string;
  }
}

const SurveyTopNav = (props:Props) => {
  const allSurveys = useTypedSelector(state => state.matiriality.AllSurveys);
  const companyDetails = useTypedSelector(state => state.companies.companyDetails);
  const [surveyName, setSurveyName] = React.useState<any>("");
  const [logo, setLogo] = React.useState<any>("");
  const [companyName, setCompanyName] = React.useState<any>("");
  const dispatch = useAppDispatch();

  const getSurveyId = (url: string): string | null => {
    const params = new URLSearchParams(url);
    return params.get('survey_id');
  };

  React.useEffect(() => {
    if (allSurveys?.status === 'idle') {
      dispatch(getMaterialitySurveys())
    }
    if (companyDetails?.status === 'idle') {
      dispatch(getCompanyDetails());
    }
  }, []);

  React.useEffect(() => {
    setLogo(companyDetails?.data?.logo);
    setCompanyName(companyDetails?.data?.firm_name);
  }, [companyDetails]);

  React.useEffect(() => {
    const surveyId = getSurveyId(window.location.search);
    setSurveyName(allSurveys?.data?.find((survey) => survey.id === Number(surveyId))?.title || "");
    console.log(surveyId, allSurveys, surveyName);
  }, [allSurveys]);

  return (
      <div style={{ backgroundColor: PrimaryTheme.primaryBlack, display: 'flex', alignItems: 'center', height: '79px', padding: '0 1rem', color: 'white' }}>
          <img
                src={props.companyDetails?.logo
                      ? props.companyDetails?.logo
                      : "https://placehold.co/400?text=Company+Logo"
                }
                alt={props.companyDetails?.logo ? props.companyDetails?.logo : ''}
                style={{
                  objectFit: "contain",
                  width: "150px",
                  height: "80%",
                }}
          />
          <p style={{ fontSize: '1.2rem', fontWeight: 600, marginLeft: '1rem' }}>
            {
              props.companyDetails?.name ? props.companyDetails?.name : 'Company Name'
            }
          </p>
          <p style={{ fontSize: '1.2rem', fontWeight: 600, marginLeft: 'auto' }}>
            {
              surveyName ? surveyName : 'MATERIALITY ASSESSMENT'
            }
          </p>
    </div>
  )
}

export default SurveyTopNav