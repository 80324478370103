import * as React from 'react';
import { Row, Col } from 'antd';
import { BEPagination } from '../BEPagination';
import './style.scss';
import { BEInput } from '../BEFormItems/BEInput';

export interface IBECardPaginationProps {
  noOfRows?: number; // default 3, can be 1, 2, 3, 4, 6, 8, 12, 24
  ComponentsArrey: any[];
  noOfColumns?: number; //default 3
  pageNo?: number;
  open: boolean;
  search?: boolean;
  searchText?: string;
  setSearchedValue?: Function;
}


export function BECardPagination(props: IBECardPaginationProps) {
  const [currentData, setCurrentData] = React.useState<Array<any>>([]);
  const [cardsPerPage, setCardsPerPage] = React.useState<number>(0);
  const [pageNo, setPageNo] = React.useState<number>(1);
  const [searchValue, setSearchValue] = React.useState<string>('');

  React.useEffect(() => {
    setCardsPerPage(props.noOfColumns ? props.noOfColumns * (props.noOfRows ? props.noOfRows : 3) : 3);
  }, [props.ComponentsArrey, props.noOfColumns, props.noOfRows, props.open]);

  React.useEffect(() => {
    setCurrentData(props.ComponentsArrey.slice(0, cardsPerPage));
  }, [props.ComponentsArrey, cardsPerPage]);

  const handlePageChange = (page: number) => {
    setPageNo(page);
    const startIndex = (page - 1) * cardsPerPage;
    const endIndex = startIndex + cardsPerPage;
    setCurrentData(props.ComponentsArrey.slice(startIndex, endIndex));
  };

  React.useEffect(() => {
    setPageNo(props.pageNo ? props.pageNo : 1);
  }, [props.pageNo]);

  return (
    <div className='card-pagination'>
      {/* implement search */}
      <Row>
        {
          props.search &&
          <div style={{ display: 'flex', margin: '1rem 0', gap: '1rem', alignItems: 'center' }}>
            <BEInput
              placeholder={props.searchText || 'Search'}
              style={{ width: '313px' }} size='large'
              search
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                props.setSearchedValue && props.setSearchedValue(e.target.value);
              }}
            />
          </div>

        }

      </Row>
      <Row gutter={16}>
        {currentData.map((dataItem, index) => (
          <Col key={index} span={24 / (props.noOfColumns ? props.noOfColumns : 3)}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {dataItem}
          </Col>
        ))}
      </Row>
      <div className='pagination-box'>
        <BEPagination
          total={props.ComponentsArrey.length}
          pageSize={cardsPerPage}
          onChange={handlePageChange}
          current={pageNo}
        />
      </div>
    </div>
  );
}

export default BECardPagination;