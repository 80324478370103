import React from 'react'
import IconProps from './Interface/IconProps';

const Suppliers = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          d="M10.425 8.32484C10.425 6.94984 9.29996 5.82484 7.92496 5.82484C6.54996 5.82484 5.42496 6.94984 5.42496 8.32484C5.42496 9.69984 6.54996 10.8248 7.92496 10.8248C9.29996 10.8248 10.425 9.69984 10.425 8.32484ZM7.92496 9.15817C7.46663 9.15817 7.09163 8.78317 7.09163 8.32484C7.09163 7.8665 7.46663 7.4915 7.92496 7.4915C8.38329 7.4915 8.75829 7.8665 8.75829 8.32484C8.75829 8.78317 8.38329 9.15817 7.92496 9.15817ZM13.3416 10.8248C14.2666 10.8248 15.0083 10.0832 15.0083 9.15817C15.0083 8.23317 14.2666 7.4915 13.3416 7.4915C12.4166 7.4915 11.6666 8.23317 11.675 9.15817C11.675 10.0832 12.4166 10.8248 13.3416 10.8248ZM9.99996 1.6665C5.39996 1.6665 1.66663 5.39984 1.66663 9.99984C1.66663 14.5998 5.39996 18.3332 9.99996 18.3332C14.6 18.3332 18.3333 14.5998 18.3333 9.99984C18.3333 5.39984 14.6 1.6665 9.99996 1.6665ZM4.87496 14.2582C5.44163 13.8082 6.76663 13.3332 7.92496 13.3332C7.98329 13.3332 8.04996 13.3415 8.11663 13.3415C8.31663 12.8082 8.67496 12.2665 9.19996 11.7915C8.73329 11.7082 8.29163 11.6582 7.92496 11.6582C6.84163 11.6582 5.09996 12.0332 3.98329 12.8498C3.56663 11.9832 3.33329 11.0165 3.33329 9.9915C3.33329 6.3165 6.32496 3.32484 9.99996 3.32484C13.675 3.32484 16.6666 6.3165 16.6666 9.9915C16.6666 10.9915 16.4416 11.9415 16.0416 12.7998C15.2083 12.3082 14.075 12.0748 13.3416 12.0748C12.075 12.0748 9.59163 12.7498 9.59163 14.3248V16.6415C7.69996 16.5332 6.01663 15.6332 4.87496 14.2582Z"
          fill={props.stroke ? props.stroke : "white"}
        />
      </g>
    </svg>
  );
}

export default Suppliers