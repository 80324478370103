import * as React from 'react';
import './style.scss';
import Logo from '../../../assets/images/Company/Logo.png'
import { EntitySelectCascader } from './EntitySelectCascader';
import { Notification } from './Notification';
import { UserTopNav } from './UserTopNav';
import { useLocation } from 'react-router-dom';

export interface ITopMenubarProps {
}


export function TopMenubar(props: ITopMenubarProps) {
  const location = useLocation();
  const [activeFeature, setActiveFeature] = React.useState('Dashboard');

  React.useEffect(() => {
    const path = location.pathname.split('/')[2];
    const word = path?.replace(/-/g, ' ');
    if(!word) return;
    const firstLetter = word.charAt(0)
    const firstLetterCap = firstLetter.toUpperCase()
    const remainingLetters = word.slice(1)
    const capitalizedWord = firstLetterCap + remainingLetters
    setActiveFeature(capitalizedWord);
  }, [location.pathname])
  return (
    <div className='top-menu-bar'>
      <img className='logo' src={Logo} alt="Logo" />
      <p className='name'>{activeFeature}</p>
      <EntitySelectCascader />
      <Notification />
      <UserTopNav />
    </div>
  );
}
