import * as React from 'react';
import { useTypedSelector } from '../../../../../../../Config/Hooks/useTypedSelector';
import { shortMonths } from '../../../../../../../Config/Data/genralData';
import dayjs from 'dayjs';
import { AddCommaToNumber, rotateArray } from '../../../../../../../Config/Functions/UsefullFunctions';
import { metricType } from '../../../../../../../Redux/Types/dataManagerTypes';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, Legend, LabelList, ResponsiveContainer, Global, Label } from 'recharts';
import { graphColors } from '../../../../../../../Config/Theme/graphColors';
import { GlobalLoader } from '../../../../../../../Components/GlobalLoader';

export interface ITrendGraphProps {
  metricID: number;
  isFullYear?: boolean;
  metric?: metricType;
}
export function TrendGraph(props: ITrendGraphProps) {
  const myMetricsData = useTypedSelector(state => state.onBoarding.metrics.myMetricsData);
  const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
  const currentBusinessGroup = useTypedSelector(state => state.common.homePage.currentBusinessGroup);
  const mySelectedMetrics = useTypedSelector(state => state.onBoarding.metrics.myMetricsPerYear);
  const myMetricTargets = useTypedSelector(state => state.targets.MyMetricTargets);
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const businessUnitsRefs = useTypedSelector(state => state.entity.BusinessUnitRefs);
  const metrics = useTypedSelector(state => state.onBoarding.metrics.directMetrics);
  const [chartData, setChartData] = React.useState<any>([]);
  const [selectedMetrics, setSelectedMetrics] = React.useState<any>(mySelectedMetrics?.data)
  const tableDataDM = useTypedSelector(state => state.dataManager.metrics.tableDataDM);
  const fullYearTableDataDM = useTypedSelector(state => state.dataManager.metrics.fullYearTableDataDM);
  const yearEnd = useTypedSelector(state => state.companies.yearEnd);
  const padding = 40
  const [target, setTarget] = React.useState<number | undefined>(-1);
  const [metricUnit, setMetricUnit] = React.useState("");
  const [load, setLoad] = React.useState(false);

  React.useEffect(() => {
    setSelectedMetrics(mySelectedMetrics?.data);
    setLoad(mySelectedMetrics?.status === 'loading');
  }, [mySelectedMetrics]);

  const renderLabelContent = (props: number) => {
    return AddCommaToNumber(props, false, true, true);
  };

  React.useEffect(() => {
    if (myMetricsData?.status === 'success') {
      let tempData: any = Array(12).fill(0);
      let total: any = 0;
      let dataToUse: any = props.isFullYear ? fullYearTableDataDM : tableDataDM;

      dataToUse[props.metricID]?.
        map((item: any) => {
          const month = dayjs(item.date_of_entry).format('MMM');
          const index = shortMonths.indexOf(month);
          if (item?.status !== 3 && (props.metric?.annual !== true || tempData[index] === 0)) {
            tempData[index] += item.value;
            total += item.value;
          }
        });
      let monthsHavingData = tempData.filter((item: any) => item !== 0).length;
      let average = monthsHavingData === 0 ? 0 : Math.floor(total / monthsHavingData);

      const currentMetric = selectedMetrics?.find((item: any) => item.id === props.metricID);
      const metric_id = currentMetric?.metric;
      let targetValue: number = -1;
      if (currentBusinessUnit) {
        targetValue = myMetricTargets?.data?.find((item) =>
          item.metric === metric_id &&
          item.business_unit === currentBusinessUnit &&
          item?.end_year === currentYear &&
          item?.start_year === currentYear
        )?.target_value as number;
      } else if (currentBusinessGroup) {
        const businessUnits = businessUnitsRefs.data.filter((item: any) => item.business_group === currentBusinessGroup).map((item) => item.business_unit);
        targetValue = myMetricTargets?.data?.find((item) =>
          item.metric === metric_id &&
          businessUnits.includes(item.business_unit as number) &&
          item?.end_year === currentYear &&
          item?.start_year === currentYear
        )?.target_value as number;
      } else {
        targetValue = myMetricTargets?.data?.find((item) =>
          item.metric === metric_id &&
          item?.end_year === currentYear &&
          item?.start_year === currentYear
        )?.target_value as number;
      }
      const unit = metrics?.data?.find((item: any) => item.id === currentMetric?.metric)?.unit;
      setMetricUnit(unit ? unit : "");
      console.log("targetValue", targetValue);
      setTarget(Number(targetValue));
      setChartData(
        rotateArray(tempData.map((item: any, index: number) => {
          return {
            Name: shortMonths[index],
            Value: item,
            Average: average,
            Average_target: (targetValue / 12).toFixed(2)
          }
        }), yearEnd, true)
      );
    }
  }, [myMetricsData, tableDataDM, props.metricID, selectedMetrics, props?.isFullYear, currentBusinessGroup, currentBusinessUnit]);
  return (
    <div style={{ height: '40vh', padding: "1rem 1.5rem 0 1rem", fontSize: "10px" }}>

      {load ? <GlobalLoader height='100%' /> :
        <ResponsiveContainer width='100%' height='100%' minWidth={0} minHeight={0}>

          <LineChart data={chartData} width={500} height={300}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="Name" padding={{ left: padding, right: padding }} />
            <YAxis padding={{ top: padding }}>
              <Label value={"(" + metricUnit + ")"} angle={-90} style={{ textAnchor: 'start' }} position="insideLeft" />
            </YAxis>
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="Value" stroke={graphColors[0]}>
              {/* <LabelList dataKey="Value" position="top" formatter={renderLabelContent} /> */}
            </Line>
            <ReferenceLine />
            <Line type="monotone" dataKey="Average" stroke={graphColors[1]} />
            {!currentBusinessGroup && !currentBusinessUnit && target !== -1 &&
              <Line type="monotone" dataKey="Average_target" stroke={graphColors[2]} />}
          </LineChart>
        </ResponsiveContainer>}
    </div>
  );
}
