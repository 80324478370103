import { BEMessage } from "../../../Components/BEMessage";
import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import { AppDispatch } from "../../../Redux/store";
import { ADD_METRICS, PATCH_SELECTED_METRICS_STATUS } from "../../../Utils/Routes/Onboarding";
import { getMyMetrics } from "../../../views/Actions/OnboardingStep4Actions";

export const updateTarget = (id: any, body: any) => async (dispatch: AppDispatch) => {
    console.log(id);
    const [data, error] = await handleAPICall(PATCH_SELECTED_METRICS_STATUS(id, body))
    if (data) {
        BEMessage.success("Update Successfull");
        dispatch(getMyMetrics())
    }
    else {
        BEMessage.error("Update unsuccessfull");
    }
}

export const addMetrics = (data:any) => async (dispatch:AppDispatch) => {
    const [res, error] = await handleAPICall(ADD_METRICS(data));
    if (res) {
        await dispatch(getMyMetrics());
        return true
    }
    else{
        console.log(error);
        BEMessage.error('Something went wrong');
        return null
    }
    
}

export const calcProgress = (baseLine: number, target: number, currentValue: number, target_type: string) => {

    if (target === -1) {
        return 0
    }
    
    if (target_type === 'A') { // absolute increase
        return target-currentValue;
    } else if (target_type === 'B') { // absolute decrease
        return currentValue - target;
    } else if (target_type === 'C') { // % increase
        return (currentValue * 100) / (baseLine * (1 + target/100));
    } else { // % decrease
        return (currentValue * 100) / (baseLine * (1 - target/100));
    }
}

export const getTargetValue: (record: any) => number = (record: any) => {
    switch (record?.target_type) {
      case 'B': return record.target_value
        break;
      case 'A': return record.target_value
        break;
      case 'C': return Number(record.baseline_value * (1 + record.target_value / 100))?.toFixed(2)
        break;
      case 'D': return Number(record.baseline_value * (1 - record.target_value / 100))?.toFixed(2)
        break;
    }
  }

export const calcProgressStatus = (record: any, currentValue: number) => {
    if (record.target_type === 'A') { // absolute increase
        if (currentValue >= record.target_value) {
            return "success"
        }
    } else if (record.target_type === 'B') { // absolute decrease
        if (currentValue < record.target_value) {
            return "success"
        } else {
            return "error"
        }
    } else if (record.target_type === 'C') { // % increase
        if (currentValue >= getTargetValue(record)) {
            return "success"
        }
    } else { // % decrease
        if (currentValue < getTargetValue(record)) {
            return "success"
        } else {
            return "error"
        }
    }
    return "warning"
}