import * as React from 'react';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useLocation, useParams } from 'react-router-dom';
import ToolTip from '../../../../Components/BEToolTip';
import { Breadcrumb } from 'antd';
import { navigateTo } from '../../../../Navigation/NavigationManager';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import './style.scss';
import { FolderType } from '../../../../Redux/Types/vaultTypes';
import { title } from 'process';
export interface IVaultBreadCrumpProps {
}

export function VaultBreadCrumb (props: IVaultBreadCrumpProps) {
  let { folder_id, fy_id, metric_id } = useParams();
  const myMetrics = useTypedSelector(state => state.onBoarding.metrics.myMetrics);
  const VaultFolders = useTypedSelector((state) => state.vault.Folders);
  const location=useLocation()
  const isRootVaultPath=location.pathname==='/home/settings/vault'
  return (

    <div className='vault-breadcrumb'>
      <Breadcrumb  
      style={{fontSize: "1rem", fontWeight: "400", textDecoration:isRootVaultPath ? 'none' :'underline', }}
        items={[
          {
            title: (
              <>
              {isRootVaultPath ? <h3 style={{color:PrimaryTheme.primaryBlack}}>Vault</h3> : 'Vault'}
              </>
            ),
            onClick: () => navigateTo("/home/settings/vault"),
          },
          {
            title: folder_id === '0' ? 'System Folder' :
            folder_id === '-1' ? 'Supplier Assessment' :
             VaultFolders?.data?.find((item: FolderType) => item.id === Number(folder_id))?.title,
            onClick: () => navigateTo(`/home/settings/vault/${folder_id}`),
          },
          {
            title: `${folder_id === '0' ? 'FY': ''} ${fy_id}`,
            onClick: () => navigateTo(`/home/settings/vault/${folder_id}/${fy_id}`),
          },
          {
            title:
              folder_id === '-1'? metric_id: 
              myMetrics?.data.filter((metric: any) => metric.id == metric_id)[0]?.title,
            onClick: () => navigateTo(`/home/settings/vault/${folder_id}/${fy_id}/${metric_id}`),
          }
        ].slice(0, folder_id ? fy_id ? metric_id ? 4 : 3 : 2 : 1)
      }
      />
    </div>
  );
}
