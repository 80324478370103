import { Col, Row } from 'antd'
import { BEInput } from '../../../Components/BEFormItems/BEInput'
import React, { useEffect, useState } from 'react'
import { BEButton } from '../../../Components/BEFormItems/BEButton';
import { getAllUsers } from '../../../views/Actions/UsersManagementActions';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import UserListTable from '../../../views/Onboarding/Step3/UserListTable';
import AddUser from '../../../views/Onboarding/Drawers/AddUser';
import { TopSection } from '../Components/TopSection';
import SettingsBackButton from '../Components/BackButton';

const Users = () => {
  const dispatch = useAppDispatch()
  const users = useTypedSelector((state) => state.userMgmt);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);


  useEffect(() => {
    if (users?.status === "idle")
    dispatch(getAllUsers());
  }, []);

  return (
    <>
      <TopSection />
      <div style={{ margin: "2rem 0rem" }}>
        <SettingsBackButton title='Back' style={{marginLeft:'1.5rem'}}/>
        <AddUser open={openDrawer} setOpen={setOpenDrawer} />
        <AddUser isEdit open={openEditDrawer} setOpen={setOpenEditDrawer} />
        <div>
          <UserListTable
            loading={users?.status === "loading"}
            users={users?.data}
            setAddUser={setOpenDrawer}
            setEditUser={setOpenEditDrawer}
          />
        </div>
      </div>
    </>
  );
}

export default Users