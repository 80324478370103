import {  Flex, Row } from "antd";
import EmailIcon from '../../../../assets/images/EmailIcon.svg';
import { BEButton } from "../../../../Components/BEFormItems/BEButton";
import { PrimaryTheme } from "../../../../Config/Theme/theames";
import { navigateTo } from "../../../../Navigation/NavigationManager";
export function RequestSubmitted(){
	return (<Flex style={{height:'360px',width:'100%'}} gap={'1.5rem'}  vertical justify="center" align='center'>
		<Flex vertical gap={'1.5rem'}> 
			<Row justify={'center'} align={'middle'}>
				<img src={EmailIcon} />
			</Row>
			<Row justify={'center'}>
				<p style={{textAlign:'center'}}>We received your request, our sales <br /> team will reach you shortly</p>
			</Row>
		</Flex>
		<Row>
			<BEButton
				ghost
				onClick={()=>navigateTo('/login')}
				className="secondary-green"
			>
				Back to login
			</BEButton>
		</Row>
		</Flex>)
	
}