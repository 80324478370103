import * as React from 'react';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import TrackerCard from '../../../../../Components/Cards/TrackerCard';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import AssignmentIcon from '../../../../../Components/BEIcons/AssignmentIcon';
import PendingResponseIcon from '../../../../../Components/BEIcons/PendingResponseIcon';
import TrackerIcon from '../../../../../Components/BEIcons/TrackerIcon';
import { SupplierRiskClassificationThreshold } from '../../AllAssessments/AllAssessments';
export interface ISupplierDetailCardsProps {
  averageScore: number | null;
}

export function SupplierDetailCards (props: ISupplierDetailCardsProps) {
  const supplierResult = useTypedSelector(state => state.supplier.SupplierResults);
  const supplierStatus = useTypedSelector(state => state.supplier.SupplierStatus);
  const averageScore = useTypedSelector(state => state.supplier.SupplierResults.average);
  const supplierScore = useTypedSelector(state => state.supplier.SupplierScore);

  return (
    <div className='supplier-detail-cards'> 
        <TrackerCard
          title='AVERAGE SCORE'
          body={
              props.averageScore ? props.averageScore.toFixed(2)+ '%' : '-'
          }
          icon={<AssignmentIcon/>}
          loading={supplierResult?.status==='loading' || supplierStatus?.status==='loading'}
        />
        <TrackerCard
          title='NO. OF PARTNERS ENGAGED'
          body={supplierStatus?.status==='success' ? supplierStatus?.data.length : '-'}
          icon={<TrackerIcon/>}
          loading={supplierStatus?.status==='loading'}
        />
        <TrackerCard
          title='RISK CLASSIFICATION'
          body={props.averageScore? props.averageScore < SupplierRiskClassificationThreshold.Medium ?
             <div style={{color:PrimaryTheme.secondaryRedError}}>High Risk</div>:
              props.averageScore < SupplierRiskClassificationThreshold.Low ? <div style={{color:PrimaryTheme.amber}}>Medium Risk</div> : <div style={{color:PrimaryTheme.primaryGreen}}>Low Risk</div> : '-'}
          icon={<PendingResponseIcon/>}
          loading={supplierResult?.status==='loading' || supplierStatus?.status==='loading'}
        />
    </div>
  );
}
