import React from 'react';
import { Avatar } from 'antd';
import ToolTip from '../BEToolTip';
import Administration from '../../assets/images/DepartmentImages/Administration.png';
import Finance from '../../assets/images/DepartmentImages/Finance.png';
import HumanResources from '../../assets/images/DepartmentImages/HumanResources.png';
import InformationTechnology from '../../assets/images/DepartmentImages/InformationTechnology.png';
import Legal from '../../assets/images/DepartmentImages/Legal.png';
import Operations_Projects from '../../assets/images/DepartmentImages/Operation_Projects.png';
import Procurement from '../../assets/images/DepartmentImages/Procurement.png';
import ESG_EHS from '../../assets/images/DepartmentImages/ESG_EHS.png';
import Others from '../../assets/images/DepartmentImages/Other.png';
import UsersIcon from '../BEIcons/UsersIcon';
import { PrimaryTheme } from '../../Config/Theme/theames';

interface AvatarDetails {
    id: string | number;
    name: string;
    avatarUrl?: string;
    isImage?: boolean;
    src?: string;
}

interface CollabProps {
    users: AvatarDetails[];
    forDepartment?: boolean;
}

const BECollab: React.FC<CollabProps> = (props: CollabProps) => {
    const getDepartmentImages = (dept_name: string) => {
        console.log(dept_name);
        switch (dept_name) {
            case 'Finance':
                return Finance;
            case 'Human Resources':
                return HumanResources;
            case 'Information Technology':
                return InformationTechnology;
            case 'Legal':
                return Legal;
            case 'Operations / Projects':
                return Operations_Projects;
            case 'Procurement':
                return Procurement;
            case 'ESG / EHS':
                return ESG_EHS;
            case 'Others':
                return Others;
            case 'Administration':
                return Administration;
            default:
                return Others;
        }
    };
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {props?.users.map(user => (
                <ToolTip key={user.id} title={user.name}>
                    <Avatar
                        style={{ marginLeft: -10, border: '2px solid white' }}
                        src={props.forDepartment ? <img src={getDepartmentImages(user.name)} alt={user.name} />
                            : user.isImage ? <img src={user.src} alt={user.name} /> : user.avatarUrl !== '' ? user.avatarUrl : <UsersIcon inheritSize fill={PrimaryTheme.primaryGreyDark} />}
                    />
                </ToolTip>
            ))}
        </div>
    );
};

export default BECollab;
