import React from 'react'
import IconProps from './Interface/IconProps';

const Layers = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 16}
      height={props.inheritSize ? "100%" : 17}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M7.99333 13.17L3.08 9.34998L2 10.19L8 14.8566L14 10.19L12.9133 9.34331L7.99333 13.17ZM8 11.4766L12.9067 7.65664L14 6.80998L8 2.14331L2 6.80998L3.08667 7.65664L8 11.4766ZM8 3.82998L11.8267 6.80998L8 9.78998L4.17333 6.80998L8 3.82998Z"
          fill={props.fill ? props.fill : "#181818"}
        />
      </g>
    </svg>
  );
}

export default Layers