import * as React from 'react';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { postBusinessUnitRef } from '../../Actions/BusinessUnitActions';
import { BEDrawer } from '../../../Components/BEDrawer';
import { BETabButton } from '../../../Components/BETabButton';
import { ColumnType } from 'antd/es/table';
import { Checkbox } from 'antd';
import { BEButton } from '../../../Components/BEFormItems/BEButton';
import { BETable } from '../../../Components/BETable';
import { BusinessUnitType } from '../../../Redux/Types/entityTypes';
import { BEInput } from '../../../Components/BEFormItems/BEInput';

export interface IAddbusinessUnitToGroupProps {
  open: boolean
  setOpen: Function
  businessGroup: any;
  checkedBusinessUnits: any;
  setCheckedBusinessUnits: Function;
  isEdit: boolean;
}

export function AddbusinessUnitToGroup(props: IAddbusinessUnitToGroupProps) {
  const dispatch = useAppDispatch();
  const businessUnits = useTypedSelector(state => state.entity.BusinessUnits);
  const businessUnitRefs = useTypedSelector(state => state.entity.BusinessUnitRefs);
  const [searchValue, setSearchValue] = React.useState<any>('');
  const [filteredBusinessUnits, setFilteredBusinessUnits] = React.useState<any>([]);

  React.useEffect(() => {
    let tempData:any = businessUnits?.data.filter((item:BusinessUnitType)=>item.name?.toLowerCase().includes(searchValue?.toLowerCase()));
    setFilteredBusinessUnits(tempData);
  }, [searchValue, businessUnits])

  const columns: ColumnType<any>[] = [
    {
      title: <Checkbox 
          onChange={(e)=>{
            if(e.target.checked){
              props.setCheckedBusinessUnits(businessUnits?.data.map((item:any)=>item.id));
            }
            else{
              if(props.isEdit){
                props.setCheckedBusinessUnits(businessUnitRefs?.data.filter((item:any)=>item.business_group === props.businessGroup.id).map((item:any)=>item.business_unit))
              }
              else props.setCheckedBusinessUnits([]);
            }
          }}
          checked={props.checkedBusinessUnits.length === businessUnits?.data.length}
      />,
      render: (text: any, record: any) => <Checkbox
      disabled= {
        props.isEdit && businessUnitRefs?.data?.find((item:any)=>item.business_group === props.businessGroup.id && item.business_unit === record.id)?true:false
      }
        checked={props.checkedBusinessUnits.includes(record.id)}
        onChange={(e) => {
          if (e.target.checked) {
            props.setCheckedBusinessUnits([...props.checkedBusinessUnits, record.id])
          }
          else {
            props.setCheckedBusinessUnits(props.checkedBusinessUnits.filter((item: any) => item !== record.id))
          }
        }}
      />
    },
    {
      title: 'Business Unit',
      dataIndex: 'name',
      key: 'name'
    },
  ]

  return (
    <div>
      <BEDrawer
        heading={'Add Business Units'}
        open={props.open}
        setOpen={props.setOpen}
        footer={
          <div style={{ display: 'flex', gap: '1rem' }}>
            <BEButton
              size='large' className='primary'
              onClick={() => props.setOpen(false)}
            >
              Add
            </BEButton>
            {/* <BEButton
              size='large'
              onClick={() => props.setOpen(false)}
            >
              Cancel
            </BEButton> */}
          </div>
        }
      >
        <BEInput search placeholder='Search for business units' style={{ width: '100%',marginBottom:'1rem' }} size='large'
          onChange={(e) => {
            setSearchValue(e.target.value)
          }}
        />
        <BETable columns={columns} data = {filteredBusinessUnits} pagination />
      </BEDrawer>
    </div>
  );
}
