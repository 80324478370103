import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { Radio } from 'antd';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import './style.scss';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import { EditReport } from '../NewEditReport';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { setAllSelectedTemplateTopics, setCurrentGRESBSection } from '../../../../../Redux/ReportingReducer';
import { ChooseAssets } from '../ChooseAsset';
import { getReportData, getTemplateTopics } from '../../../Actions/template';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { getGresbAuthorizationCode, getGRESBSlugData, isGresbAuthorized } from '../../../Actions/gresbActions';
import { BEMessage } from '../../../../../Components/BEMessage';
import { useNavigate } from 'react-router-dom';

export interface IChooseAssessmentProps {
    open: boolean;
    setOpen: Function;
}

export function ChooseAssessment(props: IChooseAssessmentProps) {
    const [value, setValue] = React.useState(1);
    const [openReport, setOpenReport] = React.useState(false);
    const [openChooseAsset, setOpenChooseAsset] = React.useState(false);
    const currentReport = useTypedSelector(state => state.reporting.currentReport);
    const gresbSlugData = useTypedSelector(state => state.reporting.gresbSlugData);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        dispatch(setCurrentGRESBSection(value === 0 ? 'entity' : 'asset'));
        if (gresbSlugData?.status === 'idle')
            dispatch(getGRESBSlugData());
        getSelectedTopics();
        if (currentReport?.id && props.open) {
            dispatch(getReportData(currentReport?.id));
        }
    }, [value, props.open]);

    const getSelectedTopics = async () => {
        if (currentReport?.template) {
            const res = await dispatch(getTemplateTopics(currentReport?.template, false))
            dispatch(setAllSelectedTemplateTopics({
                status: 'success',
                data: res
            }))
        }
    }

    return (
        <BEDrawer
            width='100%'
            heading='Choose Assessment'
            open={props.open}
            setOpen={props.setOpen}
            footer={
                <div>
                    <BEButton
                        className='primary'
                        size='large'
                        onClick={() => {
                            if (isGresbAuthorized()) {
                                navigate('/home/reporting/reports');
                                props.setOpen(false);
                                value === 0 ? setOpenReport(true) : setOpenChooseAsset(true);
                            } else {
                                BEMessage.error('Please connect with GRESB to proceed');
                                return;
                            }
                        }
                        }
                    >
                        Start report
                    </BEButton>
                    {' '}
                    <BEButton
                        size='large'
                        onClick={() => props.setOpen(false)}
                    >
                        Cancel
                    </BEButton>
                </div>
            }
        >
            <div className='choose-assessment-container'>
                <div className='connect-gresb-container'>
                    <div className='connect-gresb-desc'>
                        <h3>Connect with GRESB</h3>
                        <p>To streamline your GRESB assessment process, connect your GRESB account with Breathe ESG to directly populate your assessment answers from our platform. Please note that the final submission must be completed on the GRESB portal. If your entity does not yet have an account, kindly sign up and then proceed to connect both accounts.
                        </p>
                    </div>
                    <BEButton
                        size='large'
                        onClick={async () => {
                            await dispatch(getGresbAuthorizationCode(1, currentReport?.id || 0));
                        }}
                        disabled={isGresbAuthorized()}
                    >
                        {isGresbAuthorized() ? 'Connected' : 'Connect'}
                    </BEButton>
                </div>
                <div style={{ fontSize: "0.8rem", backgroundColor: PrimaryTheme.primaryGreyLight, padding: "1rem", borderRadius: "8px" }}>
                    <i>
                        It is possible to switch between the two assessments at any time, but a GRESB submission is considered complete only when all indicators in both assessments have been answered.
                    </i>
                </div>
                <Radio.Group
                    value={value}
                    onChange={(e: any) => {
                        setValue(e.target.value);
                    }}
                >
                    <div className='section-selection-container'>
                        <div className='section-selection'>
                            <span>
                                <Radio value={0}>
                                    <h3>Entity Level Assessment</h3>
                                    <div className='section-description'>
                                        <p>
                                            The Entity level assessment involves qualitative indicators that are to be answered for the entire company.
                                        </p>
                                        <p>
                                            Every indicator in the Entity Level Assessment can be answered with ‘Yes’ or ‘No’ and in some cases with ‘Not applicable’. If ‘Yes’ is selected, the participant has the option to further classify the response by selecting one or more sub-options.
                                        </p>
                                        <p>
                                            Participants should select all sub-options that accurately describe the entity and for which the entity can provide evidence.
                                        </p>
                                    </div>
                                </Radio>
                            </span>
                        </div>
                        <div className='section-selection'>
                            <span>
                                <Radio value={1}>
                                    <h3>Asset Level Assessment</h3>
                                    <div className='section-description'>
                                        <p>
                                            GRESB requires property companies and funds to report this data for both Landlord Controlled and Tenant Controlled areas of all assets  that are held during the reporting year, including those that have been sold or purchased.
                                        </p>
                                        <p>
                                            Asset-level data is required to complete indicators from the Performance Component. Energy, GHG, Water, Waste, Building Certifications, and Efficiency Measures are measured at an asset level.
                                        </p>
                                    </div>
                                </Radio>
                            </span>
                        </div>
                    </div>
                </Radio.Group>
            </div>
            <EditReport open={openReport} setOpen={setOpenReport} />
            <ChooseAssets open={openChooseAsset} setOpen={setOpenChooseAsset} />
        </BEDrawer>
    );
}
