import React from 'react'
import IconProps from './Interface/IconProps';

const DataManager = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          d="M9.99996 1.6665C5.39996 1.6665 1.66663 5.39984 1.66663 9.99984C1.66663 14.5998 5.39996 18.3332 9.99996 18.3332C14.6 18.3332 18.3333 14.5998 18.3333 9.99984C18.3333 5.39984 14.6 1.6665 9.99996 1.6665ZM16.6083 9.1665H10.8333V3.3915C13.8416 3.7665 16.2333 6.15817 16.6083 9.1665ZM3.33329 9.99984C3.33329 6.60817 5.88329 3.79984 9.16663 3.3915V16.6082C5.88329 16.1998 3.33329 13.3915 3.33329 9.99984ZM10.8333 16.6082V10.8332H16.6083C16.2333 13.8415 13.8416 16.2332 10.8333 16.6082Z"
          fill={props.stroke ? props.stroke : "white"}
        />
      </g>
    </svg>
  );
}

export default DataManager