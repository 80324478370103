import { Form, Input } from "antd";
import React from "react";
import '../../styles.scss';
import FileUpload from "../../../../../../../../../../Components/BEFileUpload/FileUpload";
import { LableRequired } from "../../../../../../../../../../Components/BEFormItems/LableRequired";
import { PrimaryTheme } from "../../../../../../../../../../Config/Theme/theames";
import InfoIcon from "../../../../../../../../../../Components/BEIcons/InfoIcon";
import { useAppDispatch } from "../../../../../../../../../../Config/Hooks/useAppDispatch";
import { myMetricDataBulkUpload } from "../../../../../../../../Actions";
import { useTypedSelector } from "../../../../../../../../../../Config/Hooks/useTypedSelector";
import { GlobalLoader } from "../../../../../../../../../../Components/GlobalLoader";
import { setCurrentMyMetricDataBulkUploadFileId } from "../../../../../../../../../../Redux/DataManagerReducer";
import { checkCeleryTaskStatusPeriodically, getCeleryTaskResult } from "../../../../../../../../../../views/Actions/HomeActions";
import { BEMessage } from "../../../../../../../../../../Components/BEMessage";
import { BEInput } from "../../../../../../../../../../Components/BEFormItems/BEInput";


interface BulkUploadStep1Props {
	setStep: Function;
	my_metric_id: number;
}

const BulkUploadStep1: React.ForwardRefRenderFunction<{ onButtonClick: () => void }, BulkUploadStep1Props> = (props, ref) => {
	const [file, setFile] = React.useState<File | null>(null);
	const [form] = Form.useForm();
	const dispatch = useAppDispatch();
	const [loading, setLoading] = React.useState<boolean>(false);
	const currentBusinessUnit =useTypedSelector(state=>state.common.homePage.currentBusinessUnit);

	const onButtonClick = async () => {
		const fileName = form.getFieldValue('name');
		if (fileName === '' || fileName === undefined) {
			BEMessage.error('Please enter file name');
			return;
		}
		setLoading(true);
		const res = await dispatch(myMetricDataBulkUpload(props.my_metric_id, 0, fileName, file));
		await dispatch(checkCeleryTaskStatusPeriodically([res], 1000, async () => {
			const [fileId, msg] = await dispatch(getCeleryTaskResult(res));
			if (fileId) {
				dispatch(setCurrentMyMetricDataBulkUploadFileId(fileId));
				setLoading(false);
				props.setStep(2);
			} else {
				BEMessage.error(msg || 'Failed to upload file');
				setLoading(false);
			}
		}));
	}

	const downloadSampleFile = () => {
		const link = document.createElement('a');
	
		if(currentBusinessUnit){
			link.href = `/Templates/MetricDataTemplate.xlsx`;
			link.setAttribute('download', `MetricDataTemplate.xlsx`);
		}else{
			link.href = `/Templates/MetricDataTemplateAllBU.xlsx`;
			link.setAttribute('download', `MetricDataTemplateAllBU.xlsx`);
		}
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
	
	React.useImperativeHandle(ref, () => ({ onButtonClick }), [file]);
	return (
		<Form form={form}>
			{
				loading ?
					<GlobalLoader height="50vh" /> :
					<>
						<LableRequired>Name </LableRequired>
						<Form.Item
							name="name"
							rules={[{ required: true, message: 'Please enter file name' }]}
						>
							<BEInput
								placeholder='Enter file name'
							/>
						</Form.Item>
						<LableRequired>Upload file </LableRequired>
						<FileUpload
							width='inherit'
							handleFileChange={(file: File) => setFile(file)}
							text='Upload your data file'
							hint='Please upload only XLS or XLSX , Max file size is 5MB'
							fileType={'excel'}
							fileSize={5 * 1024 * 1024}
						/>
						<div className='sample-download'>
							<div className='icon'><InfoIcon inheritSize fill={PrimaryTheme.primaryGray} /></div>
							<p><span onClick={downloadSampleFile} style={{color:PrimaryTheme.primaryBlueInfo,cursor:'pointer'}}
							>Click here</span> to download and review sample file</p>
						</div>
					</>
			}
		</Form>
	)
}
export default React.forwardRef(BulkUploadStep1);