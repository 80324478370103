import {
    GET_ALL_BUSINESS_UNITS,
    EDIT_BUSINESS_UNIT,
    DELETE_BUSINESS_UNIT,
    POST_BUSINESS_UNIT,
    GET_BUSINESS_UNIT_CONTRIBUTORS,
    POST_BUSINESS_UNIT_CONTRIBUTOR,
    DELETE_BUSINESS_UNIT_CONTRIBUTOR,
    GET_BUSINESS_UNIT_REFS,
    DELETE_BUSINESS_UNIT_REF,
    POST_BUSINESS_UNIT_REF
} from "../../Utils/Routes/EntityRouts";
import { handleAPICall } from "../../Config/Functions/HandleAPICall";
import { navigateTo } from "../../Navigation/NavigationManager";
import { AppDispatch } from "../../Redux/store";
import { BEMessage } from "../../Components/BEMessage";
import { 
    setBusinessUnits,
     setBusinessUnitContributors,
     setBusinessUnitRefs 
    } from "../../Redux/EntityReducer";
import { GET_CONTRIBUTOR_BUSINESS_UNITS } from "../../Utils/Routes/ContributorRoutes";


export const postBusinessUnitRef = (data: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(POST_BUSINESS_UNIT_REF(data));
    if (res) {
        dispatch(getBusinessUnitRefs());
        return true
    }
    else {
        console.log(error);
        return false
    }
}

export const getBusinessUnitRefs = () => async (dispatch: AppDispatch) => {
    dispatch(setBusinessUnitRefs({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_BUSINESS_UNIT_REFS());
    if (data) {
        dispatch(setBusinessUnitRefs({
            status: "success",
            data: data?.data
        }));
    }
    else {
        console.log(error);
        dispatch(setBusinessUnitRefs({
            status: "error",
            data: []
        }));
    }
}

export const deleteBusinessUnitRef = (id: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(DELETE_BUSINESS_UNIT_REF(id));
    if (res) {
        dispatch(getBusinessUnitRefs());
        BEMessage.success("Business Unit has been removed from the group.");
    }
    else {
        console.log(error);
    }
}

export const getBusinessUnitContributors = (id: any) => async (dispatch: AppDispatch) => {
    dispatch(setBusinessUnitContributors({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_BUSINESS_UNIT_CONTRIBUTORS(id));
    if (data) {
        dispatch(setBusinessUnitContributors({
            status: "success",
            data: data?.data
        }));
    }
    else {
        console.log(error);
        dispatch(setBusinessUnitContributors({
            status: "error",
            data: []
        }));
    }
}

export const deleteBusinessUnitContributor = (id: any, buId: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(DELETE_BUSINESS_UNIT_CONTRIBUTOR(id));
    if (res) {
        dispatch(getBusinessUnitContributors(buId));
        BEMessage.success("Contributor Deleted Successfully");
        return true
    }
    else {
        console.log(error);
        return false
    }
}

export const addBusinessUnitContributor = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(POST_BUSINESS_UNIT_CONTRIBUTOR(data));
    if (res) {
        dispatch(getBusinessUnitContributors(id));
        BEMessage.success("Contributor Added Successfully");
        return true
    }
    else {
        console.log(error);
        return false
    }
}
export const getBusinessUnits = () => async (dispatch: AppDispatch) => {
    dispatch(setBusinessUnits({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_ALL_BUSINESS_UNITS());
    if (data) {
        dispatch(setBusinessUnits({
            status: "success",
            data: data?.data
        }));
    }
    else {
        console.log(error);
        dispatch(setBusinessUnits({
            status: "error",
            data: []
        }));
    }
}

export const addBusinessUnit = (data: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(POST_BUSINESS_UNIT(data));
    if (res) {
        dispatch(getBusinessUnits());
        BEMessage.success("Business Unit Added Successfully");
        console.log('new bu',res);
        return res?.data;
    }
    else {
        console.log(error);
        return null;
    }

}

export const editBusinessUnit = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(EDIT_BUSINESS_UNIT(id, data));
    if (res) {
        dispatch(getBusinessUnits());
        BEMessage.success("Business Unit Updated Successfully");
    }
    else {
        console.log(error);
    }
}

export const deleteBusinessUnit = (id: any, setLoading?: Function) => async (dispatch: AppDispatch) => {
    setLoading && setLoading(true);
    const [res, error] = await handleAPICall(DELETE_BUSINESS_UNIT(id));
    if (res) {
        dispatch(getBusinessUnits());
        dispatch(getBusinessUnitRefs());
        BEMessage.success("Business Unit Deleted Successfully");
    }
    else {
        console.log(error);
    }
    setLoading && setLoading(false);
}

export const getContributorBusinessUnits = () => async (dispatch: AppDispatch) => {
    dispatch(setBusinessUnitContributors({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_CONTRIBUTOR_BUSINESS_UNITS());
    if (data) {
        dispatch(setBusinessUnitContributors({
            status: "success",
            data: data?.data
        }));
    }
    else {
        console.log(error);
        dispatch(setBusinessUnitContributors({
            status: "error",
            data: []
        }));
    }
}