import * as React from 'react';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { postSectionAnswers,getAnswersFilledByCoSuppliers,getSectionQuestions } from '../../../Actions/supplierFormActions';
import { getSupplierOptions } from '../../../Actions/supplierAction';
import { useSearchParams } from 'react-router-dom';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import { MainSupplierForm } from '../MainSupplierForm';

export interface ICosupplierFillAssessmentProps {
}

export function CosupplierFillAssessment (props: ICosupplierFillAssessmentProps) {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const supplierAssessmentQuestions = useTypedSelector(state => state.supplier.SupplierQuestions);
  const supplierAssessmentAnswers = useTypedSelector(state => state.supplier.SupplierAnswers);
  const supplierOptions = useTypedSelector(state => state.supplier.SupplierOptions);

  React.useEffect(() => {
    dispatch(getSectionQuestions(searchParams.get('token') as string));
    dispatch(getSupplierOptions());
    dispatch(getAnswersFilledByCoSuppliers(searchParams.get('token') as string,Number(searchParams.get('assessment')),Number(searchParams.get('supplier')) ));
  },[]);

  if(supplierAssessmentQuestions?.status === 'idle'|| supplierOptions?.status === 'idle') return <GlobalLoader/>

  return (
    <MainSupplierForm isFillSection={true}/>
  );
}
