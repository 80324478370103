import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import FileUpload from '../../../../../Components/BEFileUpload/FileUpload';
import { Form } from 'antd';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { BEMessage } from '../../../../../Components/BEMessage';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { BETextArea } from '../../../../../Components/BEFormItems/BETextArea';
import { BETable } from '../../../../../Components/BETable';
import { ColumnType } from 'antd/es/table';
import DeleteIcon from '../../../../../Components/BEIcons/DeleteIcon';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import { deleteGresbEntityEvidence, postGresbEntityEvidence } from '../../../Actions/gresbActions';

export interface IUploadEvidenceDrawerProps {
    open: boolean;
    setOpen: Function;
    evidenceData: any;
}

export function UploadEvidenceDrawer(props: IUploadEvidenceDrawerProps) {
    const dispatch = useAppDispatch();
    const [file, setFile] = React.useState<any>(null);
    const currentReport = useTypedSelector(state => state.reporting.currentReport);
    const gresbEntityEvidences = useTypedSelector(state => state.reporting.gresbEntityEvidences?.data);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [topicId, setTopicId] = React.useState<any>(null);
    const [evidenceList, setEvidenceList] = React.useState<any>([]);
    const [variableCode, setVariableCode] = React.useState<any>(null);
    const [onlyHyperlink, setOnlyHyperlink] = React.useState<boolean>(false);
    const [form] = Form.useForm();

    const columns: ColumnType<any>[] = [
        {
            title: 'Evidence',
            key: 'hyperlink',
            render: (text: any, record: any) => (
                    <a href={record.hyperlink} target='_blank' rel='noreferrer'>Link</a>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text: any, record: any) => (
                <div
                    onClick={async () => {
                        setLoading(true);
                        await dispatch(deleteGresbEntityEvidence(record.key, currentReport?.id || 0));
                        setLoading(false);
                        props.setOpen(false);
                    }}
                    style={{ width: "1rem", cursor: 'pointer' }}
                >
                    <DeleteIcon
                        stroke={PrimaryTheme.primaryGray}
                        inheritSize
                    />
                </div>
            )
        }
    ];

    React.useEffect(() => {
        form.resetFields();
        setTopicId(props.evidenceData?.id);
        setVariableCode(props.evidenceData?.variable_code);
        setOnlyHyperlink(props.evidenceData?.response_type === 'Evidence(H)');
        setEvidenceList(gresbEntityEvidences.filter((item: any) => item.topic === props.evidenceData?.id)
            .map((item: any) => {
                return {
                    key: item.id,
                    hyperlink: item.evidence_type === 'H' ? item.hyperlink : item.evidence
                }
            })
        );
    }, [props.open])

    const onFinish = async (values: any) => {
        setLoading(true);
        console.log('values', values);
        if (onlyHyperlink) {
            const body = {
                hyperlink: values.hyperlink,
                hyperlink_notes: values.description,
                variable_code: variableCode,
                report: currentReport?.id,
                topic: topicId,
                evidence_type: 'H'
            }
            await dispatch(postGresbEntityEvidence(body));
            props.setOpen(false);
        } else {
            if (file) {
                if (values.hyperlink) {
                    const hyperlinkBody = {
                        hyperlink: values.hyperlink,
                        hyperlink_notes: values.description,
                        variable_code: variableCode,
                        report: currentReport?.id,
                        topic: topicId,
                        evidence_type: 'H'
                    }
                    await dispatch(postGresbEntityEvidence(hyperlinkBody));
                }
                const fileBody = {
                    evidence: file,
                    variable_code: variableCode,
                    report: currentReport?.id,
                    topic: topicId,
                    evidence_type: 'F',
                    file_notes: values.description
                }
                await dispatch(postGresbEntityEvidence(fileBody));
                props.setOpen(false);
            } else if (values.hyperlink) {
                const hyperlinkBody = {
                    hyperlink: values.hyperlink,
                    hyperlink_notes: values.description,
                    variable_code: variableCode,
                    report: currentReport?.id,
                    topic: topicId,
                    evidence_type: 'H'
                }
                await dispatch(postGresbEntityEvidence(hyperlinkBody));
            } else {
                BEMessage.error('Please enter hyperlink or upload file');
            }
        }
        setLoading(false);
    };
    return (
        <div>
            <BEDrawer
                width='fit-content'
                open={props.open}
                setOpen={props.setOpen}
                heading='Add Reporting Evidence'
                footer={
                    <>
                        <BEButton size='large' className='primary' loading={loading}
                            onClick={() => {
                                form.submit();
                            }}>Save</BEButton>
                        <BEButton size='large' onClick={() => props.setOpen(false)}>Cancel</BEButton>
                    </>
                }
            >
                <Form form={form} onFinish={onFinish}>
                    <p style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>
                        {
                            onlyHyperlink ?
                                <span>Enter hyperlink</span>
                                :
                                <span>Enter hyperlink</span>
                        }
                    </p>
                    {onlyHyperlink ?
                        <Form.Item name='hyperlink'>
                            <BEInput
                                placeholder="Enter hyperlink"
                            />
                        </Form.Item>
                        :

                        <>
                            <Form.Item name='hyperlink'>
                                <BEInput
                                    placeholder="Enter hyperlink"
                                />
                            </Form.Item>
                            <p style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>
                                Upload evidence
                            </p>
                            <FileUpload
                                width='350px'
                                handleFileChange={(file: any) => setFile(file)} refreshUpload={props.open}
                                fileType='any'
                                hint='File can be in any format up to 5MB in size'
                                fileSize={5000000}
                            />
                            <br />
                        </>
                    }

                    <p style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>
                        Indicate where the relevant information can be found
                    </p>
                    <Form.Item name='description' rules={[{ required: false }]}>
                        <BETextArea
                            placeholder='The evidence can be found in the third paragraph in the example section.'
                            form={form}
                            rows={6}
                        />
                    </Form.Item>
                </Form>

                {
                    evidenceList?.length > 0 &&
                    <BETable
                        columns={columns}
                        data={evidenceList}
                        pagination={true}
                    />
                }

            </BEDrawer>
        </div>
    );
}
