import React from 'react'
import IconProps from './Interface/IconProps'

const EyeIcon = (props: IconProps) => {
    return (
        <svg
            width={props.inheritSize ? "100%" : 24}
            height={props.inheritSize ? "100%" : 24}
            viewBox="0 0 24 24"
            fill={props.fill ? props.fill : "none"}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.4569 14.7975C22.435 14.7481 21.9056 13.5738 20.7287 12.3969C19.1606 10.8287 17.18 10 15 10C12.82 10 10.8394 10.8287 9.27124 12.3969C8.09437 13.5738 7.56249 14.75 7.54312 14.7975C7.51469 14.8614 7.5 14.9306 7.5 15.0006C7.5 15.0706 7.51469 15.1398 7.54312 15.2037C7.56499 15.2531 8.09437 16.4269 9.27124 17.6038C10.8394 19.1713 12.82 20 15 20C17.18 20 19.1606 19.1713 20.7287 17.6038C21.9056 16.4269 22.435 15.2531 22.4569 15.2037C22.4853 15.1398 22.5 15.0706 22.5 15.0006C22.5 14.9306 22.4853 14.8614 22.4569 14.7975ZM15 19C13.0762 19 11.3956 18.3006 10.0044 16.9219C9.43352 16.3542 8.94786 15.7068 8.56249 15C8.94776 14.2931 9.43343 13.6457 10.0044 13.0781C11.3956 11.6994 13.0762 11 15 11C16.9237 11 18.6044 11.6994 19.9956 13.0781C20.5676 13.6456 21.0543 14.2929 21.4406 15C20.99 15.8412 19.0269 19 15 19ZM15 12C14.4067 12 13.8266 12.1759 13.3333 12.5056C12.8399 12.8352 12.4554 13.3038 12.2284 13.8519C12.0013 14.4001 11.9419 15.0033 12.0576 15.5853C12.1734 16.1672 12.4591 16.7018 12.8787 17.1213C13.2982 17.5409 13.8328 17.8266 14.4147 17.9424C14.9967 18.0581 15.5999 17.9987 16.148 17.7716C16.6962 17.5446 17.1648 17.1601 17.4944 16.6667C17.824 16.1734 18 15.5933 18 15C17.9992 14.2046 17.6828 13.442 17.1204 12.8796C16.558 12.3172 15.7954 12.0008 15 12ZM15 17C14.6044 17 14.2178 16.8827 13.8889 16.6629C13.56 16.4432 13.3036 16.1308 13.1522 15.7654C13.0009 15.3999 12.9613 14.9978 13.0384 14.6098C13.1156 14.2219 13.3061 13.8655 13.5858 13.5858C13.8655 13.3061 14.2219 13.1156 14.6098 13.0384C14.9978 12.9613 15.3999 13.0009 15.7654 13.1522C16.1308 13.3036 16.4432 13.56 16.6629 13.8889C16.8827 14.2178 17 14.6044 17 15C17 15.5304 16.7893 16.0391 16.4142 16.4142C16.0391 16.7893 15.5304 17 15 17Z"
                fill={props.fill ? props.fill : "#333333"}
            />
        </svg>
    );
}

export default EyeIcon