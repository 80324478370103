import * as React from 'react';
import { StepCollapse } from '../../../../../../Components/BECollapse/StepCollapse';
import { Row, Col, Form } from 'antd';
import { BEInput } from '../../../../../../Components/BEFormItems/BEInput';
import { LableRequired } from '../../../../../../Components/BEFormItems/LableRequired';
import { BETextArea } from '../../../../../../Components/BEFormItems/BETextArea';
import BEDatePicker from '../../../../../../Components/BEDatePicker';
import Button from 'antd';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';
import { createMaterialitySurveys, editAssessment, getMaterialitySurveys } from '../../../../Actions/matirialityActions';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import dayjs from 'dayjs';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { BEMessage } from '../../../../../../Components/BEMessage';
import { useParams } from 'react-router-dom';

export interface IStep1Props {
  open: boolean;
  setOpen?: Function
  onCancle: Function
  isEdit?:boolean
  currentStakeholder?: any
}

export function Step1(props: IStep1Props) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const allSurveys = useTypedSelector((state) => state.matiriality.AllSurveys)
  const [surveyDetails, setSurveyDetails] = React.useState<any>(null)
  const currentMatirialitySurvey = useTypedSelector((state) => state.matiriality.currentMaterialitySurvey)

  React.useEffect(() => {
    if(currentMatirialitySurvey){
      form.setFieldsValue({
        title: currentMatirialitySurvey.title,
        deadline: dayjs(currentMatirialitySurvey.deadline),
        description: currentMatirialitySurvey.description,
      }); 
    }
  },[currentMatirialitySurvey])

  const onSubmit = () => {
    setLoading(true)
    const deadline = form.getFieldsValue().deadline
    if (dayjs(deadline).isBefore(dayjs(new Date))) {
      BEMessage.error("Deadline shouldn't be in the past")
      setLoading(false);
      return
    }

    let obj = {
        ...form.getFieldsValue(),
        deadline:dayjs(form.getFieldsValue().deadline).format('YYYY-MM-DD'),
    }
    if (props.isEdit || currentMatirialitySurvey) {
      dispatch(editAssessment(Number(currentMatirialitySurvey?.id), obj)).then(() => {
        setLoading(false);
        dispatch(getMaterialitySurveys());
        props.setOpen!(false)
      });;
    }
    else {
      dispatch(createMaterialitySurveys(obj)).then(() => {
        setLoading(false);
        form.resetFields();
      });
    }
  }

  React.useEffect(() => {
    if (props.isEdit && allSurveys?.status === 'success') {
        const filteredAssessment = allSurveys?.data.filter((survey) => {
        return survey.id == props.currentStakeholder
        })
      form.setFieldsValue({
        title: filteredAssessment[0].title,
        deadline: dayjs(filteredAssessment[0].deadline),
        description: filteredAssessment[0].description,
      });
    }
  }, [props.isEdit, allSurveys])
  return (
    <div>
      {!props.isEdit ? (
        <StepCollapse
          step={1}
          heading="Survey Details"
          subheading="Enter basic details about the survey you’re creating"
          open={props.open}
          setOpen={props.setOpen}
        >
          <div style={{ width: "50%", padding: "1rem" }}>
            <Form form={form}>
              <Row gutter={16}>
                <Col span={12}>
                  <LableRequired>Title</LableRequired>
                  <Form.Item name="title">
                    <BEInput size="large" placeholder="Enter title" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <LableRequired>Deadline</LableRequired>
                  <Form.Item name="deadline">
                    <BEDatePicker
                      handleDateChange={(value: any) =>
                        form.setFieldsValue({ deadline: value })
                      }
                      format="DD MMM YYYY"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <p>Description</p>
                  <Form.Item
                    name="description"
                  >
                    <BETextArea
                      rows={4}
                      form={form}
                      placeholder="Enter description"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ marginTop: "1rem" }}>
                  <BEButton
                    loading={loading}
                    onClick={onSubmit}
                    size="large"
                    className="primary"
                  >
                    Next
                  </BEButton>
                  <BEButton
                    size="large"
                    style={{ marginLeft: "1rem" }}
                    onClick={() => props.onCancle()}
                  >
                    Cancel
                  </BEButton>
                </Col>
              </Row>
            </Form>
          </div>
        </StepCollapse>
      ) : (
        <Form form={form}>
          <Row gutter={16}>
            <Col span={12}>
              <LableRequired>Title</LableRequired>
              <Form.Item name="title">
                <BEInput size="large" placeholder="Enter title" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <LableRequired>Deadline</LableRequired>
              <Form.Item name="deadline">
                <BEDatePicker
                  handleDateChange={(value: any) =>
                    form.setFieldsValue({ deadline: value })
                  }
                  format="DD MMM YYYY"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <LableRequired>Description</LableRequired>
              <Form.Item
                name="description"
                rules={[{ required: true, message: "Select a description" }]}
              >
                <BETextArea
                  rows={4}
                  form={form}
                  placeholder="Enter description"
                />
              </Form.Item>
            </Col>
            <Col span={24} style={{ marginTop: "1rem" }}>
              <BEButton
                loading={loading}
                onClick={onSubmit}
                size="large"
                className="primary"
              >
                Next
              </BEButton>
              <BEButton
                size="large"
                style={{ marginLeft: "1rem" }}
                onClick={() => props.onCancle()}
              >
                Cancel
              </BEButton>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
}
