import ToolTip from '../../../../Components/BEToolTip';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import './styles.scss'
interface TargetProgressBarProps{
	targetType: string;
	progress: number;
	baseLineValue: number;
	targetValue: number;
	current_value: number;
}
const TargetProgressBar = (props:TargetProgressBarProps) => {
	const isTargetTypeAC = props.targetType === 'A' || props.targetType === 'C';
	const isTargetTypeBD = props.targetType === 'B' || props.targetType === 'D';
	
	const positiveGradientAC = `linear-gradient(to right, transparent, ${PrimaryTheme.primaryGreen} 100%)`;
  	const positiveGradientBD = `linear-gradient(to left, transparent , ${PrimaryTheme.primaryGreen}  100%)`;

	
	const negativeGradientAC = `linear-gradient(to left, transparent, ${PrimaryTheme.primaryRedError} 100%)`;
	const negativeGradientBD = `linear-gradient(to right, transparent, ${PrimaryTheme.primaryRedError} 100%)`;
	
    const newPercentage = Math.min(props.progress, 100);
    const progressPercentage = Math.max(newPercentage, -100);
  
	const positiveProgressStyles = {
	  width: `${Math.abs(progressPercentage/2)}%`,
	  backgroundImage: isTargetTypeAC ? positiveGradientAC : positiveGradientBD,
	  height: '100%',
	  borderRadius: '3px',
	};
  
	const negativeProgressStyles = {
	  width: `${Math.abs(progressPercentage/2)}%`,
	  backgroundImage: isTargetTypeAC ? negativeGradientAC : negativeGradientBD,
	  height: '100%',
	  borderRadius: '3px',
	};

  return (
	<div className="progress-bar-container">
		<div style={{display:'flex',justifyContent:'space-between',flexDirection:isTargetTypeAC ? 'row-reverse':'row'}}>
		<span>Target: {props.targetValue}</span>
		<span>
			{props.progress >= 0 ? (
				<>
				{Math.round(Math.abs(progressPercentage))}% of target met
				</>
			) : (
				<>Moving away from target</>
			)}
		</span>

		</div>
		<ToolTip title={
			<div>
				<p style={{fontSize:'small'}}>Baseline value : {props.baseLineValue}</p>
				<p  style={{fontSize:'small'}}>Target value : {props.targetValue}</p>
				<p  style={{fontSize:'small'}}>Current value : {props.current_value}</p>
			</div>
		}>
		<div className="progress-bar">	
		{props.progress >= 0 ? (
			<div style={{
				...positiveProgressStyles,
				float: isTargetTypeAC ? 'left' : 'right',
				position: 'absolute', 
				left: isTargetTypeAC ? '50%' : '',
				right: isTargetTypeBD ? '50%' : '',
			}}>
			</div>
			) : (
			<div style={{
				...negativeProgressStyles,
				float: isTargetTypeAC ? 'right' : 'left',
				position: 'absolute',
				right: isTargetTypeAC ? '50%' : '',
				left: isTargetTypeBD ? '50%' : '',
			}}>
				</div>
			)}	
			<div className="base-line-icon">	
			</div>
		</div>
		</ToolTip>
	</div>
  )
}

export default TargetProgressBar