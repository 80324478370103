import * as React from 'react';
import StackedColumnCharts from '../../../../Components/BECharts/StackedColumnCharts';
import { rotateArray } from '../../../../Config/Functions/UsefullFunctions';
import { shortMonths } from '../../../../Config/Data/genralData';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { PrimaryTheme } from '../../../../Config/Theme/theames';

export interface ITrackerGraphProps {
    data: any;
}

export function TrackerGraph(props: ITrackerGraphProps) {
    const yearEnd = useTypedSelector(state => state.companies.yearEnd);
    const currentYear = useTypedSelector((state) => state.dataManager.metrics.dateSlicer.currentYear);
    const currentMonth = useTypedSelector((state) => state.dataManager.metrics.dateSlicer.currentMonth);
    const [chartData, setChartData] = React.useState<any[]>([]);
    const getChartData = (data: any) => {
        let chartData: any[] = [
            {
                name: 'Filled',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
                name: 'Pending',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
                name: 'Approved',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
        ];
        
        data.forEach((item: any) => {
            let monthIndex = ((item.month-1)+ 12 - yearEnd)%12;
            chartData[1].data[monthIndex] += item.pending;
            chartData[0].data[monthIndex] += item.filled;
            chartData[2].data[monthIndex] += item.approved;
        });
        return chartData;
    }

    React.useEffect(() => {
        setChartData(getChartData(props.data));
    }, [props.data, currentYear, currentMonth]);

    return (
        <div>
            <h3>Metric level completion status by month</h3>
            <br />
            <div
            style={{
                border: `1px solid ${PrimaryTheme.primaryGreyLight}`,
                borderRadius: '5px'
                }}
            >
                <StackedColumnCharts
                    height='42vh'
                    data={rotateArray(chartData, yearEnd, true)}
                    yAxisLabel='Number of metrics'
                    xAxisLabels={rotateArray(shortMonths, yearEnd, true)}
                />
            </div>
        </div>
    );
}
