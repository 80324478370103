import * as React from 'react';
import { VaultFolder } from '../../Components/VaultFolder';
import AddIcon from '../../../../Components/BEIcons/AddIcon';
import './index.scss';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import { CreateFolder } from '../Drawers/CreateFolder';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { deleteVaultFolders, getVaultFolders } from '../../Actions';
import { FolderType } from '../../../../Redux/Types/vaultTypes';
import { navigateTo } from '../../../../Navigation/NavigationManager';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { Dropdown, MenuProps } from 'antd';
import BEConfirmModal from '../../../../Components/BEConfirmModal';
import { GlobalLoader } from '../../../../Components/GlobalLoader';
import { BEPagination } from '../../../../Components/BEPagination';
import { TopSection } from '../../../Settings/Components/TopSection';
import { ColumnsType } from 'antd/es/table';
import { ChangeDateFormat } from '../../../../Config/Functions/UsefullFunctions';
import EditIcon from '../../../../Components/BEIcons/EditIcon';
import DeleteIcon from '../../../../Components/BEIcons/DeleteIcon';
import { NewBETable } from '../../../../Components/BETable/newBETable';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
export interface IAllVaultFoldersProps {
}

export function AllVaultFolders(props: IAllVaultFoldersProps) {
  const [createNewFolder, setCreateNewFolder] = React.useState(false);
  const [createdFolderName, setCreatedFolderName] = React.useState('New Folder');
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const users=useTypedSelector((state)=>state.userMgmt?.data)
  const VaultFolders = useTypedSelector((state) => state.vault.Folders);
  const view = useTypedSelector((state) => state.vault.view);
  const [userFolders, setUserFolders] = React.useState<FolderType[]>(VaultFolders?.data)
  const [isEdit, setIsEdit] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [hoveredFolderId, setHoveredFolderId] = React.useState(0)
  const [page, setPage] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [userFolderSearchValue, setUserFolderSearchValue] = React.useState<string>("all");
  const dispatch = useAppDispatch();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <span onClick={() => {
          setCreateNewFolder(true);
          setIsEdit(true);
        }}>
          Edit Folder
        </span>
      ),
    },
    {
      key: '2',
      label: (
        <span onClick={() => {
          setConfirmDelete(true);
        }}>
          Delete Folder
        </span>
      ),
    }
  ];

  const folderColumns: ColumnsType<any>=[
    {
      title: 'S. No.',
      dataIndex: 'sno',
      key: 'sno',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render:(text,record)=>{
        return <span onClick={() => navigateTo(record.title==="System Folder" ? '/home/settings/vault/0' :`/home/settings/vault/${record.id}`)} style={{color:PrimaryTheme.primaryBlueInfo,textDecoration:'underline',cursor:'pointer'}}>{text}</span>
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Uploaded On',
      dataIndex: 'uploaded_on',
      key: 'uploaded_on',
      render: (value, data: any) => {
        return <p>{ChangeDateFormat(data.uploaded_on)}</p>;
      },
    },
    {
      title: 'Uploaded By',
      dataIndex: 'uploaded_by',
      key: 'uploaded_by',
      render: (value, data: any) => {
        const user = users?.find((item: any) => item.id === data.uploaded_by);
        return <p>{user?.first_name} {user?.last_name}</p>
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => (
        <div style={{ display: "flex", gap: "1rem", flexDirection: "row" }} onMouseEnter={() => setHoveredFolderId(record.id)}>
          <div style={{ width: "1rem" }} onClick={() => {
           setCreateNewFolder(true);
           setIsEdit(true);
          }}>
            <EditIcon inheritSize={true} stroke={PrimaryTheme.primaryGreyDark} />
          </div>
          <div style={{ width: "1rem" }} onClick={() => {
            setConfirmDelete(true);
          }}>
            <DeleteIcon inheritSize={true} stroke={PrimaryTheme.primaryGreyDark} />
          </div>
        </div>)
    }
  ]

  const systemFolderColumn: ColumnsType<any>=[
    {
      title: 'S. No.',
      dataIndex: 'sno',
      key: 'sno',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render:(text,record)=>{
        return <span onClick={() => navigateTo(record.title==="System Folder" ?
          record.title==="Supplier Assessment" ? '/home/settings/vault/-1' :
           '/home/settings/vault/0' :`/home/settings/vault/${record.id}`)} 
        style={{color:PrimaryTheme.primaryBlueInfo,textDecoration:'underline',cursor:'pointer'}}>{text}</span>
      }
    },
  ]
  React.useEffect(() => {
    setLoader(true);
    if (VaultFolders?.status === "success") {
      setLoader(false);
    }
    setUserFolders(VaultFolders?.data);
  }, [VaultFolders]);

  return (
    <>
    {view==='grid'?
      <div className='system-folder-container' style={{display:'flex',gap:'2rem'}}>
       <VaultFolder name={"System Folder"} onClick={() => navigateTo("/home/settings/vault/0")} />
        <VaultFolder name={"Supplier Assessment"} onClick={() => navigateTo("/home/settings/vault/-1")} />
       </div>: 
        <div style={{width:'100%',margin:'1.5rem',padding:'1rem'}}>
          <NewBETable columns={systemFolderColumn} data={[{title:'System Folder'},{title:'Supplier Assessment'}]} />
        </div>
      } 

      <div className='user-folders-container'>
        {loader ?
          <>
            <div className='top-section'>
              <h3>User folders</h3>
              <div style={{ flexGrow: 1 }} />
              {/* <BEInput size='large' style={{ width: "313px" }} search columnSearchValue={userFolderSearchValue}
              searchForColumns={{ "All": "all", "Folders": "folders", "Files": "files" }} /> */}
            </div>
            {view === 'grid' ?
            <>
             <div className='user-folder-section'>
              <div className='new-folder' onClick={() => {
                setIsEdit(false);
                setCreateNewFolder(true);
              }}>
                <div className='circle'>
                  <AddIcon stroke={PrimaryTheme.primaryGreen} />
                </div>
                <span style={{ fontSize: "14px", lineHeight: "21px", fontWeight: "600" }}>New Folder</span>
              </div>
              <div className='folders-section'>
                {userFolders?.slice((currentPage - 1) * 10, currentPage * 10).map((item: FolderType) =>
                  <Dropdown menu={{ items }}>
                    <a onMouseEnter={() => setHoveredFolderId(item.id)} onClick={(e) => e.preventDefault()}>
                      <VaultFolder
                        key={item.id}
                        name={item.title}
                        onClick={() => navigateTo(`/home/settings/vault/${item.id}`)}
                      />
                    </a>
                  </Dropdown>
                )}
              </div>
            </div>
            <BEPagination
              total={userFolders?.length}
              pageSize={10}
              onChange={(page) => { setPage(page); setCurrentPage(page); }}
              current={currentPage}
            />
            </>
            :
            <div style={{width:'100%'}}>
              <NewBETable
                data={userFolders}
                columns={folderColumns}
                TableTopRightComponents={
                  <BEButton onClick={()=>{
                    setIsEdit(false);
                    setCreateNewFolder(true);
                  }} 
                  style={{backgroundColor:PrimaryTheme.primaryGreen}}
                  type='primary'>New Folder</BEButton>
                }
                pagination
                search
              /> 
            </div>}
          </> :
          <GlobalLoader height='100%' />
        }
      </div>
      <CreateFolder
        folderId={hoveredFolderId}
        open={createNewFolder}
        isEdit={isEdit}
        setOpen={setCreateNewFolder}
        setCurrentPage={setCurrentPage}
      />
      <BEConfirmModal
        title='Delete Folder?'
        visible={confirmDelete}
        setVisible={setConfirmDelete}
        onConfirm={async () => {
          await dispatch(deleteVaultFolders(hoveredFolderId)).then(() => {
            (VaultFolders?.data.length % 10 === 1) && setCurrentPage(Math.ceil(VaultFolders?.data.length / 10) - 1);
          })
        }}
        message='Are you sure you want to delete this file?'
      />
    </>
  );
}
