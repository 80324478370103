import * as React from 'react';
import { BETabs } from '../../../../../../Components/BETabs';
import { PrimaryTheme } from '../../../../../../Config/Theme/theames';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { useParams } from 'react-router-dom';
import { VaultFolder } from '../../../../Components/VaultFolder';
import { metricType } from '../../../../../../Redux/Types/dataManagerTypes';
import { pillarOrder, PillerMap } from '../../../../../../views/Onboarding/Step4/TopTabsFilter';
import '../../index.scss';
import { navigateTo } from '../../../../../../Navigation/NavigationManager';
import { ColumnsType } from 'antd/es/table';
import { RootState } from '../../../../../../Redux/store';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { ChangeDateFormat, filterDataByFY } from '../../../../../../Config/Functions/UsefullFunctions';
import { exportReport } from '../../../../../Reporting/Actions/template';
import { Spin } from 'antd';
import ExportExcelIcon from '../../../../../../Components/BEIcons/ExportIcons/ExportExcelIcon';
import { NewBETable } from '../../../../../../Components/BETable/newBETable';
import BECardPagination from '../../../../../../Components/BECardsPagination';

export interface IFYFoldersProps {
}

function DataManager() {
  const FYData = useTypedSelector(state => state.vault.FYData);
  const combinedSelectedMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.combinedSelectedMetricsPerYear);
  const [currentFYMetrics, setCurrentFYMetrices] = React.useState<any>([]);
  const { fy_id } = useParams();
  const view = useTypedSelector(state => state.vault.view);

  React.useEffect(() => {
    if (FYData?.status === 'success' && combinedSelectedMetricsPerYear?.status === 'success') {
      let temp: any = [];
      Object.keys(FYData?.data[Number(fy_id)!] || {})?.map((item: any) => {
        let filteredMetric = combinedSelectedMetricsPerYear?.data.filter((metric: any) => metric.id == item && metric && metric.pillars);
        if (filteredMetric.length > 0) {
          temp.push(filteredMetric[0]);
        }
      });
      console.log(temp,'temp')
      setCurrentFYMetrices(temp);
    }
  }, [fy_id, FYData, combinedSelectedMetricsPerYear]);
  

  const metricColumns:ColumnsType<any>=[
    {
      title: 'S. No.',
      dataIndex: 'sno',
      key: 'sno',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render:(text,record)=> {
      console.log(record)
      return <span onClick={()=> navigateTo(`/home/settings/vault/0/${fy_id}/${record.id}`)} style={{color:PrimaryTheme.primaryBlueInfo,textDecoration:'underline'}}>{text}</span>}
    },
  ]
  return (
    <div style={{ margin: "1rem 3rem 0 3rem", padding: "1rem" }}>
      <BETabs
        items={
          Array.from(
            new Set(currentFYMetrics.map((item: metricType) => item.pillars))
          ).map((item: any) => {
            return {
              key: item,
              label: PillerMap[item],
              children:
              <>
                {view ==='grid' ?  <div className='system-folder-container-tabs'>
                  <div className='metric-folders'>
                    <BECardPagination
                      open={false}
                      ComponentsArrey={
                        currentFYMetrics.filter((metric: metricType) => metric.pillars === item).map((metric: metricType) => {
                          return <VaultFolder
                            onClick={() => {
                              navigateTo(`/home/settings/vault/0/${fy_id}/${metric.id}`);
                            }}
                            name={metric.title}
                          />
                        })
                      }
                      noOfColumns={8}
                      noOfRows={1}
                    />
                  </div>
                </div>
                :
                <div>
                  <NewBETable 
                    columns={metricColumns}
                    data={currentFYMetrics.filter((metric: metricType) => metric.pillars === item)}
                  />
                </div>}
              </>
            }
          })
        } />
    </div>
  );
}

function Reports() {
  const dispatch = useAppDispatch()
  const { fy_id } = useParams()
  const [loading, setLoading] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<any>(null)
  const reports = useTypedSelector((state: RootState) => state.reporting.allReports)
  const allUsers = useTypedSelector((state: RootState) => state.userMgmt);
  const [tableData, setTableData] = React.useState<any>([]);
  const reportingYearEnd = useTypedSelector(state => state.companies.companyDetails?.data?.reporting_year_end);

  const columns: ColumnsType<any> = [
    {
      title: "Report Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => (a < b ? -1 : 1),
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      filters: allUsers?.data.map((user) => {
        return { text: user.first_name, value: user.id };
      }),
      onFilter: (value, record) => {
        return record.reviewer === Number(value);
      },
      render: (value, data: any) => {
        return (
          <p>
            {allUsers?.data?.find((user: any) => user.id === data.reviewer)
              ?.first_name +
              " " +
              allUsers?.data?.find((user: any) => user.id === data.reviewer)
                ?.last_name}
          </p>
        );
      },
    },
    {
      title: "Start Date",
      dataIndex: "from_date",
      key: "from_date",
      render: (value, data: any) => {
        return <p>{ChangeDateFormat(data.from_date)}</p>;
      },
    },
    {
      title: "End Date",
      dataIndex: "to_date",
      key: "to_date",
      render: (value, data: any) => {
        return <p>{ChangeDateFormat(data.to_date)}</p>;
      },
    },

    {
      title: 'Published',
      dataIndex: 'published_on',
      render: (value, data: any) => {
        return <p>{data.published_on && data.stage >= 3
          ? ChangeDateFormat(data.published_on) : '-'}</p>
      },
    },
    {
      title: "Download",
      dataIndex: "download",
      key: "download",
      render: (value, data: any) => {
        if (data.stage >= 3)
          return (
            <div
              style={{ cursor: 'pointer' }}
              onMouseEnter={() => setSelectedRow(data)}
              onClick={async () => {
                setLoading(true)
                await dispatch(exportReport(selectedRow))
                setLoading(false)
              }}
            >
              {loading && selectedRow?.id === data.id
                ? (
                  <Spin size="small" />
                ) : (
                  <ExportExcelIcon fill={PrimaryTheme.primaryGreen} />
                )}
            </div>
          );
      },
    }
  ];

  React.useEffect(() => {
    if (reports?.status === 'success') {
      fillTableData()
    }
  }, [fy_id, reports])


  const fillTableData = async () => {
    let dateWithReports = reports?.data.filter((report) => report.stage >= 3)
    let data = filterDataByFY(Number(fy_id), dateWithReports, 'from_date', reportingYearEnd || '12-31')
    setTableData(data);
  }

  return (
    <div style={{ margin: "2rem 0 0 3rem", padding: "1rem" }}>
      <NewBETable data={tableData} columns={columns} />
    </div>
  )
}


export function FYFolders(props: IFYFoldersProps) {
  return (
    <div>
      {
        // two tabs one for MetricFIles and one for ReportFiles
      }
      <BETabs
        tabBarStyle={{
          backgroundColor: PrimaryTheme.primaryGreyLight, height: "4rem",
          paddingLeft: '3rem', paddingTop: '1rem', marginTop: "2rem"
        }}
        type='card'
        items={[
          {
            key: 'Metrics',
            label: 'Data Manager',
            children: <DataManager />,
          },
          {
            key: 'Reports',
            label: 'Reports',
            children: <Reports />,
          }
        ]}
      />
    </div>
  );
}
