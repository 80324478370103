import React from 'react'
import IconProps from './Interface/IconProps';

const IntegrationIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 16}
      height={props.inheritSize ? "100%" : 17}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M7.33333 10.6133L5.88667 9.16659L7.33333 7.71992L6.39333 6.77325L4 9.16659L6.39333 11.5599L7.33333 10.6133Z"
          fill={props.fill ? props.fill : "black"}
        />
        <path
          d="M9.60667 11.5599L12 9.16659L9.60667 6.77325L8.66667 7.71992L10.1133 9.16659L8.66667 10.6133L9.60667 11.5599Z"
          fill={props.fill ? props.fill : "black"}
        />
        <path
          d="M12.6667 3.16659H9.88C9.6 2.39325 8.86667 1.83325 8 1.83325C7.13333 1.83325 6.4 2.39325 6.12 3.16659H3.33333C3.24 3.16659 3.15333 3.17325 3.06667 3.19325C2.80667 3.24659 2.57333 3.37992 2.39333 3.55992C2.27333 3.67992 2.17333 3.82659 2.10667 3.98659C2.04 4.13992 2 4.31325 2 4.49992V13.8333C2 14.0133 2.04 14.1933 2.10667 14.3533C2.17333 14.5133 2.27333 14.6533 2.39333 14.7799C2.57333 14.9599 2.80667 15.0933 3.06667 15.1466C3.15333 15.1599 3.24 15.1666 3.33333 15.1666H12.6667C13.4 15.1666 14 14.5666 14 13.8333V4.49992C14 3.76659 13.4 3.16659 12.6667 3.16659ZM8 2.99992C8.27333 2.99992 8.5 3.22659 8.5 3.49992C8.5 3.77325 8.27333 3.99992 8 3.99992C7.72667 3.99992 7.5 3.77325 7.5 3.49992C7.5 3.22659 7.72667 2.99992 8 2.99992ZM12.6667 13.8333H3.33333V4.49992H12.6667V13.8333Z"
          fill={props.fill ? props.fill : "black"}
        />
      </g>
    </svg>
  );
}

export default IntegrationIcon