import * as React from 'react';
import UsersIcon from '../../../../Components/BEIcons/UsersIcon';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { Dropdown } from 'antd';
import { MenuProps } from 'antd';
import { Label } from 'recharts';
import { navigateTo } from '../../../../Navigation/NavigationManager';
import BEConfirmModal from '../../../../Components/BEConfirmModal';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { getNewToken, logoutAction } from '../../../Actions/LoginActions';
import { ProfileModal } from '../Profile';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';

export interface IUserTopNavProps {
}

export function UserTopNav(props: IUserTopNavProps) {
  const userInfo = JSON.parse(localStorage.getItem("userInfo")!);
  const userName = userInfo?.first_name ? `${userInfo?.first_name} ${userInfo?.last_name}` : `${userInfo?.username}`
  const [showLogoutModal, setShowLogoutModal] = React.useState(false);
  const [showProfileModal, setShowProfileModal] = React.useState(false);
  const userProfile = useTypedSelector((state) => state.user.userProfile);
  const dispatch = useAppDispatch();

  // const goToZero = async() => {
  //   const userInfo = JSON.parse(localStorage.getItem("userInfo")!);
  //   const token = await dispatch(getNewToken());
  //   if(!token) return; 
  //   const querryString = `?token=${JSON.stringify(token)}&user_info=${JSON.stringify(userInfo)}`
  //   const esgUrl  = process.env.REACT_APP_ZERO_URL;
  //   window.open(`${esgUrl}/login${querryString}`, '_blank');
  // }

  const items: MenuProps['items'] = [
    {
      key: 1,
      label: 'View profile',
      onClick: () => {
        setShowProfileModal(true);
      }
    },
    {
      key: 2,
      label: 'Help & Support',
      onClick: () => {
        navigateTo('https://breatheesg.freshdesk.com/', true, true)
      }
    },
    // {
    //   key: 3,
    //   label: 'Go to Breathe Zero',
    //   onClick:goToZero
    // },
    {
      key: 3,
      label: <p style={{ color: PrimaryTheme.secondaryRedError }}>Logout</p>,
      onClick: () => {
        setShowLogoutModal(true);
      }
    }
  ]
  return (
    <>
      <p style={{
        fontWeight: '600',
        color: PrimaryTheme.primaryGray,
        fontSize: '0.9rem',
      }}>{userName}</p>
      <Dropdown menu={{ items }} trigger={['click']}>
        <div style={{ height: '16px', borderRadius: '50%', marginBottom: userProfile?.data?.profile_pic === null ? "0" : "0.3rem" }}>
          {userProfile?.data?.profile_pic === null ?
          <UsersIcon inheritSize fill={PrimaryTheme.primaryGreyDark} /> :
            <img src={userProfile?.data?.profile_pic} alt='user-image'
            style={{ height: '24px', borderRadius: '50%', width:"24px"}}/>}
        </div>
      </Dropdown>
      <BEConfirmModal
        allowAuditorAccess
        title="Confirm Logout"
        message="Are you sure you want to logout?"
        visible={showLogoutModal}
        setVisible={setShowLogoutModal}
        onConfirm={async () => {
          await dispatch(logoutAction());
        }}
      />
      <ProfileModal
        open={showProfileModal}
        setOpen={setShowProfileModal}
      />
    </>
  );
}
