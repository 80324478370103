import React, { useEffect, useState } from 'react'
import { BETable } from '../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import EmissionCard from '../../../../Components/Cards/EmissionCard';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import {
  filterDataByMonthOrBU,
  getAllEmissionCalculations,
  getScope3EmissionCalculations,
} from '../../Actions';
import { AddCommaToNumber } from '../../../../Config/Functions/UsefullFunctions';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import { Col, Row } from 'antd';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { TotalEmissionsByScopeGraph } from './Graphs/EmmisionsByScopeGraph';
import { TotalEmissionsByTypeGraph } from './Graphs/EmissionsBYTypeGraph';
import { GlobalLoader } from '../../../../Components/GlobalLoader';
import { EmmisionsDonutChart } from './Graphs/EmmisionsDonutCharts';
import './style.scss';
import { SetTableAndGraphDataDM } from '../../../DataManager/Components/SetTableAndGraphDataFunctionDM';

export interface IOverviewProps {

}

const Overview = () => {
  const dispatch = useAppDispatch()
  const scope1CalculationsByMonth = useTypedSelector(state => state.emissions.calculations.scope1calculationForMonths);
  const scope2CalculationsByMonth = useTypedSelector(state => state.emissions.calculations.scope2calculationForMonths);
  const scope3CalculationsByMonth = useTypedSelector(state => state.emissions.calculations.scope3calculationForMonths);
  const yearEnd = useTypedSelector((state) => state.companies.yearEnd);
  const currentBU = useTypedSelector(state => state.common.homePage.currentBusinessUnit)
  const currentBG = useTypedSelector(state => state.common.homePage.currentBusinessGroup)
  const [scope1Total, setScope1Total] = useState(0)
  const [scope2Total, setScope2Total] = useState(0)
  const [scope3Total, setScope3Total] = useState(0)
  const [totalEmission, setTotalEmission] = useState(0)
  const [allCalculations, setAllCalculations] = useState<any>([])
  const [filteredMetrics, setFilteredMetrics] = useState<any>([])
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const currentMonth = useTypedSelector((state) => state.dataManager.metrics.dateSlicer.currentMonth);
  const myMetrics = useTypedSelector((state) => state.onBoarding.metrics.myMetrics);
  const [filteredScope1Calc, setScope1FilteredCalc] = useState<any[]>([])
  const [filteredScope2Calc, setScope2FilteredCalc] = useState<any[]>([])
  const [filteredScope3Calc, setScope3FilteredCalc] = useState<any[]>([])

  const columns: ColumnsType<any> = [
    {
      title: "Sr. No.",
      dataIndex: "metric",
      render: (value, record, ind) => ind + 1,
    },
    {
      title: "Metric",
      dataIndex: "my_metric",
      render: (value, record) => {
        return <p>{
          myMetrics?.data?.find((obj: any) => obj.id === record.my_metric)?.title
        }</p>;
      }
    },
    // {
    //   title: "Business Unit",
    //   dataIndex: "business_unit",
    //   render: (value, record) => {
    //     return <p>{
    //       businessUnits?.find((obj: any) => obj.id === record.business_unit)?.name
    //     }</p>;
    //   },
    // }, 
    // {
    //   title: "Month",
    //   dataIndex: "month",
    //   render: (value) => {
    //     return <p>{shortMonths[value - 1]}</p>;
    //   },
    // },
    // {
    //   title: "Quantity",
    //   dataIndex: "tco2e",
    //   render: (value, record) => {
    //     return (
    //       <p>
    //         {AddCommaToNumber(Number(value), false, true)}
    //       </p>
    //     );
    //   },
    // },
    {
      title: "Scope",
      dataIndex: "scope",
      render: (value, record) => {
        return <p>{value}</p>;
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      render: (value) => {
        return <p>{value ? AddCommaToNumber(Number(value), false, true,true,3) : "-"}</p>;
      },
    },
    {
      title: "CO2 (kg)",
      dataIndex: "co2",
      render: (value) => {
        return <p>{value ? AddCommaToNumber(Number(value), false, true,true,3) : "-"}</p>;
      },
    },
    {
      title: "CH4 (kg)",
      dataIndex: "ch4",
      render: (value) => {
        return <p>{value ? AddCommaToNumber(Number(value), false, true,true,3) : "-"}</p>;
      },
    },
    {
      title: "N2O (kg)",
      dataIndex: "n2o",
      render: (value) => {
        return <p>{value ? AddCommaToNumber(Number(value), false, true,true,3) : "-"}</p>;
      },
    },
    {
      title: "CO2e (tonne)",
      dataIndex: "tco2e",
      render: (value, record) => {
        return {
          props: {
            style: { backgroundColor: PrimaryTheme.primaryGreenLight },
          },
          children: (
            <p
              style={{
                fontWeight: 700,
                backgroundColor: PrimaryTheme.primaryGreenLight,
              }}
            >
              {/* {value !== undefined
                ? value?.toFixed(3)
                : record?.Tco2e?.toFixed(3)} */}
              {AddCommaToNumber(Number(value), false, true,true,3)}
            </p>
          ),
        };
      },
      sorter: (a, b) => {
        return a.tco2e < b.tco2e ? -1 : 1;
      },
    },
    // {
    //   title: "Biogenic CO2*",
    //   dataIndex: "co2",
    // },
  ];


  useEffect(() => {
      dispatch(getAllEmissionCalculations(currentYear))
      dispatch(getScope3EmissionCalculations(currentYear))
  }, [currentYear, yearEnd])

  const filterDataFunction =async()=>{
    let res = await dispatch(filterDataByMonthOrBU(true,true))
    setScope1FilteredCalc(res?.scope1Data)
    setScope2FilteredCalc(res?.scope2Data)
    setScope3FilteredCalc(res?.scope3Data)
  }

  useEffect(() => {
    if(scope1CalculationsByMonth?.status === 'success' && scope2CalculationsByMonth?.status === 'success' && scope3CalculationsByMonth?.status === 'success')
      filterDataFunction()
  }, [currentBU, currentBG, scope1CalculationsByMonth, scope2CalculationsByMonth, currentMonth, scope3CalculationsByMonth])

  useEffect(() => {
    if (scope1CalculationsByMonth?.status === 'success' && scope2CalculationsByMonth?.status === 'success') {
      let metricsAggrigatedData: any = {};
      [...filteredScope1Calc, ...filteredScope2Calc].forEach((obj: any) => {
        if (metricsAggrigatedData[obj?.my_metric]) {
          metricsAggrigatedData[obj.my_metric] = {
            ...metricsAggrigatedData[obj.my_metric],
            tco2e: metricsAggrigatedData[obj.my_metric].tco2e + obj.tco2e,
            ch4: metricsAggrigatedData[obj.my_metric].ch4 + obj.ch4,
            n2o: metricsAggrigatedData[obj.my_metric].n2o + obj.n2o,
            co2: metricsAggrigatedData[obj.my_metric].co2 + obj.co2,
            quantity: metricsAggrigatedData[obj.my_metric].quantity + obj.value
          }
        }
        else metricsAggrigatedData[obj.my_metric] = {
          ...obj,
          quantity: obj.value,
        };
      })
      let tempArr = Object.values(metricsAggrigatedData)
      setFilteredMetrics(tempArr)
    }
  }, [filteredScope1Calc, filteredScope2Calc])


  useEffect(() => {
    if(scope1CalculationsByMonth?.status === 'success' && scope2CalculationsByMonth?.status === 'success' && scope3CalculationsByMonth?.status === 'success' ){
      let total = filteredScope1Calc.reduce((acc: any, obj: any) => acc + obj.tco2e, 0)
      let total2 = filteredScope2Calc.reduce((acc: any, obj: any) => acc + obj.tco2e, 0)
      let total3 = filteredScope3Calc.reduce((acc: any, obj: any) => acc + obj.tco2e, 0)
      setScope1Total(total)
      setScope2Total(total2)
      setScope3Total(total3)
      setTotalEmission(total + total2 + total3)
    }
  }, [filteredScope1Calc, filteredScope2Calc, filteredScope3Calc])


  return (
    <div style={{ padding: "1rem 0" }} className='emissions-overview'>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "90%",
        }}
      >
        <EmissionCard
          value={AddCommaToNumber(totalEmission, false, true)}
          loading={
            scope1CalculationsByMonth?.status === 'loading' ||
            scope2CalculationsByMonth?.status === 'loading'
          }
          unit="tCO2e"
          description="Total Emissions"
        />
        <EmissionCard
          value={AddCommaToNumber(scope1Total, false, true)}
          loading={
            scope1CalculationsByMonth?.status === 'loading'
          }
          unit="tCO2e"
          description="Scope 1 Emissions"
        />
        <EmissionCard
          value={AddCommaToNumber(scope2Total, false, true)}
          loading={
            scope2CalculationsByMonth?.status === 'loading'
          }
          unit="tCO2e"
          description="Scope 2 Emissions"
        />
        <EmissionCard
          value={AddCommaToNumber(scope3Total, false, true)}
          loading={
            scope3CalculationsByMonth?.status === 'loading'
          }
          unit="tCO2e"
          description="Scope 3 Emissions"
        />
      </div>

      <div style={{ margin: "3rem 0" }}>
        {
          scope1CalculationsByMonth?.status !== 'loading' && scope2CalculationsByMonth?.status !== 'loading'
            ? <TotalEmissionsByScopeGraph /> : <GlobalLoader height='20rem' />
        }

      </div>
      {
        currentBU === 0 &&
        <div style={{ paddingTop: "1rem" }}>
          <EmmisionsDonutChart />
        </div>
      }
      <div style={{ margin: "5rem 0" }}>
        {
          scope1CalculationsByMonth?.status !== 'loading' && scope2CalculationsByMonth?.status !== 'loading'
            ? <TotalEmissionsByTypeGraph /> : <GlobalLoader height='20rem' />
        }

      </div>
      <div style={{ marginTop: "3rem" }}>
        <div
          style={{ marginBottom: "1rem", fontSize: "1.2rem", fontWeight: 600 }}
        >
          Emission Calculations
        </div>
        <Row style={{ margin: "1rem 0" }}>
          <Col span={8}>
            <BEInput
              search
              placeholder="Search by metric name"
              size="large"
              onChange={(e) => {
                const val = e.target.value?.toLowerCase();
                const filteredList = allCalculations.filter((obj: any) => {
                  return obj?.metric?.toLowerCase().includes(val);
                });
                setFilteredMetrics(filteredList);
              }}
            />
          </Col>
        </Row>
        <BETable

          data={filteredMetrics}
          loading={
            scope1CalculationsByMonth?.status === "loading" ||
            scope2CalculationsByMonth?.status === "loading"
          }

          columns={columns}
          pagination
          formatTitle={true}
        />
      </div>
      <SetTableAndGraphDataDM />
    </div>
  );
}

export default Overview;