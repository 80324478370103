import * as React from "react";
import { PrimaryTheme } from "../../../../Config/Theme/theames";
import AirIcon from "../../../../Components/BEIcons/Metrics/AirIcon";
import BDIcon from "../../../../Components/BEIcons/Metrics/BDIcon";
import BiodiversityIcon from "../../../../Components/BEIcons/Metrics/BiodiversityIcon";
import CommIcon from "../../../../Components/BEIcons/Metrics/CommIcon";
import DEOIcon from "../../../../Components/BEIcons/Metrics/DEOIcon";
import EHSIcon from "../../../../Components/BEIcons/Metrics/EHSIcon";
import EnergyIcon from "../../../../Components/BEIcons/Metrics/EnergyIcon";
import EWBIcon from "../../../../Components/BEIcons/Metrics/EWBIcon";
import GHGEmissions from "../../../../Components/BEIcons/Metrics/GHGEmissions";
import HRIIcon from "../../../../Components/BEIcons/Metrics/HRIIcon";
import OccupancyIcon from "../../../../Components/BEIcons/Metrics/OccupancyIcon";
import PQSIcon from "../../../../Components/BEIcons/Metrics/PQSIcon";
import TAndEIcon from "../../../../Components/BEIcons/Metrics/T&EIcon";
import WaterIcon from "../../../../Components/BEIcons/Metrics/WaterIcon";
import WasteIcon from "../../../../Components/BEIcons/Metrics/WasteIcon";
import { useTypedSelector } from "../../../../Config/Hooks/useTypedSelector";
import RefregerantIcon from "../../../../Components/BEIcons/Metrics/RefregerantIcon";
import { MaterialUse } from "../../../../Components/BEIcons/materialUse";
import ShoppingCartIcon from "../../../../Components/BEIcons/ShoppingCartIcon";
import ComplaintsIcon from "../../../../Components/BEIcons/ComplaintsIcon";
import './style.scss'
import CareerDevelopmentIcon from "../../../../Components/BEIcons/CareerDevelopmentIcon";
import AnnualReportIcon from "../../../../Components/BEIcons/AnnualReportsIcon";
import AuditIcon from "../../../../Components/BEIcons/AuditIcon";
import DataSecurityIcon from "../../../../Components/BEIcons/DataSecurityIcon";
import RegulatoryComplianceIcon from "../../../../Components/BEIcons/RegulatoryComplianceIcon";
import RiskManagementIcon from "../../../../Components/BEIcons/RiskManagementIcon";
import StakeHolderCommunicationIcon from "../../../../Components/BEIcons/StakeholderCommIcon";

export interface ISideItemProps {
  active?: boolean;
  onClick?: Function;
  name?: string;
  style?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
  noIcon?: boolean;
}

export const MetricIconNameList = [
  {
    name: "Energy",
    icon: EnergyIcon,
  },
  {
    name: "Water",
    icon: WaterIcon,
  },
  {
    name: "Waste",
    icon: WasteIcon,
  },
  {
    name: "Biodiversity",
    icon: BiodiversityIcon,
  },
  {
    name: "GHG Emissions",
    icon: GHGEmissions,
  },
  {
    name: "Air pollution",
    icon: AirIcon,
  },
  {
    name: "Employee well being and benefits",
    icon: EWBIcon,
  },
  {
    name: "Product/Services Quality and safety",
    icon: PQSIcon,
  },
  {
    name: "Employee health and safety",
    icon: EHSIcon,
  },
  {
    name: "Diversity and equal opportunity",
    icon: DEOIcon,
  },
  {
    name: "Human rights issues",
    icon: HRIIcon,
  },
  {
    name: "Training and education",
    icon: TAndEIcon,
  },
  {
    name: "Communities",
    icon: CommIcon,
  },
  {
    name: "Board details",
    icon: BDIcon,
  },
  {
    name: "Board diversity",
    icon: BDIcon,
  },
  {
    name: "Operational metrics",
    icon: OccupancyIcon,
  },
  {
    name: "Refrigerants",
    icon: RefregerantIcon,
  },
  {
    name: "Material use",
    icon: MaterialUse,
  },
  {
    name: "Procurement practices",
    icon: ShoppingCartIcon,
  },
  {
    name: "Grievance redressal",
    icon: ComplaintsIcon,
  },
  {
    name: "Career Development",
    icon: CareerDevelopmentIcon,
  },
  {
    name: 'Annual Reports',
    icon: AnnualReportIcon
  },
  {
    name: 'Audits',
    icon: AuditIcon
  },
  {
    name: "Data security",
    icon: DataSecurityIcon
  },
  {
    name: "Regulatory Compliance",
    icon: RegulatoryComplianceIcon
  },
  {
    name: "Risk management",
    icon: RiskManagementIcon
  },
  {
    name: "Stakeholder Communication",
    icon: StakeHolderCommunicationIcon
  }
];

export function SideItem(props: ISideItemProps) {
  const [icon, setIcon] = React.useState<React.ReactNode>(null);
  const metrics = useTypedSelector(state => state.onBoarding.metrics.directMetrics?.data);
  const myMetrics = useTypedSelector(state => state.onBoarding.metrics.myMetricsPerYear?.data);
  const [selectedMetricCnt, setSelectedMetricCnt] = React.useState(0);
  const myDerivedMetrics = useTypedSelector(state => state.onBoarding.metrics.myDerivedMetricsPerYear?.data);
  const derivedMetrics = useTypedSelector(state => state.onBoarding.metrics.derivedMetrics?.data);
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  React.useEffect(() => {
    MetricIconNameList.forEach((itm) => {
      if (itm.name === props.name) {
        setIcon(
          <itm.icon
            stroke={
              props.active
                ? PrimaryTheme.primaryGreen
                : PrimaryTheme.primaryGray
            }
            inheritSize
          />
        );
      }
    });
  }, [props.active]);

  React.useEffect(() => {
    let cnt = 0;
    metrics.forEach((metric) => {
      if (metric.category === props.name) {
        if(myMetrics?.find((item) => item.metric === metric.id)) cnt++;
      }
    })
    const derivedMetricsMap:any = {}
    myDerivedMetrics.filter((item: any) => item.year === currentYear).forEach((metric) => {
      derivedMetricsMap[metric.derived_metric] = metric.id
    })
    
    derivedMetrics.forEach((metric) => {
      if (metric.category === props.name) {
        if(derivedMetricsMap[metric.id]) cnt++
      }
    })
    setSelectedMetricCnt(cnt)
  }, [myMetrics, currentYear, myDerivedMetrics])


  return (
    <div
      className="side-filter-item"
      onClick={() => props.onClick && props.onClick()}
      style={{
        backgroundColor: props.active
          ? PrimaryTheme.primaryGreenLight
          : "transparent",
        ...props.style,
      }}
    >
      <div style={{...props.innerStyle,
      gridTemplateColumns:props.noIcon?'1fr':'1fr 5fr'
      }}>
        {
          !props.noIcon &&
          <div style={{ height: "20px", width: "20px" }}>{icon}</div>}
        <p
          style={{
            color: props.active
              ? PrimaryTheme.primaryGreen
              : PrimaryTheme.primaryGray,
          }}
        >
          {`${props.name} ${selectedMetricCnt?`(${selectedMetricCnt})`:``}`}
        </p>
      </div>
    </div>
  );
}
