import { Row, Col ,Form } from "antd"
import { BEButton } from "../../../../Components/BEFormItems/BEButton"
import { BEInput } from "../../../../Components/BEFormItems/BEInput"
import { LableRequired } from "../../../../Components/BEFormItems/LableRequired"
import { PrimaryTheme } from "../../../../Config/Theme/theames"
import { countryCodeData,countryCodeReversed } from "../../../../Config/Data/countryCodeData"
import { useState } from "react"
import { useAppDispatch } from "../../../../Config/Hooks/useAppDispatch"
import { accessRequestForm } from "../../../Actions/LoginActions"

export interface INoPermissionFormProps{
	setIsRequestSubmitted:Function
}
export interface FormSubmitProps{
	name:string,
	work_email:string,
	phone_number:string,
	company:string,


}
export function NoPermissionForm(props:INoPermissionFormProps){
	const [form]=Form.useForm();
	const [countryCode,setCountryCode]=useState('91')
	const [loading,setLoading]=useState<boolean>(false)
	const dispatch=useAppDispatch()
	
	const onSubmit=async(values:FormSubmitProps)=>{
		setLoading(true);
		const phone_number=`+${countryCode} ${values.phone_number}`
		const obj={
			...values,
			phone_number:phone_number
		}
		let data=await dispatch(accessRequestForm(obj))
		setLoading(false);
		if(data){
			props.setIsRequestSubmitted(true)
		}	
	}
	console.log(countryCode)
	return   <Form style={{width:'100%',display:'flex', flexDirection:'column', gap:'0.5rem', color:PrimaryTheme.primaryGray}} form={form} onFinish={onSubmit} >
	<Row justify={'space-between'} >
		<Col span={11}>
			<LableRequired >Name</LableRequired>
			<Form.Item name={'name'} rules={[{required:true,message: 'Please enter a name',}]}>
				<BEInput size='large' placeholder='Enter your Name'  />
			</Form.Item>
		</Col>
		<Col span={11}>
			<LableRequired >Work email</LableRequired>
			<Form.Item name={'work_email'} rules={[{ required: true, message: 'Please enter an email' }, { type: 'email', message: 'Please enter a valid email' }]}>
			   <BEInput  size='large' placeholder='name@business.com'  />
			</Form.Item>
			</Col>
	</Row>
	<Row justify={'space-between'}>
		<Col span={11}>
			<LableRequired >Phone number</LableRequired>
			<Form.Item name={'phone_number'} rules={[{required:true,message:"Please enter a phone number",pattern:new RegExp(/^[0-9]+$/)}]}>
			<BEInput 
			   searchForColumns={countryCodeData} 
			   placeholder='Enter phone number'
			   selectSize='large'
			   size='large'
			   
			   onChangeColumnSearch={(val:any)=>{
				const ans=countryCodeReversed[val]
				setCountryCode(ans)
			}
			   }
			   columnSearchValue={`+${countryCode}`}
				 />
		</Form.Item>
		</Col>
		<Col span={11}>
			<LableRequired >Company</LableRequired>
			<Form.Item name={'company'} rules={[{required:true,message: 'Please enter a company name',}]}>
				<BEInput size='large' placeholder='Microsoft'  />
			</Form.Item>
		</Col>
	</Row>
	<Row>
		<BEButton
			htmlType='submit'
			loading={loading}
			style={{margin: '0.5rem 0 0.5rem' }}
			className='primary'
		>
			Send Request
		</BEButton>
	</Row>
</Form>
}