import { BEMessage } from "../../../Components/BEMessage";
import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import { setMyDerivedMetricTargets, setMyMetricTargets } from "../../../Redux/TargetsReducer";
import { AppDispatch } from "../../../Redux/store";
import { DELETE_MY_DERIVED_METRIC_TARGET, DELETE_MY_METRIC_TARGET, GET_MY_DERIVED_METRIC_TARGETS, GET_MY_METRIC_TARGETS, PATCH_MY_DERIVED_METRIC_TARGET, PATCH_MY_METRIC_TARGET, POST_MY_DERIVED_METRIC_TARGET, POST_MY_METRIC_TARGET } from "../../../Utils/Routes/TargetRoutes";

export const getMyMetricTargets = () => async (dispatch: AppDispatch) => {
    dispatch(setMyMetricTargets({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_MY_METRIC_TARGETS());
    if (data) {
        dispatch(setMyMetricTargets({
            status: "success",
            data: data?.data
        }));
    }
    else {
        console.log(error);
        dispatch(setMyMetricTargets({
            status: "error",
            data: []
        }));
    }
}

export const createMyMetricTarget = (data: any, noMessage = false) => async (dispatch: AppDispatch) => {
    if (data.length === 0) {
        return;
    }
    const [responseData, error] = await handleAPICall(POST_MY_METRIC_TARGET(data));
    if (responseData) {
        if (!noMessage)
            BEMessage.success('Target created successfully');
        dispatch(getMyMetricTargets());
    }
    else {
        console.log(error);
    }
}

export const updateMyMetricTarget = (id: number, data: any, noMessage = false) => async (dispatch: AppDispatch) => {
    const [responseData, error] = await handleAPICall(PATCH_MY_METRIC_TARGET(id, data));
    if (responseData) {
        if (!noMessage)
            BEMessage.success('Target updated successfully');
        dispatch(getMyMetricTargets());
    }
    else {
        console.log(error);
    }
}

export const deleteMyMetricTarget = (id: number, noMessage = false) => async (dispatch: AppDispatch) => {
    const [responseData, error] = await handleAPICall(DELETE_MY_METRIC_TARGET(id));
    if (responseData) {
        if (!noMessage)
            BEMessage.success('Metric target deleted successfully');
        dispatch(getMyMetricTargets());
    }
    else {
        console.log(error);
    }
}

export const getTargetValue: (type: string, value: number, baseline: number) => number = (type: string, value: number, baseline: number) => {
    switch (type) {
        case 'B': return value
            break;
        case 'A': return value
            break;
        case 'C': return Number((baseline * (1 + value / 100))?.toFixed(2))
            break;
        case 'D': return Number((baseline * (1 - value / 100))?.toFixed(2))
            break;
    }
    return 0;
}

export const getMyDerivedMetricTargets = () => async (dispatch: AppDispatch) => {
    dispatch(setMyDerivedMetricTargets({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_MY_DERIVED_METRIC_TARGETS());
    if (data) {
        dispatch(setMyDerivedMetricTargets({
            status: "success",
            data: data?.data
        }));
    }
    else {
        console.log(error);
        dispatch(setMyDerivedMetricTargets({
            status: "error",
            data: []
        }));
    }
}

export const createMyDerivedMetricTarget = (data: any, noMessage = false) => async (dispatch: AppDispatch) => {
    if (data.length === 0) {
        return;
    }
    const [responseData, error] = await handleAPICall(POST_MY_DERIVED_METRIC_TARGET(data));
    if (responseData) {
        if (!noMessage)
            BEMessage.success('Target created successfully');
        dispatch(getMyDerivedMetricTargets());
    }
    else {
        console.log(error);
    }
}

export const updateMyDerivedMetricTarget = (id: number, data: any, noMessage = false) => async (dispatch: AppDispatch) => {
    const [responseData, error] = await handleAPICall(PATCH_MY_DERIVED_METRIC_TARGET(id, data));
    if (responseData) {
        if (!noMessage)
            BEMessage.success('Target updated successfully');
        dispatch(getMyDerivedMetricTargets());
    }
    else {
        console.log(error);
    }
}

export const deleteMyDerivedMetricTarget = (id: number, noMessage = false) => async (dispatch: AppDispatch) => {
    const [responseData, error] = await handleAPICall(DELETE_MY_DERIVED_METRIC_TARGET(id));
    if (responseData) {
        if (!noMessage)
            BEMessage.success('Derived metric target deleted successfully');
        dispatch(getMyDerivedMetricTargets());
    }
    else {
        console.log(error);
    }
}
