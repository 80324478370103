import * as React from 'react';
import { Checkbox, Col, Form, Row } from 'antd';
import { LableRequired } from '../../../../../../../Components/BEFormItems/LableRequired';
import { BEInput } from '../../../../../../../Components/BEFormItems/BEInput';
import BEMultipleSelect from '../../../../../../../Components/BEMultipleSelect';
import { useTypedSelector } from '../../../../../../../Config/Hooks/useTypedSelector';
import { createTemplateDraft, createTemplateTopics, getAllTemplateDrafts, patchTemplateDraft } from '../../../../../Actions/template';
import { useAppDispatch } from '../../../../../../../Config/Hooks/useAppDispatch';
import { GlobalLoader } from '../../../../../../../Components/GlobalLoader';
import BEConfirmModal from '../../../../../../../Components/BEConfirmModal';
import { EditTemplate } from '../../../EditTemplate';
import { GRESBEntitySections } from '../../../../../../../data/frameworks';
import { BEMessage } from '../../../../../../../Components/BEMessage';
import { setSelectedGresbEntityTopics, setSelectedTemplateTopics } from '../../../../../../../Redux/ReportingReducer';
import { ReportingTemplateTypes, TemplateSelectedTopicTypes } from '../../../../../../../Redux/Types/reportingTypes';
import { BEInfoBox } from '../../../../../../../Components/BEInfoBox';
import InfoIcon from '../../../../../../../Components/BEIcons/InfoIcon';
import { PrimaryTheme } from '../../../../../../../Config/Theme/theames';
import { AssessmentInfo } from '../../../AssessmentInfo';
import ToolTip from '../../../../../../../Components/BEToolTip';

export interface IAddTemplateStep2Props {
  setStep: Function;
  setOpenEditTemplate: Function;
}

//none,default
const seledctionOptions = [
  {
    value: 'none',
    label: 'None'
  },
  {
    value: 'default',
    label: 'Default'
  }
]

const AddTemplateStep2: React.ForwardRefRenderFunction<{ onButtonClick: () => void }, IAddTemplateStep2Props> = (props, ref) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [load, setLoad] = React.useState(false);
  const selectedFrameWork = useTypedSelector((state) => state.reporting.selectedFrameWork);
  const selectedGresbEntityTopics = useTypedSelector(state => state.reporting.selectedGresbEntityTopics);
  const [openConformationModal, setOpenConformationModal] = React.useState(false);
  const [openInfo, setOpenInfo] = React.useState(false);
  const [openEditTemplate, setOpenEditTemplate] = React.useState(false);
  const [gresbSelectedSections, setGresbSelectedSections] = React.useState<string[]>(GRESBEntitySections.filter((section) => section.alwaysChecked)
    .map((section) => section.section));

  const handleCreateTemplate = async (selectedTopics: TemplateSelectedTopicTypes[], currentTemplate: ReportingTemplateTypes) => {
    setLoad(true);
    if (selectedTopics.length === 0) {
      BEMessage.error('Please select atleast one topic to create template');
      setLoad(false);
      return;
    }
    const dataToSend = selectedTopics.map((item) => {
      return { ...item, topic_id: item.id, template: currentTemplate?.id, fy: (item.fy && item.fy !== "nan") ? Number(item.fy) : 0 }
    });
    // send in the batch of 200
    // await dispatch(createTemplateTopics(dataToSend.slice(-100, -1)));
    let i, j, temparray: any = [], chunk = 100, dataToDispatch: any = [], noError = true;
    for (i = 0, j = dataToSend.length; i < j; i += chunk) {
      temparray = dataToSend.slice(i, i + chunk);
      dataToDispatch.push(temparray);
    }
    await Promise.all([...dataToDispatch.map(async (item: any) => {
      noError = await dispatch(createTemplateTopics(item)) && noError;
    })]);
    await dispatch(getAllTemplateDrafts());
    await dispatch(patchTemplateDraft(currentTemplate?.id, { topicsChosen: true, stage: 2 }));
    noError ? BEMessage.success('Topics added successfully & Template is created') : BEMessage.error('Error in adding all topics');
    setLoad(false);
  }

  const handleForm = async (values: any) => {
    const obj = {
      name: values.name,
      framework: selectedFrameWork?.code,
      industry: 'all',
    };
    if (gresbSelectedSections.length <= 2 && selectedFrameWork?.code === 'GRESB') {
      BEMessage.error('Please select atleast one section out of Performance or Development');
      return;
    }
    setLoad(true);
    const currTemplate = await dispatch(createTemplateDraft(obj));
    setLoad(false);
    if (selectedFrameWork?.code === 'GRESB') {
      const topicsData = [
        // ...selectedGresbAssetTopics?.data,
        ...selectedGresbEntityTopics?.data.filter((item) => gresbSelectedSections.includes(item.section))
      ];
      dispatch(setSelectedTemplateTopics({
        data: topicsData,
        status: 'success',
      }));
      await handleCreateTemplate(topicsData, currTemplate);
    } else {
      setOpenConformationModal(true);
    }
    props.setOpenEditTemplate(false);
  }

  // handle button click from templates to provoke the next step  *-
  const onButtonClick = async () => {
    if (form.getFieldValue('name') === undefined) {
      BEMessage.error('Please enter template name');
      return;
    }
    form.submit();
  }
  React.useImperativeHandle(ref, () => ({ onButtonClick }), []);
  return (
    <div className='add-template-step2'>
      {load
        ?
        <GlobalLoader height='25vh' />
        :
        <div>
          <Form onFinish={handleForm} form={form}>
            <Row gutter={48}>
              <Col span={8}>
                <LableRequired>Template Name</LableRequired>
                <Form.Item
                  name='name'
                  rules={[{ required: true, message: 'Please enter template name' }]}
                >
                  <BEInput size='large' placeholder='Enter template name' />
                </Form.Item>
              </Col>
              {selectedFrameWork?.code !== "GRESB" && <Col span={8}>
                <LableRequired>Apply Selection</LableRequired>
                <Form.Item
                  name='selection'
                  rules={[{ required: selectedFrameWork?.code !== 'BRSR', message: 'Please select selection option' }]}
                >
                  <BEMultipleSelect
                    single
                    valueFeild='value'
                    data={seledctionOptions}
                    labelFeild='label'
                    disabled={selectedFrameWork?.code === 'BRSR'}
                    defaultValue={selectedFrameWork?.code === 'BRSR' ? "Default" : ""}
                  />
                </Form.Item>
              </Col>}
            </Row>
            {selectedFrameWork?.code === "GRESB" &&
              <>
                <Row>
                  <Col span={5}>
                    <h2>
                      Select GRESB Assessment Components
                    </h2>
                  </Col>
                  <Col>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        color: PrimaryTheme.primaryBlueInfo,
                        fontSize: '0.8rem',
                        justifyContent: 'center',
                        gap: '0.5rem',
                        marginTop: '0.4rem',
                        textDecoration: 'underline',
                      }}
                      onClick={() => setOpenInfo(true)}
                    >
                      <span style={{ width: "1rem", paddingTop:"0.2rem" }}>
                        <ToolTip title={"Learn more"}>
                        <InfoIcon
                          inheritSize
                          fill={PrimaryTheme.primaryGray}
                        />
                        </ToolTip>
                      </span>
                      Learn more
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className='gresb-section-selection'>
                    {GRESBEntitySections.map((section, index) =>
                      <div key={index} className='gresb-section'>
                        <Checkbox
                          checked={gresbSelectedSections.includes(section.section)}
                          onChange={(e) => {
                            if (section.alwaysChecked) {
                              BEMessage.info('This section is always selected');
                              return;
                            }
                            if (e.target.checked) {
                              setGresbSelectedSections([...gresbSelectedSections, section.section]);
                            } else {
                              setGresbSelectedSections(gresbSelectedSections.filter((item) => item !== section.section));
                            }
                          }}
                        >
                          <h3>{section.section}</h3>
                        </Checkbox>
                        <p>{section.description}</p>
                      </div>
                    )}
                  </div>
                </Row>
              </>}
          </Form>
          <BEConfirmModal
            type='success'
            title='Template Created'
            visible={openConformationModal}
            setVisible={setOpenConformationModal}
            message='Template has been created successfully. You can choose topics now or later.'
            onConfirm={() => { setOpenEditTemplate(true) }}
            confirmButtonText='Choose Now'
            cancelButtonText='Choose Later'
          />
          <EditTemplate open={openEditTemplate} setOpen={setOpenEditTemplate} />
          <AssessmentInfo open={openInfo} setOpen={setOpenInfo} />
        </div>
      }
    </div>
  );
}

export default React.forwardRef(AddTemplateStep2);
