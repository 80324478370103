
import * as React from 'react';
import './style.scss';
import { TopTabsFilter } from './TopTabsFilter';
import { getCombinedSelectedMetrics, getMyMetrics } from '../../Actions/OnboardingStep4Actions';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { GlobalLoader } from '../../../Components/GlobalLoader';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import { OnboardingTopNav } from '../OnboardingView/OnboardingTopNav';
import { getDerivedMetrics, getMyDerivedMetrics } from '../../../Features/DataManager/Actions';
import { getCompanyDetails } from '../../Actions/OnboardingActions';
import { getFYByDate} from '../../../Config/Functions/UsefullFunctions';
import dayjs from 'dayjs';
import { SetTableAndGraphDataDM } from '../../../Features/DataManager/Components/SetTableAndGraphDataFunctionDM';
import { setDateSlicerYearDM } from '../../../Redux/DataManagerReducer';

export interface IStep4Props {
  isNotOnboarding?: boolean;
}



export function Step4(props: IStep4Props) {
  const dispatch = useAppDispatch()
  const combinedSelectedMetrics = useTypedSelector(state => state.onBoarding.metrics.combinedSelectedMetrics);
  const companyDetails = useTypedSelector(state => state.companies.companyDetails);
  const myMetrics = useTypedSelector(state => state.onBoarding.metrics.myMetrics);
  const myDerivedMetrics = useTypedSelector(state => state.onBoarding.metrics.myDerivedMetrics);
  const metrics = useTypedSelector(state => state.onBoarding.metrics.directMetrics);
  const derivedMetrics = useTypedSelector(state => state.onBoarding.metrics.derivedMetrics);

  React.useEffect(() => {
    if (metrics?.status === 'idle')
      dispatch(getMyMetrics())
    if (derivedMetrics?.status === 'idle')
      dispatch(getDerivedMetrics())
    if (combinedSelectedMetrics?.status === 'idle')
      dispatch(getCombinedSelectedMetrics())
    if (myMetrics?.status === 'idle')
      dispatch(getMyMetrics())
    if (myDerivedMetrics?.status === 'idle')
      dispatch(getMyDerivedMetrics())
    if (companyDetails?.status === 'idle')
      dispatch(getCompanyDetails())
  }, []);

  React.useEffect(() => {
    // ******************* setting the default FY for the metrics *******************
    if (companyDetails?.status === 'success') {
      dispatch(setDateSlicerYearDM(getFYByDate(dayjs().format('YYYY-MM-DD'), companyDetails?.data?.reporting_year_end || '2024-02-01')))
    }
  }, [companyDetails]);

  return (
    <div className="onboarding-step-4">
      {!props.isNotOnboarding && <OnboardingTopNav title="Select and add the ESG Metrics that your company want to report" />}
      {myMetrics?.status === "success" && myDerivedMetrics?.status === "success" ? (
        <>
          <TopTabsFilter />
        </>
      ) : (
        <GlobalLoader height="80vh" />
      )}
      <SetTableAndGraphDataDM />
    </div>
  );
}
