import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { Col, Form, InputNumber, Row, Select } from 'antd';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { ColumnsType } from 'antd/es/table';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import { BETable } from '../../../../../Components/BETable';
import DeleteIcon from '../../../../../Components/BEIcons/DeleteIcon';
import { createMyDerivedMetricTarget, createMyMetricTarget, deleteMyDerivedMetricTarget, deleteMyMetricTarget, getTargetValue, updateMyDerivedMetricTarget, updateMyMetricTarget } from '../../../Actions';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { BEMessage } from '../../../../../Components/BEMessage';
import dayjs from 'dayjs';
import BEConfirmModal from '../../../../../Components/BEConfirmModal';
import { ExcelTargetTable } from '../../../Components/ExcelTargetTable';
import { setYearSpecificTargetValues } from '../../../../../Redux/TargetsReducer';
import { options, targetYears } from '../../../../../Config/Data/genralData';

export interface ISetupTargetProps {
    open: boolean;
    setOpen: Function;
    selectedMetric: any;
    // type: string;
    level: string;
    isEdit?: boolean;
}

export function SetupTarget(props: ISetupTargetProps) {
    const [loading, setLoading] = React.useState(false);
    const [targetType, setTargetType] = React.useState('A');
    // const existingYears = useTypedSelector(state => state.common.financialYears?.data);
    const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    const myMetricTargets = useTypedSelector(state => state.targets.MyMetricTargets);
    const MyDerivedMetricTargets = useTypedSelector(state => state.targets.MyDerivedMetricTargets);
    const [openModal, setOpenModal] = React.useState(false);
    const [targetvalueForPercentType, setTargetValueForPercentType] = React.useState<number>(0);
    const [triggerTargetcalulation, setTriggerTargetcalulation] = React.useState<boolean>(false);
    const [startYear, setStartYear] = React.useState<number>(currentYear);
    const [endYear, setEndYear] = React.useState<number>(currentYear);
    const yearEndDate = useTypedSelector(state => state.companies.companyDetails?.data?.reporting_year_end);
    const [isReportingYearEndDecember, setIsReportingYearEndDecember] = React.useState(false);
    const [baselineYear, setBaselineValueYear] = React.useState<any>(null);
    const [businessUnit, setBusinessUnit] = React.useState<any>(null);
    const businessUnits = useTypedSelector(state => state.entity.BusinessUnits);
    const yearSpecificTargetValues = useTypedSelector(state => state.targets.yearSpecificTargetValues);
    const monthlyMetricData = useTypedSelector(state => state.onBoarding.metrics.myMetricsDataByMonthAndBusinessUnit);
    const myMetrics = useTypedSelector(state => state.onBoarding.metrics.myMetrics);
    const monthlyDerivedMetricData = useTypedSelector(state => state.onBoarding.metrics.myDerivedMetricsDataByMonthAndBusinessUnit);
    const myDerivedMetrics = useTypedSelector(state => state.onBoarding.metrics.myDerivedMetrics);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const handleTargetTypeChange = (value: any) => {
        !value ? setTargetType('A') : setTargetType(value);
    }

    const checkAllYearSpecificTargetValues = () => {
        // Check for sum of year specific target values < target value
        const baselineValue = form.getFieldValue('baselineValue') ? form.getFieldValue('baselineValue') : 0;
        const targetValue = form.getFieldValue('targetValue');
        let result = true;
        const specificTargetValues = yearSpecificTargetValues?.filter((item) => item.value !== '').map((item) => item.value);
        if (targetType === 'A') {
            specificTargetValues.forEach((item, index) => {
                if (item <= baselineValue) {
                    BEMessage.error(`Target value for year ${yearSpecificTargetValues[index].year} should be greater than baseline value`);
                    setLoading(false);
                    result = result && false;
                }
                if (item >= targetValue) {
                    BEMessage.error(`Target value for year ${yearSpecificTargetValues[index].year} should be less than overall target value`);
                    setLoading(false);
                    result = result && false;
                }
            });
            if (targetValue <= baselineValue) {
                BEMessage.error(`Overall target value should be greater than baseline value`);
                setLoading(false);
                result = result && false;
            }
        } else if (targetType === 'B') {
            specificTargetValues.forEach((item, index) => {
                if (item >= baselineValue) {
                    BEMessage.error(`Target value for year ${yearSpecificTargetValues[index].year} should be less than baseline value`);
                    setLoading(false);
                    result = result && false;
                }
                if (item <= targetValue) {
                    BEMessage.error(`Target value for year ${yearSpecificTargetValues[index].year} should be greater than overall target value`);
                    setLoading(false);
                    result = result && false;
                }
            });
            if (targetValue >= baselineValue) {
                BEMessage.error(`Overall target value should be less than baseline value`);
                setLoading(false);
                result = result && false;
            }
        } else if (targetType === 'C') {
            specificTargetValues.forEach((item, index) => {
                if (item as number > targetvalueForPercentType) {
                    BEMessage.error(`Target value for year ${yearSpecificTargetValues[index].year} should be less than overall target value`);
                    setLoading(false);
                    result = result && false;
                }
            });
            if (targetvalueForPercentType <= baselineValue) {
                BEMessage.error(`Overall target value should be greater than baseline value`);
                setLoading(false);
                result = result && false;
            }
        } else if (targetType === 'D') {
            specificTargetValues.forEach((item, index) => {
                if (item as number < targetvalueForPercentType) {
                    BEMessage.error(`Target value for year ${yearSpecificTargetValues[index].year} should be greater than overall target value`);
                    setLoading(false);
                    result = result && false;
                }
            });
            if (targetvalueForPercentType >= baselineValue) {
                BEMessage.error(`Overall target value should be less than baseline value`);
                setLoading(false);
                result = result && false;
            }
        }

        if (!props.isEdit) {
            const alreadyExistingTarget = myMetricTargets?.data.filter((item) =>
                item.metric === props.selectedMetric?.metric &&
                item.business_unit === businessUnit
            )

            // existingYears set
            // push all years from startYear to endYear
            let msg = '';
            alreadyExistingTarget.forEach((item) => {
                if (item.start_year === item.end_year) {
                    msg = `Target already exists for the year ${item.start_year}`;
                } else {
                    msg = `Target already exists for the year range ${item.start_year} - ${item.end_year}`;
                }
                if (startYear <= item.start_year && endYear >= item.end_year) {
                    BEMessage.error(msg);
                    setLoading(false);
                    result = result && false;
                } else if (startYear >= item.start_year && endYear <= item.end_year) {
                    BEMessage.error(msg);
                    setLoading(false);
                    result = result && false;
                } else if (startYear <= item.start_year && endYear >= item.start_year) {
                    BEMessage.error(msg);
                    setLoading(false);
                    result = result && false;
                } else if (startYear <= item.end_year && endYear >= item.end_year) {
                    BEMessage.error(msg);
                    setLoading(false);
                    result = result && false;
                }
            });
        }

        return result;
    }


    const checkAllBUValueExceedsCompanyValue = () => {
        const isTargetBeingSetAlongCompany = businessUnit === null;
        const targetValue = form.getFieldValue('targetValue');
        const baselineValue = form.getFieldValue('baselineValue') ? form.getFieldValue('baselineValue') : 0;
        let targetAlongCompany: any = myMetricTargets?.data.find((item) => item.metric === props.selectedMetric?.metric
            && item.business_unit === null
            && item.start_year === startYear
            && item.end_year === endYear
        );
        if (props.selectedMetric?.type === 'derived' || props.selectedMetric?.isDerived) {
            targetAlongCompany = MyDerivedMetricTargets?.data.find((item) => item.derived_metric === props.selectedMetric?.derived_metric
                && item.business_unit === null
                && item.start_year === startYear
                && item.end_year === endYear
            );
        }
        let targetAlongBUs: any = myMetricTargets?.data.filter((item) => item.metric === props.selectedMetric?.metric
            && item.business_unit !== null
            && item.start_year === startYear
            && item.end_year === endYear
        );
        if (props.selectedMetric?.type === 'derived' || props.selectedMetric?.isDerived) {
            targetAlongBUs = MyDerivedMetricTargets?.data.filter((item) => item.derived_metric === props.selectedMetric?.derived_metric
                && item.business_unit !== null
                && item.start_year === startYear
                && item.end_year === endYear
            );
        }
        let buTargetValue = targetAlongBUs.reduce((acc: any, val: any) => {
            return acc + val?.target_value
        }, 0);

        let companyTargetValue = targetAlongCompany?.target_value;

        if (isTargetBeingSetAlongCompany) {
            companyTargetValue = getTargetValue(targetType, Number(targetValue), Number(baselineValue));
        } else {
            buTargetValue = getTargetValue(targetType, Number(targetValue), Number(baselineValue)) + buTargetValue;
        }

        if (companyTargetValue < buTargetValue) {
            return false;
        }
        return true;
    }

    React.useEffect(() => {
        // Extract reporting year end month and day, if 31st December, then set the year as it is, else set the year as year - 1
        const yearEndMonth = dayjs(yearEndDate).format('MM-DD').split('-')[0];
        const yearEndDay = dayjs(yearEndDate).format('MM-DD').split('-')[1];
        const yearEndMonthDay = `${yearEndMonth}-${yearEndDay}`;
        const yearEndMonthDay31Dec = '12-31';
        if (yearEndMonthDay === yearEndMonthDay31Dec) {
            setIsReportingYearEndDecember(true);
        }
    }, [yearEndDate]);

    const handleFormSubmit = async (values: any) => {
        setLoading(true);

        let data: any = {
            target_type: targetType,
            baseline: values.baselineValue ? Number(values.baselineValue) : 0,
            target_value: Number(values.targetValue),
            progress_threshold: getTargetValue(targetType, Number(values.targetValue), values.baselineValue ? values.baselineValue : 0) * Number(values.progressThreshold) / 100,
            baseline_year: baselineYear,
            business_unit: businessUnit ? businessUnit : null,
            start_year: startYear,
            end_year: endYear,
        }

        if (props.selectedMetric?.type === 'derived' || props.selectedMetric?.isDerived) {
            data = {
                ...data,
                derived_metric: props.selectedMetric?.derived_metric
            }
        } else {
            data = {
                ...data,
                metric: props.selectedMetric?.metric

            }
        }

        if (props.selectedMetric?.type === 'derived' || props.selectedMetric?.isDerived) {
            if (props.isEdit) {
                data = {
                    ...data,
                    derived_metric: props.selectedMetric?.id
                }
                await dispatch(updateMyDerivedMetricTarget(props.selectedMetric?.targetId, data));
            } else {
                await dispatch(createMyDerivedMetricTarget([data]));
            }
        } else {
            if (props.isEdit) {
                data = {
                    ...data,
                    metric: props.selectedMetric?.id
                }
                await dispatch(updateMyMetricTarget(props.selectedMetric?.targetId, data));
            } else {
                await dispatch(createMyMetricTarget([data]));
            }
        }

        if (startYear !== endYear) {
            let targets = yearSpecificTargetValues?.filter((item) =>
                item.value !== '' &&
                item.isExisting === false
            ).map((item) => {
                let obj: any = {
                    target_type: targetType,
                    baseline: values.baselineValue ? Number(values.baselineValue) : 0,
                    target_value: targetType === 'C' || targetType === 'D' ? Number(item.percentage) : Number(item.value),
                    baseline_year: baselineYear,
                    progress_threshold: getTargetValue(targetType, Number(item.percentage), values.baselineValue ? values.baselineValue : 0) * Number(values.progressThreshold) / 100,
                    business_unit: businessUnit ? businessUnit : null,
                    start_year: item.year,
                    end_year: item.year
                }
                if (props.selectedMetric?.type === 'derived' || props.selectedMetric?.isDerived) {
                    obj = {
                        ...obj,
                        derived_metric: props?.isEdit ? props.selectedMetric?.id : props.selectedMetric?.derived_metric
                    }
                } else {
                    obj = {
                        ...obj,
                        metric: props?.isEdit ? props.selectedMetric?.id : props.selectedMetric?.metric
                    }
                }
                return obj;
            });

            if (props.selectedMetric?.type === 'derived' || props.selectedMetric?.isDerived) {
                await dispatch(createMyDerivedMetricTarget(targets, true));
            } else {
                await dispatch(createMyMetricTarget(targets, true));
            }

            if (props.isEdit) {
                let targetsToDelete = yearSpecificTargetValues?.filter((item) =>
                    item.value === '' &&
                    item.isExisting === true
                ).map((item) => item.targetId);

                if (props.selectedMetric?.type === 'derived' || props.selectedMetric?.isDerived) {
                    await Promise.all(targetsToDelete.map((id) => dispatch(deleteMyDerivedMetricTarget(id as number, true))));
                } else {
                    await Promise.all(targetsToDelete.map((id) => dispatch(deleteMyMetricTarget(id as number, true))));
                }

                let targetsToUpdate = yearSpecificTargetValues?.filter((item) =>
                    item.value !== '' &&
                    item.isExisting === true
                ).map((item) => {
                    let obj: any = {
                        target_type: targetType,
                        baseline: values.baselineValue ? Number(values.baselineValue) : 0,
                        target_value: targetType === 'C' || targetType === 'D' ? Number(item.percentage) : Number(item.value),
                        baseline_year: baselineYear,
                        progress_threshold: getTargetValue(targetType, Number(item.percentage), values.baselineValue ? values.baselineValue : 0) * Number(values.progressThreshold) / 100,
                        business_unit: businessUnit ? businessUnit : null,
                        start_year: item.year,
                        end_year: item.year,
                        targetId: item.targetId
                    }
                    if (props.selectedMetric?.type === 'derived' || props.selectedMetric?.isDerived) {
                        obj = {
                            ...obj,
                            derived_metric: props.selectedMetric?.id
                        }
                    } else {
                        obj = {
                            ...obj,
                            metric: props.selectedMetric?.id
                        }
                    }
                    return obj;
                });

                if (props.selectedMetric?.type === 'derived' || props.selectedMetric?.isDerived) {
                    await Promise.all(targetsToUpdate.map((item) => dispatch(updateMyDerivedMetricTarget(item.targetId, item, true))));
                } else {
                    await Promise.all(targetsToUpdate.map((item) => dispatch(updateMyMetricTarget(item.targetId, item, true))));
                }
            }
        }
        setLoading(false);
        props.setOpen(false);
    }

    const setEditTargetValues = () => {
        // set the values of the target to the form
        setTargetType(props?.selectedMetric?.target_type);
        setStartYear(props?.selectedMetric?.start_year);
        setEndYear(props?.selectedMetric?.end_year);
        setBusinessUnit(props?.selectedMetric?.business_unit);
        setBaselineValueYear(props?.selectedMetric?.baseline_year);
    }

    React.useEffect(() => {
        if (props.selectedMetric && props.open && props.isEdit) {
            setEditTargetValues();
        }
    }, [props.selectedMetric, props.open]);


    React.useEffect(() => {
        if (props.isEdit) {
            const tValue = (props.selectedMetric?.target_type === 'C'
                ? ((props?.selectedMetric?.target_value - props?.selectedMetric?.baseline) / props?.selectedMetric?.baseline) * 100
                : props.selectedMetric?.target_type === 'D' ?
                    ((props?.selectedMetric?.baseline - props?.selectedMetric?.target_value) / props?.selectedMetric?.baseline) * 100
                    : props?.selectedMetric?.target_value);

            const progress = ((props?.selectedMetric?.progress_threshold) / (props?.selectedMetric?.target_value) * 100).toFixed(2);
            form.setFieldsValue({
                baselineValue: props?.selectedMetric?.baseline,
                baselineYear: props?.selectedMetric?.baseline_year,
                targetValue: tValue.toFixed(2),
                progressThreshold: progress,
                startYear: props?.selectedMetric?.start_year,
                endYear: props?.selectedMetric?.end_year,
                targetType: props?.selectedMetric?.target_type,
                businessUnit: props?.selectedMetric?.business_unit
            });
            setTargetValueForPercentType(props?.selectedMetric?.target_value);

            let existingTargets: any = myMetricTargets?.data
                .filter((item) => item.metric === props.selectedMetric?.id
                    && item.start_year >= startYear
                    && item.end_year <= endYear
                    && item.business_unit === businessUnit
                    && item.start_year === item.end_year
                );

            if (props.selectedMetric?.isDerived || props.selectedMetric?.type === 'derived') {
                existingTargets = MyDerivedMetricTargets?.data
                    .filter((item) => item.derived_metric === props.selectedMetric?.id
                        && item.start_year >= startYear
                        && item.end_year <= endYear
                        && item.business_unit === businessUnit
                        && item.start_year === item.end_year
                    );
            }

            let existingYears: any[] = [];
            let existingTargetsData = existingTargets.map((item: any) => {
                const tValue = (props.selectedMetric?.target_type === 'C'
                    ? ((item?.target_value - item?.baseline) / item?.baseline) * 100
                    : props.selectedMetric?.target_type === 'D' ?
                        ((item?.baseline - item?.target_value) / item?.baseline) * 100
                        : item?.target_value);
                existingYears.push(item.start_year);
                return {
                    targetId: item.id,
                    year: item.start_year,
                    percentage: Number(tValue?.toFixed(2)),
                    value: item.target_value,
                    isExisting: true
                }
            });

            for (let i = startYear; i <= endYear; i++) {
                if (!existingYears.includes(i)) {
                    existingTargetsData.push({
                        targetId: null,
                        year: i,
                        value: '',
                        percentage: '',
                        isExisting: false
                    });
                }
            }
            existingTargetsData = existingTargetsData.sort((a: any, b: any) => a.year - b.year);
            dispatch(setYearSpecificTargetValues(existingTargetsData));
        }
    }, [
        myMetricTargets, MyDerivedMetricTargets,
        props.selectedMetric,
        startYear, endYear, businessUnit,
        props.open, props.isEdit
    ]);

    React.useEffect(() => {
        const value = getTargetValue(targetType, Number(form.getFieldValue('targetValue')), Number(form.getFieldValue('baselineValue')));
        setTargetValueForPercentType(Number.isNaN(value) ? 0 : value);
    }, [targetType, triggerTargetcalulation, props.open]);

    React.useEffect(() => {
        if (props.open && !props.isEdit) {
            form.resetFields();
            setTargetValueForPercentType(0);
            setBaselineValueYear(null);
            setBusinessUnit(null);
            setStartYear(currentYear);
            setEndYear(currentYear);
        }
    }, [props.open]);

    return (
        <BEDrawer
            open={props.open}
            setOpen={props.setOpen}
            heading='Setup targets'
            width='fit-content'
            footer={
                <>
                    <BEButton
                        className='primary'
                        size='large'
                        loading={loading}
                        onClick={() => {
                            if (checkAllBUValueExceedsCompanyValue()) {
                                if (checkAllYearSpecificTargetValues()) {
                                    form.submit();
                                }
                            } else {
                                setOpenModal(true);
                            }
                        }}
                    >
                        {props.isEdit ? 'Update target' : 'Add target'}
                    </BEButton>
                    <BEButton
                        size='large'
                        onClick={() => props.setOpen(false)}
                    >
                        Cancel
                    </BEButton>
                </>
            }
        >
            <Form
                form={form}
                onFinish={handleFormSubmit}
            >
                <Row>
                    <div style={{ display: "flex", flexDirection: "column" }} >
                        <LableRequired>
                            Year duration
                            {/* {"(based on years configured)"} */}
                        </LableRequired>
                        <Row>
                            <Col>
                                <Form.Item
                                    name="startYear"
                                    rules={[{ required: true, message: "Please select start year" }]}
                                >
                                    <Select
                                        style={{ width: 190, marginRight: 20 }}
                                        size="large"
                                        placeholder="Start year"
                                        value={startYear}
                                        disabled={props.isEdit}
                                        onChange={(value) => setStartYear(Number(value))}
                                        options={targetYears.map((year) => {
                                            return { value: year, label: isReportingYearEndDecember ? `${year}` : `${year}-${year + 1}` }
                                        })}
                                    />

                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="endYear"
                                    rules={[{ required: true, message: "Please select end year" }]}
                                >
                                    <Select
                                        style={{ width: 190 }}
                                        size="large"
                                        placeholder="End year"
                                        value={endYear}
                                        disabled={props.isEdit}
                                        onChange={(value) => setEndYear(Number(value))}
                                        options={targetYears
                                            .filter((year) => year >= startYear)
                                            .map((year) => {
                                                return { value: year, label: isReportingYearEndDecember ? `${year}` : `${year}-${year + 1}` }
                                            })}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </Row>

                {props.level === 'business-unit' &&
                    <Row>
                        <div style={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }} >
                            <LableRequired>Business unit</LableRequired>
                            <Form.Item
                                name="businessUnit"
                                rules={[{ required: true, message: "Please select business unit" }]}
                            >
                                <Select
                                    style={{ width: 400 }}
                                    size='large'
                                    value={businessUnit}
                                    disabled={props.isEdit}
                                    onChange={(val) => setBusinessUnit(val)}
                                    options={businessUnits?.data.map((unit: any) =>
                                        ({ value: unit.id, label: unit.name })
                                    )}
                                />
                            </Form.Item>
                        </div>
                    </Row>
                }

                <Row>
                    <div style={{ display: "flex", flexDirection: "column" }} >
                        <LableRequired>Target Type</LableRequired>
                        <Form.Item
                            name="targetType"
                            rules={[{ required: true, message: "Please select target type" }]}
                        >
                            <Select
                                style={{ width: 400 }}
                                size='large'
                                value={targetType}
                                disabled={props.isEdit}
                                onChange={handleTargetTypeChange}
                                options={options}
                            />
                        </Form.Item>
                    </div>
                </Row>

                <Row>
                    <div style={{ display: "flex", flexDirection: "column" }} >
                        <LableRequired>Baseline year</LableRequired>

                        <Form.Item
                            name="baselineYear"
                            rules={[
                                { required: true, message: "Please select/enter baseline year" },
                                { type: 'number', message: 'Please enter a valid year' }
                            ]}
                        >
                            <Select
                                style={{ width: 400 }}
                                size="large"
                                onChange={(value: any, options: any) => {
                                    if (value < 1900 || value > 2100) {
                                        BEMessage.error('Please enter an year between 1900 and 2100');
                                        return;
                                    }
                                    let baselineValue: number | undefined;
                                    if (props.selectedMetric?.type === 'derived' || props.selectedMetric?.isDerived) {
                                        baselineValue = monthlyDerivedMetricData?.data.find((item) =>
                                            item.my_derived_metric === props?.selectedMetric?.id &&
                                            item.month === 0 &&
                                            (businessUnit ? item.business_unit === businessUnit : item.business_unit === null)
                                        )?.value;
                                    } else {
                                        baselineValue = monthlyMetricData?.data.find((item) =>
                                            item.my_metric === props?.selectedMetric?.id &&
                                            item.month === 0 &&
                                            (businessUnit ? item.business_unit === businessUnit : item.business_unit === null)
                                        )?.total_value;
                                    }
                                    console.log(baselineValue);
                                    if (baselineValue) {
                                        form.setFieldsValue({ baselineValue: baselineValue?.toFixed(2) });
                                    }
                                    setBaselineValueYear(value);

                                }}
                                onBlur={(e) => setTriggerTargetcalulation(!triggerTargetcalulation)}
                                placeholder="Select year"
                                value={baselineYear}
                                disabled={props.isEdit}
                                options={
                                    targetYears
                                        .filter((year) => year < startYear)
                                        .map((year) => {
                                            return { value: year, label: isReportingYearEndDecember ? `${year}` : `${year}-${year + 1}` }
                                        })
                                }
                            />
                        </Form.Item>
                    </div>
                </Row>

                <Row>
                    <div style={{ display: "flex", flexDirection: "column" }} >
                        <LableRequired>Baseline value</LableRequired>

                        <Form.Item
                            name="baselineValue"
                            rules={[{ required: true, message: "Please enter numeric value" }]}
                        >
                            <InputNumber
                                style={{ width: "400px" }}
                                size='large'
                                disabled={props.isEdit}
                                onBlur={() => setTriggerTargetcalulation(!triggerTargetcalulation)}
                            />
                        </Form.Item>
                    </div>
                </Row>

                <Row>
                    <div style={{ display: "flex", flexDirection: "column" }} >
                        <LableRequired>Alert threshold in %</LableRequired>
                        <Form.Item
                            name="progressThreshold"
                            rules={[{ required: true, message: "Please enter numeric value" }]}
                        >
                            <InputNumber
                                style={{ width: "400px" }}
                                size='large'
                                disabled={props.isEdit}
                                max={100}
                                min={0}
                            />
                        </Form.Item>
                    </div>
                </Row>

                <Row>
                    <div style={{ display: "flex", flexDirection: "column" }} >
                        <LableRequired>{(targetType === 'C' || targetType === 'D') ? "Target %" : "Target value"}</LableRequired>
                        <Form.Item
                            name="targetValue"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter numeric value"
                                }
                            ]}
                        >
                            <InputNumber
                                onBlur={() => setTriggerTargetcalulation(!triggerTargetcalulation)}
                                style={{ width: "400px" }}
                                size='large'
                                min={0}
                                max={targetType === 'C' || targetType === 'D' ? 100 : undefined}
                            />
                        </Form.Item>
                    </div>
                </Row>

                {(targetType === 'C' || targetType === 'D') &&
                    <Row>
                        <div style={{ display: "flex", flexDirection: "column" }} >
                            <LableRequired>Target value</LableRequired>

                            <Form.Item>
                                <BEInput
                                    style={{ width: "400px" }}
                                    size='large'
                                    disabled={true}
                                    value={targetvalueForPercentType}
                                />
                            </Form.Item>

                        </div>
                    </Row>
                }

                {
                    startYear !== endYear &&
                    <Row>
                        <div style={{ display: "flex", flexDirection: "column", marginBottom: "1rem", width: "400px" }} >
                            <p>Add year specific target values</p>
                            <ExcelTargetTable
                                targetType={targetType}
                                startYear={startYear}
                                endYear={endYear}
                                baselineValue={form.getFieldValue('baselineValue')}
                                isEdit={props?.isEdit as boolean}
                            />
                        </div>
                    </Row>
                }

            </Form>
            <BEConfirmModal
                title="Confirm"
                message="The targets set at business unit level for this metric has exceeded the overall company level target. Do you want to proceed?"
                visible={openModal}
                setVisible={setOpenModal}
                onConfirm={async () => {
                    form.submit();
                }}
            />
        </BEDrawer >
    );
}