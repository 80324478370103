import type { StatusType } from "../Types/userTypes";
import { ReportingState } from "../Types/reportingTypes";
import { Reducer, createSlice } from "@reduxjs/toolkit";
import { clearStore } from "../ExtraReducrActions";
import { setCurrentDepartmentDM } from "../DataManagerReducer";
import { GRESBEntitySections } from "../../data/frameworks";

const initialState: ReportingState = {
  TemplateDrafts: {
    status: "idle",
    data: [],
  },
  currentTemplate: null,
  templateTopics: {
    status: 'idle',
    data: []
  },
  templateFrameworkTopics: {
    status: 'idle',
    data: []
  },
  allSelectedTemplateTopics: {
    status: 'idle',
    data: []
  },
  selectedTemplateTopics: {
    status: 'idle',
    data: []
  },
  selectedGresbAssetTopics: {
    status: 'idle',
    data: []
  },
  selectedGresbEntityTopics: {
    status: 'idle',
    data: []
  },
  currentAddTemplateState: 1,
  selectedFrameWork: null,
  selectedIndustry: 'All',
  selectedTemplateSection: null,
  selectedTemplateDimension: null,
  selectedTemplateCategory: null,
  Reports: {
    status: "idle",
    data: [],
  },
  editReportStep: 1,
  reportBusinessUnits: {
    status: 'idle',
    data: []
  },
  allReports: {
    status: 'idle',
    data: []
  },
  reportData: {
    status: 'idle',
    data: {}
  },
  gresbAssetData: { // contins all the information for gresb assets for a particular report
    status: 'idle',
    data: []
  },
  gresbEntityData: { // contins all the information for gresb entities for a particular report
    status: 'idle',
    data: []
  },
  selectedGresbEntitySections: {
    status: 'idle',
    data: GRESBEntitySections.filter((section) => section.alwaysChecked).map((section) => section.section)
  },
  evidence: {
    status: 'idle',
    data: []
  },
  topicStatus: {
    status: 'idle',
    data: []
  },
  assignedDepartmentByReportTopics: {
    status: 'idle',
    data: []
  },
  gresbEntityAccordionStatus: {
    status: 'idle',
    data: {}
  },
  reportChats: {
    status: 'idle',
    data: []
  },
  reportActivityActions: {
    status: 'idle',
    data: [],
  },
  // unsavedReportData: [],
  currentReport: null,
  currentEntity: null,
  currentGRESBSection: null,
  selectedGRESBAsset: null,
  selectedYear: new Date().getFullYear(),
  assignReportToUsers: {
    status: 'idle',
    data: []
  },
  omittedReportTopics: {
    status: 'idle',
    data: []
  },
  gresbEntityEvidences: {
    status: 'idle',
    data: []
  },
  gresbSlugData: {
    status: 'idle',
    data: {}
  },
  gresbAssetErrors: {},
  DepartmentTypeReporting:'All'
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
  reducers: {
    setTemplatesDrafts: (state, action) => {
      state.TemplateDrafts = action.payload;
    },
    setReports: (state, action) => {
      state.Reports = action.payload;
    },
    setCurrentAddTemplateState: (state, action) => {
      state.currentAddTemplateState = action.payload
    },
    setSelectedFramework: (state, action) => {
      state.selectedFrameWork = action.payload
    },
    setSelectedIndustry: (state, action) => {
      state.selectedIndustry = action.payload
    },
    setSelectedTemplateSection: (state, action) => {
      state.selectedTemplateSection = action.payload
    },
    setSelectedTemplateDimension: (state, action) => {
      state.selectedTemplateDimension = action.payload
    },
    setSelectedTemplateCategory: (state, action) => {
      state.selectedTemplateCategory = action.payload
    },
    setTemplateTopics: (state, action) => {
      state.templateTopics = action.payload
    },
    setTemplateFrameworkTopics: (state, action) => {
      state.templateFrameworkTopics = action.payload
    },
    setAllSelectedTemplateTopics: (state, action) => {
      state.allSelectedTemplateTopics = action.payload
    },
    setSelectedTemplateTopics: (state, action) => {
      state.selectedTemplateTopics = action.payload
    },
    setSelectedGresbAssetTopics: (state, action) => {
      state.selectedGresbAssetTopics = action.payload
    },
    setSelectedGresbEntityTopics: (state, action) => {
      state.selectedGresbEntityTopics = action.payload
    },
    setCurrentTemplate: (state, action) => {
      state.currentTemplate = action.payload
    },
    setEditReportStep: (state, action) => {
      state.editReportStep = action.payload
    },
    setReportBusinessUnits: (state, action) => {
      state.reportBusinessUnits = action.payload
    },
    setAllReports: (state, action) => {
      state.allReports = action.payload
    },
    setReportData: (state, action) => {
      state.reportData = action.payload
    },
    setGresbAssetData: (state, action) => {
      state.gresbAssetData = action.payload
    },
    setGresbEntityData: (state, action) => {
      state.gresbEntityData = action.payload
    },
    setSelectedGresbEntitySections: (state, action) => {
      state.selectedGresbEntitySections = action.payload
    },
    setGresbAssetErrors: (state, action) => {
      state.gresbAssetErrors = action.payload
    },
    setGresbEnityEvidences: (state, action) => {
      state.gresbEntityEvidences = action.payload
    },
    setGresbEntityAccordionStatus: (state, action) => {
      state.gresbEntityAccordionStatus = action.payload
    },
    setReportChats: (state, action) => {
      state.reportChats = action.payload
    },
    setReportActivityActions:(state, action) => {
      state.reportActivityActions = action.payload
    },
    setAssignedReportToUsers: (state, action) => {
      state.assignReportToUsers = action.payload
    },
    // setUnsavedReportData: (state, action) => {
    //   state.unsavedReportData = action.payload
    // },
    addReportData: (state, action) => {
      // state.reportData?.data
    },
    editReportData: (state, action) => {
      // const index = state.reportData?.data.findIndex(item => item.id === action.payload?.id);
      // state.reportData?.data[index] = action.payload?.data
    },
    setCurrentReport: (state, action) => {
      state.currentReport = action.payload
    },
    setCurrentGRESBSection: (state, action) => {
      state.currentGRESBSection = action.payload
    },
    setSelectedYear: (state, action) => {
      state.selectedYear = action.payload
    },
    setReportingEvidence: (state, action) => {
      state.evidence = action.payload
    },
    setRportingTopicStatus: (state, action) => {
      state.topicStatus = action.payload
    },
    setAssignedDepartmentByReportTopics: (state, action) => {
      state.assignedDepartmentByReportTopics = action.payload
    },
    setOmittedReportTopics: (state, action) => {
      state.omittedReportTopics = action.payload
    },
    setCurrentDepartmentReporting: (state, action) => {
      state.DepartmentTypeReporting = action.payload
    },
    setSelectedGresbAsset: (state, action) => {
      state.selectedGRESBAsset = action.payload
    },
    setGresbSlugData: (state, action) => {
      state.gresbSlugData = action.payload
    },
    setCurrentEntity: (state, action) => {
      state.currentEntity = action.payload
    }
  },
});

export const {
  setTemplatesDrafts,
  setReports,
  setCurrentAddTemplateState,
  setSelectedFramework,
  setSelectedIndustry,
  setTemplateTopics,
  setSelectedTemplateSection,
  setSelectedTemplateDimension,
  setAllSelectedTemplateTopics,
  setTemplateFrameworkTopics,
  setCurrentTemplate,
  setEditReportStep,
  setReportBusinessUnits,
  setAllReports,
  setReportData,
  addReportData,
  setSelectedGresbAsset,
  editReportData,
  setGresbAssetData,
  setGresbEntityData,
  setGresbAssetErrors,
  setGresbEnityEvidences,
  setGresbEntityAccordionStatus,
  setSelectedGresbEntitySections,
  // setUnsavedReportData,
  setReportChats,
  setReportActivityActions,
  setCurrentReport,
  setCurrentGRESBSection,
  setSelectedGresbAssetTopics,
  setSelectedGresbEntityTopics,
  setSelectedYear,
  setSelectedTemplateCategory,
  setSelectedTemplateTopics,
  setAssignedReportToUsers,
  setReportingEvidence,
  setRportingTopicStatus,
  setAssignedDepartmentByReportTopics,
  setOmittedReportTopics,
  setCurrentDepartmentReporting,
  setGresbSlugData,
  setCurrentEntity
} = userSlice.actions;

const reportingReducer: Reducer<ReportingState> = userSlice.reducer;

export default reportingReducer;