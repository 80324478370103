import './index.scss';
import TrackerCard from '../../../../Components/Cards/TrackerCard';

export interface ITargetsTrackerCardsProps {
  targetsData: any[];
}

export function TargetsTrackerCards (props: ITargetsTrackerCardsProps) {
  return (
    <div className='tracker'>
      <TrackerCard noHighlight={true} title={'Total KPIs'} body={props.targetsData.length} />
      <TrackerCard noHighlight={true} title={'No. of KPIs met'} body={props.targetsData
        .filter((item) => item.progress >= 100).length} />
    </div>
  );
}

