import React from 'react'
import IconProps from './Interface/IconProps';


const SendIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 12}
      height={props.inheritSize ? "100%" : 12}
      viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M0.809039 6.22286C0.809039 6.22286 0.506212 6.16074 0.312798 5.92994C0.312798 5.92994 0.136785 5.7199 0.11129 5.44265C0.11129 5.44265 0.0856914 5.16426 0.221682 4.92602C0.221682 4.92602 0.371478 4.6636 0.660457 4.55035L10.7576 0.584805C10.7576 0.584805 10.9834 0.497271 11.222 0.53828C11.222 0.53828 11.4607 0.579289 11.6443 0.737169C11.6443 0.737169 11.8279 0.895049 11.9043 1.12487C11.9043 1.12487 11.9806 1.35469 11.9273 1.5935L9.87102 10.5734C9.87102 10.5734 9.8192 10.7991 9.66253 10.9696C9.66253 10.9696 9.50585 11.1402 9.28534 11.2109C9.28534 11.2109 9.06483 11.2816 8.83821 11.2339C8.83821 11.2339 8.61159 11.1863 8.4392 11.0337L3.61078 6.78532L0.810842 6.22323L0.809039 6.22286ZM0.983062 5.36534L3.89791 5.9505C3.97318 5.96561 4.04315 6.00027 4.1008 6.05098L9.0172 10.3768L9.0182 10.3776L11.0738 1.40064L0.980321 5.36479L0.983062 5.36534Z" fill="white"/>
	<path d="M11.5108 1.35838C11.6247 1.27613 11.6923 1.1441 11.6923 1.00362C11.6923 0.996837 11.6921 0.990059 11.6918 0.983286C11.6878 0.898342 11.6592 0.81639 11.6094 0.747455C11.5415 0.653391 11.439 0.590169 11.3244 0.571696C11.3014 0.567982 11.2781 0.566117 11.2548 0.566116C11.2497 0.566116 11.2447 0.566203 11.2396 0.566377C11.1529 0.569377 11.069 0.59812 10.9986 0.648951L3.55587 6.02455C3.46181 6.09249 3.39834 6.19519 3.37986 6.30975C3.37615 6.33278 3.37428 6.35607 3.37428 6.3794C3.37428 6.38444 3.37437 6.38947 3.37454 6.39451C3.37754 6.48127 3.40629 6.56518 3.45712 6.63556C3.53937 6.74944 3.6713 6.8169 3.81178 6.8169C3.81856 6.8169 3.82534 6.81674 3.83211 6.81642C3.91706 6.81247 3.99901 6.78385 4.06794 6.73406L11.5108 1.35838Z" fill="white"/>
	<path d="M4.24928 9.93886V6.37944C4.24928 6.13782 4.05341 5.94194 3.81178 5.94194C3.57016 5.94194 3.37428 6.13777 3.37428 6.3794V9.9396C3.37473 10.2065 3.52329 10.4273 3.52329 10.4273C3.67185 10.6481 3.91821 10.7488 3.91821 10.7488C4.16457 10.8495 4.42527 10.796 4.42527 10.796C4.68598 10.7425 4.87272 10.5528 4.87272 10.5528L6.57661 8.84896C6.65866 8.76691 6.70475 8.65563 6.70475 8.5396C6.70475 8.42356 6.65866 8.31228 6.57661 8.23024C6.49456 8.14819 6.38328 8.1021 6.26725 8.1021C6.15122 8.1021 6.03994 8.14819 5.95789 8.23024L4.24928 9.93886Z" fill="white"/>
</svg>
  );
}

export default SendIcon