import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from '../../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../../Config/Hooks/useAppDispatch';
import { NewBETable } from '../../../../../../../Components/BETable/newBETable';
import { ColumnsType } from 'antd/es/table';
import { DownloadIcon } from '../../../../../../../stories/Icon.stories';
import '../../../index.scss';
import File, { FileIconWithMenu } from '../../../../../Components/File';
import { BECardPagination } from '../../../../../../../Components/BECardsPagination';
export interface IMetricFolderProps {
}

export function MetricFolder(props: IMetricFolderProps) {
  const { fy_id, metric_id } = useParams();
  const FYData = useTypedSelector(state => state.vault.FYData);
  const combinedSelectedMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.combinedSelectedMetricsPerYear);
  const [tableData, setTableData] = React.useState<any>([]);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const view=useTypedSelector(state=>state.vault.view)

  const columns: ColumnsType<any> = [
    {
      title:'S. No.',
      dataIndex:'sno',
      key:'sno',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Evidence',
      dataIndex: 'evidence',
      key: 'evidence',
      render: (text: any) => {
        // open the file in a new tab
        return (
          <a href={text} target="_blank">Evidence</a>
        )
      }
    },
    {
      title: 'Download',
      dataIndex: 'download',
      key: 'download',
      render: (text: any) => {
        // download the file
        return (
          <a href={text} ><DownloadIcon/></a>
        )
      }
    },
  ];

  React.useEffect(() => {
    if (FYData?.status === 'success' && combinedSelectedMetricsPerYear?.status === 'success') {
      setTableData(
        FYData?.data[Number(fy_id)][Number(metric_id)]
      )
    }
  }, [fy_id, metric_id, FYData]);

  return (
    <div className='system-folder-container'>
        <h3>
          {
            combinedSelectedMetricsPerYear?.data.filter((metric) => metric.id === Number(metric_id))[0]?.title
          }
        </h3><br/>
        {
          view==='grid' ? 
          <BECardPagination
          open={view === 'grid'}
          noOfColumns={8}
          noOfRows={1}
          ComponentsArrey={
            tableData.filter((item: any) => searchValue !== '' ? item.title?.toLowerCase().includes(searchValue?.toLowerCase()) : true)
            .map((item: any, index: number) => {
              console.log(item)
              return <FileIconWithMenu 
              menuItems={
                [
                  {
                    key: '1',
                    label: (
                      <a href={item.evidence} target='_blank'>Download</a>
                    ),
                },
                ]
              } 
                iconSrc=''
                fileName='Evidence'
                />
            })}
          search
          setSearchedValue={setSearchValue}
        />
           :
          //all files of this metrics  to be displayed here
          <NewBETable
            data={tableData}
            columns={columns}
          />
        }
    </div>
  );
}
