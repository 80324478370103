import './style.scss';
import { useTypedSelector } from '../../../../../../../Config/Hooks/useTypedSelector';
import BEMultipleSelect from '../../../../../../../Components/BEMultipleSelect';
import { setCurrentDepartmentReporting, setSelectedTemplateTopics } from '../../../../../../../Redux/ReportingReducer';
import { useAppDispatch } from '../../../../../../../Config/Hooks/useAppDispatch';
import { omittedReportTopicsTypes, ReportingTopicStatusTypes, TemplateSelectedTopicTypes } from '../../../../../../../Redux/Types/reportingTypes';

export interface ITopSectionProps {
}

interface departmentSelectTypeReporting {
    name: string,
    value: string,
}

export const ReportingTopicsMap = [
    { value: -1, label: 'All', type: 'success' },
    { value: 0, label: 'Pending', type: 'warning' },
    { value: 1, label: 'Completed', type: 'success' },
    { value: 2, label: 'Approved', type: 'success' },
    { value: 3, label: 'Rejected', type: 'error' },
    { value: 4, label: 'Assigned to me', type: 'warning' },
];

export const departmentSelectTypeReporting: departmentSelectTypeReporting[] = [
    { name: 'All', value: 'All' },
    { name: 'Finance', value: 'Finance' },
    { name: 'Human Resources', value: 'Human Resources' },
    { name: 'Information Technology', value: 'Information Technology' },
    { name: 'Legal', value: 'Legal' },
    { name: 'ESG / EHS', value: 'ESG / EHS' },
    { name: 'Operations / Projects', value: 'Operations / Projects' },
    { name: 'Administration', value: 'Administration' },
    { name: 'Finance', value: 'Finance' },
    { name: 'Others', value: 'Others' },
    { name: 'Procurement', value: 'Procurement' }
];

export function TopSection(props: ITopSectionProps) {
    const dispatch = useAppDispatch();
    const currentSection = useTypedSelector(state => state.reporting.selectedTemplateSection);
    const allSelectedTopics = useTypedSelector(state => state.reporting.allSelectedTemplateTopics);
    const topicsStatus = useTypedSelector(state => state.reporting.topicStatus);
    const selectedTopics = useTypedSelector(state => state.reporting.selectedTemplateTopics);
    const assignedDepartmentByReportTopics = useTypedSelector(state => state.reporting.assignedDepartmentByReportTopics);
    const currentGRESBSection = useTypedSelector(state => state.reporting.currentGRESBSection);
    const currentReport = useTypedSelector(state => state.reporting.currentReport);
    const omittedReportTopics = useTypedSelector(state => state.reporting.omittedReportTopics);
    const assignReportToUsers = useTypedSelector(state => state.reporting.assignReportToUsers);
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');

    const handleChange = (value: any) => {
        // for each topic in allSelectedTopics?.data 
        // find status from topicsStatus?.data using disclosure_code
        // if status not found then consider as pending
        let assignedTopics: any = [];
        let tempData: any = [];
        const omitedUniqueCodes = new Set<string>(omittedReportTopics?.data.map((topic: omittedReportTopicsTypes) => selectedTopics?.data?.find((item: TemplateSelectedTopicTypes) => item.id === topic.topic_id)?.unique_code || ''));
        const allUniqueCodes = new Set<string>(allSelectedTopics?.data.map((topic: TemplateSelectedTopicTypes) => topic.unique_code));
        const statusUniqueCodeMap = new Map<string, number>();
        topicsStatus?.data.forEach((topic) => statusUniqueCodeMap.set(topic.unique_code, topic?.status));
        const validUniqueCodes = new Set<string>(Array.from(allUniqueCodes).filter((code) => !omitedUniqueCodes.has(code) &&
            (value === -1 || statusUniqueCodeMap.get(code) === value || (value === 0 && !statusUniqueCodeMap.has(code)))
        ));
        const assignedUnoqueCodes = assignReportToUsers?.data.filter((item) => item.report === currentReport?.id && item.user === userInfo.id).map((item) => item.unique_code);
        allSelectedTopics?.data.forEach((topic) => {
            if (validUniqueCodes.has(topic.unique_code) || value===-1) tempData.push(topic);
            if (value === 4 && assignedUnoqueCodes.includes(topic.unique_code)) assignedTopics.push(topic);
        });
        if (value === 4) tempData = assignedTopics;
        dispatch(setSelectedTemplateTopics({
            status: 'success',
            data: tempData
        }))
    }

    const handleDepartmentChange = (value: any) => {
        let tempData: any = [];
        const sectionsByDepartment = assignedDepartmentByReportTopics?.data
            .filter((item) => (item.department === value || value === 'All') && item.report === currentReport?.id)
            .map((item) => item.section);
        allSelectedTopics?.data.forEach((topic) => {
            if (sectionsByDepartment.includes(topic.section) || value === 'All') tempData.push(topic);
        });

        dispatch(setSelectedTemplateTopics({
            status: 'success',
            data: tempData
        }))
    }
    return (
        <div className='top-section-reports'>
            <div className='top-section-title'>
                <div className='heading'>
                    {
                        selectedTopics?.data.length !== 0 && currentSection
                    }
                </div>
                {/* <div className='sub-heading'>
                    Details of listed entity
                </div> */}
            </div>
            <div style={{ flexGrow: 1 }} />

            {/* <div className='select-box'>
                <BEMultipleSelect
                    single
                    placeholder='Department'
                    name='Department'
                    data={departmentSelectTypeReporting.map((dept, index) => dept)}
                    valueFeild='name'
                    labelFeild='name'
                    onChange={(dept: any) => {
                        dispatch(setCurrentDepartmentReporting(dept));
                        handleDepartmentChange(dept);
                    }}
                />
            </div> */}

            {
                currentGRESBSection !== 'entity' &&
                <div className='select-box'>
                    <BEMultipleSelect single data={ReportingTopicsMap} onChange={handleChange}
                        valueFeild='value' labelFeild='label' placeholder='Select topic' defaultValue={-1}
                    />
                </div>
            }

            {/*<BEButton className='primary' size='middle'>
                Save
            </BEButton> */}

        </div>
    );
}
