import * as React from 'react';
import companyLogo from '../../../assets/images/Company/Logo_horizontal_white.png'
import { PrimaryTheme } from '../../../Config/Theme/theames';
import LoginImage from '../../../assets/images/loginImage.svg';
export interface ILeftComponentLoginProps {
    step: number;
}

export function LeftComponentLogin (props: ILeftComponentLoginProps) {
  return (
    <div className='left-section'>
        
           <img src={LoginImage} alt="image" className='logo-img' />
        
    </div>
  );
}
