import React, { useEffect } from 'react'
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import Step1 from '../Steps/Step1';
import Step3 from '../Steps/Step3';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { BEMessage } from '../../../../../Components/BEMessage';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { setCreateSurveyStep, setCurrentAssessment, setSuppliers, setSuppliersSurveyRefs } from '../../../../../Redux/SupplierReducer';
import { Step2 } from '../Steps/Step2';
import { deleteSupplierAssessment } from '../../../Actions/supplierAction';

export interface IAddSupplierAssessMentProps {
  open: boolean;
  setOpen: Function;
}

const AddSupplierAssessment = (props: IAddSupplierAssessMentProps) => {
  const dispatch = useAppDispatch();
  const currentAssessment = useTypedSelector(
    (state) => state.supplier.currentAssessment
  );
  const [loading, setLoading] = React.useState(false);
  const currentSurveyStep = useTypedSelector(
    (state) => state.supplier.createSurveyStep
  );
  const onCancle = () => {
    props.setOpen(false);
  };

  const handlCancle = async () => {
    // if(currentSurveyStep>1){
    //   setLoading(true)
    //   await dispatch(deleteSupplierAssessment(currentAssessment?.id))
    //   setLoading(false)
    // }
    // dispatch(setCreateSurveyStep(1));
    props.setOpen(false);
  };

  // clean up
  useEffect(() => {
    if (!props.open) {
      dispatch(setCurrentAssessment(null))
      dispatch(setSuppliersSurveyRefs({status: 'idle',data: []}))
    }
  }, [props.open]);

  return (
    <BEDrawer
      open={props.open}
      setOpen={props.setOpen}
      heading="Add Value Chain Assessment"
      width="100vw"
      footer={
        <>
          {/* <BEButton
            className="primary"
            size="large"
            disabled={currentSurveyStep !== 4}
            onClick={() => {
              BEMessage.success("Successfully added Supplier Assessment")
              dispatch(setCreateSurveyStep(1));
              props.setOpen(false)
            }}
          >
            Add Supplier Assessment Survey
          </BEButton> */}
          <BEButton
            size="large"
            onClick={() => {
              handlCancle();
            }}
            loading={loading}
          >
            Cancel
          </BEButton>
        </>
      }
    >
      <div className="add-materiality-assessment">
        <Step1
          open={currentSurveyStep === 1}
          setOpen={props.setOpen}
          onCancle={onCancle}
        />
        <Step2 open={currentSurveyStep === 2} onCancle={onCancle} />
        <Step3
          open={currentSurveyStep === 3}
          setOpen={props.setOpen}
          onCancle={onCancle}
        />
      </div>
    </BEDrawer>
  );
}

export default AddSupplierAssessment