import React from 'react'
import IconProps from './Interface/IconProps'

const CloseIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      viewBox="0 0 24 24"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2997 5.7107C17.9097 5.3207 17.2797 5.3207 16.8897 5.7107L11.9997 10.5907L7.10973 5.7007C6.71973 5.3107 6.08973 5.3107 5.69973 5.7007C5.30973 6.0907 5.30973 6.7207 5.69973 7.1107L10.5897 12.0007L5.69973 16.8907C5.30973 17.2807 5.30973 17.9107 5.69973 18.3007C6.08973 18.6907 6.71973 18.6907 7.10973 18.3007L11.9997 13.4107L16.8897 18.3007C17.2797 18.6907 17.9097 18.6907 18.2997 18.3007C18.6897 17.9107 18.6897 17.2807 18.2997 16.8907L13.4097 12.0007L18.2997 7.1107C18.6797 6.7307 18.6797 6.0907 18.2997 5.7107Z"
        fill={props.fill ? props.fill : "#333333"}
      />
    </svg>
  );
}

export default CloseIcon