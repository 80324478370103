import * as React from 'react';
import { SideItemDM } from './SideItemDM';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { setCurrentCategory } from '../../../../../Redux/OnboardingReducer';
import { setSearchTabMetrics } from '../../../../../Redux/DataManagerReducer';

export interface ISIdeFilterProps {
}

export function SIdeFilterDM(props: ISIdeFilterProps) {
  const dispatch = useAppDispatch()
  const combinedSelectedMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.combinedSelectedMetricsPerYear.data);
  const searchTabMetrics = useTypedSelector(state => state.dataManager.metrics.searchTabMetrics);
  const currentCategory = useTypedSelector(state => state.onBoarding.metrics.currentCategory);
  const currentPiller = useTypedSelector(state => state.onBoarding.metrics.currentPillar);

  React.useEffect(() => {
    if (searchTabMetrics.dropDownMetric) {
      dispatch(setCurrentCategory(searchTabMetrics.dropDownMetric.category));
    } else {
      dispatch(setCurrentCategory(Array.from(new Set(combinedSelectedMetricsPerYear?.filter((item: any) => item.pillars === currentPiller).map((item) => item.category))).sort()[0]))
    }
  }, [currentPiller])

  return (
    <div className='side-filter'>
      {
        Array.from(new Set(combinedSelectedMetricsPerYear?.filter((item: any) => item.pillars === currentPiller).map((item) => item.category))).sort()
          .map((item: any, index) => {
            return <SideItemDM
              key={index}
              active={currentCategory === item}
              name={item}
              onClick={() => {dispatch(setCurrentCategory(item));
                dispatch(setSearchTabMetrics({
                  tabsValue: searchTabMetrics.tabsValue,
                  data: searchTabMetrics?.data,
                  dropDownMetric: null
              }))
            }}
            />
          })
      }

    </div>
  );
}
