import * as React from 'react';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { getCompanyDetails } from '../../../../Actions/OnboardingActions';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import { Row,Col } from 'antd';
import { isJson } from '../../../Step1';
import dayjs from 'dayjs';
export interface IReviewStep1Props {
}

export default function ReviewStep1 (props: IReviewStep1Props) {
  const dispatch = useAppDispatch()
  const companyDetails = useTypedSelector(state => state.companies.companyDetails)

  React.useEffect(() => {
    dispatch(getCompanyDetails())
  }, [])
  
  if (companyDetails?.status === 'loading') {
    return <GlobalLoader />
  }
  return (
    <div className='step-1'>
      <div className='outer-box'>
          <Row gutter={20}>
            <Col span={16}>
               <Row gutter={20}>
                 <Col span={6}>
                  <p className='heading'>Name</p>
                  </Col>
                 <Col span={18}>
                  <p>{companyDetails?.data?.firm_name}</p>
                  </Col>
                 <Col span={6}>
                  <p className='heading'>Address</p>
                  </Col>
                  <Col span={18}>
                    <p>{companyDetails?.data?.address}</p>
                    </Col>
                  <Col span={6}>
                    <p className='heading'>Industry</p>
                    </Col>
                  <Col span={18}>
                    <p>{
                       isJson(companyDetails?.data?.industry!)?JSON.parse(companyDetails?.data?.industry!):companyDetails?.data?.industry
                      }</p>
                    </Col>
                    <Col span={6}>
                            <p className='heading'>Sector</p>
                            </Col>
                          <Col span={18}>
                            <p>{companyDetails?.data?.sector ? companyDetails?.data?.sector
                            : 'Not Selected'  
                          }</p>
                          </Col>
                      <Col span={6}>
                        <p className='heading'>Website</p>
                        </Col>
                      <Col span={18}>
                        <p>{companyDetails?.data?.website}</p>
                        </Col>
                       
                          {/* <Col span={6}>
                            <p className='heading'>Revenue</p>
                            </Col>
                          <Col span={18}>
                            <p>{companyDetails?.data?.revenue}</p>
                            </Col> */}
                            <Col span={6}>
                              <p className='heading'>Reporting Year End</p>
                              </Col>
                            <Col span={18}>
                              <p>{
                                dayjs(companyDetails?.data?.reporting_year_end).format('DD MMMM')
                                }</p>
                              </Col>
                              <Col span={6}>
                          <p className='heading'>Currency</p>
                          </Col>
                        <Col span={18}>
                          <p>{companyDetails?.data?.currency}</p>
                          </Col>
                         
               </Row>
            </Col>
            <Col span={8}>
               <div className='logo'><img src={companyDetails?.data?.logo} alt='logo' /></div>
            </Col>
          </Row>
      </div>

    </div>
  );
}
