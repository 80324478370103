import { PrimaryTheme } from "../../../Config/Theme/theames";
import TrackerTable from "./TrackerTable";
import { useAppDispatch } from "../../../Config/Hooks/useAppDispatch";

const Tracker = () => {
  const dispatch = useAppDispatch();
  return (
    <div style={{ margin: "1rem 2rem" }}>
      {/* <Row>
        <TrackerCard
          title="PENDING TRACKERS"
          body="45/60"
          icon={<TrackerIcon inheritSize stroke={PrimaryTheme.secondaryGray} />}
        />
        <TrackerCard
          title="PENDING REVIEWS"
          body="3"
          icon={<ReviewIcon inheritSize stroke={PrimaryTheme.secondaryGray} />}
        />
      </Row> */}

      <div style={{ margin: "0" }}>
        {/* <Row>
          <Col span={6}>
            <BEInput search />
          </Col>
        </Row> */}
        
          
            
            {/* <div className="status-filter-select" style={{ position: "absolute", left: "-12rem", bottom: "-2.2rem" }}>
              <BEMultipleSelect
                single
                placeholder='Department'
                name='Department'
                data={departmentSelectTypeDM.map((dept, index) => dept)}
                valueFeild='name'
                labelFeild='name'
                onChange={(dept: any) => {
                  dispatch(setCurrentDepartmentDM(dept));
                }}
              />
            </div> */}
            
        </div>
        <TrackerTable />
      </div>
  );
};

export default Tracker;
