import { Form, Row } from 'antd';
import * as React from 'react';
import { BESubjectTagList } from './Components/BESubjectTagList';
import './style.scss';
import BEMultipleSelect from '../BEMultipleSelect';
import { BEFilterableBarGraph } from './Components/BEFilterableBarGraph';
import { BEFilterableLineChart } from './Components/BEFilterableLineChart';
import { BEFilterableAreaChart } from './Components/BEFilterableAreaChart';
import { ZeroStateChart } from './Components/ZeroStateComponent';
import { GlobalLoader } from '../GlobalLoader';

export interface IBEFilterableChartProps {
    rightFilters: IBEFilterInterface[];
    subjectFilter: IBEFilterInterface;
    handleFilterChange?: Function;
    title?: string;
}

export interface optionsListItem {
    label: string;
    value: any;
}

export interface IBEFilterInterface {
    label: string;
    options: optionsListItem[];
    multiSelect?: boolean;
    defaultOption: optionsListItem;
}

const chartOptions: optionsListItem[] = [
    {
        label: 'Bar Chart',
        value: 'bar'
    },
    {
        label: 'Line Chart',
        value: 'line'
    },
    {
        label: 'Area Chart',
        value: 'area'
    }
];

export function BEFilterableChart(props: IBEFilterableChartProps) {

    const [titleList, setTitleList] = React?.useState<optionsListItem[]>([]);
    const [chartType, setChartType] = React?.useState<string>('bar');
    const [filterMap, setFilterMap] = React?.useState<Map<string, any[]>>(
        new Map<string, string[]>()
    );
    const [chartData, setChartData] = React?.useState<any[]>([]);
    const [selectedSubject, setSelectedSubject] = React?.useState<string[]>([]);
    const [loading, setLoading] = React?.useState<boolean>(false);

    const getData = async (fmap: Map<string, any[]>) => {
        const data = props.handleFilterChange && await props.handleFilterChange(fmap);
        setChartData(data);
    };

    const handleSubjectList = async (titleList: optionsListItem[]) => {
        const updatedFilterMap = new Map(filterMap);
        const subjectFilterValue = titleList.map(item => item.value);
        updatedFilterMap.set(props?.subjectFilter?.label, subjectFilterValue);

        setFilterMap(updatedFilterMap);
        setSelectedSubject(subjectFilterValue);
        setTitleList(titleList);

        await getData(updatedFilterMap);
    };

    // Retrieve filter values from localstorage
    const getFilterValues = async () => {
        const key = props.subjectFilter?.label + '_analytics_filter';
        const filterValues = localStorage.getItem(key);
        if (filterValues) {
            const filterMap: Map<string, any[]> = new Map(JSON.parse(filterValues));
            setFilterMap(filterMap);
            const subjectValues = filterMap.get(props.subjectFilter?.label) as string[];
            setSelectedSubject(subjectValues);
            setTitleList(subjectValues?.map((item: string) => ({
                value: item,
                label: props.subjectFilter.options.find((op: any) => op.value === item)?.label
            }) as optionsListItem));
            await getData(filterMap);
        }
    }

    // store filter values in localstorage
    const storeFilterValues = (filterMap: Map<string, any[]>) => {
        const key = props.subjectFilter?.label + '_analytics_filter';
        localStorage.setItem(key, JSON.stringify(Array.from(filterMap.entries())));
    }

    React?.useEffect(() => {
        // create a map of the filters and selected values
        let filterMap = new Map<string, any[]>();
        props?.rightFilters?.forEach(filter => {
            filterMap?.set(filter?.label, [filter?.defaultOption?.value]);
        });
        setFilterMap(filterMap);
    }, [props?.rightFilters, props?.subjectFilter]);

    React?.useEffect(() => {
        setLoading(props?.subjectFilter === undefined);
        getFilterValues();
    }, [props.subjectFilter]);

    return (
        <div className='chart-filter'>
            {
                loading ?
                    <GlobalLoader height='40vh' />
                    :
                    <>
                        {
                            props?.title &&
                            <h3 className='chart-title'>
                                {props?.title}
                            </h3>
                        }
                        <Row className='filter-container'>
                            <div className='left-filters'>
                                <BEMultipleSelect
                                    data={props?.subjectFilter?.options}
                                    labelFeild='label'
                                    valueFeild='value'
                                    single={props?.subjectFilter?.multiSelect ? false : true}
                                    name={props?.subjectFilter?.label}
                                    isDataStrArray
                                    value={selectedSubject}
                                    modifySelectedLabelClass
                                    maxTagCount={0}
                                    noForm
                                    placeholder={`Select ${props?.subjectFilter?.label}`}
                                    maxTagPlaceholder={(values: any) => ``}
                                    onChange={async (e: string | string[]) => {
                                        setSelectedSubject(e as string[]);
                                        let subjectFilterValue = props?.subjectFilter?.multiSelect ? e as string[] : [e as string];
                                        const updatedFilterMap = new Map(filterMap);
                                        updatedFilterMap?.set(props?.subjectFilter?.label, subjectFilterValue);
                                        setFilterMap(updatedFilterMap);
                                        const listTitle = subjectFilterValue?.map((item: string) => ({
                                            value: item,
                                            label: props.subjectFilter.options.find((op: any) => op.value === item)?.label
                                        }));
                                        storeFilterValues(updatedFilterMap);
                                        setTitleList(listTitle as optionsListItem[]);
                                        await getData(updatedFilterMap);
                                    }}
                                />

                            </div>
                            <div className='right-filters'>
                                {
                                    props?.rightFilters?.map((filter, index) => {
                                        return (
                                            <BEMultipleSelect
                                                key={index}
                                                data={filter?.options}
                                                labelFeild='label'
                                                valueFeild='value'
                                                noForm
                                                value={filterMap?.get(filter?.label)}
                                                single={filter?.multiSelect ? false : true}
                                                name={filter?.label}
                                                isDataStrArray
                                                defaultValue={filter?.defaultOption?.value}
                                                placeholder={`Select ${filter?.label}`}
                                                onChange={async (e: string | string[]) => {
                                                    let fmap = filterMap;
                                                    let filterValue = filter?.multiSelect ? e as string[] : [e as string];
                                                    fmap?.set(filter?.label, filterValue);
                                                    setFilterMap(fmap);
                                                    storeFilterValues(fmap);
                                                    await getData(fmap);
                                                }}
                                            />
                                        )
                                    })
                                }
                                <BEMultipleSelect
                                    data={chartOptions}
                                    labelFeild='label'
                                    valueFeild='value'
                                    noForm
                                    placeholder='Select Chart Type'
                                    single
                                    isDataStrArray
                                    defaultValue={chartType}
                                    onChange={(e: string) => setChartType(e)}
                                />
                            </div>
                        </Row>
                        <Row className='subject-values'>
                            {
                                titleList?.length > 0 &&
                                <BESubjectTagList
                                    titleList={titleList}
                                    setTitleList={handleSubjectList}
                                />
                            }
                        </Row>
                        <Row className='chart-container'>
                            {titleList?.length > 0 ?
                                chartType === 'bar' ?
                                    <BEFilterableBarGraph
                                        data={chartData}
                                    />
                                    : chartType === 'line' ?
                                        <BEFilterableLineChart
                                            data={chartData}
                                        />
                                        : chartType === 'area' ?
                                            <BEFilterableAreaChart
                                                data={chartData}
                                            />
                                            : null
                                : <ZeroStateChart subject={props.subjectFilter?.label} />
                            }
                        </Row>
                    </>
            }
        </div>
    );
}
