import * as React from 'react';
import { ColumnsType } from 'antd/es/table';
import { Select } from 'antd';
import { BETable } from '../../../../../Components/BETable';

interface ResultsPriorityTableProps {
  topics: Array<any>;
  answers: Array<any>;
  data: Array<any>;
  loading: boolean;
}
export interface ResultsPriorityTableTypes {
  topic_name: string;
  topic_id: number;
  stakeholder_priority: number;
  business_priority: number;
}

export function ResultsPriorityTable (props: ResultsPriorityTableProps) {
  const [data, setData] = React.useState<any>([]);
  const [currentCatagory, setCurrentCatagory] = React.useState<string>("all");
  const [catagories, setCatagories] = React.useState<any[]>([]);
  const columns: ColumnsType<ResultsPriorityTableTypes> = [
    {
      title: "Topic Name",
      dataIndex: "topic_name",
    },
    {
      title: "Stakeholder Priority",
      dataIndex: "stakeholder_priority",
      sorter: (a, b) => a.stakeholder_priority - b.stakeholder_priority,
      render: (text, record) => (
        text===0?<span>No Response</span>
        :<span>{text}</span>
      )
        

    },
    {
      title: "Business Priority",
      dataIndex: "business_priority",
      sorter: (a, b) => a.business_priority - b.business_priority,
    },
    {
      title:currentCatagory,
      dataIndex: currentCatagory,
    }
  ];
  React.useEffect(() => {
    let stakeholdersPriorityArrey: any[] = [];
    let topicsMap: any = {};
    let allTopicsWeight: any = {};

    for (let element of props.topics) {
      topicsMap[element.id] = element["topic"];
      allTopicsWeight[element.id] = 0;
    }

    for (let element of props.answers) {
      if(currentCatagory===element.category || currentCatagory==="all"){
        allTopicsWeight[element.topic] =
        allTopicsWeight[element.topic] +
        props.topics.length -
        element.priority +
        1;
      }
    }

    let weightedTopics: Array<{ topic: string; weight: any }> = [];
    for (const [key, value] of Object.entries(allTopicsWeight)) {
      weightedTopics.push({ topic: key, weight: value });
    }
    weightedTopics.sort((a, b) => (a.weight < b.weight ? 1 : -1));
    for (let element of weightedTopics) {
      stakeholdersPriorityArrey.push(element.topic);
    }
    let resultData: any = [];
  console.log('propdata',props?.data);
    props?.data.forEach((element) => {
      let sid = Number(element.topic_id);
      let obj:any = {
        ...element,
        [currentCatagory]:stakeholdersPriorityArrey.indexOf(String(sid)) + 1,
      };
      resultData.push(obj);
    });
    console.log('ress',resultData);
    setData(resultData);
  }, [props.topics, props.answers, currentCatagory,props?.data]);

  React.useEffect(() => {
    let tempCatagories: any[] = [];
    console.log(props.answers);
    props.answers.forEach((element) => {
      if (!tempCatagories.includes(element.category))
        tempCatagories.push(element.category);
    }
    );
    console.log(tempCatagories);
    setCatagories(tempCatagories);
  }, [props.answers]);

  const tableColumns: ColumnsType<ResultsPriorityTableTypes> | undefined =
    columns.map((item, index) => ({
      ...item,
    }));
  return (
    <div style={{padding:'1rem'}}>
      <div style={{display:'flex',alignItems:'center',gap:'0.5rem',marginBottom:'1rem'}}>
          <p style={{margin:'0'}}>View by category</p>
          <Select
          size='large'
            value={currentCatagory}
            placeholder="Filter by"
            style={{ width: '10rem' }}
            onChange={(value:any) =>  setCurrentCatagory(value)}
          >
            <Select.Option
            value="all">All</Select.Option>
            {catagories?.map((e, i) => (
              <Select.Option value={e} key={i}>
                {e}
              </Select.Option>
            ))}
          </Select>
            
        </div>
          <BETable
            columns={tableColumns}
            data={data}
            loading={props.loading}
            rowKey="topic_id"
            pagination
            
            />
    </div>
  );
}
