import * as React from 'react';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { NewBETable } from '../../../../../../Components/BETable/newBETable';
import { GlobalLoader } from '../../../../../../Components/GlobalLoader';
import { VaultFolder } from '../../../../Components/VaultFolder';
import { navigateTo } from '../../../../../../Navigation/NavigationManager';
import { ColumnsType } from 'antd/es/table';
import { useParams } from 'react-router-dom';

export interface ISupplierFoldersProps {
}

export function AllSuppliersFolders (props: ISupplierFoldersProps) {
  const supplierEvidence = useTypedSelector(state => state.vault.supplierEvidence);
  const view = useTypedSelector(state => state.vault.view);
  const assessment = useParams<any>().fy_id;

  const columns:ColumnsType<any>=[
    {
      title: 'S. No.',
      dataIndex: 'sno',
      key: 'sno',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render:(text,record)=> {
        return <span onClick={()=>
           navigateTo(`/home/settings/vault/-1/${assessment} ${record.supplier}`
        )} 
        style={{color:'#1890ff',textDecoration:'underline',cursor:'pointer'}}>{text}</span>}
    }
  ]

  return (
    <div className='system-folder-container'>
    {supplierEvidence.status !== 'loading' ?
      view=='grid' ? <div style={{ display: 'flex', gap: '1rem' }} >
      {

        Array.from( new Set(supplierEvidence.data.filter((item:any)=>item.assessment===assessment)
          .map((item:any)=>item.supplier)))
        .map((item:any)=> {
          return <VaultFolder
            onClick={() => navigateTo(`/home/settings/vault/-1/${assessment}/${item}`)}
            name={item}
          />
        })

      }
    </div>
    : <div>
      <NewBETable
        pagination
        columns={columns}
        data={
          Array.from( new Set(supplierEvidence.data.filter((item:any)=>item.assessment===assessment)
            .map((item:any)=>item.supplier)))
              .map((item:any)=> {return {title:item,supplier:item}})
            }
        />
     </div>
    :<GlobalLoader height='20rem' />}
  </div>
  );
}
