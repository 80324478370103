import * as React from 'react';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import {
  deleteGlobalStakeholder,
  getGlobalStakeholders,
  bulkUploadGlobalStakeholder
} from '../../Actions/matirialityActions';
import { AddStakeHolder } from '../Drawers/AddStakeholder';
import { ColumnsType } from 'antd/es/table';
import { BETable } from '../../../../Components/BETable';
import BEConfirmModal from '../../../../Components/BEConfirmModal';
import { BEBulkUploadDrawer } from '../../../../Components/BEDrawer/BEBulkUploadDrawer';

export interface IAllStakeholdersProps {
}

export function AllStakeholders(props: IAllStakeholdersProps) {
  const dispatch = useAppDispatch()
  const allStakeholders = useTypedSelector(state => state.matiriality.globalStakeholders)
  const selectedRow = useTypedSelector(state => state.common.selectedRow)
  const [open, setOpen] = React.useState(false)
  const [openBulkUpload, setOpenBulkUpload] = React.useState(false)
  const [openEdit, setOpenEdit] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)
  const [filteredStakeholders, setFilteredStakeholders] = React.useState<any>([])

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Type",
      dataIndex: "internalExternal",
      render: (value, record) => {
        return value === true ? "External" : "Internal";
      },
    },
  ];

  const onDelete = async () => {
    await dispatch(deleteGlobalStakeholder(selectedRow?.id))
  }
  React.useEffect(() => {
    if (allStakeholders?.status === 'success') setFilteredStakeholders(allStakeholders?.data)
  }, [allStakeholders])

  React.useEffect(() => {
    if (allStakeholders?.status === 'idle') {
      dispatch(getGlobalStakeholders())
    }
  }, [allStakeholders])
  return <div style={{ padding: "1rem 0" }}>
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem', gap: '1rem' }}>
      <BEInput style={{ width: '313px' }} search placeholder='Search for a stakeholder' size="large" onChange={(e) => {
        const filtered = allStakeholders?.data.filter((stk) => stk.name?.toLowerCase().includes(e.target.value?.toLowerCase()))
        setFilteredStakeholders(filtered)
      }} />
      <BEButton className='primary' style={{ marginLeft: 'auto' }} size="large"
        onClick={() => {
          setOpenBulkUpload(true);
        }}
      >Bulk Upload</BEButton>
      <BEButton className='primary' size="large"
        onClick={() => {
          setOpen(true);
        }}
      >+ Add Stakeholder</BEButton>

    </div>
    <AddStakeHolder open={open} setOpen={setOpen} isGlobal />
    <AddStakeHolder open={openEdit} setOpen={setOpenEdit} isGlobal isEdit />
    <BEConfirmModal visible={openDelete} setVisible={setOpenDelete} onConfirm={onDelete} title='Confirm Delete' message='Are you sure you want to delete the stakeholder?' />

    <BETable data={filteredStakeholders} columns={columns} loading={allStakeholders?.status === 'loading'} handleDelete={() => { setOpenDelete(true) }} handleEdit={() => {
      setOpenEdit(true);
    }} />
    <BEBulkUploadDrawer
      fileKey='STAKEHOLDERS'
      fileName='StakeholdersTemplate'
      open={openBulkUpload}
      setOpen={setOpenBulkUpload}
      heading='Bulk Upload Stakeholders'
      columnArray={['name', 'email', 'role', 'category', 'internalExternal']}
      uploadFunction={bulkUploadGlobalStakeholder}
    />
  </div>;
}
