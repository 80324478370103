import * as React from 'react';
import './style.scss';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import { getLogs } from '../Actions';
import { ColumnsType } from 'antd/es/table';
import { LogsType } from '../../../Redux/Types/settingsTypes';
import { NewBETable } from '../../../Components/BETable/newBETable';
import { ChangeDateFormat, getFYByDate, showFYFormatByYear } from '../../../Config/Functions/UsefullFunctions';
import dayjs from 'dayjs';
import { ViewTab } from '../../../Components/BETabs/ViewTab';
import { BEButton } from '../../../Components/BEFormItems/BEButton';
import exportToCSV from '../../../Utils/Download/Excell/ExportExcell';
import { TopSection } from '../Components/TopSection';
import SettingsBackButton from '../Components/BackButton';

interface ILogsProps {
}

export function Logs(props: ILogsProps) {
  const dispatch = useAppDispatch();
  const logs = useTypedSelector(state => state.settings.logs);
  const users = useTypedSelector(state => state.userMgmt);
  const yearEnd = useTypedSelector(state => state.companies.companyDetails?.data?.reporting_year_end);
  const companyDetails = useTypedSelector(state => state.companies.companyDetails);

  const columns: ColumnsType<LogsType> = [
    {
      title: 'Section',
      dataIndex: 'section',
      key: 'section',
      filters: Array.from(new Set(logs?.data.map(log => log.section))).map(section => ({ text: section, value: section })),
      onFilter: (value, record) => record.section === value,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text) => text.toUpperCase()
    },
    {
      title: 'Summary',
      dataIndex: 'message',
      key: 'message',
      width: '300px'
    },
    {
      title: 'Timestamp',
      key: 'timestamp',
      render: (record) => {
        return dayjs(record.timestamp).format('DD/MM/YYYY hh:mm A');
      },
      sorter: (a, b) => dayjs(a.timestamp).isAfter(dayjs(b.timestamp)) ? 1 : -1,
    },
    {
      title: 'Financial Year',
      key: 'month_of_entry',
      render: (record) => {
        return showFYFormatByYear(getFYByDate(record.month_of_entry, yearEnd || ''), yearEnd || '');
      },
      filters: Array.from(new Set(logs?.data.map(log => getFYByDate(log.month_of_entry, yearEnd || '')))).map(fy => ({ text: showFYFormatByYear(fy, yearEnd || ''), value: fy })),
      onFilter: (value, record) => getFYByDate(record.month_of_entry, yearEnd || '') === value,
    },
    {
      title: 'User',
      key: 'user',
      render: (record) => {
        const user = users?.data?.find(user => user.id === record.user);
        return user?.first_name + ' ' + user?.last_name;
      },
      filters: Array.from(new Set(logs?.data.map(log => log.user))).map(user => {
        const userObj = users?.data?.find(u => u.id === user);
        return { text: userObj?.first_name + ' ' + userObj?.last_name, value: userObj?.id }
      }),
      onFilter: (value, record) => record.user === value
    },
    {
      title: 'IP Address',
      key: 'ip',
      dataIndex: 'ip'
    }
  ]

  React.useEffect(() => {
    dispatch(getLogs())
  }, []);

  return (
    <>
      <TopSection />
      <div className='logs'>
        <SettingsBackButton  title='Back'/>
        <BEButton
          allowAuditorClick
          style={{
            float: 'right',
            marginBottom: '10px'
          }}
          className='primary'
          onClick={() => exportToCSV(
            logs?.data.map((item, index) => {
              const user = users?.data?.find(user => user.id === item.user);
              return {
                "S.No.": index + 1,
                "User": user?.first_name + ' ' + user?.last_name,
                "Action": item.action?.toUpperCase(),
                "Summary": item?.message,
                "Time Stamp": dayjs(item.timestamp).format('DD/MM/YYYY hh:mm A'),
                "Financial Year": showFYFormatByYear(getFYByDate(item?.month_of_entry, yearEnd || ''), yearEnd || ''),
                "Section": item?.section,
              }
            })
            , companyDetails?.data?.firm_name + "_Audit_Logs")}
        >
          Download
        </BEButton>
        <NewBETable
          columns={columns}
          data={[...logs?.data].sort((a, b) => dayjs(a.timestamp).isAfter(dayjs(b.timestamp)) ? -1 : 1)}
          loading={logs?.status === 'loading'}
          pagination
        />
      </div>
    </>
  );
}
