import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';

export interface IAssessmentInfoProps {
    open: boolean;
    setOpen: Function;
}

export function AssessmentInfo(props: IAssessmentInfoProps) {
    return (
        <BEDrawer
            width='35vw'
            open={props.open}
            setOpen={props.setOpen}
            title="GRESB Assessment Information"
            heading='GRESB Assessment Information'
            footer={
                <div>
                    <BEButton
                        size='large'
                        className='primary'
                        onClick={() => props.setOpen(false)}
                    >
                        Close
                    </BEButton>
                </div>
            }
        >
        <div style={{padding: '1rem', fontSize:"0.8rem"}}>
            <p>The Real Estate Assessment is structured in three components namely 1.Management, 2.Performance 3.Development.</p>
            <p>
                Each of the above Component is divided into Aspects that comprise of individually scored indicators.
                Participants who complete the Performance and Management components receive a <b>GRESB Standing Investments Benchmark Report</b>,
                while participants who choose to complete the Management and Development components receive a <b>GRESB Development Benchmark Report</b>.
                Those who complete all three components, receive both reports.
            </p>
            <p>The possible combinations are set out below.</p>
            <ul>
                <li>Portfolios with only standing investments submit: Management and Performance components.</li>
                <li>Portfolios with only development projects submit: Management and Development components.</li>
                <li>Portfolios with both standing investments and development projects submit: Management, Performance, and  Development components.</li>
            </ul>
            <p>You can change your selection at any point before submitting your Assessment.</p>
            </div>
        </BEDrawer>
    );
}
