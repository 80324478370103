import { Navigate, useParams } from 'react-router-dom';
import { FYFolders } from '../../Views/AllFolders/SystemFolders/FYFolders';
import { AllSuppliersFolders } from '../../Views/AllFolders/SupplierFolders/AllSuppliers';

export interface IFolderContentProps {
}

export function FolderContent2 (props: IFolderContentProps) {
  const params = useParams();
  return (
    <div>
        {
          Number(params.folder_id)=== 0?
            <FYFolders/> :
          Number(params.folder_id)=== -1 ? 
            <AllSuppliersFolders/>:
            <Navigate to='/home/settings/vault'/>
        }
    </div>
  );
}
