import * as React from 'react';
import { TopTabsTargets } from '../../Components/TopTabs';

export interface IMultiYearTargetsProps {
}

export function MultiYearTargets (props: IMultiYearTargetsProps) {
  return (
    <div>
        {/* <TopTabsTargets type='multi' /> */}
        <TopTabsTargets />
    </div>
  );
}
