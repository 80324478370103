import { useParams } from 'react-router-dom';
import { SystemFolder } from '../../Views/AllFolders/SystemFolders';
import { UsersFolder } from '../../Views/AllFolders/UsersFolder';
import { SupplierFolders } from '../../Views/AllFolders/SupplierFolders';

export interface IFolderContentProps {
}

export function FolderContent (props: IFolderContentProps) {
  const params = useParams();
  return (
    <div>
        {
          Number(params.folder_id)=== 0?
            <SystemFolder/> :
          Number(params.folder_id)=== -1 ? 
            <SupplierFolders/>
            : 
            <UsersFolder/>
        }
    </div>
  );
}
