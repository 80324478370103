import React, { useEffect } from "react";
import { Form, Select, Space } from "antd";
import './style.scss';

interface MultipleSelectProps {
  data: any[];
  children?: React.ReactNode;
  onChange?: Function;
  name?: string;
  rules?: any[];
  placeholder?: string;
  single?: boolean;
  minWidth?: string;
  loading?: boolean;
  valueFeild?: string;    //if data is array of objects pass the valueFeild and labelFeild
  labelFeild?: string;
  modifySelectedLabelClass?: boolean;
  form?: any;
  isDataStrArray?: boolean; // if data is array of strings pass this prop
  disabled?: boolean
  defaultValue?: any;
  maxTagCount?: number;
  maxTagPlaceholder?: any;
  value?: any;
  noForm?: boolean;
}

const BEMultipleSelect = (props: MultipleSelectProps) => {

  if (props?.valueFeild && props?.labelFeild && props?.noForm) return (
    <Select
      className={props?.modifySelectedLabelClass ? 'be-multiple-select' : ''}
      onChange={(e) => {
        props?.form?.setFieldValue(props.name, e)
        props.onChange && props.onChange(e)
      }}
      loading={props.loading}
      size="large"
      mode={props.single ? undefined : "multiple"}
      style={{
        width: "100%",
        minWidth: props.minWidth || "150px"
      }}
      allowClear
      value={props.value}
      showSearch
      popupMatchSelectWidth={false}
      optionFilterProp="children"
      placeholder={props.placeholder}
      disabled={props.disabled}
      defaultValue={props?.defaultValue}
      maxTagCount={props.maxTagCount}
      maxTagPlaceholder={props.maxTagPlaceholder}
    >
      {props?.data?.map((industry, index) => {
        return (
          <Select.Option
            key={index}
            value={industry[props?.valueFeild || '']}
          >
            {industry[props?.labelFeild || '']}
          </Select.Option>
        );
      })}
    </Select>
  )

  if (props.valueFeild && props.labelFeild) return (
    <Form.Item name={props.name} rules={props.rules}>
      <Select
        className={props?.modifySelectedLabelClass ? 'be-multiple-select' : ''}
        onChange={(e) => {
          props?.form?.setFieldValue(props.name, e)
          props.onChange && props.onChange(e)
        }}
        loading={props.loading}
        size="large"
        mode={props.single ? undefined : "multiple"}
        style={{
          width: "100%",
          minWidth: props.minWidth || "150px"
        }}
        allowClear
        value={props.value}
        showSearch
        optionFilterProp="children"
        placeholder={props.placeholder}
        disabled={props.disabled}
        defaultValue={props?.defaultValue}
        maxTagCount={props.maxTagCount}
        maxTagPlaceholder={props.maxTagPlaceholder}
      >
        {props?.data?.map((industry, index) => {
          return (
            <Select.Option
              key={index}
              value={industry[props?.valueFeild || '']}
            >
              {industry[props?.labelFeild || '']}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  )
  return (
    <Form.Item name={props.name} rules={props.rules}>
      <Select
        onChange={(e) => {
          props?.form?.setFieldValue(props.name, e)
          props.onChange && props.onChange(e)
        }}
        className={props?.modifySelectedLabelClass ? 'be-multiple-select' : ''}
        loading={props.loading}
        size="large"
        mode={props.single ? undefined : "multiple"}
        allowClear
        style={{ width: "100%" }}
        showSearch
        placeholder={props.placeholder}
        disabled={props.disabled}
        defaultValue={props?.defaultValue}
        maxTagCount={props.maxTagCount}
        maxTagPlaceholder={props.maxTagPlaceholder}
      >
        {
          props.isDataStrArray ?
            props?.data.map((industry, index) => {
              return (
                <Select.Option key={index} value={industry}>
                  {industry}
                </Select.Option>
              );
            })
            :

            props?.data.map((industry) => {
              return (
                <Select.Option value={industry.title}>
                  <Space>{industry.title} </Space>
                </Select.Option>
              );
            })
        }
      </Select>
    </Form.Item>
  );
};

export default BEMultipleSelect;
