

import React from 'react'
import IconProps from './Interface/IconProps'

const RegulatoryComplianceIcon = (props: IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      fill="none"
	  viewBox="0 0 22 23"
	  xmlns="http://www.w3.org/2000/svg">
<path d="M3 16V9H5V16H3ZM9 16V9H11V16H9ZM0 7V5L10 0L20 5V7H0ZM4.475 5H15.525L10 2.25L4.475 5ZM0 20V18H12.05C12.0833 18.35 12.125 18.6875 12.175 19.0125C12.225 19.3375 12.3 19.6667 12.4 20H0ZM15 12.25V9H17V11.25L15 12.25ZM18 23C16.85 22.7167 15.8958 22.0542 15.1375 21.0125C14.3792 19.9708 14 18.8167 14 17.55V15L18 13L22 15V17.55C22 18.8167 21.6208 19.9708 20.8625 21.0125C20.1042 22.0542 19.15 22.7167 18 23ZM17.275 20L20.75 16.55L19.7 15.5L17.275 17.875L16.3 16.9L15.25 17.975L17.275 20Z"
  fill={props.stroke ? props.stroke : "white"}/>
</svg>

  );
}

export default RegulatoryComplianceIcon