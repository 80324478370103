import React, { useState } from 'react'
import { BETable } from '../../../../../Components/BETable'
import { ColumnsType } from 'antd/es/table'
import EditIcon from '../../../../../Components/BEIcons/EditIcon';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import EditConversionFactors from '../EditConversionFactors';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { setCurrentScope1Configuration } from '../../../../../Redux/EmmisionsReducer';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import ZoomInIcon from '../../../../../Components/BEIcons/ZoomInIcon';
import { ConfigurationsType } from '../../../../../Redux/Types/emisionsTypes';

const Scope1Table = () => {
  const dispatch = useAppDispatch()
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const scope1Configurations = useTypedSelector(state => state.emissions.configurations.scope1Configurations)
  const [open, setOpen] = useState(false);
  const role = useTypedSelector((state) => state.user.userRole);
  const columns: ColumnsType<ConfigurationsType> = [
    {
      title: "Sr. No.",
      dataIndex: "",
      render: (value, record, ind) => {
        return ind + 1;
      },
      width: "100px",
    },
    {
      title: "Metric",
      dataIndex: "metric",
      filters: Array.from(new Set(scope1Configurations?.data.map((item) => item.metric))).map((metric) => ({ text: metric, value: metric })),
      onFilter: (value, record) => record.metric === value,
    },
    {
      title: "Unit",
      dataIndex: "unit",
    },
    {
      title: "Source",
      dataIndex: "config_type",
      onFilter: (value, record) => record.config_type === value,
      filters: [
        {
          text: "Custom",
          value: 1,
        },
        {
          text: "Custom (Direct Conversion)",
          value: 2,
        },
        {
          text: "DEFRA (Default)",
          value: 3,
        },
        {
          text: "IPCC (Default)",
          value: 4,
        },
      ],
      render: (value, record) => {
        return value === 1
          ? "Custom" :
          value === 2 ? "Custom (Direct Conversion)"
            : record.emission_category === "Fugitive emissions"
              ? "DEFRA (Default)"
              : "IPCC (Default)";
      }
    },
    {
      title: "Conversion Factors",
      dataIndex: "metric",
      render: (value, record) => {
        return (
          <div
            style={{ height: "1rem", cursor: "pointer" }}
            onMouseEnter={() => {
              dispatch(setCurrentScope1Configuration(record));
            }}
            onClick={() => setOpen(true)}
          >
            {
              role !== 'SUPER_ADMIN' && role !== 'ESG_CONSULTANT' ? <ZoomInIcon inheritSize fill={PrimaryTheme.primaryGray} /> :
                <EditIcon inheritSize stroke={PrimaryTheme.primaryGray} />
            }
          </div>
        );
      },
      width: "200px",
    },
  ];

  return (
    <div style={{ margin: '1rem 0' }}>
      <EditConversionFactors open={open} setOpen={setOpen} isScope1 />

      <BETable
        pagination
        loading={scope1Configurations?.status === 'loading'}
        data={
          scope1Configurations?.data.filter((item) => item?.year === currentYear)
        } columns={columns} />
    </div>
  )
}

export default Scope1Table