import * as React from 'react';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { VaultFolder } from '../../../Components/VaultFolder';
import dayjs from 'dayjs';
import { navigateTo } from '../../../../../Navigation/NavigationManager';
import { ReportingReportType } from '../../../../../Redux/Types/reportingTypes';
import { getFYByDate } from '../../../../../Config/Functions/UsefullFunctions';
import '../index.scss'
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import { NewBETable } from '../../../../../Components/BETable/newBETable';
import { ColumnsType } from 'antd/es/table';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';


export interface ISystemFolderProps {
}

export function SystemFolder(props: ISystemFolderProps) {
  const dispatch = useAppDispatch();
  const yearEnd = useTypedSelector(state => state.companies.yearEnd);
  const FYdata = useTypedSelector(state => state.vault.FYData);
  const reports = useTypedSelector(state => state.reporting.allReports);
  const [loader, setLoader] = React.useState(false);
  const view=useTypedSelector(state=>state.vault.view)
  const [yearsWithData, setYearsWithData,] = React.useState<any>([]);
  const reportingYearEnd = useTypedSelector(state => state.companies.companyDetails?.data?.reporting_year_end);
  React.useEffect(() => {
    setLoader(true);
    if (FYdata?.status === 'success' && reports?.status === 'success') {
      setLoader(false);
      setYearsWithDataFun()
    }
  }, [reports, FYdata]);

  const systemFolderColumns : ColumnsType<any>=[
    {
      title: 'S. No.',
      dataIndex: 'sno',
      key: 'sno',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',

      render:(text,record)=> {
        console.log(record)
      return <span onClick={()=> navigateTo(`/home/settings/vault/0/${record.year}`)} style={{color:PrimaryTheme.primaryBlueInfo,textDecoration:'underline',cursor:'pointer'}}>{text}</span>}
    },
  ]
  const setYearsWithDataFun = async () => {
    let tempYearsWithData: any = [];
    Object.keys(FYdata?.data).map((item: any) => {
      tempYearsWithData.push((Number(item)));
    })

    reports?.data.map(async(data: ReportingReportType) => {
      let yearOfReport = getFYByDate(data.from_date,reportingYearEnd || '12-31')
      if (!tempYearsWithData.includes(yearOfReport) && data.stage >= 3) {
        tempYearsWithData.push(yearOfReport);
      }
    })
    setYearsWithData(tempYearsWithData);
  }

  return (
    <div className='system-folder-container'>
      {!loader ?
        view=='grid' ? <div style={{ display: 'flex', gap: '1rem' }} >
        {
          yearsWithData.map((item: any) => {
            return <VaultFolder
              onClick={() => navigateTo(`/home/settings/vault/0/${item}`)}
              name={
                yearEnd === 12 ? `FY ${item}` : `FY ${Number(item)} - ${(Number(item) + 1) % 100}`
              }
            />
          })
        }
      </div>
      : <div>
        <NewBETable columns={systemFolderColumns} data={yearsWithData.map((item:any)=> {return {title:yearEnd === 12 ? `FY ${item}` : `FY ${Number(item)} - ${(Number(item) + 1) % 100}`,year:item}})} />
      </div>
      :<GlobalLoader height='20rem' />}
    </div>
  );
}
