import {
    Reducer,
    createSlice,
} from "@reduxjs/toolkit";
import { clearStore } from "../ExtraReducrActions";
import { TargetType } from "../Types/targetTypes";


const initialState: TargetType = {
    MyMetricTargets: {
        status: 'idle',
        data: []
    },
    MyDerivedMetricTargets: {
        status: 'idle',
        data: []
    },
    yearSpecificTargetValues: []
}
export const targetsSlice = createSlice({
    name: "targets",
    initialState,
    extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
    reducers: {
        setMyMetricTargets: (state, action) => {
            state.MyMetricTargets = action.payload;
        },
        setMyDerivedMetricTargets: (state, action) => {
            state.MyDerivedMetricTargets = action.payload;
        },
        setYearSpecificTargetValues: (state, action) => {
            state.yearSpecificTargetValues = action.payload;
        }
    }
});

export const {
    setMyMetricTargets,
    setMyDerivedMetricTargets,
    setYearSpecificTargetValues
} = targetsSlice.actions;

const targetReducer: Reducer<TargetType> = targetsSlice.reducer;

export default targetReducer;
