import * as React from 'react';
import { NewBETable } from '../../../Components/BETable/newBETable';
import { ColumnsType } from 'antd/es/table';
import ExportExcelIcon from '../../../Components/BEIcons/ExportIcons/ExportExcelIcon';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { PlatformDataDownload } from '../Actions';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import './style.scss';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { SetTableAndGraphDataDM } from '../../DataManager/Components/SetTableAndGraphDataFunctionDM';
import { getAllStakeholdersForAssessment, getGlobalStakeholders, getMaterialitySurveys } from '../../Materiality/Actions/matirialityActions';
import { getSupplierSurveys, getSuppliers } from '../../Suppliers/Actions/supplierAction';
import { TopSection } from '../Components/TopSection';
import SettingsBackButton from '../Components/BackButton';

export interface IDataDownloadProps {
}

export function DataDownload(props: IDataDownloadProps) {
    const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    const allSurveys = useTypedSelector(state => state.matiriality.AllSurveys);
    const allStakeHolders = useTypedSelector(state => state.matiriality.globalStakeholders);
    const supllierAssessments = useTypedSelector(state => state.supplier.SuppliersSurveys);
    const Suppliers = useTypedSelector(state => state.supplier.Suppliers);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (allSurveys?.status === "idle") {
            dispatch(getMaterialitySurveys());
        }
        if (allStakeHolders?.status === "idle") {
            dispatch(getGlobalStakeholders());
        }
        if (supllierAssessments?.status === "idle") {
            dispatch(getSupplierSurveys());
        }
        if (Suppliers?.status === "idle") {
            dispatch(getSuppliers());
        }
    }, []);

    const columns: ColumnsType<any> = [
        {
            title: "S.No",
            dataIndex: "sno.",
            render: (value, record, index) => index + 1,

        },
        {
            title: "Section",
            dataIndex: "section_name",
            key: "section_name",
        },
        {
            title: "Download",
            dataIndex: "download",
            key: "download",
            render: (value, record) =>
                <div style={{ cursor: "pointer" }} onClick={async () => {
                    dispatch(PlatformDataDownload(record.key, currentYear));
                }}
                >
                    <ExportExcelIcon fill={PrimaryTheme.primaryGreen} />
                </div>

        }
    ];
    const data = [
        {
            key: 1,
            section_name: "Data Manager",
            download: "Download"
        },
        /*{
            key: 3,
            section_name: "Entity Manager(Business groups)",
            download: "Download"
        },
        {
            key: 4,
            section_name: "Entity Manager(Business Units)",
            download: "Download"
        },
        {
            key: 7,
            section_name: "Reporting",
            download: "Download"
        },
        {
            key: 8,
            section_name: "Materiality",
            download: "Download"
        },
        {
            key: 9,
            section_name: "Stakeholders",
            download: "Download"
        },
        {
            key: 10,
            section_name: "Supplier Assessment",
            download: "Download"
        },
        {
            key: 11,
            section_name: "Suppliers",
            download: "Download"
        }*/
    ]

    return (
        <>
            <TopSection />
            <div className='data-download'>
                <SettingsBackButton title='Back' style={{ margin: '1.5rem 0' }} />
                <NewBETable
                    data={data}
                    columns={columns}
                    search
                />

                <SetTableAndGraphDataDM />

            </div>
        </>
    );
}

