import API from "../../API";

export const GET_MY_METRIC_TARGETS = async () => {
    return await API.GET(`/cleopatra/my-metric-targets/`);
};

export const POST_MY_METRIC_TARGET = async (data: any) => {
    return await API.POST(`/cleopatra/my-metric-targets/`, data);
};

export const PATCH_MY_METRIC_TARGET = async (id: number, data: any) => {
    return await API.PATCH(`/cleopatra/my-metric-targets/${id}/`, data);
};

export const DELETE_MY_METRIC_TARGET = async (id: number) => {
    return await API.DELETE(`/cleopatra/my-metric-targets/${id}/`);
}

export const GET_MY_DERIVED_METRIC_TARGETS = async () => {
    return await API.GET(`/cleopatra/my-derived-metric-targets/`);
}

export const POST_MY_DERIVED_METRIC_TARGET = async (data: any) => {
    return await API.POST(`/cleopatra/my-derived-metric-targets/`, data);
}

export const PATCH_MY_DERIVED_METRIC_TARGET = async (id: number, data: any) => {
    return await API.PATCH(`/cleopatra/my-derived-metric-targets/${id}/`, data);
}

export const DELETE_MY_DERIVED_METRIC_TARGET = async (id: number) => {
    return await API.DELETE(`/cleopatra/my-derived-metric-targets/${id}/`);
}
