
import { Outlet } from 'react-router-dom';
import { TopSectionTargets } from '../TopSection';

export interface ITargetsLayoutProps {
}

export function TargetsLayout (props: ITargetsLayoutProps) {
  return (
    <div style={{padding: "0"}}>
      <TopSectionTargets/>
      <Outlet/>
    </div>
  );
}
