import React from 'react'
import ActivityActions from "./ActivityActions";
import {Chat} from "./Chats";
import './styles.scss'
import { Card } from 'antd';


interface Props {
	actions: any[]
	chats: any[]
	height?: any;
	uniqueCode: string;
	
}

const BEChat = (props: Props) => {

  return <Card> 
	<div className="activity" style={{height:props.height || '600px'}}>
		<ActivityActions activityActions={props.actions}/>
		<Chat 
			chats={props.chats} 
			uniqueCode={props.uniqueCode} 
		/>
	</div>
	</Card>
}

export default BEChat