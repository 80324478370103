import * as React from 'react';
import { BEButton } from '../../../Components/BEFormItems/BEButton';
import CompanyLogo from "../../../assets/images/Company/Logo_horizontal.png";
import { useNavigate } from 'react-router';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { useLocation } from 'react-router-dom';
import { onGoogleLoginFailed } from '../../Actions/LoginActions';
import { Col, Form, Row, Select } from 'antd';
import { BEInput } from '../../../Components/BEFormItems/BEInput';
import { LableRequired } from '../../../Components/BEFormItems/LableRequired';
import { NoPermissionForm } from './NoPermissionForm';
import { RequestSubmitted } from './RequestSubmitted';
import { relative } from 'path';


export interface INoPermissionProps {
    is404?: boolean;
}

export function NoPermission(props: INoPermissionProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const [msg, setMsg] = React.useState('');
    const [isRequestSubmitted,setIsRequestSubmitted]=React.useState<boolean>(false)

    React.useEffect(() => {
        const msg = onGoogleLoginFailed(location);
        setMsg(msg);
    }, []);

    return (
      <div  style={{height:'100vh',width:'100vw',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:PrimaryTheme.primaryGreenDark,position:'relative'}}>
        <Col style={{backgroundColor:PrimaryTheme.primaryGreyLight, gap:4,display:'flex',flexDirection:'column', padding:'1rem', borderRadius:'7px',width:'590px'}}>
            <Row style={{width:'100%',position: isRequestSubmitted ?'absolute' :'static'}}>
             <div style={{ display: "flex", padding:'1rem 0 1rem'}}>
                    <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                        <div
                            style={{ display: "flex", justifyContent: "space-around" }}
                            className="logo"
                            onClick={() => navigate("/home/dashboard")}
                         >
                            <img
                                src={CompanyLogo}
                                height={"22px"}
                            />{" "}
                    </div>
                </div>
                </div>
            </Row>
           {!isRequestSubmitted ? <p style={{padding:'1rem 1rem 1rem 0 ', lineHeight:'21px', color:PrimaryTheme.primaryBlack}}>Hi there, welcome to Breathe ESG, looks like you don’t have access to the Breathe platform , please <br /> use the form below to create an account with us.</p> :null} 
            <Row>
                {!isRequestSubmitted ? <NoPermissionForm setIsRequestSubmitted={setIsRequestSubmitted} />: <RequestSubmitted/>}  
            </Row>
        </Col>
      </div>

    );

}
