

import React from 'react'
import IconProps from './Interface/IconProps'

const StakeHolderCommunicationIcon = (props: IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 7L7.5 3.5L11 0L14.5 3.5L11 7ZM0 18V14C0 13.4333 0.195833 12.9583 0.5875 12.575C0.979167 12.1917 1.45 12 2 12H5.275C5.60833 12 5.925 12.0833 6.225 12.25C6.525 12.4167 6.76667 12.6417 6.95 12.925C7.43333 13.575 8.02917 14.0833 8.7375 14.45C9.44583 14.8167 10.2 15 11 15C11.8167 15 12.5792 14.8167 13.2875 14.45C13.9958 14.0833 14.5833 13.575 15.05 12.925C15.2667 12.6417 15.5208 12.4167 15.8125 12.25C16.1042 12.0833 16.4083 12 16.725 12H20C20.5667 12 21.0417 12.1917 21.425 12.575C21.8083 12.9583 22 13.4333 22 14V18H15V15.725C14.4167 16.1417 13.7875 16.4583 13.1125 16.675C12.4375 16.8917 11.7333 17 11 17C10.2833 17 9.58333 16.8875 8.9 16.6625C8.21667 16.4375 7.58333 16.1167 7 15.7V18H0ZM3 11C2.16667 11 1.45833 10.7083 0.875 10.125C0.291667 9.54167 0 8.83333 0 8C0 7.15 0.291667 6.4375 0.875 5.8625C1.45833 5.2875 2.16667 5 3 5C3.85 5 4.5625 5.2875 5.1375 5.8625C5.7125 6.4375 6 7.15 6 8C6 8.83333 5.7125 9.54167 5.1375 10.125C4.5625 10.7083 3.85 11 3 11ZM19 11C18.1667 11 17.4583 10.7083 16.875 10.125C16.2917 9.54167 16 8.83333 16 8C16 7.15 16.2917 6.4375 16.875 5.8625C17.4583 5.2875 18.1667 5 19 5C19.85 5 20.5625 5.2875 21.1375 5.8625C21.7125 6.4375 22 7.15 22 8C22 8.83333 21.7125 9.54167 21.1375 10.125C20.5625 10.7083 19.85 11 19 11Z" 
	fill={props.stroke ? props.stroke : "white"}
/>
</svg>
  );
}

export default StakeHolderCommunicationIcon