
import {
    GET_SUPPLIER_ASSESSMENT_ANSWER_STATUS,
    POST_SUPPLIER_ASSESSMENT_ANSWERS,
    PATCH_SUPPLIER_ASSESSMENT_ANSWER_STATUS,
    POST_SECTION_ANSWERS,
    EDIT_SUPPLIER_ANSWER,
    GET_ANSWERS_FILLED_BY_COSUPPLIERS,
    GET_CO_SUPPLIERS_INFO,
    INVITE_SECTION_SUPPLIER,
    GET_SECTION_QUESTIONS,
    GET_SUPPLIER_SELECTED_QUESTIONS ,
    GET_SUPPLIER_ASSESSMENT_ANSWERS,
    CHANGE_SUPPLIER_STATUS
} from "../../../Utils/Routes/SupplierRoutes";
import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import { navigateTo } from "../../../Navigation/NavigationManager";
import { AppDispatch } from "../../../Redux/store";
import { BEMessage } from "../../../Components/BEMessage";
import {
    setAssessmentStatus,
    setSupplierAnswers,
    setCoSuppliers,
    setSupplierQuestions,
    setSupplierStatus
} from "../../../Redux/SupplierReducer";


export const getSupplierAssessmentAnswers = (token: string) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_SUPPLIER_ASSESSMENT_ANSWERS(token));
    if (data) {
        let dataToSet: any = {};
        data?.data.forEach((item: any) => {
            dataToSet[item.question] = item;
        })
        dispatch(setSupplierAnswers({
            status: "success",
            data: dataToSet,
        }));
    }
    else {
        console.log(error);
        dispatch(setSupplierAnswers({
            status: "error",
            data: [],
        }));
    }
}

export const changeSupplierStatus = (token: string,currentStatus:number) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(CHANGE_SUPPLIER_STATUS(token));
    if (data) {
        if(currentStatus==1) dispatch(setAssessmentStatus(2))
        else {
           dispatch(setAssessmentStatus(3))
            BEMessage.success("Assessment submitted successfully");
        }
    }
    else {
        console.log(error);
    }
}

export const getSupplierSelectedQuestions = (token: string) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_SUPPLIER_SELECTED_QUESTIONS(token));
    if (data) {
        console.log(data);
        dispatch(setSupplierQuestions({
            status: "success",
            data: data?.data,
        }));
        dispatch(setAssessmentStatus(data?.status));
    }
    else {
        console.log(error);
        dispatch(setSupplierQuestions({
            status: "error",
            data: [],
        }));
    }
}


export const getSupplierAssessmentStatus = (assessment: Number, supplier: Number) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_SUPPLIER_ASSESSMENT_ANSWER_STATUS(assessment, supplier));
    if (data) {
        dispatch(setAssessmentStatus(data?.data));
    }
    else {
        console.log(error);
    }
}

export const patchSupplierAssessmentStatus = (body: any, assessment: Number, supplier: Number, token: string) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(PATCH_SUPPLIER_ASSESSMENT_ANSWER_STATUS(body, assessment, supplier, token));
    if (data) {
        dispatch(setAssessmentStatus(true));
        BEMessage.success("Assessment submitted successfully");
    }
    else {
        console.log(error);
    }
}

export const postSupplierAssessmentAnswers = (body: any, token: string) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(POST_SUPPLIER_ASSESSMENT_ANSWERS(body,token));
    if (data) {
        dispatch(getSupplierAssessmentAnswers(token));
        // BEMessage.success("Answers submitted successfully");
    }
    else {
        console.log(error);
    }
}

export const editSupplierAnswer = (body: any, token: string,id:any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(EDIT_SUPPLIER_ANSWER(body, token, id));
    if (data) {
        dispatch(getSupplierAssessmentAnswers(token));
    }
    else {
        console.log(error);
    }
}

export const getSectionQuestions = (token: string) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_SECTION_QUESTIONS(token));
    setSupplierQuestions({
        status: "loading",
        data: [],
    })
    if (data) {
        dispatch(setSupplierQuestions({
            status: "success",
            data: data?.data,
        }));
    }
    else {
        console.log(error);
        dispatch(setSupplierQuestions({
            status: "error",
            data: [],
        }));
    }
}


    export const postSectionAnswers = (body: any, token: string, assessment: Number, supplier: Number) => async (dispatch: AppDispatch) => {
        const [data, error] = await handleAPICall(POST_SECTION_ANSWERS(body, token, assessment, supplier));
        if (data) {
            BEMessage.success("Answers submitted successfully");
        }
        else {
            console.log(error);
        }
    }

    export const getAnswersFilledByCoSuppliers = (token: string, assessment: Number, supplier: Number) => async (dispatch: AppDispatch) => {
        dispatch(setSupplierAnswers({
            status: "loading",
            data: [],
        }))
        const [data, error] = await handleAPICall(GET_ANSWERS_FILLED_BY_COSUPPLIERS(token, assessment, supplier));
        if (data) {
            const dataToSet: any = {};
            data?.data.forEach((item: any) => {
                if (dataToSet?.hasOwnProperty(item.question)) {
                    dataToSet[item.question].push(item);
                }
                else dataToSet[item.question] = [item];
            });
            dispatch(setSupplierAnswers({
                status: "success",
                data: dataToSet,
            }));
        }
        else {
            console.log(error);
            dispatch(setSupplierAnswers({
                status: "error",
                data: [],
            }));
        }
    }

    export const getCoSuppliersInfo = (token: string, assessment: Number, supplier: Number) => async (dispatch: AppDispatch) => {
        dispatch(setCoSuppliers({
            status: "loading",
            data: [],
        }))
        const [data, error] = await handleAPICall(GET_CO_SUPPLIERS_INFO(token, assessment, supplier));
        if (data) {
            dispatch(setCoSuppliers({
                status: "success",
                data: data?.data,
            }));
        }
        else {
            console.log(error);
            dispatch(setCoSuppliers({
                status: "error",
                data: [],
            }));
        }
    }

    

    export const editSectionAnswer = (body: any, id: Number, token: string) => async (dispatch: AppDispatch) => {
        const [data, error] = await handleAPICall(EDIT_SUPPLIER_ANSWER(body, token,Number(id)));
        if (data) {
            BEMessage.success("Answer edited successfully");
        }
        else {
            console.log(error);
        }
    }

    export const InviteCoSuppliers = (body: any, token: string) => async (dispatch: AppDispatch) => {
        const [data, error] = await handleAPICall(INVITE_SECTION_SUPPLIER(body, token));
        if (data) {
            BEMessage.success("Co-supplier invited successfully");
        }
        else {
            console.log(error);
        }
    }

