import * as React from 'react';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { BETabs } from '../../../../../Components/BETabs';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { setCurrentPillar } from '../../../../../Redux/OnboardingReducer';
import { PillerMap } from '../../../../../views/Onboarding/Step4/TopTabsFilter';
import { SIdeFilterDM } from '../SideFilterDM';
import { InnerTabsFilterDM } from '../InnerTabsFilterDM';
import { pillarOrder } from '../../../../../views/Onboarding/Step4/TopTabsFilter';
import { TopTabSearch } from './TopTabSearch';
import { setPillarsDM, setSearchTabMetrics } from '../../../../../Redux/DataManagerReducer';
import { StatusFilter } from './StatusFilter';

export interface ITopTabsDMProps {
}

interface ObjectType {
    [key: string]: string
}

function InnerTabs(props: any) {
    return (
        <div className='inner-tabs'>
            <SIdeFilterDM />
            <InnerTabsFilterDM />
        </div>
    )
}

export function TopTabsDM(props: ITopTabsDMProps) {
    const dispatch = useAppDispatch()
    const activeTab = useTypedSelector(state => state.onBoarding.metrics.currentPillar);
    const combinedSelectedMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.combinedSelectedMetricsPerYear);
    const searchTabMetrics = useTypedSelector(state => state.dataManager.metrics.searchTabMetrics);
    const myDerivedMetrics = useTypedSelector(state => state.onBoarding.metrics.myDerivedMetricsPerYear);
    const myMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.myMetricsPerYear);
    const setActiveTab = (key: string) => {
        dispatch(setCurrentPillar(key))
    }

    React.useEffect(() => {
        if (activeTab === "" || !combinedSelectedMetricsPerYear?.data?.find((item: any) => item.pillars === activeTab)) {
            if (combinedSelectedMetricsPerYear?.data.length > 0) dispatch(setCurrentPillar(combinedSelectedMetricsPerYear?.data[0]?.pillars))
        }
        const pillarsValue = Array.from(new Set(combinedSelectedMetricsPerYear?.data
            .filter((item: any) => item.pillars !== "nan")
            .map((item: any) => item.pillars)))
            .sort((a: any, b: any) => pillarOrder.indexOf(a) - pillarOrder.indexOf(b))
            .reduce((acc, currentValue, index) => {
                acc[PillerMap[currentValue]] = currentValue;
                return acc;
            }, {});
        const pillarsAll = {
            ...pillarsValue,
            "All": "All"
        };
        dispatch(setPillarsDM(pillarsAll));
    }, [combinedSelectedMetricsPerYear, myDerivedMetrics])

    return (
        <div>
            <BETabs
                destroyInactiveTabPane
                className='outer-tabs'
                size='large'
                type='card'
                activeKey={activeTab}
                tabBarGutter={20}
                tabBarStyle={{ marginLeft: 40, marginTop: '2rem' }}
                onChange={(key) => {
                    setActiveTab(key);
                    dispatch(setSearchTabMetrics({
                        tabsValue: searchTabMetrics.tabsValue,
                        data: searchTabMetrics?.data,
                        dropDownMetric: null
                    }))
                }}

                items={
                    [...Array.from(new Set([...myDerivedMetrics?.data, ...myMetricsPerYear?.data].map((item: any) => item.pillars)))
                        // .filter((item: any) => item.pillars !== "nan")
                        .sort((a: any, b: any) => pillarOrder.indexOf(a) - pillarOrder.indexOf(b))
                        .map((item: any, index) => {
                            return {
                                key: item,
                                label: PillerMap[item] +
                                    (searchTabMetrics.tabsValue === item || searchTabMetrics.tabsValue === "All"
                                        ? ` (${searchTabMetrics?.data.filter((met) => met.pillars === item).length})`
                                        : ""),
                                children: <div className='tab-content'>
                                    <InnerTabs />
                                </div>
                            }
                        }),
                        // {
                        // key: 'derived',
                        // label: 'Derived Metrics',
                        // children: <div className='tab-content'>
                        //     <DerivedMetricsData />
                        // </div>
                        // }
                    ]
                }
            />
        </div>
    );
}
