import * as React from 'react';
import './style.scss';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { setCurrentDepartmentDM, setCurrentMetricTypeDM, setCurrentTopicStatusDM } from '../../../../../../Redux/DataManagerReducer';
import FilterIconImage from '../../../../../../assets/images/FilterIcon.svg';
import BEMultipleSelect from '../../../../../../Components/BEMultipleSelect';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';

export interface IStatusFilterProps {
}

interface topicStatusMapType {
  name: string,
  value: number,
  type: 'error' | 'success' | 'warning'
}

interface metricTypeDM {
  name: string,
  value: number,
}

interface departmentSelectTypeDM {
  name: string,
  value: string,
}

export const topicStatusMapDM: topicStatusMapType[] = [
  { name: 'All', value: 3, type: 'success' },
  { name: 'Pending', value: 0, type: 'error' },
  { name: 'Filled', value: 1, type: 'warning' },
  { name: 'Completed', value: 2, type: 'success' },
  { name: 'Rejected', value: 0, type: 'error' },
];

export const metricTypeDM: metricTypeDM[] = [
  { name: 'All', value: 0 },
  { name: 'Annual', value: 1 },
  { name: 'HQ-A', value: 2 },
  { name: 'Calculated', value: 3 },
  { name: 'Scope 1', value: 4 },
  { name: 'Scope 2', value: 5 },
  { name: 'Assigned to me', value: 6 }
];

export const departmentSelectTypeDM: departmentSelectTypeDM[] = [
  { name: 'All', value: 'All' },
  { name: 'Finance', value: 'Finance' },
  { name: 'Human Resources', value: 'Human Resources' },
  { name: 'Information Technology', value: 'Information Technology' },
  { name: 'Legal', value: 'Legal' },
  { name: 'ESG / EHS', value: 'ESG / EHS' },
  { name: 'Operations / Projects', value: 'Operations / Projects' },
  { name: 'Administration', value: 'Administration' },
  { name: 'Finance', value: 'Finance' },
  { name: 'Others', value: 'Others' },
  { name: 'Procurement', value: 'Procurement' }
];

export function StatusFilter(props: IStatusFilterProps) {
  const dispatch = useAppDispatch();
  const wrapperRef = React.useRef(null);
  const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);

  return (
    <div className='status-filter-dm' ref={wrapperRef}>
      {/* <ToolTip title={
          currentStatus
        }>
          <div className='status-icon-box'
            onClick={() => setOpen(!open)}
          >
            <img width={20} src={FilterIconImage} alt='FilterIcon' />
          </div>
        </ToolTip> */}
      {/* <div style={{display: "flex", alignItems: "center", height:"42px"}}>
        <img width={25} height={20} src={FilterIconImage} alt='FilterIcon' />{" "}
      </div> */}
      {/* <div className='status-filter-select'>
        <BEMultipleSelect
          single
          placeholder='Department'
          name='Department'
          data={departmentSelectTypeDM.map((dept, index) => dept)}
          valueFeild='name'
          labelFeild='name'
          onChange={(dept: any) => {
            dispatch(setCurrentDepartmentDM(dept));
          }}
        />
      </div> */}
      <div className='status-filter-select'>
        <BEMultipleSelect
          single
          placeholder='Status'
          name='Status'
          data={topicStatusMapDM.map((status, index) => status)}
          valueFeild='name'
          labelFeild='name'
          onChange={(status: any) => {
            dispatch(setCurrentTopicStatusDM(status));
          }}
        />
      </div>
      <div className='status-filter-select'>
        <BEMultipleSelect
          single
          placeholder='Type'
          name='Type'
          data={metricTypeDM
            .filter((item: any) => item.value === 6 && currentBusinessUnit === 0 ? false : true)
            .map((status, index) => status)}
          valueFeild='name'
          labelFeild='name'
          onChange={(type: any) => {
            dispatch(setCurrentMetricTypeDM(type));
          }}
        />
      </div>

      {/* { open && 
        <div className='status-dropdown'>
             {
                topicStatusMapDM.map((status, index) =>  status.name)
                .map((status, index) => {
                  return (
                    <div key={index} className='status-item'
                      onClick={() => {
                        dispatch(setCurrentTopicStatusDM(status));
                        setOpen(false);
                      }}
                    >
                      {status}
                    </div>
                  )
                })
             }
          </div>
          } */}
    </div>
  );
}
