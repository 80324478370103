import { TopSection } from '../Components/TopSection';
import SettingsBackButton from '../Components/BackButton';
import { LimitsTable } from '../../Targets/Views/LimitsTable';

export interface ILimitsProps {
}

export function Limits(props: ILimitsProps) {
    return (
        <>
            <TopSection />
            <div style={{ padding: "1rem 1.5rem" }}>
                <SettingsBackButton title='Back'/>
                <LimitsTable />
            </div>
        </>
    );
}
