

import React from 'react'
import IconProps from './Interface/IconProps'

const CareerDevelopmentIcon = (props: IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      viewBox="0 0 16 20" fill="none"  xmlns="http://www.w3.org/2000/svg">
<path d="M7.5 4.5C6.95 4.5 6.47917 4.30417 6.0875 3.9125C5.69583 3.52083 5.5 3.05 5.5 2.5C5.5 1.95 5.69583 1.47917 6.0875 1.0875C6.47917 0.695833 6.95 0.5 7.5 0.5C8.05 0.5 8.52083 0.695833 8.9125 1.0875C9.30417 1.47917 9.5 1.95 9.5 2.5C9.5 3.05 9.30417 3.52083 8.9125 3.9125C8.52083 4.30417 8.05 4.5 7.5 4.5ZM1 22L3.75 7.9L2 8.65V12H0V7.3L5.25 5.15C5.7 4.96667 6.15833 4.94583 6.625 5.0875C7.09167 5.22917 7.45 5.51667 7.7 5.95L8.65 7.55C9.1 8.28333 9.70417 8.875 10.4625 9.325C11.2208 9.775 12.0667 10 13 10V12C11.9 12 10.8792 11.7667 9.9375 11.3C8.99583 10.8333 8.2 10.2 7.55 9.4L6.95 12.4L9 14.45V22H7V16L4.85 14L3.1 22H1ZM14.75 22V8H11V1H20V8H16.25V22H14.75ZM16.025 6.975L18.5 4.5L16.025 2.025L14.95 3.1L15.6 3.75H12.5V5.25H15.6L14.95 5.9L16.025 6.975Z"
        fill={props.stroke ? props.stroke : "white"}
      />
    </svg>
  );
}

export default CareerDevelopmentIcon