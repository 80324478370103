import * as React from 'react';
import { BEDrawer, DrawerFooterNotificationHeight } from '..';
import './style.scss';
import { BEButton } from '../../BEFormItems/BEButton';
import DownloadIcon from '../../BEIcons/DownloadIcon';
import FileUpload from '../../BEFileUpload/FileUpload';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { BEMessage } from '../../BEMessage';
import { ColumnsType } from 'antd/es/table';
import { BETable } from '../../BETable';
import { PrimaryTheme } from '../../../Config/Theme/theames';

export interface IBEBulkUploadDrawerProps {
  uploadFunction: Function;  //**this function should return response or null
  uploadFunctionParams?: any[]; //**this is the parameter that will be passed to the upload function
  open: boolean;
  setOpen: Function;
  heading: string;
  columnArray: any[];  //for setting the columns of the error table
  fileName: string; // just name
  //this the name of the file that will be downloaded, it should be defined in assets/Templates
  fileKey: string; // this is the key that will be used to send the file to the backend
}

export function BEBulkUploadDrawer(props: IBEBulkUploadDrawerProps) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [uploaded, setUploaded] = React.useState(false);
  const [columns, setColumns] = React.useState<ColumnsType<any>>([]);
  const [rejectedEntries, setRejectedEntries] = React.useState<any[]>([]);
  const [accpetedEntries, setAccpetedEntries] = React.useState<any[]>([]);


  React.useEffect(() => {
    setUploaded(false);
    setRejectedEntries([]);
  }, [props.open]);

  React.useEffect(() => {
    const newColumns: any[] = props.columnArray.map((column: string) => {
      return {
        title: column,
        dataIndex: column,
        key: column,
        editable: true,
        render : (text: any) => <span>{text === 'nan' ? '' : text}</span>
      };
    });
    newColumns.push({
      title: 'Error',
      dataIndex: 'error',
    });
    setColumns(newColumns);
  }, [props.columnArray]);


  const handleUpload = async () => {
    if (!file) {
      BEMessage.error('Please select a file to upload');
      return;
    }
    setLoading(true);
    let res = null;
    if (props.uploadFunctionParams) {
      res = await dispatch(props.uploadFunction(...props.uploadFunctionParams, { [props.fileKey]: file }));
    } else {
      res = await dispatch(props.uploadFunction({ [props.fileKey]: file }));
    }
    setLoading(false);
    if (res) {
      if (res.dropped_rows?.length > 0) {
        BEMessage.error('Some entries were rejected');
      }
      else {
        BEMessage.success('All entries were uploaded successfully');
        props.setOpen(false);
      }
      setRejectedEntries(res.dropped_rows);
      setAccpetedEntries(res?.data);
      setUploaded(true);
    }
    else {
      BEMessage.error('Check business unit name, date format and other fields in the file and try again.');
      setFile(null);
      return;
    }

  };

  return (
    <BEDrawer
      heading={props.heading}
      open={props.open}
      setOpen={props.setOpen}
      width='fit-content'
      footer={
        uploaded ?
          <>
            <BEButton onClick={() => {
              setUploaded(false);
              setFile(null);
            }} size='large' className='primary'>
              Upload Again
            </BEButton>
            <BEButton onClick={() => props.setOpen(false)} size='large' >
              Close
            </BEButton>
          </>
          :
          <>
            <BEButton onClick={handleUpload} size='large' className='primary'>
              Upload
            </BEButton>
            <BEButton onClick={() => props.setOpen(false)} size='large' >
              Cancel
            </BEButton>
          </>
      }
      footerNotification={
        !uploaded &&
        <div className='download-template'
          style={{ height: DrawerFooterNotificationHeight }}
        >
          <p style={{ fontSize: '1rem' }}
          >Use our CSV Template to bulk upload files</p>
          <BEButton size='large' className='download-template-btn'
            onClick={() => {
              const link = document.createElement('a');
              link.href = `/Templates/${props.fileName}.xlsx`;
              link.setAttribute('download', `${props.fileName}.xlsx`);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
          >
            <DownloadIcon />
            Download Template
          </BEButton>
        </div>
      }
    >
      {
        !uploaded ?
          <div className='bulk-upload-drawer'>
            <FileUpload
              loading={loading}
              fileType='excel'
              text='Upload the populated CSV Template'
              hint='Only .csv files are supported'
              handleFileChange={setFile}
              refreshUpload={props.open}
            />
          </div> :
          <div className='bulk-upload-result'>
            <div className='accepted'>
              <p className='heading'>Valid Entries ({accpetedEntries.length})</p>
              <p className='sub-heading'>These are the valid entries fetched from the uploaded CSV</p>
              <BETable
                pagination
                columns={columns.slice(0, columns.length - 1)}
                data={accpetedEntries}
              />
            </div>
            <hr style={{ border: `1px solid ${PrimaryTheme.primaryGreyLight}` }} />
            <div className='rejected'>
              <p className='heading'>Invalid Entries ({rejectedEntries.length})</p>
              <p className='sub-heading'>These are the invalid entries fetched from the uploaded CSV</p>
              <BETable
                pagination
                columns={columns}
                data={rejectedEntries}
              />
            </div>
          </div>
      }
    </BEDrawer>
  );
}
