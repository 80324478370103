import * as React from 'react';
import EditableTable, { EditableColumnTypes } from '../../../../Components/BETable/ExcelLikeTable';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { setYearSpecificTargetValues } from '../../../../Redux/TargetsReducer';
import { getTargetValue } from '../../Actions';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';

export interface IExcelTargetTableProps {
    startYear: number;
    endYear: number;
    targetType: string;
    baselineValue: number;
    isEdit: boolean;
}

export function ExcelTargetTable(props: IExcelTargetTableProps) {
    const [dataSource, setDataSource] = React.useState<any>([]);
    const yearSpecificTargetValues = useTypedSelector(state => state.targets.yearSpecificTargetValues);
    const dispatch = useAppDispatch();

    const columns: EditableColumnTypes[] = [
        {
            title: 'YEAR',
            dataIndex: 'year',
            editable: false,
        },
        {
            title: 'TARGET PERCENTAGE',
            dataIndex: 'percentage',
            editable: true,
            hidden: props.targetType === 'A' || props.targetType === 'B',
        },
        {
            title: 'TARGET VALUE',
            dataIndex: 'value',
            editable: true,
            rules: {
                isNumber: true,
                isDisabled: props.targetType === 'C' || props.targetType === 'D'
            }
        }
    ];

    const createDataSource = () => {
        let tempData = [];
        for (let i = props.startYear; i <= props.endYear; i++) {
            tempData.push({
                targetId: null,
                year: i,
                value: '',
                percentage: '',
                isExisting: false,
                changed: false
            });
        }
        setDataSource(tempData);
    }

    React.useEffect(() => {
        if (!props.isEdit) {
            createDataSource();
        }
    }, [props.startYear, props.endYear]);

    React.useEffect(() => {
        if (yearSpecificTargetValues.length > 0 && props.isEdit) {
            setDataSource(yearSpecificTargetValues);
        }
    }, [yearSpecificTargetValues]);
  
    const handleSave = (row: any, dataIndex: string) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.year === item.year);
        if (dataIndex === 'percentage') {
            if (row[dataIndex] === '') {
                newData.splice(index, 1, { ...row, value: ''});
            } else {
                newData.splice(index, 1, {
                    ...row,
                    value: getTargetValue(props.targetType, row.percentage, props.baselineValue),
                    changed: true
                });
            }
        } else {
            newData.splice(index, 1, { ...row});
        }
        dispatch(setYearSpecificTargetValues(newData));
        setDataSource(newData);
    }

    return (
        <EditableTable
            data={dataSource}
            columns={columns}
            handleSave={handleSave}
            allowEmptySave={true}
        />
    );
}
