import * as React from 'react';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { NotificationBox } from './NotificationBox';
import { NotificationTypes } from '../../../../../Redux/Types/commonTypes';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { getNotifications, patchNotification } from '../../../../Actions/HomeActions';
import dayjs from 'dayjs';

export interface INotificationBoxProps {
}

export function NotificationsBox (props: INotificationBoxProps) {
  const dispatch = useAppDispatch();
  const notifications = useTypedSelector(state => state.common.notification);

  const handleChangeAllNotificationStatus = async() => {
    const allObjs =  notifications?.data.filter((notification:NotificationTypes) => !notification.read).map((notification:NotificationTypes) => {
      {return {id:notification.id, read:true}}
    })
    await Promise.all(allObjs.map((obj) => dispatch(patchNotification(obj.id, obj))))
  }

  return (
    <div className='notifications'>
      {
        notifications?.data.filter((notification:NotificationTypes) => !notification.read).length > 0 &&
        <div className='mark-all-read' onClick={handleChangeAllNotificationStatus}>
          Mark All as Read
        </div>
      }
      {
        [...notifications?.data].sort((a:NotificationTypes, b:NotificationTypes) => dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? 1 : -1).
        map((notification:NotificationTypes, index:number) => (
            <NotificationBox key={index} notification={notification}/>    
        ))
      } 
    </div>
  );
}
