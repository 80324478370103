import { Col, Progress, ProgressProps, Row } from 'antd';
import './style.scss';
import * as React from 'react';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { AllMonths } from '../../../../Config/Functions/UsefullFunctions';

export interface ITrackerCardProps {
    data: any[];
}

export function TrackerCard(props: ITrackerCardProps) {
    const [completed_units, setCompletedUnits] = React.useState(0);
    const currentYear = useTypedSelector((state) => state.dataManager.metrics.dateSlicer.currentYear);
    const currentMonth = useTypedSelector((state) => state.dataManager.metrics.dateSlicer.currentMonth);
    const currentBusinessUnit = useTypedSelector((state) => state.common.homePage.currentBusinessUnit);
    const currentBusinessGroup = useTypedSelector((state) => state.common.homePage.currentBusinessGroup);
    const companyName = useTypedSelector((state) => state.companies.companyDetails.data?.firm_name);
    const fyEnd = useTypedSelector((state) => state.companies.companyDetails.data?.reporting_year_end);
    const businessUnits = useTypedSelector((state) => state.entity.BusinessUnits.data);
    const businessGroups = useTypedSelector((state) => state.entity.BusinessGroups.data);
    const businessUnitRefs = useTypedSelector((state) => state.entity.BusinessUnitRefs);
    const [name, setName] = React.useState<string | undefined>("");
    const [pending_units, setPendingUnits] = React.useState(0);
    const [percent, setPercent] = React.useState(0);

    React.useEffect(() => {
        let totalCompleted = 0;
        let totalPending = 0;
        let totalUnits = 0;

        setName(currentBusinessUnit !== 0 ?
            businessUnits?.find((item: any) => item.id === currentBusinessUnit)?.name :
            currentBusinessGroup !== 0 ?
            businessGroups?.find((item: any) => currentBusinessGroup === item.id)?.name :
            companyName);

        props.data.forEach((element: any) => {
            totalCompleted += element.approved;
            totalPending += element.pending;
            totalUnits += element.total;
        });

        setCompletedUnits(totalCompleted);
        setPendingUnits(totalPending);
        setPercent(Number((totalCompleted / totalUnits * 100)?.toFixed(2)));

    }, [props.data, currentYear, currentMonth, currentBusinessGroup, currentBusinessUnit]);



    const conicColors: ProgressProps['strokeColor'] = {
        '0%': PrimaryTheme.primaryRedError,
        '30%': PrimaryTheme.amber,
        '70%': PrimaryTheme.primaryGreenLight,
        '100%': PrimaryTheme.primaryGreen,
    };

    return (
        <div>
            <h3>
                Completion Risk:{' '}
                {name}
                {' '}
                for the {' '}
                {
                    currentMonth !== 0 ?
                    'month of ' + AllMonths[currentMonth-1] + ' ' + currentYear :
                    'Financial Year ' +
                    (fyEnd?.split('-')[1] === '12' ? currentYear :
                    currentYear + '-' + (currentYear + 1))
                }
            </h3>
            <br />
            <div className='tracker-card'>
                <Row className='tracker-card-progress-container'>
                    <Progress type="dashboard" size={300} gapDegree={180} strokeWidth={18} percent={percent} strokeColor={conicColors} />
                </Row>
                <Row className='tracker-card-info-container'>
                    <Col className='tracker-card-info'>
                        <p className='tracker-card-info-title'>
                            No. of completed metrics
                        </p>
                        <p className='tracker-card-info-value'>
                            {completed_units}
                        </p>
                    </Col>
                    <Col className='tracker-card-info'>
                        <p className='tracker-card-info-title'>
                            No. of pending metrics
                        </p>
                        <p className='tracker-card-info-value'>
                            {pending_units}
                        </p>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
