import React from 'react';
import { BEInput } from '../../../../../../Components/BEFormItems/BEInput'
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { setSearchTabMetrics } from '../../../../../../Redux/DataManagerReducer';
import './style.scss'
import { setCurrentCategory, setCurrentGroup, setCurrentPillar } from '../../../../../../Redux/OnboardingReducer';
import { TopicStatusTypeDM } from '../../../../../../Redux/Types/dataManagerTypes';
import { getTopicStatusDM } from '../../../../Actions';
import { departmentSelectTypeDM, metricTypeDM, topicStatusMapDM } from '../StatusFilter';
import { filterDataByMonthOrBU } from '../../../../../Emissions/Actions';

interface ObjectType {
    [key: string]: string
}
export interface ITopTabSearchDMProps {
}

export function TopTabSearch(props: ITopTabSearchDMProps) {
    const [searchValue, setSearchValue] = React.useState("");
    const combinedSelectedMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.combinedSelectedMetricsPerYear);
    const searchTabMetrics = useTypedSelector(state => state.dataManager.metrics.searchTabMetrics);
    const [currentItemId, setCurrentItemId] = React.useState<number>(0);
    const [tabValue, settabValue] = React.useState(searchTabMetrics.tabsValue);
    const [searchDropdown, setSearchDropdown] = React.useState(false);
    const searchForColumns = useTypedSelector(state => state.dataManager.pillars);
    const statusValue = useTypedSelector(state => state.dataManager.filter.TopicStatus);
    const tableDataDM = useTypedSelector(state => state.dataManager.metrics.tableDataDM);
    const metricType = useTypedSelector(state => state.dataManager.filter.MetricTypeDM);
    const departmentType = useTypedSelector(state => state.dataManager.filter.DepartmentTypeDM);
    const assignedDepartmentByCategory = useTypedSelector(state => state.dataManager.assignedDepartmentByCategory);
    const contributorsByMetrics = useTypedSelector(state => state.dataManager.contributorsByMetrics);
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');

    const dispatch = useAppDispatch()
    const onChangeColumnSearch = (val: string) => {
        settabValue(val);
    };
    const handleSearchFocus = () => {
        setSearchDropdown(true);
    };
    const handleSearchBlur = () => {
        if (!currentItemId) {
            setSearchDropdown(false);
        }
    };
    const handleMetricSelect = (item: any) => {
        setSearchDropdown(false);
        dispatch(setSearchTabMetrics({
            tabsValue: searchTabMetrics.tabsValue,
            data: searchTabMetrics?.data,
            dropDownMetric: item
        }));
        dispatch(setCurrentPillar(item.pillars));
        dispatch(setCurrentCategory(item.category));
        if (item.category !== 'Energy' && item.category !== 'Water' && item.category !== 'Waste') {
            dispatch(setCurrentGroup(item.group));
        } else {
            dispatch(setCurrentGroup(item.tab));
        }
        setSearchValue(item.title);

        // using setTimeout since changing pillars and changing category, group 
        // setTimeout(() => {
        //     dispatch(setCurrentCategory(item.category));
        // }, 100);

        // setTimeout(() => {
        //     if(item.category!=='Energy') {
        //         dispatch(setCurrentGroup(item.group));
        //     } else {
        //         dispatch(setCurrentGroup(item.tab));
        //     }
        // }, 200);
    }

    const getEmissionType = (metric: any) => {
        // let res = await dispatch(filterDataByMonthOrBU(true,true));
        // const scope1Data = res?.scope1Data;
        // const scope2Data = res?.scope2Data;
        // console.log(scope1Data)
        // const inScope1 = scope1Data.filter((item: any) => item.bcode === bcode);
        // const inScope2 = scope2Data.filter((item: any) => item.bcode === bcode);
        // console.log(inScope1);
        // console.log(inScope2);
        // if (inScope1.length) {
        //     return "Scope 1";
        // } else if (inScope2.length) {
        //     return "Scope 2";
        // }
        // return ""
        if (metric?.category === 'Refrigerants' || (metric?.category === 'Energy' && metric?.tab === 'Fuels'))
            return "Scope 1";
        else if (metric.bcode == 'BE008.19' || metric.bcode == 'BE008.18' || metric.bcode == 'BE008.14')
            return "Scope 2";
        return "";
    }

    const setSearchTabMetricsFunc = async () => {
        let tempData: any[] = [];
        for (let i = 0; i < combinedSelectedMetricsPerYear?.data.length; i++) {
            const metric: any = combinedSelectedMetricsPerYear?.data[i!];
            let ItemStatus = await dispatch(getTopicStatusDM(metric?.id));
            let ItemType = "All";
            const metricAssignedToUser = contributorsByMetrics.data.find((item) => item.my_metric === metric?.id && item.user === userInfo.id);

            if (metricAssignedToUser !== undefined && metricAssignedToUser !== null && metricType === "Assigned to me") {
                ItemType = "Assigned to me";
            }
            
            if (metric?.hasOwnProperty("direct") && metricType !== "Assigned to me") {
                ItemType = "Calculated";
            }
            if (metric?.annual && metricType !== "Calculated" && metricType !== "Assigned to me") {
                ItemType = "Annual";
                if (!metric?.applicable_to_all && metricType !== "Annual") {
                    ItemType = "HQ-A";
                }
            }
            if (metricType === 'Scope 1' || metricType === 'Scope 2') {
                ItemType = getEmissionType(metric);
            }
            let ItemDepartment = "All";

            if (metric?.category === 'Energy' || metric?.category === 'Water' || metric?.category === 'Waste') {
                ItemDepartment = assignedDepartmentByCategory?.data?.find((item) => item.category === metric?.tab)?.department || "All";
            } else {
                ItemDepartment = assignedDepartmentByCategory?.data?.find((item) => item.category === metric?.group)?.department || "All";
            }
            if (combinedSelectedMetricsPerYear?.data[i!].title?.toLowerCase().includes(searchValue?.toLowerCase())
                && (statusValue === 'Rejected' ? 
                    (topicStatusMapDM?.find((status) => status.value === 0)?.name === 'Pending' &&
                    (tableDataDM[metric?.id]?.every(item => item.status === 3) || false))
                : 
                    (topicStatusMapDM?.find((status) => status.value === ItemStatus)?.name === statusValue || statusValue === 'All'))
                && (metricTypeDM?.find((type) => type.name === ItemType)?.name === metricType || metricType === 'All')
                && (departmentSelectTypeDM?.find((type) => type.name === ItemDepartment)?.name === departmentType || departmentType === 'All')
            ) {
                tempData = [...tempData, metric];
            }
        }
        if (tabValue === "All") {
            dispatch(setSearchTabMetrics({
                tabsValue: "All",
                data: tempData,
                dropDownMetric: searchTabMetrics.dropDownMetric
            }))
        } else {
            dispatch(setSearchTabMetrics({
                tabsValue: tabValue,
                data: tempData.filter((item: any) => item.pillars === tabValue),
                dropDownMetric: searchTabMetrics.dropDownMetric
            }))
        }
    }
    React.useEffect(() => {
        setSearchTabMetricsFunc();
    }, [searchValue, tabValue, combinedSelectedMetricsPerYear, statusValue, metricType, departmentType]);
    if (combinedSelectedMetricsPerYear?.status !== 'success') return null;
    return (
        <div
            style={{
                position: 'relative'
                // position: "absolute",1
                // top: "12.5rem",
                // right: "3rem",
                // zIndex: 4,
                // fontSize: "0.85rem",
            }}
            onMouseOut={() => setCurrentItemId(0)}
        >
            <BEInput
                onFocus={handleSearchFocus}
                onBlur={handleSearchBlur}
                size='large'
                search
                searchForColumns={searchForColumns}
                onChangeColumnSearch={onChangeColumnSearch}
                columnSearchValue={tabValue}
                value={searchValue}
                selectSize='large'
                onChange={(e) => setSearchValue(e.target.value)}
            />
            {searchDropdown &&
                <div className='tabSearchDropdown'>
                    {searchTabMetrics?.data.map((item: any) =>
                        <div
                            onMouseOver={() => {
                                setCurrentItemId(item.id);
                            }}
                            onClick={() => {
                                handleMetricSelect(item);
                            }}
                            className='list-items'
                        >
                            {item.title}
                        </div>
                    )}
                    {
                        searchTabMetrics?.data.length === 0 &&
                        <div className='list-items'>
                            No Metric Found
                        </div>
                    }
                </div>}
        </div>
    )
}

