import * as React from 'react';
import { BETabs } from '../../../../Components/BETabs';
import './style.scss'
import { AddMetrics } from '../Drawers/AddMetrics';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { setCurrentGroup } from '../../../../Redux/OnboardingReducer';
import { InnerContent } from './InnerContent';
import { Metric } from '../../../../Redux/Types/dataManagerTypes';

export interface IInnerTabsFilterProps {
}

export function InnerTabsFilter (props: IInnerTabsFilterProps) {
    const [open,setOpen] = React.useState(false);
    const dispatch = useAppDispatch();
    const currentPillar = useTypedSelector(state => state.onBoarding.metrics.currentPillar);
    const currentCategory: string = useTypedSelector(state => state.onBoarding.metrics.currentCategory);
    const metrics = useTypedSelector(state => state.onBoarding.metrics.directMetrics?.data);
    const derivedMetrics = useTypedSelector(state => state.onBoarding.metrics.derivedMetrics?.data);
    const currentGroup = useTypedSelector(state => state.onBoarding.metrics.currentGroup)

    React.useEffect(() => {
      const isCurrentGroupPresent = [...derivedMetrics, ...metrics]?.filter((item:Metric)=>item.category === currentCategory).some((item: Metric) => item.group === currentGroup)
      if(!isCurrentGroupPresent){
        if(currentCategory!=='Energy' && currentCategory!=='Water' && currentCategory!=='Waste')
          dispatch(setCurrentGroup(Array.from(new Set([...derivedMetrics, ...metrics]?.filter((item:any)=>item.pillars === currentPillar && item.category === currentCategory).map((item:any)=>item.group)))[0]))
          else
          dispatch(setCurrentGroup(Array.from(new Set([...derivedMetrics, ...metrics]?.filter((item:any)=>item.pillars === currentPillar && item.category === currentCategory).map((item:any)=>item.tab)))[0]))
      }
    }, [currentCategory,currentPillar])

  return (
    <div className='inner-tabs-filter'>
     <BETabs
      destroyInactiveTabPane
      
      onChange={(key)=>dispatch(setCurrentGroup(key))}
      activeKey={currentGroup}

      items={
        (currentCategory!=='Energy' && currentCategory!=='Water' && currentCategory!=='Waste')?
        Array.from(new Set([...derivedMetrics, ...metrics]?.filter((item:any)=>item.pillars === currentPillar && item.category === currentCategory).map((item:any)=>item.group)))
        .map((item:any,index)=>{
          return {
            key:item,
            label:item,
            children:
            <div className='tab-content'>
               {
                <InnerContent setOpenAdd={setOpen}/>
               }              
            </div>
          }
        })
        :
      Array.from(new Set([...derivedMetrics, ...metrics]?.filter((item:any)=>item.pillars === currentPillar && item.category === currentCategory).map((item:any)=>item.tab)))
      .map((item:any,index)=>{
        return {
          key:item,
          label:item,
          children:
          <div className='tab-content'>
             {
              <InnerContent setOpenAdd={setOpen}/>
             }              
          </div>
        }
      })
      }
     />
     <AddMetrics
        open={open}
        setOpen={setOpen}
     />
    </div>
  );
}
