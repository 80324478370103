import { GET_ALL_BUSINESS_GROUPS,POST_BUSINESS_GROUP,EDIT_BUSINESS_GROUP,DELETE_BUSINESS_GROUP } from "../../Utils/Routes/EntityRouts";
import { handleAPICall } from "../../Config/Functions/HandleAPICall";
import { navigateTo } from "../../Navigation/NavigationManager";
import { AppDispatch } from "../../Redux/store";
import { BEMessage } from "../../Components/BEMessage";
import { setBusinessGroups } from "../../Redux/EntityReducer";
import { getBusinessUnitRefs } from "./BusinessUnitActions";


export const getBusinessGroups = () => async (dispatch:AppDispatch) => {
    dispatch(setBusinessGroups({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_ALL_BUSINESS_GROUPS());
    if (data) {
        dispatch(setBusinessGroups({
            status: "success",
            data: data?.data
        }));
    }
    else{
        console.log(error);
        dispatch(setBusinessGroups({
            status: "error",
            data: []
        }));
    }
}

export const addBusinessGroup = (data:any) => async (dispatch:AppDispatch) => {
    const [res, error] = await handleAPICall(POST_BUSINESS_GROUP(data));
    if (res) {
        dispatch(getBusinessGroups());
        return res?.data;
    }
    else{
        console.log(error);
        return null;
    }
}

export const editBusinessGroup = (id:any,data:any) => async (dispatch:AppDispatch) => {
    const [res, error] = await handleAPICall(EDIT_BUSINESS_GROUP(id,data));
    if (res) {
        dispatch(getBusinessGroups());
        return res?.data;
    }
    else{
        console.log(error);
        BEMessage.error("Business Group Updated Failed");
        return null;
    }
}

export const deleteBusinessGroup = (id:any) => async (dispatch:AppDispatch) => {
    const [res, error] = await handleAPICall(DELETE_BUSINESS_GROUP(id));
    if (res) {
       dispatch(getBusinessGroups());
       BEMessage.success("Business Group Deleted Successfully");
    }
    else{
        console.log(error);
    }
}