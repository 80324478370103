import React from "react";
import IconProps from "./Interface/IconProps";

const AddIcon = (props: IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      viewBox="0 0 24 24"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_2240)">
        <path
          d="M12 0.857422V23.1431"
          stroke={props.stroke ? props.stroke : "white"}
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.857422 11.9316H23.1431"
          stroke={props.stroke ? props.stroke : "white"}
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2240">
          <rect
            width={props.inheritSize ? "100%" : 24}
            height={props.inheritSize ? "100%" : 24}
            fill={props.fill ? props.fill : "none"}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddIcon;
