import * as React from 'react';
import { deleteMaterialitySurvey, getMaterialitySurveys } from '../../Actions/matirialityActions';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { BETable } from '../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import { ChangeDateFormat, isDateInFY } from '../../../../Config/Functions/UsefullFunctions';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { AddMatirialityAssessMent } from '../Drawers/AddMatirialityAssessment';
import { navigateTo } from '../../../../Navigation/NavigationManager';
import {
  setCreateSurveyStep,
  setCurrentMaterialitySurvey,
  setRespondantsToAssessment,
  setStakeholdersToAssessment
} from '../../../../Redux/MatirialityReducer';
import EditIcon from '../../../../Components/BEIcons/EditIcon';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import DeleteIcon from '../../../../Components/BEIcons/DeleteIcon';
import BEConfirmModal from '../../../../Components/BEConfirmModal';
import dayjs, { Dayjs } from 'dayjs';

export interface IAllMatirialityAssessMentsProps {
}

export function AllMatirialityAssessMents(props: IAllMatirialityAssessMentsProps) {
  const dispatch = useAppDispatch();
  const allSurveys = useTypedSelector((state) => state.matiriality.AllSurveys);
  const currentSurvey = useTypedSelector(state => state.matiriality.currentMaterialitySurvey)
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const yearEnd = useTypedSelector(state => state.companies.companyDetails?.data?.reporting_year_end);
  const [open, setOpen] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false)
  const [filteredAssessments, setFilteredAssessments] = React.useState<any>([]);
  const stakeholders = useTypedSelector((state) => state.matiriality.stakeholders);

  React.useEffect(() => {
    if (allSurveys?.status === 'idle')
      dispatch(getMaterialitySurveys());
  }, []);

  React.useEffect(() => {
    if (allSurveys?.status === 'success') {
      setFilteredAssessments(allSurveys?.data)
    }
  }, [allSurveys])


  const columns: ColumnsType<any> = [
    {
      title: 'Title',
      dataIndex: 'title',
      render: (value, record) => {
        return <div
          style={{
            fontWeight: record?.topicsChosen === true ? 'bold' : 'normal',
            textDecoration: record?.topicsChosen === true ? 'underline' : 'none',
            cursor: record?.topicsChosen === true ? 'pointer' : 'default'
          }}
          onClick={() => {
            if (record?.topicsChosen === true)
              dispatch(setRespondantsToAssessment({
                status: 'idle',
                data: []
              }))
            dispatch(setStakeholdersToAssessment({
              status: 'idle',
              data: []
            }))
            navigateTo(`/home/materiality/assessments/${record.id}`)
          }}>
          {value}
        </div>
      }
    },
    {
      title: 'Description',
      dataIndex: 'description'
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      render: (text, record) => ChangeDateFormat(text)
    },
    {
      title: 'Status',
      dataIndex: 'topicsChosen',
      render: (text, record) => record.topicsChosen ? 'Published' : 'Draft'
    },
    {
      title: 'Responses',
      render: (text, record) => {
        if (record.total_stakeholders_invited === 0) return <div>-</div>
        return <div>
          {record.stakeholders_survey_completed} / {record.total_stakeholders_invited}
        </div>
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text: any, record: any) => {
        return (
          <div style={{ display: "flex", gap: "0.5rem" }}>

            {record.stakeholders_survey_completed === 0 &&
              <div
                style={{ height: "1rem", cursor: "pointer" }}
                onClick={() => {
                  dispatch(setCurrentMaterialitySurvey(record));
                  dispatch(setCreateSurveyStep(2));
                  setOpen(true);
                }}
              >
                <EditIcon
                  inheritSize
                  stroke={PrimaryTheme.primaryGray}
                />
              </div>
            }

            <div
              style={{ height: "1rem", cursor: "pointer" }}
              onClick={() => {
                dispatch(setCurrentMaterialitySurvey(record));
                setConfirmDelete(true);
              }}
            >
              <DeleteIcon
                inheritSize
                stroke={PrimaryTheme.primaryGray}
              />
            </div>
          </div>
        );
      }
    }
  ]

  return (
    <div style={{ padding: "1rem 0" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <BEInput
          style={{ width: "313px" }}
          search
          placeholder="Search for an assessment"
          size="large"
          onChange={(e) => {
            const value = e.target.value?.toLowerCase();
            const filteredData = allSurveys?.data?.filter((item: any) => {
              return item.title?.toLowerCase().includes(value);
            });
            setFilteredAssessments(filteredData);
          }}
        />
        <BEButton
          className="primary"
          style={{ marginLeft: "auto" }}
          size="large"
          onClick={() => {
            dispatch(setCurrentMaterialitySurvey(null));
            dispatch(setCreateSurveyStep(1));
            setOpen(true);
          }}
        >
          + Add Assessment
        </BEButton>
      </div>
      {/* Number(dayjs(item.deadline).format('YYYY')) === currentYear */}
      <BETable
        loading={allSurveys?.status === "loading"}
        columns={columns}
        data={filteredAssessments.filter((item: any) =>
          // inFinYear(dayjs(item.deadline), currentYear, yearEnd)
          isDateInFY(currentYear,item.deadline, yearEnd || '')
        )}
        pagination
      />
      <BEConfirmModal
        title="Confirm Delete"
        message={`Are you sure you want to delete the assessment ${currentSurvey?.title}? All related survey data will be deleted`}
        visible={confirmDelete}
        setVisible={setConfirmDelete}
        onConfirm={async () => {
          if (currentSurvey)
            await dispatch(deleteMaterialitySurvey(currentSurvey?.id));
        }}
      />
      <AddMatirialityAssessMent open={open} setOpen={setOpen} />
    </div>
  );
}
