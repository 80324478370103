import { BEButton } from "../../../../Components/BEFormItems/BEButton";
import { useAppDispatch } from "../../../../Config/Hooks/useAppDispatch";
import GoogleImage from '../../../../assets/images/Login/GOOGLE.svg'
import { onGoogleLogin  } from "../../../Actions/LoginActions";
export function GoogleLogin(){
	const dispatch=useAppDispatch();
	return(
		<div>
			<BEButton
				size="large"
				icon={
					<img src={GoogleImage} style={{height:'1rem'}}/>
				}
				onClick={()=>dispatch(onGoogleLogin)}
			/>
		</div>
	)

}