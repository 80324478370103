import API from "../../API";

export const GET_VAULT_FILES = async () => {
    return await API.GET("/enterprise/esg-files/");
}

export const POST_VAULT_FILES = async (data:any) => {
    return await API.POST("/enterprise/esg-files/", data, true);
}

export const PATCH_VAULT_FILES = async (id: any, data: any) => {
    return await API.PATCH(`/enterprise/esg-files/${id}/`, data);
}

export const DELETE_VAULT_FILES = async (id:any) => {
    return await API.DELETE(`/enterprise/esg-files/${id}/`);
}

export const GET_VAULT_FOLDERS = async () => {
    return await API.GET("/enterprise/esg-folders/");
}

export const POST_VAULT_FOLDERS = async (data:any) => {
    return await API.POST("/enterprise/esg-folders/", data);
}

export const PATCH_VAULT_FOLDERS = async (id: any, data: any) => {
    return await API.PATCH(`/enterprise/esg-folders/${id}/`, data);
}

export const DELETE_VAULT_FOLDERS = async (id:any) => {
    return await API.DELETE(`/enterprise/esg-folders/${id}/`);
}

export const GET_SUPPLIER_EVIDENCE = async () => {
    return await API.GET("/enterprise/supplier-assessment-evidence/");
}