import React from 'react'
import { Avatar, Card, Mentions } from 'antd';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { BEButton } from '../../BEFormItems/BEButton';
import SendIcon from '../../BEIcons/SendIcon';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { postReportChats } from '../../../Features/Reporting/Actions/template';
import ToolTip from '../../BEToolTip';
import { BELineWithText } from '../../BELinewithText';
import UsersIcon from '../../BEIcons/UsersIcon';

interface Props {
	chats: any[]
	uniqueCode: string
}

export const Chat = (props: Props) => {

	const userInfo = JSON.parse(localStorage.getItem("userInfo") || '{}');
	const allUsers = useTypedSelector(state => state.userMgmt.data);
	const [userIdsToSend, setUserIdsToSend] = React.useState<number[]>([]);
	const [message, setMessage] = React.useState<string>('')
	const currentReportId = useTypedSelector(state => state.reporting.currentReport?.id);
	const [loadingSendButton, setLoadingSendButton] = React.useState<boolean>(false);
	const [groupedChat, setGroupedChat] = React.useState<any[]>([]);
	const [sortedChatByDate, setSortedChatByDate] = React.useState<any[]>([])

	const dispatch = useAppDispatch();

	async function handleSendMessage() {
		if (message) {
			setLoadingSendButton(true)
			const sentMessage = await dispatch(postReportChats({
				unique_code: props.uniqueCode,
				message: message,
				report_id: currentReportId,
				from_user_id: userInfo?.id,
				to_users: userIdsToSend.join(','),
			}))
			setMessage('');
			setLoadingSendButton(false)
		}
	}

	function formatDateHeader(date: Date) {
		const today = new Date();
		const yesterday = new Date(today);
		yesterday.setDate(today.getDate() - 1);
		const newDate = new Date(date)

		const daysAgo = Math.floor((today.getTime() - newDate.getTime()) / (1000 * 60 * 60 * 24));

		if (newDate.toDateString() === today.toDateString()) {
			return "Today";
		} else if (newDate.toDateString() === yesterday.toDateString()) {
			return "Yesterday";
		} else if (daysAgo === 2) {
			return "2 days ago";
		} else {
			return newDate.toLocaleDateString('en-GB', {
				day: 'numeric',
				month: 'long',
				year: 'numeric',
			});
		}
	}

	React.useEffect(() => {
		const groupedChat = props.chats?.reduce((acc, chat) => {
			const date = new Date(chat.timestamp).toLocaleDateString('en-GB', {
				year: 'numeric',
				month: 'long',
				day: 'numeric'
			})
			if (!acc[date]) {
				acc[date] = [];
			}
			acc[date].push(chat);
			console.log(acc)
			return acc;
		}, {}
		)
		setGroupedChat(groupedChat)
		const sortedDates = Object.keys(groupedChat).sort((a: any, b: any) => new Date(a.timestamp).valueOf() - new Date(a.timestamp).valueOf())
		setSortedChatByDate(sortedDates);
	}, [props.chats]);

	return (
		<div className='chats-container'>
			<div className='chats'>
				{sortedChatByDate.map(date =>
					<>
						<BELineWithText text={formatDateHeader(date)} />
						{groupedChat[date].map((chat: any) => {
							const date = new Date(chat.timestamp)
							let hours = date.getHours();
							const minutes = date.getMinutes();
							const ampm = hours >= 12 ? 'pm' : 'am';
							hours = hours % 12;
							hours = hours ? hours : 12;
							const minutesFormatted = minutes < 10 ? '0' + minutes : minutes;
							return <>

								<div className={`chat ${chat.from_user == userInfo?.id ? 'send' : ''}`}>
									<div className={`message ${chat.from_user == userInfo?.id ? 'send' : ''}`}>
										<ToolTip title={allUsers.find((user) => user.id === chat.from_user)?.email}>
											<Avatar src={allUsers.find((user) => user.id === chat.from_user)?.profile_pic || <UsersIcon inheritSize fill={PrimaryTheme.primaryGreyDark}/>} />
										</ToolTip>
										<div>
											<div className={`user-details ${chat.from_user == userInfo?.id ? 'send' : ''}`}>
												<h3>{allUsers.find((user) => user.id === chat.from_user)?.first_name}</h3>
												<span style={{ color: PrimaryTheme.primaryGray }}>{`${hours}:${minutesFormatted} ${ampm}`}</span>
											</div>
											<Card className={`message-card ${chat.from_user == userInfo?.id ? 'send' : ''}`}>
												{/* make all @mentions bold in chat.message */}
												<p
													style={{ margin: 0 }}
													dangerouslySetInnerHTML={{ __html: chat.message.replace(/@(\w+)/g, '<strong>$&</strong>') }}
												/>
											</Card>
										</div>
									</div>
								</div>
							</>
						})}
					</>
				)
				}
			</div>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div style={{ position: 'absolute', bottom: '0.5rem', width: '98%' }}>
					<div style={{ position: 'relative' }}>
						<Mentions
							value={message}
							onChange={(value) => setMessage(value)}
							style={{ padding: "0.25rem" }}
							placeholder='Message @mention'
							onSelect={(option) => {
								const userId = Number(option?.key);
								if (userId) {
									setUserIdsToSend([...userIdsToSend, userId]);
								}
							}}
							options={
								allUsers
									.filter((user) =>
										user.id !== userInfo?.id &&
										(user.role === 'ADMIN' || user.role === 'SUPER_ADMIN' || user.role === 'ESG_CONSULTANT')
									)
									.map((user, index) => {
										return {
											label:
												<div style={{ display: 'flex', alignItems: 'center' }} key={index}>
													<Avatar src={user.profile_pic} />
													<span style={{ marginLeft: '0.5rem' }}>
														{user.first_name + ' ' + user.last_name}
														<span style={{ color: PrimaryTheme.primaryGray }}>
															{` - ${user.email}`}
														</span>
													</span>
												</div>,
											value: user.first_name,
											key: String(user.id)
										}
									})}
						/>
						<div
							style={{ position: 'absolute', right: '0.25rem', top: '0.25rem' }}
						>
							<BEButton
								loading={loadingSendButton}
								onClick={handleSendMessage}
								style={{ height: '2rem' }}
								className='primary'
							>
								<span
									style={{ height: '1rem', display: 'flex', alignItems: 'center' }}>
									<SendIcon inheritSize />
								</span>
							</BEButton>

						</div>
					</div>
				</div>
			</div>
		</div>
	)
}