import * as React from 'react';
import './style.scss';
import { BETextArea } from '../../../../../Components/BEFormItems/BETextArea';
import { Form } from 'antd';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { fillReportData } from '../../../Actions/template';
import { postGresbEntityDataToPortal } from '../../../Actions/gresbActions';

export interface ITextAreaProps {
    data: any[];
    isFirstLevel?: boolean;
}

export function TextArea(props: ITextAreaProps) {
    const [form] = Form.useForm();
    const currentReport = useTypedSelector(state => state.reporting.currentReport);
    const reportData = useTypedSelector(state => state.reporting.reportData?.data);
    const dispatch = useAppDispatch();

    // To populate data in the form
    React.useEffect(() => {
        const topicId = props?.data[0]?.id;
        const topicData = reportData[topicId!] ? reportData[topicId!][0!] : null;
        const value = topicData?.data;
        form.setFieldsValue({
            description: value
        });
    }, [props?.data, reportData]);

    // function to handle saving the data
    const handleAddData = async () => {
        const data = props?.data[0];
        const value = form.getFieldValue('description');
        
        if (value === undefined || value === null || value === '') {
            return;
        }

        const dataToSend = {
            data: value
        }

        await dispatch(fillReportData({
            ...dataToSend,
            esg_report: currentReport?.id,
            topic: data?.id
        })).then(async () => {
            await dispatch(postGresbEntityDataToPortal(data?.variable_code, String(value)));
        });
    }


    // value or form.getFieldValue('description') will give the value of the text area

    return (
        <div className='textbox-parent'>
            {
                !props.isFirstLevel &&
                <p className='textbox-question'>
                    {props?.data[0]?.accounting_metric !== 'nan' ? props?.data[0]?.accounting_metric : props?.data[0]?.topic}
                </p>
            }
            <Form form={form}>
                <Form.Item
                    name="description"
                    rules={[
                        { required: true, message: 'Please enter description' },
                        { max: props?.data[0]?.word_limit, message: `Description should not exceed ${props?.data[0]?.word_limit} characters` }
                    ]}
                >
                    <BETextArea
                        value={form.getFieldValue('description')}
                        placeholder={''}
                        form={form}
                        rows={8}
                        onBlur={() => handleAddData()}
                    />
                </Form.Item>
            </Form>

        </div>
    );
}
