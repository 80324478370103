import React, { useState, useEffect } from 'react'
import { IndividualSupplierTemplate } from '../../../../../Utils/Download/PDF/Templates/SupplierAssessmentTemplate';
import { usePDF } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { Spin } from 'antd';
import DownloadIcon from '../../../../../Components/BEIcons/DownloadIcon';
import { useSelector } from 'react-redux';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import ExportExcelIcon from '../../../../../Components/BEIcons/ExportIcons/ExportExcelIcon';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import ExportPDFIcon from '../../../../../Components/BEIcons/ExportIcons/ExportPDFIcon';

interface PropTypes {
  supplierDetails: any,
  questions: any,
  answers: any,
}


export default function DownloadSupplierResponse(props: PropTypes) {
  const [loading, setLoading] = useState(false);
  const [instance, update] = usePDF({
    document: <></>
  })

  const handleDownload = async () => {
    setLoading(true);
    update(<IndividualSupplierTemplate 
      supplierData={props.supplierDetails}
      questions={props.questions}
      answers={props.answers}
      score={((props.supplierDetails.score/props.supplierDetails.total_questions)*100).toFixed(2)}
    />);
  }

  useEffect(() => {
    if (loading && instance.loading === false) {
      saveAs(instance.blob || '', `supplierAssessment-${props.supplierDetails.name}.pdf`);
      setLoading(false);
      update(<></>);
    }
  }, [instance.loading])

  return (
    <div>
      {
        loading ? <Spin /> :
          <div onClick ={handleDownload} style={{ cursor: 'pointer', height: '1rem' }}>
            <ExportPDFIcon inheritSize fill={PrimaryTheme.primaryGreen}/>
          </div>
      }
    </div>
  )
}