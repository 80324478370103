import React, { useEffect } from 'react'
import BEMenuBar from '../../../Components/BEMenubar'
import { Outlet } from 'react-router-dom'
import './styles.scss'
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector'
import { TopMenubar } from '../TopMenubar'
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { getStage } from '../../Actions/HomeActions';
import { GlobalLoader } from '../../../Components/GlobalLoader'
import { DashboardWelcomeScreen } from '../WelcomeScreens/DashboardWelcomeScreen'
import { setShowWelcomePage } from '../../../Redux/CommonReducer'
import { getCompanyDetails } from '../../Actions/OnboardingActions'
import { handleBackgroundAPICalls } from '../../Actions/HandleBackgroundApiCalls'
import { menubarWidth,menubarWidthCollapsed } from '../../../Config/Constants'

const Layout = () => {
  const dispatch = useAppDispatch()
  const menuCollapsed = useTypedSelector(state => state.common.menuCollapsed)
  const showWelcomeScreen = useTypedSelector(state => state.common.showWelcomePage)
  const companyDetails = useTypedSelector(state => state.companies.companyDetails)
  const [Loading, setLoading] = React.useState(true)
  const role = useTypedSelector((state) => state.user.userRole);

  React.useEffect(() => {
    getStageFun()
  }, []);

  useEffect(() => {
    if(companyDetails?.status === 'success') dispatch(handleBackgroundAPICalls());
  }, [companyDetails])

  const getStageFun = async () => {                          
    if (role === 'SUPER_ADMIN') {                 // if role is super admin then stage should be fetched first which redirects to onboarding if < 5
      setLoading(true)
      dispatch(getCompanyDetails())               // we need company details first to handle year end issue
      dispatch(getStage()).then(() => setLoading(false))
    }
    else{                                         // else we fetch company details first to tackel refresh token issue
      dispatch(getCompanyDetails()).then(() => setLoading(false))
    }
  }

  if (Loading) {
    return <GlobalLoader />
  }
  if(showWelcomeScreen){
    return <DashboardWelcomeScreen
      onClick={() => {
        dispatch(setShowWelcomePage(false))
      }}
    />
  }
  return (
    <div style={{ display: "flex" }}>
      <BEMenuBar />
      <div className='outlet-cnt' style={{
        width: menuCollapsed ? `calc(100vw - ${menubarWidthCollapsed})` : `calc(100vw - ${menubarWidth})`
      }}>
        <TopMenubar />
        <Outlet />
      </div>
    </div>
  );
}

export default Layout