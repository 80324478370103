import React from 'react'
import IconProps from './Interface/IconProps';

const Analytics = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 20}
      viewBox="0 0 20 20"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          d="M11.7167 8.90817L10 8.12484L11.7167 7.3415L12.5 5.62484L13.2834 7.3415L15 8.12484L13.2834 8.90817L12.5 10.6248L11.7167 8.90817ZM3.33337 12.2915L4.11671 10.5748L5.83337 9.7915L4.11671 9.00817L3.33337 7.2915L2.55004 9.00817L0.833374 9.7915L2.55004 10.5748L3.33337 12.2915ZM7.08337 8.12484L7.99171 6.1165L10 5.20817L7.99171 4.29984L7.08337 2.2915L6.17504 4.29984L4.16671 5.20817L6.17504 6.1165L7.08337 8.12484ZM3.75004 17.7082L8.75004 12.6998L12.0834 16.0332L19.1667 8.0665L17.9917 6.8915L12.0834 13.5332L8.75004 10.1998L2.50004 16.4582L3.75004 17.7082Z"
          fill={props.stroke ? props.stroke : "white"}
        />
      </g>
    </svg>
  );
}

export default Analytics