import * as React from 'react';
import { BEFilterableChart, IBEFilterInterface } from '../../../../../Components/BEFilterableChart';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { getDashoardAnalytics } from '../../../Actions';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';

export interface IAnalyticsGraphMetricsProps {
}

export function AnalyticsGraphMetrics(props: IAnalyticsGraphMetricsProps) {

    const metrics = useTypedSelector(state => state.onBoarding.metrics.directMetrics);
    const derivedMetrics = useTypedSelector(state => state.onBoarding.metrics.derivedMetrics);
    const businessUnits = useTypedSelector(state => state.entity.BusinessUnits);
    const [subjectFilter, setSubjectFilter] = React.useState<IBEFilterInterface>();
    const [rightFilters, setRightFilters] = React.useState<IBEFilterInterface[]>();
    const dispatch = useAppDispatch();

    const timeLineOptions = [
        {
            label: '3 months',
            value: 1
        },
        {
            label: '6 months',
            value: 2
        },
        {
            label: '1 year',
            value: 3
        },
        {
            label: '2 years',
            value: 4
        },
        {
            label: '5 years',
            value: 5
        }
    ];


    React.useEffect(() => {
        const optionsList = [...metrics.data, ...derivedMetrics.data].map(metric => {
            return {
                label: metric.title,
                value: metric.bcode
            }
        });
        const subjectFilter = {
            label: 'Metrics',
            options: optionsList,
            multiSelect: true,
            defaultOption: optionsList[0]
        };
        setSubjectFilter(subjectFilter);

        let businessUnitsOptions = businessUnits.data.map(bu => {
            return {
                label: bu.name,
                value: bu.id
            }
        });

        businessUnitsOptions.unshift({
            label: 'All',
            value: 'all'
        });

        const buFilter = {
            label: 'Business Units',
            options: businessUnitsOptions,
            defaultOption: businessUnitsOptions[0],
            multiSelect: false
        };

        const timeLineFilter = {
            label: 'Timeline',
            options: timeLineOptions,
            defaultOption: timeLineOptions[0],
            multiSelect: false
        }
        setRightFilters([buFilter, timeLineFilter]);
    }, [metrics, businessUnits]);

    const accumulateData = async (
        filterMap: Map<string, string[]>,
    ) => {
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
        const metricBcodes = filterMap.get('Metrics') || [];
        let businessUnitsFilterValue = filterMap.get('Business Units') || [];
        const timeLine = filterMap.get('Timeline') || [];
        const timeLineValue = parseInt(timeLine[0]);
        let startDate = '';
        let endDate = '';

        let xAxisLabels: any[] = [];

        switch (timeLineValue) {
            case 1:
                startDate = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString();
                endDate = new Date().toISOString();
                xAxisLabels = Array.from({ length: 3 }, (_, i) => {
                    const date = new Date(new Date().setMonth(currentMonth - i));
                    return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
                }).reverse();
                break;
            case 2:
                startDate = new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString();
                endDate = new Date().toISOString();
                xAxisLabels = Array.from({ length: 6 }, (_, i) => {
                    const date = new Date(new Date().setMonth(currentMonth - i));
                    return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
                }).reverse();
                break;
            case 3:
                startDate = new Date(new Date().setFullYear(currentYear - 1)).toISOString();
                endDate = new Date().toISOString();
                xAxisLabels = Array.from({ length: 12 }, (_, i) => {
                    const date = new Date(new Date().setMonth(currentMonth - i));
                    return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
                }).reverse();
                break;
            case 4:
                startDate = new Date(new Date().setFullYear(currentYear - 2)).toISOString();
                endDate = new Date().toISOString();
                xAxisLabels = Array.from({ length: 24 }, (_, i) => {
                    const date = new Date(new Date().setMonth(currentMonth - i));
                    return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
                }).reverse();
                break;
            case 5:
                startDate = new Date(new Date().setFullYear(currentYear - 5)).toISOString();
                endDate = new Date().toISOString();
                xAxisLabels = Array.from({ length: 60 }, (_, i) => {
                    const date = new Date(new Date().setMonth(currentMonth - i));
                    return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
                }).reverse();
                break;
        }

        if (
            metricBcodes.length === 0 ||
            businessUnitsFilterValue.length === 0 ||
            startDate === '' ||
            endDate === ''
        ) {
            return;
        }



        const rawData = await dispatch(getDashoardAnalytics(
            metricBcodes,
            businessUnitsFilterValue?.includes('all') ?
            businessUnits.data.map(bu => parseInt(bu.id)) :
            businessUnitsFilterValue.map(bu => parseInt(bu)),
            startDate,
            endDate
        ));

        // Accumulate data on the basis of xAxisLabels
        const accumulatedData = xAxisLabels.map(label => {
            const [monthStr, yearStr] = label.split(' ');
            const month = new Date(`${monthStr} 1, ${yearStr}`).getMonth() + 1; // month in rawData is 1-indexed
            const year = parseInt(yearStr);

            const dataPoint: any = { name: label };
            metricBcodes.forEach(metric => {
                const matchingData = rawData.filter((data: any) =>
                    data.bcode === metric &&
                    data.year === year &&
                    data.month === month
                );
                dataPoint[[...metrics.data, ...derivedMetrics.data].find(m => m.bcode === metric)?.title as string] = matchingData.reduce((sum: number, curr: any) => sum + curr.total_value, 0);
            });
            return dataPoint;
        });
        
        return accumulatedData;
    };


    return (
        <div>
            <BEFilterableChart
                handleFilterChange={accumulateData}
                rightFilters={rightFilters as IBEFilterInterface[]}
                subjectFilter={subjectFilter as IBEFilterInterface}
                // title='Metrics Analytics'
            />
        </div>
    );
}
