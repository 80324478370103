import React from "react";
import { BEDrawer } from "../../../../../../../../../Components/BEDrawer";
import { BEButton } from "../../../../../../../../../Components/BEFormItems/BEButton";
import BulkUploadStep1 from "./Step1";
import BulkUploadStep2 from "./Step2";
import { useTypedSelector } from "../../../../../../../../../Config/Hooks/useTypedSelector";
import { getMyMetricsData, getMyMetricsDataBulkUploadFiles, myMetricDataBulkUpload } from "../../../../../../../Actions";
import { useAppDispatch } from "../../../../../../../../../Config/Hooks/useAppDispatch";
import { checkCeleryTaskStatusPeriodically, getCeleryTaskResult } from "../../../../../../../../../views/Actions/HomeActions";
import { BEMessage } from "../../../../../../../../../Components/BEMessage";

interface AddBulkDataStepsProps {
	open: boolean;
	setOpen: Function;
	my_metric_id: number;
}
export default function AddBulkDataSteps(props: AddBulkDataStepsProps) {
	const [step, setStep] = React.useState<number>(1);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [cancelLoading, setCancelLoading] = React.useState<boolean>(false);
	const [displaySaveButton, setDisplaySaveButton] = React.useState<boolean>(true);
	const step1Ref = React.useRef<any>({ onButtonClick: () => { } });
	const step2Ref = React.useRef<any>({ onButtonClick: () => { } });
	const currentMyMetricDataBulkUploadFile = useTypedSelector(state => state.dataManager.currentMyMetricDataBulkUploadFileId);
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		setStep(1);
	}, [props.open]);

	return <>
		<BEDrawer heading="Bulk Upload" width="100%" open={props.open} setOpen={props.setOpen}
			footer={
				<>

					{displaySaveButton && <BEButton className='primary' size='large' loading={loading}
						onClick={async () => {
							setLoading(true);
							if (step === 1) {
								if (step1Ref?.current?.onButtonClick)
									await step1Ref.current.onButtonClick();
							} else if (step === 2) {
								if (step2Ref?.current?.onButtonClick) {
									await step2Ref.current.onButtonClick();
								}
							}
							setLoading(false);
						}}
					>
						Save and continue
					</BEButton>}
					<BEButton
						loading={cancelLoading}
						size='large'
						onClick={async () => {
							if (currentMyMetricDataBulkUploadFile) {
								setCancelLoading(true);
								const res = await dispatch(myMetricDataBulkUpload(props.my_metric_id, 3, null, null, currentMyMetricDataBulkUploadFile));
							}
							props.setOpen(false);
						}}
					>
						Cancel
					</BEButton>
				</>
			}
		>
			{step === 1 && <BulkUploadStep1 ref={step1Ref} setStep={setStep} my_metric_id={props.my_metric_id} />}
			{step === 2 && <BulkUploadStep2 ref={step2Ref} setStep={setStep} my_metric_id={props.my_metric_id} setOpen={props.setOpen} setDisplaySaveButton={setDisplaySaveButton}/>}
		</BEDrawer>
	</>
}