import * as React from 'react';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import MicrosoftImage from '../../../../assets/images/Login/MICROSOFT.svg'
import { onMicrosoftLogin } from '../../../Actions/LoginActions';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';

export interface IMicrosoftLoginProps {
}

export function MicrosoftLogin (props: IMicrosoftLoginProps) {
  const dispatch = useAppDispatch();
  return (
    <div>
      <BEButton  size='large'
        icon={
            <img src={MicrosoftImage} style={{height:'1rem'}}/>
        }
        className='okta-btn'
        
        onClick={()=> dispatch(onMicrosoftLogin)}
      >
        
      </BEButton>
    </div>
  );
}
