import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import { ChangeDateFormat } from "../../../Config/Functions/UsefullFunctions";
import { setLogs } from "../../../Redux/SettingsReducer";
import { AppDispatch, RootState } from "../../../Redux/store";
import exportToCSV from "../../../Utils/Download/Excell/ExportExcell";
import { GET_LOGS } from "../../../Utils/Routes/SettingsRouts";


export const getLogs = () => async (dispatch: AppDispatch) => {
    dispatch(setLogs({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_LOGS());
    if (data) {
        dispatch(setLogs({
            status: "success",
            data: data?.data
        }));
    }
    else {
        console.log(error);
        dispatch(setLogs({
            status: "error",
            data: []
        }));
    }
}

export const PlatformDataDownload = (section: number, currentYear: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    let data: any[] = [];
    let fileName = "";
    const directMetricsData = getState().onBoarding.metrics.myMetricsData;
    const derivedMetricsData = getState().onBoarding.metrics.myDerivedMetricsDataByMonthAndBusinessUnit;
    const allFullMyDerivedMetrics = getState().onBoarding.metrics.myDerivedMetrics;
    const derivedMetrics = getState().onBoarding.metrics.derivedMetrics;
    const businessUnits = getState().entity.BusinessUnits;
    const businessGroups = getState().entity.BusinessGroups;
    const allSurveys = getState().matiriality.AllSurveys;
    const allStakeHolders = getState().matiriality.globalStakeholders;
    const supllierAssessments = getState().supplier.SuppliersSurveys;
    const Suppliers = getState().supplier.Suppliers;
    const allReports = getState().reporting.allReports;
    const allUsers = getState().userMgmt;
    const templates = getState().reporting.TemplateDrafts;

    const getFrameworkFromTemplate = (id: number) => {
        return templates?.data?.find((item: any) => item.id === id)?.framework
    }

    if (section === 1) {
        fileName = "Data_Manager_Data";
        const combinedData: any[] = [...directMetricsData?.data, ...derivedMetricsData?.data];
        data = combinedData.map((item: any) => {
            return {
                "Metric Name": item?.hasOwnProperty('my_derived_metric') ? derivedMetrics?.data?.find((dmat) => dmat.id === allFullMyDerivedMetrics?.data?.find((met) => met.id === item.my_derived_metric)?.derived_metric)?.title : item.metric_title,
                "Business Unit": item?.hasOwnProperty('my_derived_metric') ? businessUnits?.data?.find((bu) => bu.id === item.business_unit) : item.business_unit_name,
                "Year": item.year,
                "Month": item?.hasOwnProperty('my_derived_metric') ? item.month : item.date_of_entry.split("-")[1],
                "Value": item.value,
                // "Bcode": item.bcode,
                "Timestamp": item?.hasOwnProperty('my_derived_metric') ? ChangeDateFormat(item.last_updated) : item.date_of_entry,
            }
        });
    } else if (section === 2) {
        // Data Manager(Tracker)
    } else if (section === 3) {
        fileName = "Entity_Manager(Business_groups)_Data";
        data = businessGroups?.data.map((item) => {
            return {
                "Business Group Name": item.name,
                "Created By": item.created_by,
            }
        });
    } else if (section === 4) {
        fileName = "Entity_Manager(Business_Units)_Data";
        data = businessUnits?.data.map((item) => {
            return {
                "Business Group Name": item.name,
                "Address": item.address,
                "Owner": item.owner_name,
                "Is Headquarter": item.is_headquarter ? "Yes" : "No"
            }
        });
    } else if (section === 5) {
        // Targets
    } else if (section === 6) {
        // Limits
    } else if (section === 7) {
        fileName = "Reporting_Data";
        data = allReports?.data.map((item) => {
            const user = allUsers?.data?.find((item: any) => item.id === item.reviewer);
            return {
                "Title": item.name,
                "Progress": (item.progress)?.toFixed(2) + "%",
                "Status": item.stage === 1 ? "Not Started" : item.stage === 2 ? "In Progress" : item.stage === 3 ? "Completed" : item.stage === 4 ? "Sent for Review" : item.stage === 5 ? "Approved" : "Rejected",
                "Framework": getFrameworkFromTemplate(item.template),
                "Owner": user?.first_name + " " + user?.last_name,
                "Published": item.published_on && item.stage >= 3 ? ChangeDateFormat(item.published_on) : '-'
            }
        });
    } else if (section === 8) {
        fileName = "Materiality_Data";
        data = allSurveys?.data.map((item) => {
            return {
                "Title": item.title,
                "Description": item.description,
                "Deadline": ChangeDateFormat(item.deadline),
                "Status": item.topicsChosen ? 'Published' : 'Draft',
                "Responses": item.total_stakeholders_invited === 0 ? "-" : (item.stakeholders_survey_completed + "/" + item.total_stakeholders_invited)
            }
        });
    } else if (section === 9) {
        fileName = "Stakeholders_Data";
        data = allStakeHolders?.data.map((item) => {
            return {
                "Name": item.name,
                "Email": item.email,
                "Role": item.role,
                "Category": item.category,
                "Type": item.internalExternal ? "External" : "Internal"
            }
        });
    } else if (section === 10) {
        fileName = "Supplier_Assessment_Data";
        data = supllierAssessments?.data.map((item) => {
            return {
                "Title": item.title,
                "Reporting Year": item.reporting_year,
                "Type": item.type,
                "Number of Suppliers": item.suppliers,
                "Score": item.suppliers_with_status_done === 0 ? "-" : item.suppliers_with_status_done?.toFixed(2) + "%",
                "Completion Percentage": item.suppliers === 0 ? "-" : ((item.suppliers_with_status_done / item.suppliers) * 100).toFixed(2) + "%",
                "Risk classification": item.suppliers_with_status_done === 0 ? "-" : item.suppliers_with_status_done < 50 ? "Low" : item.suppliers_with_status_done < 80 ? "Medium" : "High"
            }
        });
    } else if (section === 11) {
        fileName = "Suppliers_Data";
        data = Suppliers?.data.map((item) => {
            return {
                "Name": item.name,
                "POC": item.poc,
                "Email": item.email,
                "Industry": item.industry,
                "Last Compliance FY": item.last_compliance_fy
            }
        });
    }
    console.log(data);
    exportToCSV(data, fileName);
}
