import React from "react";
import ArrowDownIcon from "../../../../Components/BEIcons/ArrowDownIcon";
import { useNavigate } from "react-router-dom";
import './style.scss';

type SettingsBackButtonProps = {
  title: string;
  style?: React.CSSProperties;
};

const SettingsBackButton = (props: SettingsBackButtonProps) => {
  const navigate = useNavigate();
  return (
    <div
    className="settings-back-button"
      style={props.style}
      onClick={() => {
        navigate(-1);
      }}
    >
      <div className="back-btn">
        <ArrowDownIcon inheritSize />
      </div>
      <p>{props.title}</p>
    </div>
  );
};

export default SettingsBackButton;