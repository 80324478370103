import React, { useEffect } from "react";
import '../styles.scss'
import { Avatar, Dropdown, Menu, MenuProps, Timeline } from "antd";
import UsersIcon from "../../BEIcons/UsersIcon";
import FilterIcon from "../../BEIcons/FilterIcon";
import { PrimaryTheme } from "../../../Config/Theme/theames";
import { useTypedSelector } from "../../../Config/Hooks/useTypedSelector";
import { ChangeDateFormat } from "../../../Config/Functions/UsefullFunctions";
import ToolTip from "../../BEToolTip";
import BEMultipleSelect from "../../BEMultipleSelect";
import { BEButton } from "../../BEFormItems/BEButton";


interface ActivityActionsProps{
	activityActions:any[]
}


export default function ActivityActions(props:ActivityActionsProps){
	const [items,setItems]=React.useState<any[]>([]);
	const allUsers = useTypedSelector(state => state.userMgmt.data);
	const userInfo = JSON.parse(localStorage.getItem("userInfo") || '{}');
	const [selectedFilter, setSelectedFilter] = React.useState<string | null>(null);
	const [filterOptions, setFilterOptions] = React.useState<any[]>([])
	const [menuItems, setMenuItems] = React.useState<MenuProps['items']>()
	function getTimeDifference(timestamp: string) {
		const providedDate = new Date(timestamp);
		const currentDate = new Date();
	
		const differenceInMs = currentDate.getTime() - providedDate.getTime();
		const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
	
		if (differenceInDays > 2) {
			return ChangeDateFormat(String(providedDate),false) // "dd/MM/yyyy" format
		} else if (differenceInDays === 2) {
			return '2 days ago';
		} else if (differenceInDays === 1) {
			return '1 day ago';
		}
	
		const differenceInHours = Math.floor(differenceInMs / (1000 * 60 * 60));
		if (differenceInHours > 0) {
			return `${differenceInHours} hours ago`;
		}
	
		const differenceInMinutes = Math.floor(differenceInMs / (1000 * 60));
		if (differenceInMinutes > 0) {
			return `${differenceInMinutes} minutes ago`;
		}
	
		const differenceInSeconds = Math.floor(differenceInMs / 1000);
		return `${differenceInSeconds} seconds ago`;
	}
	const handleMenuClick = (e: any) => {
		if(selectedFilter == e.key || e.key == 'all'){
			setSelectedFilter(null);	
		}else{
			setSelectedFilter(e.key);
		}
  	};

	useEffect(() => {
		setFilterOptions([
			{ label: 'All', value: null},
			{ label: 'Updates', value: 'Updated' },
			{ label: 'Invite', value: 'Invited' },
			{ label: 'My Activity', value: userInfo?.id }]
		)
	},[userInfo.id])

	useEffect(() => {
		setMenuItems(filterOptions.map((option)=>({
			key: option.value !==null ? option.value : 'all' ,
			label:option.label,
			style: option.value == selectedFilter ? { backgroundColor:PrimaryTheme.primaryGreenLight } : {},
			onClick: handleMenuClick
		})))
	},[filterOptions,selectedFilter])

	useEffect(() => {
		const items=props.activityActions.filter((item)=>{
			if(!selectedFilter) return item;
			if (selectedFilter == userInfo?.id) {
				return item.activity_by == selectedFilter 
			  } else {
				return item.activity.includes(selectedFilter) 
			  }
		}).sort((a:any,b:any) => new Date(a.timestamp).valueOf()- new Date(b.timestamp).valueOf()).map((action)=>{
			return {
				dot:<ToolTip title={allUsers.find((user) => user.id === action.activity_by)?.email}>
						<Avatar src={allUsers.find((user) => user.id === action.activity_by)?.profile_pic || <UsersIcon inheritSize fill={PrimaryTheme.primaryGreyDark}/>}/>
					</ToolTip>,
				children:<>
					<p>{action.activity}</p>
					<p style={{fontSize:'0.6rem',color:PrimaryTheme.primaryGray}}>{getTimeDifference(action.timestamp)}</p>
				</>
			}
		})
		setItems(items)
	},[props.activityActions,selectedFilter])
	

	return <div className="actions">
		<div className="actions-header">
			<h2>Actions</h2>
			<div className="filter">
				<Dropdown placement="bottomRight" menu={{items:menuItems}}  trigger={['click']}>
					<div style={{right:0}}>
						<FilterIcon /> 
						{selectedFilter && <div className="filter-count">
							  1
						</div>}
					</div>
        		</Dropdown>
			</div>
		</div>
		<div className="actions-timeline">
			<Timeline  items={items}/>
		</div>
	</div>
}