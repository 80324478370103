import * as React from 'react';
import { BETabButton } from '../../../../Components/BETabButton';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import UsersIcon from '../../../../Components/BEIcons/UsersIcon';
import DataEntryIcon from '../../../../Components/BEIcons/DataEntryIcon';
import './style.scss';
import { useLocation } from 'react-router-dom';
import { Select } from 'antd';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { setAssessmentYear } from '../../../../Redux/MatirialityReducer';
import { navigateTo } from '../../../../Navigation/NavigationManager';
import dayjs from 'dayjs';
import { BENotify } from '../../../../Components/BENotify';
import { YearSelectOptions } from '../../../../Components/BEMultipleSelect/YearSelectOptions';
import { setDateSlicerYearDM } from '../../../../Redux/DataManagerReducer';

export interface ITopSectionTargetsProps {
}

export function TopSectionTargets(props: ITopSectionTargetsProps) {
  const location = useLocation()
  const assessmentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const dispatch = useAppDispatch()
  // const [activeTab, setActiveTab] = React.useState(0);
  const yearEnd = useTypedSelector(state => state.companies.yearEnd);


  // React.useEffect(() => {
    // if (location.pathname === '/home/targets/single-year') setActiveTab(0)
    // else setActiveTab(1)
  // }, [location])
  return (
    <div className="top-section">
      <BETabButton
        title="Targets"
        icon={
          <DataEntryIcon
            // stroke={
            //   activeTab === 0
            //     ? PrimaryTheme.primaryGreen
            //     : PrimaryTheme.primaryGray
            // }
            stroke={PrimaryTheme.primaryGreen}
          />
        }
        // active={activeTab === 0}
        active={location.pathname === '/home/targets'}
        onClick={() => {
          navigateTo("/home/targets");
        }}
      />
      {/* <BETabButton
        title="MULTI YEAR"
        icon={
          <UsersIcon
            fill={
              activeTab === 1
                ? PrimaryTheme.primaryGreen
                : PrimaryTheme.primaryGray
            }
          />
        }
        active={activeTab === 1}
        onClick={() => {
          navigateTo("/home/targets/multi-year");
        }}
      /> */}
      <div
        className="year"
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "auto",
          gap: "0.5rem",
        }}
      >

            <YearSelectOptions onChange={(value:any) => {dispatch(setDateSlicerYearDM(value))
                BENotify.success(`${value}`, `“You will now be viewing data for FY${value}.`, () => { }, false);
              }} value={assessmentYear}/>

      </div>
    </div>
  );
}
