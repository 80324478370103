import React from 'react'
import IconProps from './Interface/IconProps';

const LabelIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 14}
      height={props.inheritSize ? "100%" : 10}
      viewBox="0 0 14 10"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M10.42 0.893008C10.18 0.553008 9.78002 0.333008 9.33335 0.333008L2.00002 0.339675C1.26669 0.339675 0.666687 0.933008 0.666687 1.66634V8.33301C0.666687 9.06634 1.26669 9.65967 2.00002 9.65967L9.33335 9.66634C9.78002 9.66634 10.18 9.44634 10.42 9.10634L13.3334 4.99967L10.42 0.893008ZM9.33335 8.33301H2.00002V1.66634H9.33335L11.7 4.99967L9.33335 8.33301Z" fill={props.fill ? props.fill : "white"}/>
      </svg>
      
  );
}

export default LabelIcon