import React from 'react'
import { BETabButton } from '../../../../Components/BETabButton';
import { navigateTo } from '../../../../Navigation/NavigationManager';
import Dashboard from '../../../../Components/BEIcons/Dashboard';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { useLocation } from 'react-router-dom';
import { MonthsSlicer } from '../../../DataManager/Components/TopSectionDM/MonthsSlicer';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import Analytics from '../../../../Components/BEIcons/Analytics';

const TopSection = () => {
  // const dispatch = useAppDispatch();
  const location = useLocation();
  const CompanyID = useTypedSelector((state) => state.companies.companyDetails?.data?.id);

  return (
    <div className="global-top-section">
      <BETabButton
        title="TRENDS"
        icon={
          <Dashboard
            stroke={
              // PrimaryTheme.primaryGreen
              !location.pathname.includes("analytics")
                ? PrimaryTheme.primaryGreen
                : PrimaryTheme.primaryGray
            }
          />
        }
        active={!location.pathname.includes("analytics")}
        onClick={() => navigateTo("/home/dashboard")}
      />
      <BETabButton
        title="ANALYTICS"
        icon={
          <Analytics
            stroke={
              location.pathname.includes("analytics")
                ? PrimaryTheme.primaryGreen
                : PrimaryTheme.primaryGray
            }
          />
        }
        active={location.pathname.includes("analytics")}
        onClick={() => navigateTo("/home/dashboard/analytics")}
      />
      <div style={{ marginLeft: 'auto' }}><MonthsSlicer /></div>
    </div>
  );
}

export default TopSection