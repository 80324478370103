import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import {
   GET_VAULT_FILES,
   POST_VAULT_FILES,
   DELETE_VAULT_FILES,
   GET_VAULT_FOLDERS,
    POST_VAULT_FOLDERS,
    DELETE_VAULT_FOLDERS,
    PATCH_VAULT_FILES,
    PATCH_VAULT_FOLDERS,
    GET_SUPPLIER_EVIDENCE,
   } from "../../../Utils/Routes/VaultRouts";
import { 
    setVaultFIles,
    setVaultFolders,
    setSupplierEvidence,
 } from "../../../Redux/VaultReducer";
import { BEMessage } from "../../../Components/BEMessage";


export const getVaultFiles = () => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(GET_VAULT_FILES());
    if (res) {
      dispatch(setVaultFIles(res));
    }
    else {
      BEMessage.error("Couldn't get files");
      console.log(error);
    }
  }
}

export const postVaultFiles = (data:any) => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(POST_VAULT_FILES(data));
    if (res) {
      dispatch(getVaultFiles());
      BEMessage.success("File uploaded successfully");
    }
    else {
      BEMessage.error("Couldn't upload file");
      console.log(error);
    }
  }
}

export const patchVaultFiles = (id: any, data:any) => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(PATCH_VAULT_FILES(id, data));
    if (res) {
      dispatch(getVaultFiles());
      BEMessage.success("File Edited successfully");
    }
    else {
      BEMessage.error("Couldn't edit file");
      console.log(error);
    }
  }
}

export const deleteVaultFiles = (id:any) => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(DELETE_VAULT_FILES(id));
    if (res) {
      dispatch(getVaultFiles());
      BEMessage.success("File deleted successfully");
    }
    else {
      BEMessage.error("Couldn't delete file");
      console.log(error);
    }
  }
}


export const getVaultFolders = () => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(GET_VAULT_FOLDERS());
    if (res) {
      dispatch(setVaultFolders(res));
    }
    else {
      BEMessage.error("Couldn't get folders");
      console.log(error);
    }
  }
}

export const postVaultFolders = (data:any) => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(POST_VAULT_FOLDERS(data));
    if (res) {
      dispatch(getVaultFolders());
      BEMessage.success("Folder created successfully");
    }
    else {
      BEMessage.error("Couldn't create folder");
      console.log(error);
    }
  }
}

export const patchVaultFolders = (id: any, data:any) => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(PATCH_VAULT_FOLDERS(id, data));
    if (res) {
      dispatch(getVaultFolders());
      BEMessage.success("Folder Edited successfully");
    }
    else {
      BEMessage.error("Couldn't edit folder");
      console.log(error);
    }
  }
}

export const deleteVaultFolders = (id:any) => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(DELETE_VAULT_FOLDERS(id));
    if (res) {
      dispatch(getVaultFolders());
      BEMessage.success("Folder deleted successfully");
    }
    else {
      BEMessage.error("Couldn't delete folder");
      console.log(error);
    }
  }
}

export const getSupplierEvidence = () => {
  return async (dispatch: any) => {
    dispatch(setSupplierEvidence({status:'loading', data:[]}));
    const [res, error] = await handleAPICall(GET_SUPPLIER_EVIDENCE());
    if (res) {
      dispatch(setSupplierEvidence(
        {
          status:'success',
          data:res.data
        }));
    }
    else {
      BEMessage.error("Couldn't get supplier evidence");
      console.log(error);
    }
  }
}