import React from 'react'
import IconProps from './Interface/IconProps'

const LimitsIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      viewBox="0 0 28 28"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.426 4.57464L21.546 6.45464C23.466 8.38797 24.666 11.0546 24.666 14.0013C24.666 19.8946 19.8927 24.668 13.9993 24.668C8.10602 24.668 3.33268 19.8946 3.33268 14.0013C3.33268 8.5613 7.39935 4.0813 12.666 3.42797V6.1213C8.87935 6.7613 5.99935 10.0413 5.99935 14.0013C5.99935 18.4146 9.58602 22.0013 13.9993 22.0013C18.4127 22.0013 21.9993 18.4146 21.9993 14.0013C21.9993 11.788 21.106 9.78797 19.6527 8.34797L17.7727 10.228C18.7327 11.2013 19.3327 12.5346 19.3327 14.0013C19.3327 16.948 16.946 19.3346 13.9993 19.3346C11.0527 19.3346 8.66602 16.948 8.66602 14.0013C8.66602 11.5213 10.3727 9.45464 12.666 8.85464V11.708C11.866 12.1746 11.3327 13.0146 11.3327 14.0013C11.3327 15.468 12.5327 16.668 13.9993 16.668C15.466 16.668 16.666 15.468 16.666 14.0013C16.666 13.0146 16.1327 12.1613 15.3327 11.708V0.667969H13.9993C6.63935 0.667969 0.666016 6.6413 0.666016 14.0013C0.666016 21.3613 6.63935 27.3346 13.9993 27.3346C21.3594 27.3346 27.3327 21.3613 27.3327 14.0013C27.3327 10.3213 25.8393 6.98797 23.426 4.57464Z"
        fill={props.fill ? props.fill : "#333333"}
      />
    </svg>
  );
}

export default LimitsIcon